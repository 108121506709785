import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOcvTicket } from '../actions-raw-ticket/getRawOcvTicket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTicketTurnConversation } from '../actions-ticket-chat/getTicketTurnConversation'
import { getTicketLastTurnMessageId } from '../actions-ticket-chat/getTicketLastTurnMessageId'

export function getTicketResponseMarkdown(context: IDashStoreContext, ticketId: string): PromiseSnapshot<string> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.responseMarkdown
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.responseMarkdown = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<string> {
    if (context.enableV2Endpoint) {
      return doFetchFromV2()
    }

    try {
      return await fetchFromConversation()
    } catch (odsError) {
      try {
        return await fetchFromOCV()
      } catch (ocvError) {
        throw odsError
      }
    }
  }

  async function doFetchFromV2(): Promise<string> {
    await assertTicketHasUserConsent(context, ticketId)

    // 1. Get value
    const raw = await getRawV2Ticket(context, ticketId).promise
    const response = raw.diagnosticContext?.chat?.[0]?.response?.text
    if (response && response.trim()) {
      return response
    }

    throw TicketError.create('NoResponse', { ticketId })
  }

  async function fetchFromConversation(): Promise<string> {
    await assertTicketHasUserConsent(context, ticketId)

    const turnId = await getTicketLastTurnMessageId(context, ticketId).promise
    const turn = await getTicketTurnConversation(context, ticketId, turnId).promise
    const response = turn.response?.content.markdown ?? turn.response?.content.text
    if (response) {
      return response
    }

    throw TicketError.create('NoResponse', { ticketId })
  }

  async function fetchFromOCV(): Promise<string> {
    const ocvTicket = await getRawOcvTicket(context, ticketId).promise
    const response = ocvTicket.AiContext?.ResponseMessage
    if (response) {
      return response
    }

    throw TicketError.create('NoResponse', { ticketId })
  }
}
