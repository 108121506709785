import { TimeType } from '@copilot-dash/core'
import { tokens } from '@fluentui/react-components'

import { ReactElement } from 'react'
import { TimeViewTooltipContent } from './TimeViewTooltipContent'
import { FloatingTooltip, FloatingTooltipContent, FloatingTooltipTrigger } from '../FloatingTooltip'

interface IProps {
  readonly value: TimeType
  readonly children: ReactElement
}

export function TimeViewTooltip({ value, children }: IProps) {
  return (
    <FloatingTooltip>
      <FloatingTooltipTrigger>{children}</FloatingTooltipTrigger>
      <FloatingTooltipContent arrowcolor={tokens.colorNeutralBackground3}>
        <TimeViewTooltipContent value={value} />
      </FloatingTooltipContent>
    </FloatingTooltip>
  )
}
