import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  dismissButton: {
    color: tokens.colorNeutralForeground1,
    height: tokens.lineHeightBase100,
  },
  tagStyle: {
    cursor: 'pointer',
    borderRadius: '4px',
    margin: '0px 8px 4px 0px ',
    maxWidth: '100%',
  },
  textStyle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
  selectButton: {
    textAlign: 'left',
    justifyContent: 'left',
    fontWeight: tokens.fontWeightRegular,
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    },
  },
})
