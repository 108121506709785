import { Column, Row } from '../../../../components/Layout'
import { Spinner, makeStyles } from '@fluentui/react-components'

import { ConversationLayoutForData } from './ConversationLayoutForData'
import { ErrorView } from '../../../../components/Error'

export function ConversationPanel() {
  const styles = useStyles()
  const manifestSnapshot = app.store.use().feedbackCopilot.allFCConversations

  return (
    <div className={styles.root}>
      <Column>
        {(() => {
          if (manifestSnapshot) {
            switch (manifestSnapshot.status) {
              case 'waiting':
                return (
                  <Row className={styles.waiting} vAlign="center" hAlign="center">
                    <Spinner label="Loading..." />
                  </Row>
                )
              case 'error':
                return <ErrorView error={manifestSnapshot.error} />
              case 'done':
                return <ConversationLayoutForData data={manifestSnapshot.data} />
              default:
                return null
            }
          }
          return null
        })()}
      </Column>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    overflowY: 'auto', // Enable vertical scrolling
    width: '800px',
    margin: '0 auto', // Center the container
  },
  waiting: {
    minHeight: '300px',
  },
})
