import { I3sLogData, I3sLogDataItem } from '@copilot-dash/domain'
import { makeStyles, Tree, TreeItem, TreeItemLayout } from '@fluentui/react-components'
import { TreeItemErrorView } from './common/TreeItemErrorView'
import { TreeItemJsonView } from './common/TreeItemJsonView'
import { TreeItemLoadingView } from './common/TreeItemLoadingView'
import { TreeItemNoGroundingDataPermission } from './common/TreeItemNoGroundingDataPermission'
import { TreeItemTitle } from './common/TreeItemTitle'

interface IProps {
  readonly data: I3sLogData
  readonly item: I3sLogDataItem
}

export function SssLogViewTreeItemOnlineGrounding({ data, item }: IProps) {
  const styles = useStyles()

  if (!app.auth.permission?.advanced) {
    return (
      <TreeItem itemType="branch">
        <TreeItemTitle type="onlineGrounding" />
        <Tree>
          <TreeItem itemType="leaf">
            <TreeItemLayout className={styles.content}>
              <TreeItemNoGroundingDataPermission />
            </TreeItemLayout>
          </TreeItem>
        </Tree>
      </TreeItem>
    )
  }

  return <TransactionTreeItemOnlineGroundingContent data={data} item={item} />
}

function TransactionTreeItemOnlineGroundingContent({ data, item }: IProps) {
  const styles = useStyles()
  const snapshot = app.store.use.getRaw3sOnlineDataGroup4(data.ticketId, data.ticketTurnId)

  return (
    <TreeItem itemType="branch">
      <TreeItemTitle type="onlineGrounding" snapshot={snapshot} />
      <Tree>
        <TreeItem itemType="leaf">
          <TreeItemLayout className={styles.content}>
            {(() => {
              switch (snapshot.status) {
                case 'waiting':
                  return <TreeItemLoadingView />
                case 'done':
                  return <TreeItemJsonView data={snapshot.data} />
                case 'error':
                  return <TreeItemErrorView error={snapshot.error} />
              }
            })()}
          </TreeItemLayout>
        </TreeItem>
      </Tree>
    </TreeItem>
  )
}

const useStyles = makeStyles({
  content: {
    width: '100%',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
})
