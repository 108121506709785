import { ApiOdsInteractionInfos } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { convertToRawTicketInteractions } from './converters/convertToRawTicketInteractions'
import { getRawOdsTicket } from './getRawOdsTicket'
import { TicketError } from '@copilot-dash/error'

export function getRawOdsTicketInteractions(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ApiOdsInteractionInfos[]> {
  return context.getOrFetch<ApiOdsInteractionInfos[]>({
    get: (state) => {
      return state.tickets[ticketId]?.rawOdsInteractions
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.rawOdsInteractions = snapshot
    },
    fetch: async () => {
      const raw = await getRawOdsTicket(context, ticketId).promise
      const interactions = convertToRawTicketInteractions(raw)

      // There is a special case where the ODS API returns a single interaction without a message id
      if (interactions.length === 1 && !interactions[0]?.MessageId) {
        const statusCode = interactions[0]?.Diagnostic?.Conversation?.StatusCode
        if (statusCode) {
          const error = TicketError.diagnostic(statusCode, ticketId)
          if (error) {
            throw error
          }
        }

        throw TicketError.create('NoConversations', { ticketId })
      }

      return interactions
    },
  })
}
