import { FEEDBACK_INSIGHTS_SUPPORT_PRODUCT_NAME_LIST, INewTicketData } from '@copilot-dash/domain'
import { SelectTabData, SelectTabEvent, Tab, TabList } from '@fluentui/react-components'
import { memo, useMemo } from 'react'
import { Body, Column, Header, Row, Spacer } from '../../../components/Layout'
import { useSearchScreenState } from '../store'
import { useStyles } from './SearchScreenBody.styles'
import { FeedbackInsightsLayout } from './feedbackInsights'
import { AllFeedback } from './allFeedback/AllFeedback'
import { GlobalProductProvider } from '../../../providers/product'
import { ColDef } from 'ag-grid-community'

interface IProps {
  readonly selectedColumns: ColDef<INewTicketData>[]
  readonly selectedValue: unknown
  readonly onTabSelect: (event: SelectTabEvent, data: SelectTabData) => void
}

export const SearchScreenBody = memo(function SearchScreenBody({
  selectedColumns,
  selectedValue,
  onTabSelect,
}: IProps) {
  const styles = useStyles()
  const searchSnapshot = useSearchScreenState((state) => state.loadMoreSnapshot)
  const searchFeedbackResult = useSearchScreenState((state) => state.searchFeedbackResult)
  const productName = GlobalProductProvider.useCurrenProduct()
  const enableFeedbackInsights = productName && FEEDBACK_INSIGHTS_SUPPORT_PRODUCT_NAME_LIST.includes(productName)

  const ticketCount = useMemo(() => {
    switch (searchSnapshot[0]?.status) {
      case 'none':
        return '⚪'
      case 'waiting':
        return '⏳'
      case 'done':
        return searchFeedbackResult.total.toLocaleString() ?? 'No data'
      case 'error':
        return 0
      default:
        return null
    }
  }, [searchFeedbackResult.total, searchSnapshot])

  return (
    <Column className={styles.container}>
      {/* Header */}
      <Spacer height="20px" />
      <Header className={styles.header}>
        <Row>
          {/* Left */}
          <Spacer width="10px" />
          <TabList selectedValue={selectedValue} size="medium" onTabSelect={onTabSelect}>
            <Tab value="allFeedback">All Feedback ({ticketCount})</Tab>
            {enableFeedbackInsights && <Tab value="feedbackInsights">Feedback Insights</Tab>}
          </TabList>
          <Spacer />
        </Row>
      </Header>

      {/* Body */}
      <Body className={styles.body}>
        {selectedValue === 'allFeedback' && <AllFeedback selectedColumns={selectedColumns} />}
        {selectedValue === 'feedbackInsights' && <FeedbackInsightsLayout />}
      </Body>
    </Column>
  )
})
