import { Header, Row, Spacer } from '../../../components/Layout'
import { Image, Text, tokens } from '@fluentui/react-components'

import FeedbackCopilot from '../../../../assets/FeedbackCopilot.svg'
import { useStyles } from './FeedbackCopilotHeader.styles'

export function FeedbackCopilotHeader() {
  const styles = useStyles()

  return (
    <Header>
      <Row hAlign="start" vAlign="center" className={styles.header}>
        <Spacer width="12px" />
        <Image src={FeedbackCopilot} alt={'Feedback Copilot'} />
        <Spacer width="6px" />
        <Text wrap={false} weight="semibold" size={400} color={tokens.colorNeutralForeground1}>
          Feedback Copilot
        </Text>
      </Row>
    </Header>
  )
}
