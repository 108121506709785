import { ColumnIdNameMapping, ColumnKey } from '@copilot-dash/domain'
import { Divider, DrawerBody, Text, Button, mergeClasses } from '@fluentui/react-components'
import { Add16Regular, Dismiss16Regular, ReOrderDotsVertical16Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { Column, Spacer } from '../../Layout'
import { useStyles } from './ColumnSettingPanelBody.styles'
import { useScrollbar } from '../../Scrollbar/useScrollbar'

interface IProps {
  readonly NonRemovableColumns: ColumnKey[]
  readonly AllSupportedColumns: ColumnKey[]
  readonly selectedColumns: ColumnKey[]
  readonly setSelectedColumns: (columns: ColumnKey[]) => void
}

export function ColumnSettingPanelBody({
  NonRemovableColumns,
  AllSupportedColumns,
  selectedColumns,
  setSelectedColumns,
}: IProps) {
  const styles = useStyles()
  const [draggingItem, setDraggingItem] = useState<number | null>(null)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const scrollbarRef = useScrollbar()
  const removeColumn = (columnId: ColumnKey) => {
    if (NonRemovableColumns.includes(columnId)) return
    const newColumns = [...selectedColumns]
    const columns = newColumns.filter((col) => col !== columnId)
    setSelectedColumns(columns)
  }

  const addColumn = (columnId: ColumnKey) => {
    const newColumns = [...selectedColumns]
    const columns = newColumns.concat(columnId)
    setSelectedColumns(columns)
  }

  const onDragStart = (index: number) => {
    setDraggingItem(index)
  }

  const onDragOver = (index: number) => {
    if (draggingItem === index || draggingItem === null || index === null) return
    const newColumns = [...selectedColumns]
    const itemToMove = newColumns.splice(draggingItem, 1)[0]

    if (itemToMove) {
      newColumns.splice(index, 0, itemToMove)
      setDraggingItem(index)
      setSelectedColumns(newColumns)
    }
  }

  const onDragEnd = () => {
    setDraggingItem(null)
  }

  return (
    <DrawerBody ref={scrollbarRef}>
      <Divider />
      <Column className={styles.headerRight}>
        <Spacer height="12px" />
        <Column className={styles.flexTextLayout}>
          <Text>
            Change and add columns here. To change the column order, drag and drop a field. Choose a filter to change
            column type.
          </Text>
        </Column>
        <Column className={styles.flexTextLayout}>
          <Text weight="bold"> Displayed Columns</Text>
          {selectedColumns.map((column, index) => (
            <div
              key={column}
              draggable
              onDragStart={() => onDragStart(index)}
              onDragOver={() => onDragOver(index)}
              onDragEnd={onDragEnd}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              className={mergeClasses(styles.rowItemConfigLayout, styles.supportMove)}
            >
              <div className={styles.borderTextLayout}>
                <Text weight="medium"> {ColumnIdNameMapping[column]}</Text>
              </div>
              <div>
                <Button
                  size="small"
                  appearance="transparent"
                  onClick={() => removeColumn(column)}
                  icon={
                    <Dismiss16Regular
                      fontSize={18}
                      className={
                        NonRemovableColumns.includes(column)
                          ? styles.disabledDismiss16RegularLayout
                          : styles.dismiss16RegularLayout
                      }
                    />
                  }
                ></Button>
                <Button
                  appearance="transparent"
                  size="small"
                  icon={
                    <ReOrderDotsVertical16Regular
                      fontSize={18}
                      className={
                        hoveredIndex === index
                          ? styles.hoverReOrderDotsVertical16RegularLayout
                          : styles.whiteReOrderDotsVertical16RegularLayout
                      }
                    />
                  }
                ></Button>
              </div>
            </div>
          ))}
        </Column>

        <Column className={styles.flexTextLayout}>
          <Text weight="bold">Add a new column</Text>
          {AllSupportedColumns.filter((column: ColumnKey) => !selectedColumns.map((item) => item).includes(column)).map(
            (column: ColumnKey) => (
              <div key={column} className={styles.rowItemConfigLayout}>
                <div className={styles.borderTextLayout}>
                  <Text weight="medium">{ColumnIdNameMapping[column]} </Text>
                </div>

                <Button
                  appearance="transparent"
                  onClick={() => addColumn(column)}
                  icon={<Add16Regular fontSize={18} className={styles.add16RegularLayout} />}
                  size="small"
                />
                <Button
                  appearance="transparent"
                  onClick={() => addColumn(column)}
                  icon={
                    <ReOrderDotsVertical16Regular
                      fontSize={18}
                      className={styles.whiteReOrderDotsVertical16RegularLayout}
                    />
                  }
                  size="small"
                />
              </div>
            ),
          )}
        </Column>
      </Column>
    </DrawerBody>
  )
}
