import { makeStyles, mergeClasses } from '@fluentui/react-components'
// import 'ag-grid-community/styles/ag-grid.css'
// import 'ag-grid-community/styles/ag-theme-quartz.min.css'
import { AgGridReact } from 'ag-grid-react'
import { useThemeMode } from '../../theme/useThemeMode'
import { AnyPropertyItem } from './AnyPropertyType'
import { NameColumn } from './columns/name/NameColumn'
import { NameColumnView } from './columns/name/NameColumnView'
import { ValueColumn } from './columns/value/ValueColumn'
import { ValueColumnView } from './columns/value/ValueColumnView'

const THEME_CLASS_LIGHT = 'ag-theme-quartz'
const THEME_CLASS_DARK = 'ag-theme-quartz-dark'

const COMPONENTS: Record<string, unknown> = {
  nameRenderer: NameColumnView,
  valueRenderer: ValueColumnView,
}

interface IProps {
  readonly items: AnyPropertyItem[]
}

export function AnyPropertyViewContent({ items }: IProps) {
  const styles = useStyles()
  const themeMode = useThemeMode()
  const themeClass = themeMode === 'dark' ? THEME_CLASS_DARK : THEME_CLASS_LIGHT

  return (
    <AgGridReact
      className={mergeClasses(themeClass, styles.root)}
      rowData={items}
      columnDefs={[NameColumn, ValueColumn]}
      components={COMPONENTS}
      rowSelection={{
        mode: 'multiRow',
        headerCheckbox: false,
        checkboxes: false,
        enableClickSelection: false,
      }}
      headerHeight={0}
      readOnlyEdit={false}
      cacheQuickFilter={true}
    />
  )
}

const useStyles = makeStyles({
  root: {
    '& .ag-root-wrapper': {
      borderRadius: '0',
      border: 'none',
    },

    '& .ag-row-hover': {
      backgroundColor: 'transparent',
    },
  },
})
