import { memo, useState } from 'react'
import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components'
import { Column, Row, Spacer } from '../../../../../../../components/Layout'
import { AssignedTeamDoughnutChart } from './AssignedTeamDoughnutChart'
import { SwitchButton } from '../../../../../../../components/SwitchButton/SwitchButton'
import { LegendItem } from '../../../../../../../components/RatioProgressBar/Legend'
import { IssueDetailDataCard } from '../components/IssueDetailDataCard'

export interface AssignedTeamProps {
  loading: boolean
  teamsWithColors: Array<{
    count: number
    legendItem: LegendItem | undefined
    teamId: number
  }>
}

export const AssignedTeam = memo(function AssignedTeam(props: AssignedTeamProps) {
  const styles = useStyles()
  const [selected, setSelected] = useState<'left' | 'right'>('left')
  const toggleSwitch = (side: 'left' | 'right') => {
    setSelected(side)
  }
  const teams = props.teamsWithColors.map((item) => ({
    name: item.legendItem?.label || 'Unknown label',
    count: item.count,
    teamId: item.teamId,
  }))

  const backgroundColors = props.teamsWithColors
    .map((item) => item.legendItem?.color)
    .filter((color): color is string => color !== undefined)

  return (
    <Column className={styles.container}>
      <Row vAlign="center">
        <Text className={styles.title}>Assigned team</Text>
        <Spacer />
        <SwitchButton selected={selected} onToggle={toggleSwitch} labels={['Distribution', 'Data']} />
      </Row>
      <Spacer height={6} />
      {selected === 'left' ? (
        <AssignedTeamDoughnutChart loading={props.loading} teams={teams} backgroundColors={backgroundColors} />
      ) : (
        <IssueDetailDataCard loading={props.loading} dataList={props.teamsWithColors} />
      )}
    </Column>
  )
})

const useStyles = makeStyles({
  container: {
    display: 'flex',
    maxHeight: '90%',
    ...shorthands.padding('20px', '16px'),
  },
  title: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    marginLeft: tokens.spacingHorizontalSNudge,
  },
})
