import { Spinner } from '@fluentui/react-components'
import { ErrorView } from '../../Error'
import { Row } from '../../Layout'
import { TicketCallFlowStore } from '../TicketCallFlowStore'
import { TicketCallFlowLayoutForData } from './TicketCallFlowLayoutForData'

export function TicketCallFlowLayout() {
  const ticketId = TicketCallFlowStore.use((state) => state.ticketId)
  const snapshot = app.store.use.getTicketMessageIds(ticketId)

  switch (snapshot.status) {
    case 'waiting':
      return (
        <Row vAlign="center" hAlign="center" style={{ minHeight: 300 }}>
          <Spinner label="Loading..." />
        </Row>
      )
    case 'error':
      return <ErrorView error={snapshot.error} />
    case 'done':
      return <TicketCallFlowLayoutForData messageIds={snapshot.data} />
  }
}
