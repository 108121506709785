import { PromiseSnapshots } from '@copilot-dash/core'
import { IQuery, IQueryUpdatePrams } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { QueryDataConverter } from './converters/QueryDataConverter'

export async function updateQuery(context: IDashStoreContext, params: IQueryUpdatePrams): Promise<IQuery> {
  return context.requestAnd({
    request: async () => {
      const data = QueryDataConverter.updateDataToApi(params)
      const response = await context.api.logCollector.updateQuery(data)
      return QueryDataConverter.fromUpdateOrGetQueryApi(response)
    },
    onSuccess: (state, data) => {
      const productId = data.productId
      const teamId = data.teamId
      const key = `${productId}-${teamId}`
      const queryId = data.queryId

      // update queriesMap
      state.query.QueriesMap[queryId] = PromiseSnapshots.done(data)

      const myQueriesMapSnapshot = state.query.myQueriesMap[productId]
      const teamQueriesMapSnapshot = state.query.teamQueriesMap[key]
      let updateTeamQueries: IQuery[] = []
      let updateMyQueries: IQuery[] = []

      // update teamQueriesMapSnapshot
      if (data.isTeamShared) {
        if (teamQueriesMapSnapshot) {
          switch (teamQueriesMapSnapshot.status) {
            case 'error':
              state.query.teamQueriesMap[key] = PromiseSnapshots.done([data])
              break
            case 'waiting':
              break
            case 'done':
              updateTeamQueries = updateQueriesMap(teamQueriesMapSnapshot.data, data)
              state.query.teamQueriesMap[key] = PromiseSnapshots.done(updateTeamQueries)
              break
          }
        }
      }
      // update myQueriesMapSnapshot
      else {
        if (myQueriesMapSnapshot) {
          switch (myQueriesMapSnapshot.status) {
            case 'error':
              state.query.myQueriesMap[productId] = PromiseSnapshots.done([data])
              break
            case 'waiting':
              break
            case 'done':
              updateMyQueries = updateQueriesMap(myQueriesMapSnapshot.data, data)
              state.query.myQueriesMap[productId] = PromiseSnapshots.done(updateMyQueries)
              break
          }
        }
      }
    },
  })
}

function updateQueriesMap(existingQueries: IQuery[], newQuery: IQuery): IQuery[] {
  // Update or append the new query
  const updatedQueries = existingQueries.map((existingQuery) =>
    existingQuery.queryId === newQuery.queryId ? newQuery : existingQuery,
  )

  // If the query does not exist, append it
  if (!existingQueries.some((existingQuery) => existingQuery.queryId === newQuery.queryId)) {
    updatedQueries.push(newQuery)
  }

  // Sort queries by lastModifiedTime
  return updatedQueries.sort((a, b) => new Date(b.lastModifiedTime).getTime() - new Date(a.lastModifiedTime).getTime())
}
