import { Column } from '../../../Layout'
import { CopilotDashCheckBox } from '../../../Filter/CopilotDashCheckBox'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { Label } from '@fluentui/react-components'
import { memo } from 'react'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (item: string[]) => void
}

export const DefaultHasErrorMessagesfilterOptions: IFilterOption[] = [
  {
    key: 'SWW|UserEncounteredError',
    text: 'Yes',
  },
  {
    key: '!SWW&!UserEncounteredError',
    text: 'No',
  },
]

export const HasErrorMessagesFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  return (
    <Column>
      <Label weight="semibold">Has Error Messages:</Label>
      <CopilotDashCheckBox
        options={DefaultHasErrorMessagesfilterOptions}
        selectedOptions={selectedValues}
        onChange={onChangeValue}
        multiple={false}
      />
    </Column>
  )
})
HasErrorMessagesFilter.displayName = 'HasErrorMessagesFilter'
