import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  body: {
    width: '100%',
  },
  framerContainer: {
    height: '100%',
  },
  framerPanel: {
    width: '100%',
    height: '100%',
  },
  right: {
    boxShadow: '0px 0px 16px rgba(0, 0, 0, .14)',
    height: '100%',
    borderRadius: '10px 10px 0 0',
    marginTop: '20px',
    marginRight: '20px',
    '& .fui-Drawer': {
      transitionDuration: '0s',
    },
  },
})
