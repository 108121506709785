import { INewTicketData, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { CustomCellRendererProps } from 'ag-grid-react'

export const ColumnConfigEmotionType: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.EmotionType],
  field: String(ColumnKey.EmotionType) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        <Text style={{ color: props.data.thumbFeedback === 'Negative' ? 'red' : 'green' }}>
          {props.data.thumbFeedback}
        </Text>
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles truncate>{ColumnIdNameMapping[ColumnKey.EmotionType]}</TableCellLayoutWithStyles>
    ),
  },
  minWidth: 100,
  width: 140,
  sortable: true,
  resizable: true,
}
