import { PlatformType, ProductPlatform } from '@copilot-dash/domain'
import { compact, includes, sortBy, uniq } from 'lodash'

import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { useMemo } from 'react'
import { useProductIdEndpoints } from '@copilot-dash/store'

interface IProps {
  productId: number
  applications: string[]
  licenses: string[]
  selectedValues: string[] | undefined
  onChangeValue: (endpoints: string[]) => void
}

export const PlatformFilter: React.FC<IProps> = ({
  productId,
  applications,
  licenses,
  selectedValues,
  onChangeValue,
}) => {
  let productEndpoints = useProductIdEndpoints({ productId }).data

  productEndpoints =
    applications.length === 0
      ? productEndpoints
      : productEndpoints?.filter((endpoint) => includes(applications, endpoint.application))

  productEndpoints =
    licenses.length === 0
      ? productEndpoints
      : productEndpoints?.filter((endpoint) => includes(licenses, endpoint.license))

  const platformList = useMemo(() => {
    const items = uniq(compact(productEndpoints?.map((endpoint) => endpoint.platform)))

    return sortBy(items, (item) => sortPlatform(item)).map((platform) => ({
      key: platform,
      text: platform,
    }))
  }, [productEndpoints])

  return (
    <>
      <CopilotDashMultiSelectDropdown
        comboId={'combo-platformFilter'}
        filterType={'Platforms'}
        isGroup={true}
        optionsList={platformList}
        defaultSelectedOption={selectedValues}
        onChangeFilter={onChangeValue}
      />
    </>
  )
}

function sortPlatform(item: PlatformType): number {
  return ProductPlatform.indexOf(item)
}
