import { IState } from './IState'

interface IParams {
  readonly ticketId: string
  readonly selectedMessageId?: string
  readonly selectedEventId?: string
}

export function createState({ ticketId, selectedMessageId, selectedEventId }: IParams): IState {
  return {
    ticketId,
    selectedMessageId: selectedMessageId,
    selectedEventId,
    selectedServiceIds: [],
  }
}
