import { ITelemetryMetric } from '@copilot-dash/domain'
import { LockOpenRegular } from '@fluentui/react-icons'

interface IProps {
  readonly metric: ITelemetryMetric
}

export function MetricCardHeaderGroup4Indicator({ metric }: IProps) {
  if (!metric.fromGroup4) {
    return null
  }

  return <LockOpenRegular />
}
