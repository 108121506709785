import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components'

import { Dismiss24Regular } from '@fluentui/react-icons'
import { TeamDRIFilter } from './TeamDRIFilter'

interface ITeamDRIDialogProps {
  open: boolean
  onClose: () => void
  teamDRIList: string[]
  selectedTeamDRIList: string[]
  setSelectedTeamDRIList: (list: string[]) => void
}

export const TeamDRIDialog = ({
  open,
  onClose,
  teamDRIList,
  selectedTeamDRIList,
  setSelectedTeamDRIList,
}: ITeamDRIDialogProps) => {
  return (
    <Dialog
      open={open}
      onOpenChange={(_, data) => {
        if (!data.open) {
          onClose()
        }
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle
            action={
              <DialogTrigger action="close">
                <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
              </DialogTrigger>
            }
          >
            Team DRI
          </DialogTitle>
          <DialogContent>
            <TeamDRIFilter selectedTeamDRIList={selectedTeamDRIList} setSelectedTeamDRIList={setSelectedTeamDRIList} />
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
