import { ApiAISearchResponse } from '@copilot-dash/api'
import { PromiseSnapshots } from '@copilot-dash/core'
import { EnableExceptionTracking } from '@copilot-dash/logger'
import { z } from 'zod'
import { IDashStoreContext } from '../../IDashStoreContext'
import { AiSearchQueryBuilder } from './AiSearchQueryBuilder'
import { NewSearchCopilotTicketsResult } from './NewSearchTicketsAction.types'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { AiTicketConverter } from './converters/AiTicketConverter'

export class SearchTicketIdByAISearchAction {
  private readonly context: IDashStoreContext

  constructor(context: IDashStoreContext) {
    this.context = context
  }

  @EnableExceptionTracking()
  async search(options: ISearchTicketOptions): Promise<NewSearchCopilotTicketsResult> {
    const aiSearchQueryBuilder = new AiSearchQueryBuilder()
    const { searchQuery, filterQuery } = aiSearchQueryBuilder.buildQuery(options)
    const searchPrams = {
      query: searchQuery ? searchQuery : undefined,
    }
    const searchBody = {
      filter: filterQuery,
      skip: options.offset,
      size: options.count,
      orderBy:
        options.order && options.order.length > 0
          ? AiTicketConverter.postApiOrder(options.order)
          : ['createDateTime desc'],
    }

    const response = await this.context.requestAnd<ApiAISearchResponse>({
      request: async () => {
        return await this.context.api.copilotDash.getAISearchTicket(searchPrams, searchBody)
      },
      onSuccess: (state, data) => {
        data.searchItems.forEach((item) => {
          const ticket = (state.tickets[item.id] ??= {})

          if (item.utterance) {
            ticket.utteranceText = PromiseSnapshots.done(item.utterance)
          }

          if (item.response) {
            ticket.responseMarkdown = PromiseSnapshots.done(item.response)
          }

          if (item.emailAddress && z.string().email().safeParse(item.emailAddress).success) {
            ticket.email = PromiseSnapshots.done(item.emailAddress)
          }

          if (item.verbatim) {
            ticket.verbatim = PromiseSnapshots.done(item.verbatim)
          }

          if (item.customTags) {
            ticket.customTags = PromiseSnapshots.done(item.customTags)
          }

          if (item.hasUserConsent !== undefined) {
            ticket.hasUserConsent = PromiseSnapshots.done(item.hasUserConsent)
          }
        })
      },
    })

    return AiTicketConverter.fromApi(response)
  }
}
