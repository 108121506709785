import { makeStyles } from '@fluentui/react-components'
import { AnimatedTabBody } from '../../../AnimatedTabBody/AnimatedTabBody'
import { AnyDataStore } from '../../AnyDataStore'
import { AnyDataTab } from '../../AnyDataTypes'
import { AnyJson } from './Json/AnyJson'
import { AnyProperty } from './property/AnyProperty'
import { AnyTable } from './table/AnyTable'

export function AnyDataBody() {
  const styles = useStyles()
  const tabs = AnyDataStore.use((state) => state.tabs)
  const activeTab = AnyDataStore.use((state) => state.selectedTab)
  const activeTabIndex = tabs.indexOf(activeTab)

  return (
    <AnimatedTabBody className={styles.root} tabs={tabs} activeTabIndex={activeTabIndex}>
      {(tab) => {
        switch (tab) {
          case AnyDataTab.Table:
            return <AnyTable />
          case AnyDataTab.Json:
            return <AnyJson />
          case AnyDataTab.Property:
            return <AnyProperty />
        }
      }}
    </AnimatedTabBody>
  )
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    flex: 1,
    zIndex: 0,
  },
})
