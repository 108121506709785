import { Row, Spacer } from '../../../../components/Layout'
import { Skeleton, SkeletonItem, Text, makeStyles } from '@fluentui/react-components'

import { ErrorView } from '../../../../components/Error'
import { RouteLink } from '../../../../router'
import { Scrollbar } from '../../../../components/Scrollbar/Scrollbar'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketScreenVerbatim() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const verbatimSnapshot = app.store.use.getTicketVerbatim(ticketId)

  const metadataSnapshot = app.store.use.getTicketMetadata(ticketId)
  const screenshotCount = metadataSnapshot.data?.screenshotCount
  const ocvLink = metadataSnapshot.data?.ocvLink

  return (
    <Row className={styles.root} hAlign="stretch">
      <Scrollbar className={styles.scrollbar}>
        {(() => {
          switch (verbatimSnapshot.status) {
            case 'waiting':
              return (
                <Skeleton appearance="translucent">
                  <SkeletonItem />
                  <Spacer height={8} />
                  <SkeletonItem />
                </Skeleton>
              )
            case 'done':
              return (
                <Text>
                  <b>Verbatim: </b>
                  <span>
                    {verbatimSnapshot.data}
                    {!!screenshotCount && screenshotCount > 0 && !!ocvLink && (
                      <RouteLink className={styles.link} path={ocvLink} openInNewTab>
                        View screenshots
                      </RouteLink>
                    )}
                  </span>
                </Text>
              )
            case 'error':
              return <ErrorView type="inline" error={verbatimSnapshot.error} />
          }
        })()}
      </Scrollbar>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    maxHeight: '64px',
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  scrollbar: {
    width: '100%',
    position: 'relative',
  },
  link: {
    marginLeft: '12px',
    ':hover': {
      textDecoration: 'underline',
    },
  },
})
