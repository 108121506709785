import { ITicketTurnConversation } from '@copilot-dash/domain'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { Row } from '../../Layout'
import { TimeView } from '../../Time/TimeView'

interface IProps {
  readonly data: ITicketTurnConversation
}

export function TurnViewHeader({ data }: IProps) {
  const styles = useStyles()

  return (
    <Row className={styles.root} hAlign="center">
      <TimeView value={data.timestamp} format="MMMM D, YYYY h:mm A" defaultText="Unknown">
        {(text) => {
          return <Text className={styles.text}>{text}</Text>
        }}
      </TimeView>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '8px 16px',
  },
  text: {
    color: tokens.colorNeutralForeground4,
  },
})
