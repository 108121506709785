import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'

const PLACEHOLDER_BACKFILL = `Placeholder for backfill, check OCV tickets for user's description`

export async function assertTicketVerbatimValid(
  context: IDashStoreContext,
  ticketId: string,
  verbatim: string,
): Promise<void> {
  if (verbatim === PLACEHOLDER_BACKFILL) {
    throw TicketError.create('NoVerbatimDueToBackfill', { ticketId })
  }
}
