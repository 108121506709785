import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  tooltip: {
    backgroundColor: tokens.colorNeutralBackground3,
    boxShadow: tokens.shadow2,
  },
  tooltipContent: {
    maxWidth: '400px',
    maxHeight: '300px',
    overflow: 'auto',
    backgroundColor: tokens.colorNeutralBackground3,
    fontSize: tokens.fontSizeBase300,
    lineHeight: tokens.lineHeightBase300,
  },
})
