import { mergeClasses } from '@fluentui/react-components'
import { AdaptiveCard, HostConfig, PaddingDefinition, Spacing } from 'adaptivecards'
import { useEffect, useMemo, useRef } from 'react'
import { setupRootElementDefaultStyles } from '../utils/setupRootElementDefaultStyles'

interface IProps {
  readonly payload: unknown
  readonly className?: string
}

export function AdaptiveCardContent({ payload, className }: IProps): React.ReactNode {
  const rootRef = useRef<HTMLDivElement>(null)
  const instance = useMemo(() => {
    return new AdaptiveCard()
  }, [])

  // Setup configs, styles, etc
  useEffect(() => {
    instance.hostConfig = new HostConfig()
    instance.padding = new PaddingDefinition(Spacing.Large, Spacing.Large, Spacing.Large, Spacing.Large)
  }, [instance])

  // Generate child
  const child = useMemo(() => {
    instance.parse(payload)

    const child = instance.render()
    if (child) {
      setupRootElementDefaultStyles(child)
    }

    return child
  }, [instance, payload])

  // Render
  useEffect(() => {
    const root = rootRef.current
    if (!root) {
      return
    }

    if (root && child) {
      root.innerHTML = ''
      root.appendChild(child)
    }

    return () => {
      root.innerHTML = ''
    }
  }, [child])

  return <div className={mergeClasses('AdaptiveCardContent', className)} ref={rootRef} />
}
