import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { ReactNode } from 'react'
import { Column } from '../Layout'
import { AnimatedTabItem } from './AnimatedTabItem'

interface IProps<T> {
  readonly tabs: T[]
  readonly activeTabIndex: number
  readonly children: (tab: T) => ReactNode
  readonly className?: string
  readonly options?: IOptions
}

interface IOptions {
  readonly duration?: number
  readonly keepAliveDuration?: number
  readonly lazy?: boolean
}

export function AnimatedTabBody<T>({ tabs, activeTabIndex, children, className, options }: IProps<T>) {
  const styles = useStyles()

  return (
    <Column className={mergeClasses('AnimatedTabBody', styles.root, className)} vAlign="stretch" hAlign="stretch" fill>
      <Column
        className={mergeClasses('AnimatedTabBody-wrapper', styles.wrapper)}
        vAlign="stretch"
        hAlign="stretch"
        fill
      >
        {tabs.map((tab, index) => (
          <AnimatedTabItem key={index} tabIndex={index} activeTabIndex={activeTabIndex} options={options}>
            {() => children(tab)}
          </AnimatedTabItem>
        ))}
      </Column>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    flex: 1,
    zIndex: 0,
  },
})
