import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicketInteractions } from '../actions-raw-ticket-ods/getRawOdsTicketInteractions'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { getTicketSystemTags } from '../actions-ticket/getTicketSystemTags'

export async function assertTicket3sTriggered(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): Promise<void> {
  const lastMessageId = context.enableV2Endpoint ? await getLastMessageIdFromV2() : await getLastMessageIdFromV1()
  if (messageId === lastMessageId) {
    const tag = await getTicketSystemTags(context, ticketId).promise
    if (!tag.sssTriggered) {
      throw TicketError.create('No3SDueToNotTriggered', { ticketId, ticketMessageId: messageId })
    }
  }

  async function getLastMessageIdFromV1(): Promise<string | undefined> {
    const interactions = await getRawOdsTicketInteractions(context, ticketId).promise
    const messageId = interactions.find((item) => item.Index === 1)?.MessageId
    if (messageId) {
      return messageId
    }

    return undefined
  }

  async function getLastMessageIdFromV2(): Promise<string | undefined> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    return raw.diagnosticContext?.chat?.[0]?.messageId
  }
}
