import { PromiseSnapshot } from '@copilot-dash/core'
import { IQuery } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { QueryDataConverter } from './converters/QueryDataConverter'

export function getMyQueries(context: IDashStoreContext, productId: number): PromiseSnapshot<IQuery[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.query.myQueriesMap[productId]
    },
    set: (state, snapshot) => {
      state.query.myQueriesMap[productId] = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getMyQueries({
        Count: -1,
        ProductId: productId,
      })
      return QueryDataConverter.fromGetQueriesApi(response).queries
    },
  })
}
