import { ITelemetryMetric } from '@copilot-dash/domain'
import { FullScreenMaximizeRegular } from '@fluentui/react-icons'
import { MouseEventHandler } from 'react'
import { MonacoJsonViewDialog } from '../../../../../../components/Monaco/MonacoJsonViewDialog'

interface IProps {
  readonly metric: ITelemetryMetric
}

export function MetricCardHeaderOpenJson({ metric }: IProps) {
  const handleOnClick: MouseEventHandler = (event) => {
    event.stopPropagation()

    app.dialog.open({
      content: <MonacoJsonViewDialog data={metric.raw} />,
      title: true,
    })
  }

  return <FullScreenMaximizeRegular title="Show raw JSON" onClick={handleOnClick} />
}
