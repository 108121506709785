import { ITicketTurnConversation } from '@copilot-dash/domain'
import { Button, makeStyles } from '@fluentui/react-components'
import { ChevronDownRegular, ChevronUpRegular } from '@fluentui/react-icons'
import { useMemo, useState } from 'react'
import { Column, Row } from '../../../../Layout'
import { InvocationMessageViewContent } from './InvocationMessageViewContent'

interface IProps {
  readonly data: ITicketTurnConversation
}

export function InvocationMessageView({ data }: IProps) {
  const styles = useStyles()
  const [showMoreInvocations, setShowMoreInvocations] = useState(false)

  const invocationRender = useMemo(() => {
    if (data.invocations) {
      const allInvocationRender = data.invocations.map((item, index) => {
        return <InvocationMessageViewContent text={item.text} key={index} />
      })

      const filterInvocationRender = data.invocations.slice(0, 2).map((item, index) => {
        return <InvocationMessageViewContent text={index === 1 ? `${item.text} ...` : item.text} key={index} />
      })

      if (data.invocations.length > 2 && !showMoreInvocations) {
        return filterInvocationRender
      } else {
        return allInvocationRender
      }
    }
    return null
  }, [data.invocations, showMoreInvocations])

  if (!data.invocations || data.invocations.length === 0) {
    return null
  }

  return (
    <Row className={styles.root}>
      <Column className={styles.content} hAlign="stretch">
        <Row className={styles.row}>
          <Column>{invocationRender}</Column>
          {data.invocations && data.invocations.length > 2 && (
            <Button
              icon={showMoreInvocations ? <ChevronUpRegular /> : <ChevronDownRegular />}
              appearance="subtle"
              onClick={() => setShowMoreInvocations(!showMoreInvocations)}
            />
          )}
        </Row>
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '8px 16px',
    gap: '12px',
  },
  content: {
    flex: '1 1 0',
    gap: '8px',
    overflow: 'hidden',
    marginLeft: '35px',
  },
  row: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
})
