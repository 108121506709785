import { I3sLogData, I3sLogDataItem } from '@copilot-dash/domain'
import { Badge, Button, makeStyles, Text, tokens } from '@fluentui/react-components'
import * as React from 'react'
import { getErrorMessage } from '../../../../../../../components/ErrorConfigs/utils/getErrorMessage'
import { getErrorMessageDetails } from '../../../../../../../components/ErrorConfigs/utils/getErrorMessageDetails'
import { Row } from '../../../../../../../components/Layout'
import { RouteLink } from '../../../../../../../router'
import { TicketScreenStore } from '../../../../../TicketScreenStore'

function get3sOfflineDataUrl(ticketId: string, transactionId: string) {
  return `https://3sdash.microsoft.com/searchIssue?traceId=${encodeURIComponent(transactionId)}&offline=true&exTicketIds=${encodeURIComponent(ticketId)}`
}

function get3sOnlineDataUrl(ticketId: string, transactionId: string, turnIndex: number) {
  return `https://3sdash.microsoft.com/searchIssue?traceId=${encodeURIComponent(transactionId)}&replay=true&exTicketIds=${encodeURIComponent(ticketId)}&TurnIndex=${encodeURIComponent(turnIndex)}`
}

interface IProps {
  readonly data: I3sLogData
  readonly item: I3sLogDataItem
  readonly index: number
}

export function SssLogViewTreeTitle({ data, item, index }: IProps) {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const styles = useStyles()
  const isV2 = app.features.v2Ticket.use()

  const onOnlineButtonClicked = React.useCallback(() => {
    Logger.telemetry.trackEvent('TicketScreen/3SReplayButton/Click', {
      ticketId: ticketId,
    })
  }, [ticketId])

  const offlineErrorTooltip = React.useMemo(() => {
    const error = item.offlineDataError
    if (error) {
      return getErrorMessageDetails(error) || getErrorMessage(error)
    }

    return undefined
  }, [item.offlineDataError])

  const onlineErrorTooltip = React.useMemo(() => {
    const error = item.onlineDataError
    if (error) {
      return getErrorMessageDetails(error) || getErrorMessage(error)
    }

    return undefined
  }, [item.onlineDataError])

  const renderOfflineDataButton = () => {
    if (item.hasOfflineData) {
      return (
        <RouteLink
          path={get3sOfflineDataUrl(ticketId, item.transactionId)}
          className={styles.route}
          openInNewTab
          onClick={onOnlineButtonClicked}
        >
          Offline data
        </RouteLink>
      )
    }

    if (item.offlineDataError) {
      return (
        <Button className={styles.routeDisabled} size="small" disabled appearance="primary" title={offlineErrorTooltip}>
          Offline data
        </Button>
      )
    }

    return null
  }

  const renderReplayButton = () => {
    if (item.hasOnlineData) {
      return (
        <RouteLink
          path={get3sOnlineDataUrl(ticketId, item.transactionId, data.ticketTurnIndex)}
          className={styles.route}
          openInNewTab
          onClick={onOnlineButtonClicked}
        >
          Online data
        </RouteLink>
      )
    }

    if (item.onlineDataError) {
      return (
        <Button className={styles.routeDisabled} size="small" disabled appearance="primary" title={onlineErrorTooltip}>
          Online data
        </Button>
      )
    }

    return null
  }

  return (
    <Row className={styles.root} gap="gap.small" vAlign="center">
      <Text weight="semibold">3S Transaction {index + 1}: </Text>

      {/* 2. Label */}
      {item.transactionLabels.map((label, index) => {
        return (
          <Badge key={index} appearance="filled" color="informative">
            {label}
          </Badge>
        )
      })}

      {/* 3. Link */}
      <Text wrap={false}>{item.transactionId}</Text>

      {/* 4. Button */}
      {!isV2 && renderOfflineDataButton()}
      {!isV2 && renderReplayButton()}
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    flexWrap: 'wrap',
    fontSize: '14px',
  },

  route: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    paddingInlineStart: '5px',
    paddingInlineEnd: '5px',
    fontWeight: tokens.fontWeightSemibold,
    fontSize: tokens.fontSizeBase200,
    borderRadius: tokens.borderRadiusMedium,
  },

  routeDisabled: {
    cursor: 'default',
  },
})
