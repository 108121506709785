import { IQuery } from '@copilot-dash/domain'
import { Date } from './Date'
import { Text, tokens } from '@fluentui/react-components'
import { ModifiedBy } from './ModifiedBy'
import { Title } from './Title'
import { stringCompare, dateCompare, userInfoCompare } from '../../RootCauseTable/columns'

export const columnsConfig: Array<{
  id: keyof IQuery
  renderHeaderCell: () => JSX.Element
  renderCell: (data: IQuery) => JSX.Element
  cellStyle?: React.CSSProperties
  onSort?: (a: IQuery, b: IQuery, type: 'ascending' | 'descending') => number
}> = [
  {
    id: 'title',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Title
      </Text>
    ),
    renderCell: (data) => <Title rowData={data} />,
    cellStyle: {
      minWidth: 300,
      flex: '1 1 800px',
    },
    onSort: (a, b, type) => {
      const num = stringCompare(a.title, b.title, type)
      if (num !== 0) return num
      return dateCompare(a.lastModifiedBy, b.lastModifiedBy, type)
    },
  },
  {
    id: 'lastModifiedBy',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Modified By
      </Text>
    ),
    renderCell: (data) => <ModifiedBy rowData={data} />,
    cellStyle: {
      minWidth: 260,
      maxWidth: 260,
      flex: '0 0 180px',
    },
    onSort: (a, b, type) => {
      const aUser = a.lastModifiedBy ? app.store.state.users[a.lastModifiedBy]?.profile : undefined
      const bUser = b.lastModifiedBy ? app.store.state.users[b.lastModifiedBy]?.profile : undefined
      const num = userInfoCompare(aUser, bUser, type)
      if (num !== 0) return num
      return dateCompare(a.lastModifiedBy, b.lastModifiedBy, type)
    },
  },
  {
    id: 'lastModifiedTime',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Date
      </Text>
    ),
    renderCell: (data) => <Date rowData={data} />,
    cellStyle: {
      minWidth: 120,
      maxWidth: 120,
      flex: '0 0 120px',
    },
    onSort: (a, b, type) => {
      const num = dateCompare(a.lastModifiedTime, b.lastModifiedTime, type)
      if (num !== 0) return num

      return stringCompare(a.title, b.title, type)
    },
  },
]
