import { memo } from 'react'
import { IssueDetailCard } from './IssueDetailCard'
import { Column, Spacer } from '../../../../../../../components/Layout'
import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components'

export interface IssueDetailsProps {
  loading: boolean
  priority: number
  DSATsCount: number
  DSATsCoverage: number // 13.01 -> 13.01%
  vipUserIds: string[]
  tenants: Array<{
    tenantId: string
    tenantName: string
  }>
}

export const IssueDetails = memo(function IssueDetails(props: IssueDetailsProps) {
  const styles = useStyles()
  return (
    <Column className={styles.container}>
      <Text className={styles.title}>Issue details</Text>
      <Spacer height={16} />
      <IssueDetailCard
        loading={props.loading}
        priority={props.priority}
        ticketCount={props.DSATsCount}
        coverage={props.DSATsCoverage}
        vipUsers={props.vipUserIds}
        capTenants={props.tenants}
      />
    </Column>
  )
})
const useStyles = makeStyles({
  container: {
    display: 'flex',
    maxHeight: '90%',
    ...shorthands.padding('20px', '16px'),
  },
  title: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    marginLeft: tokens.spacingHorizontalSNudge,
  },
})
