import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  root: {
    padding: '8px 16px',
    backgroundColor: 'transparent',
  },
  text: {
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase300,
    wordBreak: 'break-word',
    padding: '6px 0',
  },
  markdown: {
    color: tokens.colorNeutralForeground1,
    wordBreak: 'break-word',
    marginBottom: '4px',
    display: 'block',
    padding: '6px 0',
    fontSize: tokens.fontSizeBase300,
    '&> p': {
      marginTop: '12px',
      marginBottom: '12px',
      fontSize: tokens.fontSizeBase300,
    },
    '&> h2': {
      margin: '0',
      fontWeight: tokens.fontWeightRegular,
      marginBottom: '12px',
      fontSize: tokens.fontSizeBase300,
    },
    '&> h3': {
      margin: '0',
      fontWeight: tokens.fontWeightSemibold,
      fontSize: tokens.fontSizeBase300,
    },

    '&> pre': {
      whiteSpace: 'pre-wrap',
    },
  },
  ragResultPanel: {
    maxWidth: '800px',
    backgroundColor: 'transparent',
  },
  ragResponseTitle: {
    marginBottom: '4px',
    display: 'block',
    color: tokens.colorNeutralForeground1,
    wordBreak: 'break-word',
    padding: '6px 0',
  },
  ragResultItem: {
    marginBottom: '20px',
    padding: '0', // No background or card styling
    borderRadius: '0',
  },
  ragResultItemTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    color: tokens.colorNeutralForeground1,
  },
  ragResultItemSummary: {
    marginBottom: '20px',
    color: tokens.colorNeutralForeground1,
    fontWeight: tokens.fontWeightRegular,
  },
  ragResultItemButton: {
    marginTop: '20px',
  },
  ragResultItemButtonNoMagin: {
    marginTop: '2px',
  },
  ragResultItemButtonLabel: {
    color: tokens.colorNeutralForeground2,
    border: `1px solid ${tokens.colorNeutralStroke3}`,
    borderRadius: '4px',
    fontWeight: tokens.fontWeightRegular,
    padding: '6px 12px',
    minWidth: 'auto',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&.selected': {
      pointerEvents: 'none',
      backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
      border: 'none',
    },

    '&.selected.outline': {},

    '&.unselected': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },

    '&.unselected:hover': {
      backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
      border: `1px solid ${tokens.colorBrandBackgroundInvertedSelected}`,
    },
  },
})
