import { z } from 'zod'

export const apiConversationGroup3MetricSchema = z.record(z.unknown()).and(
  z.object({
    serviceName: z.string().optional(),
    scopeId: z.string().optional(),
    scopeName: z.string().optional(),
    startTime: z.string().optional(),
    status: z.string().optional(),
    input: z.unknown().optional(),
    output: z.unknown().optional(),
    path: z.string().optional(),
    latencyMilliseconds: z.number().optional(),
    decodedData: z.record(z.unknown()).optional(),
  }),
)

const apiConversationGroup3TelemetrySchema = z.record(z.unknown()).and(
  z.object({
    metrics: z.array(apiConversationGroup3MetricSchema).optional(),
  }),
)

const apiConversationGroup3RequestSchema = z.record(z.unknown()).and(
  z.object({
    telemetry: apiConversationGroup3TelemetrySchema.optional(),
  }),
)

const apiConversationGroup3DataSchema = z.record(z.unknown()).and(
  z.object({
    requests: z.array(apiConversationGroup3RequestSchema).optional(),
  }),
)

export const apiConversationGroup3Schema = z.record(z.unknown()).and(
  z.object({
    conversation: apiConversationGroup3DataSchema.optional(),
  }),
)
