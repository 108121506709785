import { createDashAuth } from '@copilot-dash/auth'
import { createFeatures, createSettings } from '@copilot-dash/settings'
import { createDashStore } from '@copilot-dash/store'
import { createDashDialogController } from '../components/Dialog'
import { createAppRouter } from '../router'
import { createAppApi } from './createAppApi'
import { createAppEnv } from './createAppEnv'
import { createAppLogger } from './createAppLogger'
import { IApp } from './IApp'

export function createApp(): IApp {
  const env = createAppEnv()
  const auth = createDashAuth()
  const settings = createSettings(env)
  const features = createFeatures(env)
  const logger = createAppLogger(env, auth)
  const router = createAppRouter(auth)
  const api = createAppApi(env, auth, settings)
  const store = createDashStore({
    api,
    options: {
      isV2Enabled: () => features.v2Ticket.value,
      isV2DiscussionEnabled: () => features.v2Discussion.value,
      hasTier2: () => !!auth.permission?.advanced,
    },
  })
  const dialog = createDashDialogController()

  return {
    env,
    auth,
    logger,
    api,
    settings,
    features,
    store,
    router,
    dialog,
  }
}
