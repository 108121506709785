import { useFilterOptions } from '@copilot-dash/store'
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderTitle,
} from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { forwardRef, useState, useMemo } from 'react'
import { Column, Row, Spacer } from '../../Layout'
import { FilterTabBody } from '../children/body/FilterTabBody'
import { ITicketFilterFormRef } from '../children/TicketFilterForm/TicketFilterForm.types'
import { ITicketFilterPanel } from '../TicketFilterPanel.types'
import { useStyles } from './TicketsFilterPanelLayout.styles'
import { FilterPanelHeader } from '../children/header/FilterPanelHeader'
import { useTicketFilterStore, HeaderTab } from '../store/store'
import { SharedQueryTab } from '../children/body/SharedQueryTab'
import { MyQueriesTab } from '../children/body/MyQueriesTab'
import { SaveQueryScreen } from '../children/dialog/SaveQueryScreen'
import { Scrollbar } from '../../Scrollbar/Scrollbar'
import { getProductNameById, isProductSupportTeamView } from '@copilot-dash/domain'

export const TicketsFilterPanelLayout = forwardRef<ITicketFilterFormRef, ITicketFilterPanel>(
  (props: ITicketFilterPanel, ref) => {
    const styles = useStyles()
    const filterOptionsSnapshot = useFilterOptions('', '')

    const selectedHeaderTab = useTicketFilterStore((state) => state.selectedHeaderTab)
    const queryChanged = useTicketFilterStore((state) => state.queryChanged)
    const currentQuery = useTicketFilterStore((state) => state.currentQuery)
    const productId = useTicketFilterStore((state) => state.selectedProductId)
    const [saveQueryDialogOpen, setSaveQueryDialogOpen] = useState(false)

    const disableTeamQueries = useMemo((): boolean => {
      const productName = getProductNameById(productId)
      return productName ? !isProductSupportTeamView(productName) : false
    }, [productId])

    return (
      <Drawer
        className={styles.drawer}
        type="inline"
        separator
        open={props.isPanelOpen}
        onOpenChange={props.onDismiss}
        position="end"
      >
        <DrawerHeader className={styles.header}>
          <DrawerHeaderTitle
            className={styles.headerTitle}
            action={
              <Button
                appearance="subtle"
                aria-label="Close Panel"
                icon={<Dismiss24Regular />}
                onClick={props.onDismiss}
              />
            }
          >
            <FilterPanelHeader />
          </DrawerHeaderTitle>
          <Divider />
        </DrawerHeader>

        <DrawerBody className={styles.body}>
          <Scrollbar className={styles.scrollBar}>
            {/* <Column fill> </Column> */}
            {selectedHeaderTab === HeaderTab.Filters ? (
              <FilterTabBody {...props} ref={ref} />
            ) : selectedHeaderTab === HeaderTab.SharedQueries ? (
              <SharedQueryTab />
            ) : (
              <MyQueriesTab />
            )}
          </Scrollbar>
        </DrawerBody>
        {selectedHeaderTab === HeaderTab.Filters && (
          <DrawerFooter className={styles.footer}>
            <Column fill>
              <Divider className={styles.divider} />
              <Row hAlign="end" className={styles.footerRow}>
                <SaveQueryScreen
                  {...props}
                  dialogOpen={saveQueryDialogOpen}
                  setDialogOpen={setSaveQueryDialogOpen}
                  canUpdate={queryChanged && !!currentQuery}
                  type={queryChanged && !!currentQuery ? 'unset' : 'create'}
                  disableTeamSharedQueries={disableTeamQueries}
                />

                <Button
                  disabled={filterOptionsSnapshot.status === 'waiting'}
                  onClick={() => {
                    setSaveQueryDialogOpen(true)
                  }}
                  className={styles.resetButton}
                >
                  Save query
                </Button>

                <Spacer width="8px" />
                <Button
                  onClick={props.onClickReset}
                  disabled={filterOptionsSnapshot.status === 'waiting'}
                  className={styles.resetButton}
                >
                  Reset
                </Button>
              </Row>
            </Column>
          </DrawerFooter>
        )}
      </Drawer>
    )
  },
)
TicketsFilterPanelLayout.displayName = 'TicketsFilterPanelLayout'
