import type { CustomHeaderProps } from 'ag-grid-react'
import { ArrowDownFilled, ArrowUpFilled } from '@fluentui/react-icons'
import { Row, Spacer } from '../Layout'
import { useStyles } from './CustomHeaderComponent.styles'
import { mergeClasses } from '@fluentui/react-components'

export interface CopilotDashCustomHeaderProps extends CustomHeaderProps {
  customHeaderContent: React.ReactNode
}

export const CustomHeader = (props: CopilotDashCustomHeaderProps) => {
  const styles = useStyles()

  const onSortClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    if (props.column.getColDef().sortable) {
      props.progressSort(event.shiftKey)
    }
  }

  return (
    <Row
      vAlign="center"
      onClick={onSortClicked}
      className={props.column.getColDef().sortable ? mergeClasses(styles.container, styles.sortable) : styles.container}
    >
      {props.customHeaderContent} <Spacer width="4px" />
      {props.column.getSort() === 'asc' && <ArrowUpFilled className={styles.arrow} />}
      {props.column.getSort() === 'desc' && <ArrowDownFilled className={styles.arrow} />}
    </Row>
  )
}
