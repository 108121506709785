import { PromiseSnapshots } from '@copilot-dash/core'
import { IComment } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { convertV1toV2Schema } from './converters/convertV1toV2Schema'

export async function updateComment(
  context: IDashStoreContext,
  discussionId: string,
  commentId: string,
  content: string,
): Promise<IComment> {
  return context.requestAnd({
    request: async () => {
      if (!context.enableV2Discussion) {
        const response = await context.api.logCollector.editComments(discussionId, commentId, {
          Content: content,
        })

        return convertV1toV2Schema(response.comment)
      } else {
        const response = await context.api.copilotDash.editComment(discussionId, commentId, {
          Content: content,
        })
        return response
      }
    },
    onSuccess(state, data) {
      const ticket = (state.tickets[discussionId] ??= {})
      const snapshot = ticket.comments

      if (!snapshot) {
        ticket.comments = PromiseSnapshots.done([data])
        return
      }

      switch (snapshot.status) {
        case 'error':
          ticket.comments = PromiseSnapshots.done([data])
          break
        case 'waiting':
          // TODO: In this case, it's really difficult to determine what to do.
          break
        case 'done':
          {
            const index = snapshot.data.findIndex((item) => item.id === data.id)
            if (index === -1) {
              ticket.comments = PromiseSnapshots.done([...snapshot.data, data])
            } else {
              snapshot.data[index] = data
            }
          }
          break
      }
    },
  })
}
