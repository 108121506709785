import { makeStyles } from '@fluentui/react-components'
import { Scrollbar } from '../../../../Scrollbar/Scrollbar'

interface IProps {
  readonly text: string
}

export function InvocationMessageViewContent({ text }: IProps) {
  const styles = useStyles()

  const renderChild = () => {
    return <p className={styles.p}>{text}</p>
  }

  return <Scrollbar className={styles.root}>{renderChild()}</Scrollbar>
}

const useStyles = makeStyles({
  root: {
    padding: '0px 12px',
  },
  p: {
    marginTop: '6px',
    marginBottom: '6px',
    wordBreak: 'break-word',
  },
})
