import { INewTicketData, SearchTextPrefixType, columnKeySchema } from '@copilot-dash/domain'
import { mergeClasses, Text } from '@fluentui/react-components'
import { ChevronRightFilled } from '@fluentui/react-icons'
import { motion } from 'framer-motion'
import { memo, useMemo, useState } from 'react'
import { Column, Row, Spacer } from '../../../../../../../components/Layout'
import { AllSearchTableColumns } from '../../../../../../../components/TicketTableColumnConfigs'
import { useSearchScreenActions, useSearchScreenState } from '../../../../../store'
import { useStyles } from './CollapsibleTable.styles'
import { AGGridTable } from '../../../../../../../components/AGGridTable/AGGridTable'
import { ColDef } from 'ag-grid-community'

interface CollapsibleTableProps {
  tickets: INewTicketData[]
  title: string
  defaultExpanded?: boolean
  onlyShowVIPDSATs?: boolean
  hasMore?: boolean
  loadMore?: (offset: number) => void
  rowHoverStickySlot?: (rowData: INewTicketData) => JSX.Element
}

export const CollapsibleTable = memo(function CollapsibleTable({
  tickets,
  title,
  defaultExpanded = false,
  rowHoverStickySlot,
}: CollapsibleTableProps) {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)
  const [expanded, setExpanded] = useState(defaultExpanded)

  const columns = app.settings.feedbackInsightsTopIssueDetailsColumns.use()
  const colDefs: ColDef<INewTicketData>[] = useMemo(() => {
    return AllSearchTableColumns(SearchTextPrefixType.All).filter((col) => {
      const validation = columnKeySchema.safeParse(col.field)
      return validation.success && columns.includes(validation.data)
    })
  }, [columns])

  const count = tickets.length
  const countStr = count === 0 ? '' : ` (${count})`
  const autoHeight = tickets.length <= 15

  return (
    <Column fill className={styles.container}>
      <Row
        className={styles.header}
        onClick={() => {
          setExpanded((bool) => !bool)
        }}
      >
        <Text weight="semibold" size={400}>
          {title}
          {countStr}
        </Text>
        <Spacer />
        <motion.div className={styles.icon} animate={{ rotate: expanded ? 90 : 0 }}>
          <ChevronRightFilled fontSize={22} />
        </motion.div>
      </Row>
      <motion.div
        layout
        animate={expanded ? 'expanded' : 'collapsed'}
        className={styles.accordionContent}
        variants={{
          collapsed: { height: 0, paddingTop: 0 },
          expanded: { height: 'auto', paddingTop: 16 },
        }}
        initial={false}
      >
        <AGGridTable
          rowData={tickets}
          getRowId={(data) => data.data.ticketId}
          columnDefs={colDefs}
          className={mergeClasses(styles.table, autoHeight ? undefined : styles.fixedHeight)}
          onRowClicked={actions.rowClick}
          focusedRowId={ticketId}
          stickySuffixRenderer={rowHoverStickySlot}
          suppressRowVirtualisation={tickets.length < 50}
          domLayout={autoHeight ? 'autoHeight' : 'normal'}
        />
      </motion.div>
    </Column>
  )
})
