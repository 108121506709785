import { IFCNewQuery, IFCRagResponseResultAnswerItemData, INewTicketData } from '@copilot-dash/domain'
import { listenPromise, uuid } from '@copilot-dash/core'

import { IFeedbackCopilotScreenActions } from './IFeedbackCopilotScreenStoreActions'
import { IFeedbackCopilotScreenState } from './IFeedbackCopilotScreenStoreState'
import { IFeedbackCopilotScreenStore } from './IFeedbackCopilotScreenStore'
import { NewSearchCopilotTicketsResult } from '@copilot-dash/store'
import { TicketRoute } from '../../../router'

export function createFeedbackCopilotScreenActions(store: IFeedbackCopilotScreenStore): IFeedbackCopilotScreenActions {
  return new FeedbackCopilotScreenActions(store)
}
const PAGE_SIZE = 50
class FeedbackCopilotScreenActions implements IFeedbackCopilotScreenActions {
  private readonly store: IFeedbackCopilotScreenStore
  private readonly loadPromise: { [key: number]: Promise<NewSearchCopilotTicketsResult> }

  //private readonly searchLoadMoreTicketsAction: SearchLoadMoreTicketsAction
  //private readonly telemetryAction: FeedbackCopilotScreenTelemetryAction

  constructor(store: IFeedbackCopilotScreenStore) {
    this.store = store

    this.loadPromise = {}

    // this.searchLoadMoreTicketsAction = new SearchLoadMoreTicketsAction(store)
    // this.telemetryAction = new FeedbackCopilotScreenTelemetryAction(store)
  }

  onInit(hasHistory?: boolean) {
    const searchSessionId = uuid()
    this.store.setState({
      hasFCConversation: hasHistory || this.store.state.hasFCConversation,
      searchSessionId: searchSessionId,
    })
  }

  onStateChanged(
    state: IFeedbackCopilotScreenState,
    old: IFeedbackCopilotScreenState,
    isAISearchEnable: boolean,
  ): void {
    // this.telemetryAction.onStateChanged(state, old)
    if (state.clickedRagInfo !== old.clickedRagInfo && state.isRagResultPanelOpen) {
      this.store.setState({
        searchFeedbackResult: {
          tickets: [],
          total: 0,
          hasMore: true,
        },
      })
      this.clearLoadMorePromise()
      this.loadMore(0, isAISearchEnable)
    }
  }

  submit(inputValue: string): void {
    const newConv: IFCNewQuery = {
      messageId: uuid(),
      userQuery: inputValue,
      userId: app.auth.activeAccount?.localAccountId ?? '',
      clientStartTimeUTC: new Date().toISOString(),
    }
    app.store.actions.appendFCConversation(newConv)
    app.store.actions.submitFCRagQuery(newConv)
    this.toggleHasConversation(true)
    this.onDismissRagResultPanel()
  }

  ragResultClick(ragItem: IFCRagResponseResultAnswerItemData): void {
    this.store.setState({
      isRagResultPanelOpen: true,
      clickedRagInfo: ragItem,
    })
  }

  clearLoadMorePromise(): void {
    for (const key in this.loadPromise) {
      delete this.loadPromise[key]
    }
  }

  loadMore(offset: number, isAISearchEnable: boolean): void {
    // this.searchLoadMoreTicketsAction.loadMoreTickets(offset, isAISearchEnable)
    if (this.loadPromise[offset]) return
    const form = this.store.state.clickedRagInfo?.tickets
    const newLoadMorePromisePromise = app.store.actions.searchTickets(
      {
        id: form,
        count: PAGE_SIZE,
        offset: offset,
      },
      isAISearchEnable,
    )

    this.loadPromise[offset] = newLoadMorePromisePromise

    try {
      listenPromise({
        promise: newLoadMorePromisePromise,
        isAlive: () => {
          return newLoadMorePromisePromise === this.loadPromise[offset]
        },
        onUpdated: (snapshot) => {
          this.store.setState({
            loadMoreSnapshot: {
              ...this.store.state.loadMoreSnapshot,
              [offset]: snapshot,
            },
          })
        },
        onSuccess: (snapshot) => {
          const mergedTickets = [
            ...(this.store.state.searchFeedbackResult.tickets || []),
            ...(snapshot.data?.tickets || []),
          ]
          this.store.setState({
            searchFeedbackResult: {
              tickets: mergedTickets,
              total: snapshot.data?.ticketCount || 0,
              hasMore: snapshot.data?.hasMore || false,
            },
          })
        },
      })
    } catch (error) {
      this.store.setState({
        loadMoreSnapshot: {
          ...this.store.state.loadMoreSnapshot,
          [offset]: {
            status: 'error',
            error: error,
          },
        },
      })
    }
  }

  rowClick = (item?: INewTicketData): void => {
    if (!item) {
      this.store.setState({
        clickedTicketInfo: undefined,
        isTicketSummaryPanelOpen: false,
      })
      return
    }

    // Logger.telemetry.trackEvent('Search/ClickTicket', {
    //   filters: this.store.state.form,
    //   searchSessionId: this.store.state.searchSessionId ? this.store.state.searchSessionId : '',
    //   ticketId: item.ticketId,
    // })
    TicketRoute.navigator.navigateToTicketModal({ id: item.ticketId })
    this.store.setState({
      clickedTicketInfo: item,
      isTicketSummaryPanelOpen: true,
    })
  }
  onDismissRagResultPanel = (): void => {
    this.store.setState({
      isRagResultPanelOpen: false,
      clickedRagInfo: undefined,
      isTicketSummaryPanelOpen: true,
      clickedTicketInfo: undefined,
    })
  }

  onDismissTicketSummaryPanel = (): void => {
    this.store.setState({
      isTicketSummaryPanelOpen: false,
      clickedTicketInfo: undefined,
    })
  }

  toggleHasConversation = (result: boolean): void => {
    this.store.setState({
      hasFCConversation: result,
    })
  }
}
