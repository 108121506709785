import { ReactNode } from 'react'

export enum AnyDataTab {
  Table = 'Table',
  Property = 'Property',
  Json = 'JSON',
}

export interface IAnyDataOptions {
  readonly tabs?: AnyDataTab[]
  readonly toolbar?: ReactNode
  readonly table?: IAnyTableOptions
  readonly json?: IAnyJsonOptions
  readonly property?: IAnyPropertyOptions
}

export interface IAnyTableOptions {
  readonly tabName?: string
  readonly tabDescription?: string
  readonly toolbar?: ReactNode
}

export interface IAnyJsonOptions {
  readonly tabName?: string
  readonly tabDescription?: string
  readonly wrap?: boolean
  readonly toolbar?: ReactNode
}

export interface IAnyPropertyOptions {
  readonly tabName?: string
  readonly tabDescription?: string
  readonly toolbar?: ReactNode
}
