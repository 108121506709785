import { createStoreContext, useStore } from '@copilot-dash/core'
import { ReactNode, useEffect } from 'react'
import { IActions } from './store/IActions'
import { IState } from './store/IState'
import { createActions } from './store/createActions'
import { createState } from './store/createState'

interface IProps {
  readonly ticketId: string
  readonly selectedMessageId?: string
  readonly selectedEventId?: string
  readonly onSelect?: (messageId: string, scopeId: string | undefined) => void
  readonly children: ReactNode
}

export function TicketCallFlowStore({ ticketId, selectedMessageId, selectedEventId, onSelect, children }: IProps) {
  const store = useStore<IState, IActions>((set, get) => ({
    state: createState({
      ticketId,
      selectedMessageId: selectedMessageId,
      selectedEventId,
    }),
    actions: createActions({
      set,
      get,
      onSelect: (messageId, scopeId) => {
        onSelect?.(messageId, scopeId)
      },
    }),
  }))

  useEffect(() => {
    store.setState((state) => {
      state.ticketId = ticketId
      state.selectedMessageId = selectedMessageId
      state.selectedEventId = selectedEventId
    })
  }, [store, ticketId, selectedMessageId, selectedEventId])

  return <Context.Provider store={store}>{children}</Context.Provider>
}

const Context = createStoreContext<IState, IActions>()
TicketCallFlowStore.use = Context.use
TicketCallFlowStore.useActions = Context.useActions
