import * as React from 'react'
import { Fragment } from 'react'
import { Button, Body1Strong, makeStyles, tokens, mergeClasses, CounterBadge, Text } from '@fluentui/react-components'
import { Filter16Filled } from '@fluentui/react-icons'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'
import { Row, Spacer } from '../Layout'

interface IProps {
  placeholder?: string
  isTicketsFilterPanelOpen?: boolean
  onClickButton?: () => void
  badgeCount?: number
}

export const TicketsFilterButton: React.FC<IProps> = (props) => {
  const styles = useStyles()

  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Fragment>
          <Body1Strong> Filters </Body1Strong>
        </Fragment>
      }
      toolTipParentContent={
        <div className={styles.filterButton}>
          <Button
            className={mergeClasses(styles.filter, props.isTicketsFilterPanelOpen && styles.activeFilterBtn)}
            size="medium"
            icon={<Filter16Filled className={styles.icon} />}
            onClick={props.onClickButton}
            appearance="subtle"
          >
            <Row vAlign="center">
              <Text className={styles.text}>{props.placeholder}</Text>
              <Spacer size={4} />
              <CounterBadge count={props.badgeCount} className={styles.badge} size="small" />
            </Row>
          </Button>
        </div>
      }
    />
  )
}

const useStyles = makeStyles({
  filterButton: {
    minWidth: 'fit-content',
  },
  filter: {
    paddingInline: '4px',
    '&:hover': {
      backgroundColor: tokens.colorSubtleBackgroundSelected,
    },
  },
  activeFilterBtn: {
    backgroundColor: tokens.colorSubtleBackgroundSelected,
  },
  icon: {
    color: tokens.colorNeutralForeground1,
  },
  text: {
    fontWeight: tokens.fontWeightSemibold,
  },
  badge: {
    backgroundColor: tokens.colorBrandBackgroundInvertedPressed,
    color: tokens.colorNeutralForeground1,
  },
})
