import {
  Button,
  makeStyles,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarTitle,
  tokens,
} from '@fluentui/react-components'
import { RouteLink } from '../../../../../../../../router'

const TITLE = `You don’t have permission to view Grounding Data`
const SUBTITLE = `Please ensure you request permission if you truly need it, as our approval process is quite stringent.`
const LINK_ENTITLEMENT = 'https://coreidentity.microsoft.com/manage/Entitlement/entitlement/copilottier2-mnsk'

export function TreeItemNoGroundingDataPermission() {
  const styles = useStyles()

  return (
    <MessageBar className={styles.root}>
      <MessageBarBody>
        <MessageBarTitle>{TITLE}</MessageBarTitle>
        {SUBTITLE}
      </MessageBarBody>
      <MessageBarActions>
        <RouteLink path={LINK_ENTITLEMENT} openInNewTab>
          <Button className={styles.button}>Request Permission</Button>
        </RouteLink>
      </MessageBarActions>
    </MessageBar>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '12px 0',
  },
  button: {
    fontWeight: tokens.fontWeightSemibold,
  },
})
