import { memo, useCallback, useMemo, useState, type MouseEvent } from 'react'
import { useTeamViewStore } from '../../store'
import { TableLoading } from '../TableLoading'
import { ErrorView } from '../../../../components/Error'
import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbItem,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
} from '@fluentui/react-components'
import { columnsConfig } from './columns'
import { getProductNameById, CopilotDashPath, IQuery } from '@copilot-dash/domain'
import { Row, Column } from '../../../../components/Layout'
import { ToggleLeftDrawerIcon } from '../ToggleDrawerLeftIcon'
import { useStyles } from './SharedQueriesTable.styles'
import { RouteLink, TeamRoute, SearchRoute } from '../../../../router'

export const SharedQueriesView = memo(() => {
  const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
  const productId = useTeamViewStore((state) => state.computed.productId)

  if (typeof teamId === 'number' && typeof productId === 'number') {
    return <SharedQueriesTable teamId={teamId} productId={productId} />
  }
  return null
})

SharedQueriesView.displayName = 'SharedQueriesView'

interface SharedQueriesTableProps {
  teamId: number
  productId: number
}

const SharedQueriesTable = memo(({ teamId, productId }: SharedQueriesTableProps) => {
  const styles = useStyles()
  const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
  const teamName = useTeamViewStore((state) => state.teams.lastSelectedTeam)

  const queriesListSnapshot = application.store.use.getTeamQueries(teamId, productId)

  const [sort, setSort] = useState<{ columnId: string; direction: 'ascending' | 'descending' } | null>({
    columnId: 'lastModifiedTime',
    direction: 'descending',
  })

  const tableItems = useMemo(() => {
    if (queriesListSnapshot && queriesListSnapshot.status === 'done' && queriesListSnapshot.data.length > 0) {
      const full = queriesListSnapshot.data
      const list = [...full]

      if (sort) {
        list.sort((a, b) => {
          const column = columnsConfig.find((c) => c.id === sort.columnId)
          if (column && column.onSort) {
            return column.onSort(a, b, sort.direction)
          }
          return 0
        })
      }
      return list
    }
    return []
  }, [queriesListSnapshot, sort])

  const headerSortProps = useCallback(
    (columnId: string) => {
      return {
        onClick: (e: MouseEvent) => {
          const newSort =
            sort && sort.columnId === columnId
              ? {
                  columnId,
                  direction: sort.direction === 'ascending' ? ('descending' as const) : ('ascending' as const),
                }
              : { columnId, direction: 'ascending' as const }
          setSort(newSort)

          // Log telemetry for sorting
          Logger.telemetry.trackEvent('SavedQueries/Sort', {
            columnId: columnId,
            teamName: teamName ?? undefined,
            productId: productId,
          })
        },
        sortDirection: sort && sort.columnId === columnId ? sort.direction : undefined,
      }
    },
    [sort, teamName, productId],
  )

  const generatePath = useCallback((query: IQuery) => {
    const productName = getProductNameById(query.productId)

    if (!productName) return
    if (query.copilotDashPath === CopilotDashPath.search) {
      return SearchRoute.navigator.generatePath({
        product: productName,
        queryId: query.queryId,
      })
    }
    return TeamRoute.navigator.generatePath({
      product: productName,
      queryId: query.queryId,
    })
  }, [])

  const table = useMemo(() => {
    if (!queriesListSnapshot || queriesListSnapshot.status === 'waiting') {
      return <TableLoading />
    }
    if (queriesListSnapshot.status === 'error') {
      return <ErrorView error={queriesListSnapshot.error} />
    }
    if (queriesListSnapshot.status === 'done') {
      return tableItems.length > 0 ? (
        <Table sortable noNativeElements aria-label="Shared Queries" className={styles.table}>
          <TableHeader>
            <TableRow className={styles.headerRow}>
              {columnsConfig.map((column) => (
                <TableHeaderCell {...headerSortProps(column.id)} key={column.id} style={column.cellStyle}>
                  {column.renderHeaderCell()}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableItems.map((query) => {
              return (
                <RouteLink
                  path={generatePath(query) || ''}
                  key={query.queryId}
                  onClick={() => {
                    Logger.telemetry.trackEvent('SavedQueries/Navigate', {
                      isTeamShared: query.isTeamShared,
                      queryId: query.queryId,
                      productId: query.productId,
                      teamId: query.teamId,
                    })
                  }}
                >
                  <TableRow key={query.queryId} className={styles.bodyRow}>
                    {columnsConfig.map((column) => (
                      <TableCell key={column.id} style={column.cellStyle}>
                        {column.renderCell(query)}
                      </TableCell>
                    ))}
                  </TableRow>
                </RouteLink>
              )
            })}
          </TableBody>
        </Table>
      ) : (
        <Column vAlign="center" hAlign="center" fill>
          <ErrorView.Custom level="WARNING" message="No queries" />
        </Column>
      )
    }
    return null
  }, [queriesListSnapshot, tableItems, styles.table, styles.headerRow, styles.bodyRow, headerSortProps, generatePath])

  return (
    <Column className={styles.container} fill>
      <Row className={styles.header}>
        {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbButton size="large" style={{ height: 'unset', minWidth: 80, padding: 0 }}>
              <Text size={500} weight="semibold">
                Shared Queries
              </Text>
            </BreadcrumbButton>
          </BreadcrumbItem>
        </Breadcrumb>
      </Row>
      <Column className={styles.tableContainer} fill>
        {table}
      </Column>
    </Column>
  )
})

SharedQueriesTable.displayName = 'SharedQueriesTable'
