import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { IDashStoreState } from '../state/IDashStoreState'

export function createDashStoreUse(initialState: IDashStoreState) {
  return create<IDashStoreState>()(
    immer((): IDashStoreState => {
      return initialState
    }),
  )
}

export type IDashStoreUse = ReturnType<typeof createDashStoreUse>
