import { PromiseSnapshot, dayjs } from '@copilot-dash/core'
import { IComment } from '@copilot-dash/domain'
import { sortBy } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { convertV1toV2SchemaList } from './converters/convertV1toV2Schema'

export function getComments(context: IDashStoreContext, ticketId: string): PromiseSnapshot<IComment[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.comments
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.comments = snapshot
    },
    fetch: async () => {
      if (!context.enableV2Discussion) {
        const response = await context.api.logCollector.getComments(ticketId)
        const comments = convertV1toV2SchemaList(response)
        return sortBy(comments, (item) => dayjs(item.createDateTime).valueOf())
      } else {
        const response = await context.api.copilotDash.getComments(ticketId)
        return sortBy(response, (item) => dayjs(item.createDateTime).valueOf())
      }
    },
  })
}
