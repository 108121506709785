import { IBatchUpdateTicketStatusInfoResponse, IUpdateTicketStatusInfoData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { TicketStatusInfoConverter } from './converters/TicketStatusInfoConverter'

export async function batchUpdateTicketStatusInfo(
  context: IDashStoreContext,
  data: {
    TicketStatusInfoList: IUpdateTicketStatusInfoData[]
    signal: AbortSignal
  },
): Promise<IBatchUpdateTicketStatusInfoResponse> {
  const requestData = {
    TicketStatusInfoList: data.TicketStatusInfoList.map((item) => TicketStatusInfoConverter.toAPIData(item)),
  }
  return context.api.logCollector.batchUpdateTicketStatusInfo(requestData, data.signal)
}
