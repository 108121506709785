import { BaseTicketSearchAction } from './BaseTicketSearchAction'
import { EnableExceptionTracking } from '@copilot-dash/logger'
import { IDashStoreContext } from '../../IDashStoreContext'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { NewSearchCopilotTicketsResult } from './NewSearchTicketsAction.types'
import { NewTicketConverter } from './converters/NewTicketConverter'

interface IParams {
  readonly searchId: string
  readonly options: ISearchTicketOptions
}

export class SearchTicketWithIdListAction extends BaseTicketSearchAction {
  constructor(context: IDashStoreContext) {
    super(context)
  }

  @EnableExceptionTracking()
  async search(params: IParams): Promise<NewSearchCopilotTicketsResult> {
    const needToSearchPartialAiSearch = this.needToSearchPartialAiSearch(params.options)
    let partialAiSearchTicketIdList: string[] = []
    if (needToSearchPartialAiSearch) {
      partialAiSearchTicketIdList = await this.searchWithPartialAiSearch(params.options)
    }

    const request = this.createRequest({
      options: params.options,
      searchId: params.searchId,
      partialAiSearchTicketIdList,
      partialAiSearchTriggered: needToSearchPartialAiSearch,
    })

    const response = await this.context.api.logCollector.newSearchCopilotTickets(request)

    return {
      searchId: params.searchId,
      ticketCount: response.ticketCount ?? response.tickets.length,
      tickets: response.tickets.map(NewTicketConverter.fromApiContext),
      hasMore: response.hasMore ?? false,
      errorMessage: response.errorMessage,
      satCount: response.sATCount,
      dsatCount: response.dSATCount,
    }
  }

  checkNeedToSearchPartialAiSearch(options: ISearchTicketOptions): boolean {
    return this.needToSearchPartialAiSearch(options)
  }
}
