import { TimeRange } from '@copilot-dash/core'
import { useTeamViewStore } from '../store'
import { TeamRoute } from '../../../router'
import { TimeFilter } from '../../../components/SearchOptions/TimeFilter'

export const TeamDateTimeRender = () => {
  const form = useTeamViewStore((state) => state.tickets.filterForm)

  const onChanged = (range: TimeRange) => {
    const currentArgs = useTeamViewStore.getState().route.args

    Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FromDateRange', value: null })

    TeamRoute.navigator.navigate({
      ...currentArgs,
      range: range,
    })
  }

  return <TimeFilter onChanged={onChanged} range={form.range} />
}
