import { ApiConversation, ApIOdsConversationMessage } from '@copilot-dash/api'
import {
  ITicketMessageForBot,
  ITicketMessageForInvocation,
  ITicketMessageForSuggestion,
  ITicketMessageForUser,
  ITicketTurnConversation,
} from '@copilot-dash/domain'
import { compact } from 'lodash'

const MESSAGE_AUTHOR_USER = 'user'
const MESSAGE_AUTHOR_BOT = 'bot'
const MESSAGE_TYPE_CHAT = 'Chat'

export function convertTicketChatTurnFromConversationLegacy(
  turnId: string,
  api: ApiConversation,
): ITicketTurnConversation {
  return {
    id: turnId,
    timestamp: getTimestamp(),
    utterance: getUserMessage(),
    response: getBotMessage(),
    invocations: getInvocationMessages(),
    suggestions: getSuggestions(api),
    source: 'conversation-legacy',
    raw: api.conversation,
  }

  function getTimestamp(): string | undefined {
    for (const message of api.conversation.messages) {
      if (message.createdAt) {
        return message.createdAt
      }
    }

    return undefined
  }

  function getUserMessage(): ITicketMessageForUser | undefined {
    const message = api.conversation.messages.find(
      (message) => message.author === MESSAGE_AUTHOR_USER && message.messageType === MESSAGE_TYPE_CHAT,
    )

    return {
      content: {
        text: message?.text ? getMessageText(message.text) : api.conversation.utterance ?? '',
        markdown: undefined,
        adaptiveCard: undefined,
      },
      timestamp: message?.createdAt,
    }
  }

  function getBotMessage(): ITicketMessageForBot | undefined {
    const message = api.conversation.messages.find(
      (message) => message.author === MESSAGE_AUTHOR_BOT && message.messageType === MESSAGE_TYPE_CHAT,
    )

    const text = message?.text ? getMessageText(message.text) : api.conversation.botResponse
    return {
      content: {
        text: text ?? '',
        markdown: text,
        adaptiveCard: undefined,
      },
      timestamp: message?.createdAt,
    }
  }

  function getInvocationMessages(): ITicketMessageForInvocation[] {
    const invocations = compact(api.conversation.messages.map((item) => item.invocation))
    return invocations.map((item) => ({ text: item }))
  }

  function getMessageText(text: ApIOdsConversationMessage['text']): string {
    if (typeof text === 'string') {
      return text
    }

    return JSON.stringify(text)
  }
}

function getSuggestions(root: ApiConversation): ITicketMessageForSuggestion[] {
  for (const message of root.conversation.messages) {
    if (message.suggestedResponses && message.suggestedResponses.length > 0) {
      const suggestions = compact(
        message.suggestedResponses.map((item) => {
          const text = item.text
          if (!text) {
            return undefined
          }

          if (typeof text === 'string') {
            return {
              text,
            }
          }

          return undefined
        }),
      )

      if (suggestions.length > 0) {
        return suggestions
      }
    }
  }

  return []
}
