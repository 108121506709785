import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketUserConsent } from '../actions-ticket/getTicketUserConsent'

export async function assertTicketHasUserConsent(context: IDashStoreContext, ticketId: string): Promise<void> {
  const hasUserConsent = await getTicketUserConsent(context, ticketId).promise
  if (!hasUserConsent) {
    throw TicketError.create('NoUserConsentGiven', { ticketId })
  }
}
