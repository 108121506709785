import { IProductEndpointData } from '../IProductData'
import { ProductIds } from './ProductIds'

export const DefaultProductEndpoints: IProductEndpointData[] = [
  /**
   * M365Chat > Office
   */
  {
    productId: ProductIds.M365Chat,
    name: 'M365CHATM365WEBOCV',
    title: 'M365 Web',
    application: 'M365',
    platform: 'Web',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatHarmonyWebOCV',
    title: 'Office Web',
    application: 'Office.com',
    platform: 'Web',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatHarmonyDesktopOCV',
    title: 'M365 PWA',
    application: 'M365',
    platform: 'Desktop',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatM365APPIOSOCV',
    title: 'M365 APP iOS',
    application: 'Union',
    platform: 'iOS',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatM365APPAndroidOCV',
    title: 'M365 APP Android',
    application: 'Union',
    platform: 'Android',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatUnionAppOCV',
    title: 'M365 APP Legacy',
    application: 'Union Legacy',
    platform: 'Mobile - Unknown',
  },
  /**
   * M365Chat > Edge
   */
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatEdgeOCV',
    title: 'Edge Copilot',
    application: 'Edge',
    platform: 'Web',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatEdgeWebOCV',
    title: 'Edge Copilot MetaOS',
    application: 'Edge MetaOS',
    platform: 'Web',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatEdgeMobileOCV',
    title: 'Edge Mobile',
    application: 'BCB Legacy',
    platform: 'Mobile - Unknown',
  },
  /**
   * M365Chat > Teams
   */
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatTeamsMetaOSOCV',
    title: 'Teams MetaOS',
    application: 'Teams MetaOS',
    platform: 'Desktop',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatTMIOSOCMOCV',
    title: 'Teams iOS OCM',
    application: 'Teams',
    platform: 'iOS',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatTMAndroidOCMOCV',
    title: 'Teams Android OCM',
    application: 'Teams',
    platform: 'Android',
  },
  /**
   * M365Chat > Outlook
   */
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatOWAOCV',
    title: 'Outlook Web',
    application: 'Outlook',
    platform: 'Web',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatMonarchOCV',
    title: 'Outlook Monarch',
    application: 'Outlook Monarch',
    platform: 'Desktop',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatOutlookWin32OCV',
    title: 'Outlook Win32',
    application: 'Outlook',
    platform: 'Windows',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatOutlookMacOCV',
    title: 'Outlook Mac',
    application: 'Outlook',
    platform: 'Mac',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatOMAndroidOCMOCV',
    title: 'Outlook Android',
    application: 'Outlook',
    platform: 'Android',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatOMiOSOCMOCV',
    title: 'Outlook iOS',
    application: 'Outlook',
    platform: 'iOS',
  },
  /**
   * M365Chat > BCB Legacy
   */
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatCMCOCV',
    title: 'Copilot.microsoft.com',
    application: 'BCB Legacy',
    platform: 'Web',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365CHATWEBAPPOCV',
    title: 'M365 Chat Web App',
    application: 'BCB Legacy',
    platform: 'Web',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatCopilotAppOCV',
    title: 'Copilot App',
    application: 'BCB Legacy',
    platform: 'Web',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatCopilotWindowsOCV',
    title: 'Windows',
    application: 'BCB Legacy',
    platform: 'Windows',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365CHATBINGCOMOCV',
    title: 'Bing.com',
    application: 'BCB Legacy',
    platform: 'Web',
  },
  {
    productId: ProductIds.M365Chat,
    name: 'M365CHATMSBOCV',
    title: 'M365 Chat for Bing',
    application: 'BCB Legacy',
    platform: 'Web',
  },
  /**
   * M365Chat > Others
   */
  {
    productId: ProductIds.M365Chat,
    name: 'M365ChatDevUIOCV',
    title: 'DevUI',
    application: 'DevUI',
    platform: 'Web',
  },
  /**
   * CWC > Premium
   */
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatM365WebWebTabWCOCV',
    title: 'M365 Web',
    application: 'M365',
    platform: 'Web',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatHarmonyWebWebTabWCOCV',
    title: 'Office Web',
    application: 'Office.com',
    platform: 'Web',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatHarmonyDesktopWebTabWCOCV',
    title: 'M365 PWA',
    application: 'M365',
    platform: 'Desktop',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatM365APPIOSWebTabWCOCV',
    title: 'M365 APP iOS',
    application: 'Union',
    platform: 'iOS',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatM365APPAndroidWebTabWCOCV',
    title: 'M365 APP Android',
    application: 'Union',
    platform: 'Android',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatUnionAppWebTabWCOCV',
    title: 'M365 APP Legacy',
    application: 'Union Legacy',
    platform: 'Mobile - Unknown',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatEdgeWebTabWCOCV',
    title: 'Edge Copilot',
    application: 'Edge',
    platform: 'Web',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatEdgeWebWebTabWCOCV',
    title: 'Edge Copilot MetaOS',
    application: 'Edge MetaOS',
    platform: 'Web',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatTeamsMetaOSWebTabWCOCV',
    title: 'Teams MetaOS',
    application: 'Teams MetaOS',
    platform: 'Desktop',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATTMIOSOCMWebTabWCOCV',
    title: 'Teams iOS',
    application: 'Teams',
    platform: 'iOS',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATTMANDROIDOCMWebTabWCOCV',
    title: 'Teams Android',
    application: 'Teams',
    platform: 'Android',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOUTLOOKMACWebTabWCOCV',
    title: 'Outlook Mac',
    application: 'Outlook',
    platform: 'Mac',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATMONARCHWebTabWCOCV',
    title: 'Outlook Monarch',
    application: 'Outlook Monarch',
    platform: 'Desktop',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOUTLOOKWIN32WebTabWCOCV',
    title: 'Outlook Win32',
    application: 'Outlook',
    platform: 'Windows',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatOWAWebTabWCOCV',
    title: 'Outlook Web',
    application: 'Outlook',
    platform: 'Web',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOMIOSOCMWebTabWCOCV',
    title: 'Outlook iOS',
    application: 'Outlook',
    platform: 'iOS',
    license: 'Premium',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOMANDROIDOCMWebTabWCOCV',
    title: 'Outlook Android',
    application: 'Outlook',
    platform: 'Android',
    license: 'Premium',
  },
  /**
   * CWC > Included
   */
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatM365WebStandaloneWCOCV',
    title: 'M365 Web',
    application: 'M365',
    platform: 'Web',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatHarmonyWebStandaloneWCOCV',
    title: 'Office Web',
    application: 'Office.com',
    platform: 'Web',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatHarmonyDesktopStandaloneWCOCV',
    title: 'M365 PWA',
    application: 'M365',
    platform: 'Desktop',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatM365APPIOSStandaloneWCOCV',
    title: 'M365 APP iOS',
    application: 'Union',
    platform: 'iOS',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatM365APPAndroidStandaloneWCOCV',
    title: 'M365 APP Android',
    application: 'Union',
    platform: 'Android',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatUnionAppStandaloneWCOCV',
    title: 'M365 APP Legacy',
    application: 'Union Legacy',
    platform: 'Mobile - Unknown',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatCCMStandaloneWCOCV',
    title: 'CCM',
    application: 'Copilot.cloud.microsoft',
    platform: 'Web',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatEdgeStandaloneWCOCV',
    title: 'Edge Copilot',
    application: 'Edge',
    platform: 'Web',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatEdgeWebStandaloneWCOCV',
    title: 'Edge Copilot MetaOS',
    application: 'Edge MetaOS',
    platform: 'Web',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatTeamsMetaOSStandaloneWCOCV',
    title: 'Teams MetaOS',
    application: 'Teams MetaOS',
    platform: 'Desktop',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATTMIOSOCMStandaloneWCOCV',
    title: 'Teams iOS',
    application: 'Teams',
    platform: 'iOS',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATTMANDROIDOCMStandaloneWCOCV',
    title: 'Teams Android',
    application: 'Teams',
    platform: 'Android',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOUTLOOKMACStandaloneWCOCV',
    title: 'Outlook Mac',
    application: 'Outlook',
    platform: 'Mac',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATMONARCHStandaloneWCOCV',
    title: 'Outlook Monarch',
    application: 'Outlook Monarch',
    platform: 'Desktop',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOUTLOOKWIN32StandaloneWCOCV',
    title: 'Outlook Win32',
    application: 'Outlook',
    platform: 'Windows',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatOWAStandaloneWCOCV',
    title: 'Outlook Web',
    application: 'Outlook',
    platform: 'Web',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOMIOSOCMStandaloneWCOCV',
    title: 'Outlook iOS',
    application: 'Outlook',
    platform: 'iOS',
    license: 'Included',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOMANDROIDOCMStandaloneWCOCV',
    title: 'Outlook Android',
    application: 'Outlook',
    platform: 'Android',
    license: 'Included',
  },
  /**
   * CWC > Free
   */
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatM365WebFreeWCOCV',
    title: 'M365 Web',
    application: 'M365',
    platform: 'Web',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatM365APPIOSFreeWCOCV',
    title: 'M365 APP iOS',
    application: 'Union',
    platform: 'iOS',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatM365APPAndroidFreeWCOCV',
    title: 'M365 APP Android',
    application: 'Union',
    platform: 'Android',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatUnionAppFreeWCOCV',
    title: 'M365 APP Legacy',
    application: 'Union Legacy',
    platform: 'Mobile - Unknown',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatCCMFreeWCOCV',
    title: 'CCM',
    application: 'Copilot.cloud.microsoft',
    platform: 'Web',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatEdgeFreeWCOCV',
    title: 'Edge Copilot',
    application: 'Edge',
    platform: 'Web',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365ChatEdgeWebFreeWCOCV',
    title: 'Edge Copilot MetaOS',
    application: 'Edge MetaOS',
    platform: 'Web',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATTMIOSOCMFreeWCOCV',
    title: 'Teams iOS',
    application: 'Teams',
    platform: 'iOS',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATTMANDROIDOCMFreeWCOCV',
    title: 'Teams Android',
    application: 'Teams',
    platform: 'Android',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOUTLOOKMACFreeWCOCV',
    title: 'Outlook Mac',
    application: 'Outlook',
    platform: 'Mac',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATMONARCHFreeWCOCV',
    title: 'Outlook Monarch',
    application: 'Outlook Monarch',
    platform: 'Desktop',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOUTLOOKWIN32FreeWCOCV',
    title: 'Outlook Win32',
    application: 'Outlook',
    platform: 'Windows',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOMIOSOCMFreeWCOCV',
    title: 'Outlook iOS',
    application: 'Outlook',
    platform: 'iOS',
    license: 'Free',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: 'M365CHATOMANDROIDOCMFreeWCOCV',
    title: 'Outlook Android',
    application: 'Outlook',
    platform: 'Android',
    license: 'Free',
  },
  /**
   * ExcelCopilot
   */
  {
    productId: ProductIds.ExcelCopilot,
    name: 'XLWebCopilotOCV',
    title: 'Excel Web Copilot',
    platform: 'Web',
  },
  {
    productId: ProductIds.ExcelCopilot,
    name: 'XLWin32CopilotOCV',
    title: 'Excel Win32 Copilot',
    platform: 'Desktop',
  },
  {
    productId: ProductIds.ExcelCopilot,
    name: 'XLMacCopilotOCV',
    title: 'Excel Mac Copilot',
    platform: 'Mac',
  },
  {
    productId: ProductIds.ExcelCopilot,
    name: 'XLiOSCopilotOCV',
    title: 'Excel iOS Copilot',
    platform: 'iOS',
  },
  /**
   * WordCopilot
   */
  {
    productId: ProductIds.WordCopilot,
    name: 'WordWebCopilotOCV',
    title: 'Word Web Copilot',
    platform: 'Web',
  },
  {
    productId: ProductIds.WordCopilot,
    name: 'WordWin32CopilotOCV',
    title: 'Word Win32 Copilot',
    platform: 'Desktop',
  },
  {
    productId: ProductIds.WordCopilot,
    name: 'WordMacCopilotOCV',
    title: 'Word Mac Copilot',
    platform: 'Mac',
  },
  {
    productId: ProductIds.WordCopilot,
    name: 'WordiOSCopilotOCV',
    title: 'Word iOS Copilot',
    platform: 'iOS',
  },
  /**
   * PPTCopilot
   */
  {
    productId: ProductIds.PPTCopilot,
    name: 'PPTWebCopilotOCV',
    title: 'PowerPoint Web Copilot',
    platform: 'Web',
  },
  {
    productId: ProductIds.PPTCopilot,
    name: 'PPTWin32CopilotOCV',
    title: 'PowerPoint Win32 Copilot',
    platform: 'Desktop',
  },
  {
    productId: ProductIds.PPTCopilot,
    name: 'PPTMacCopilotOCV',
    title: 'PowerPoint Mac Copilot',
    platform: 'Mac',
  },
  {
    productId: ProductIds.PPTCopilot,
    name: 'PPTiOSCopilotOCV',
    title: 'PowerPoint iOS Copilot',
    platform: 'iOS',
  },
  /**
   * TeamsMeetingCopilot
   */
  {
    productId: ProductIds.TeamsMeetingCopilot,
    name: 'COPILOTMEETINGTEAMSOCV',
    title: 'Teams Meeting Copilot - During Meeting',
    meetingScenario: 'During Meeting',
  },
  {
    productId: ProductIds.TeamsMeetingCopilot,
    name: 'COPILOTRECAPTEAMSOCV',
    title: 'Teams Meeting Copilot - Post Meeting',
    meetingScenario: 'Post Meeting',
  },
  /**
   * ODSPCopilot
   */
  {
    productId: ProductIds.ODSPCopilot,
    name: 'CHATODSPCUSTOMWEBPARTOCV',
    title: 'Custom Copilot Webpart',
    application: 'Custom Copilot Webpart',
  },
  {
    productId: ProductIds.ODSPCopilot,
    name: 'CHATODSPODSPOCV',
    title: 'ODSP',
    application: 'ODSP',
  },
  {
    productId: ProductIds.ODSPCopilot,
    name: 'CHATODSPOD4BIZOCV',
    title: 'OneDrive For Business',
    application: 'OneDrive for Business',
  },
  {
    productId: ProductIds.ODSPCopilot,
    name: 'CHATODSPONEUPOCV',
    title: 'OneUp',
    application: 'OneUp',
  },
  {
    productId: ProductIds.ODSPCopilot,
    name: 'CHATODSPSPDLOCV',
    title: 'SharePoint Doc Libs',
    application: 'SharePoint Doc Libs',
  },
  {
    productId: ProductIds.ODSPCopilot,
    name: 'CHATODSPSPPOCV',
    title: 'SharePoint Pages',
    application: 'SharePoint Pages',
  },
  {
    productId: ProductIds.ODSPCopilot,
    name: 'CHATODSPSPLOCV',
    title: 'SharePoint Lists',
    application: 'SharePoint Lists',
  },
  {
    productId: ProductIds.ODSPCopilot,
    name: 'CHATODSPOCV',
    title: 'Unknown ODSP',
    application: 'Unknown ODSP',
  },
  /**
   * LoopCopilot
   */
  {
    productId: ProductIds.LoopCopilot,
    name: 'LoopWebCopilotOCV',
    title: 'Loop Web',
    platform: 'Web',
  },
  /**
   * OneNoteCopilot
   */
  {
    productId: ProductIds.OneNoteCopilot,
    name: 'OneNoteWin32CopilotOCV',
    title: 'OneNote Win32',
    platform: 'Windows',
  },
  {
    productId: ProductIds.OneNoteCopilot,
    name: 'OneNoteWebCopilotOCV',
    title: 'OneNote Web',
    platform: 'Web',
  },
  {
    productId: ProductIds.OneNoteCopilot,
    name: 'OneNoteMacCopilotOCV',
    title: 'OneNote Mac',
    platform: 'Mac',
  },
  {
    productId: ProductIds.OneNoteCopilot,
    name: 'OneNoteiOSCopilotOCV',
    title: 'OneNote iOS',
    platform: 'iOS',
  },
  /**
   * OutlookCopilot
   */
  {
    productId: ProductIds.OutlookCopilot,
    name: 'OutlookCopilotChatOWAOCV',
    title: 'Outlook Copilot OWA',
    application: 'Outlook OWA',
  },
  {
    productId: ProductIds.OutlookCopilot,
    name: 'OutlookCopilotChatMonarchOCV',
    title: 'Outlook Copilot Monarch',
    application: 'Outlook Monarch',
  },
  /**
   * TenantAdminFeedback
   */
  {
    productId: ProductIds.TenantAdminFeedback,
    name: 'TENANTSFEEDBACKSERVICE',
    title: 'Tenant Admin Feedback',
  },
  /**
   * USERP
   */
  {
    productId: ProductIds.USERP,
    name: 'USERPOCV',
    title: 'USERP',
  },
  /**
   * Search Plus
   */
  {
    productId: ProductIds.SearchPlus,
    name: 'SearchPlusChatOCV',
    title: 'Search Plus',
  },
]
