import { IFeedbackCopilotScreenState } from './IFeedbackCopilotScreenStoreState'
import { createFeedbackCopilotScreenStateForm } from './createFeedbackCopilotScreenStateForm'

export function createFeedbackCopilotScreenState(): IFeedbackCopilotScreenState {
  return {
    searchSnapshot: { status: 'none' },
    loadMoreSnapshot: { 0: { status: 'none' } },
    searchFeedbackResult: { total: 0, tickets: [], hasMore: true },
    form: createFeedbackCopilotScreenStateForm(),
    isRagResultPanelOpen: false,
    isTicketSummaryPanelOpen: false,
    clickedRagInfo: undefined,
    clickedTicketInfo: undefined,
    searchSessionId: undefined,
    hasFCConversation: false,
  }
}
