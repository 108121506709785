import { ColumnKey, columnKeySchema, INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { memo, useMemo } from 'react'
import { useFeedbackCopilotScreenActions, useFeedbackCopilotScreenState } from '../../store'

import { AGGridTable } from '../../../../components/AGGridTable/AGGridTable'
import { AllSearchTableColumns } from '../../../../components/TicketTableColumnConfigs'
import { ColDef } from 'ag-grid-community'
import { ErrorView } from '../../../../components/Error'
import { TelemetryScope } from '@copilot-dash/logger'

interface IProps {
  readonly tableItem: INewTicketData[]
  readonly selectedColumns: ColumnKey[]
  readonly keyPicker: (item: INewTicketData, index: number) => string
}

export const RagResultTableView = memo(({ tableItem, selectedColumns, keyPicker }: IProps) => {
  const actions = useFeedbackCopilotScreenActions()
  const searchFeedbackResult = useFeedbackCopilotScreenState((state) => state.searchFeedbackResult)
  const searchSessionId = useFeedbackCopilotScreenState((state) => state.searchSessionId)
  const isAISearchEnable = app.features.aiSearch.use()

  const colDefs: ColDef<INewTicketData>[] = useMemo(() => {
    return AllSearchTableColumns(SearchTextPrefixType.All).filter((col) => {
      const validation = columnKeySchema.safeParse(col.field)
      return validation.success && selectedColumns.includes(validation.data)
    })
  }, [selectedColumns])

  const loadMoreFunction = (offset: number) => {
    actions.loadMore(offset, isAISearchEnable)
  }

  const supportLoadMore = useMemo(() => {
    return searchFeedbackResult.hasMore
  }, [searchFeedbackResult])

  if (tableItem.length === 0) {
    return <ErrorView.Custom level="WARNING" message="No tickets found for this query" />
  }

  return (
    <TelemetryScope
      scope="FeedbackCopilotScreenSessionView"
      properties={{ searchSessionId: searchSessionId ? searchSessionId : '' }}
    >
      <AGGridTable
        rowData={tableItem}
        getRowId={(data) => data.data.ticketId ?? ''}
        columnDefs={colDefs}
        loadMore={loadMoreFunction}
        onRowClicked={actions.rowClick}
        hasMore={supportLoadMore}
      />
    </TelemetryScope>
  )
})

RagResultTableView.displayName = 'RagResultTableView'
