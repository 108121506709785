import { ApiCommentItem, apiCommentItemSchema, ApiCommentListResponse } from '@copilot-dash/api'
import { IComment } from '@copilot-dash/domain'

const apiCommentItemV1ToV2Schema = apiCommentItemSchema.transform((item) => ({
  id: item.commentId,
  discussionId: item.ticketId,
  userId: item.userId,
  content: item.content,
  createDateTime: item.createTime,
  updateDateTime: item.modifyTime,
}))

export function convertV1toV2SchemaList(response: ApiCommentListResponse): IComment[] {
  return response.comments.map((comment) => apiCommentItemV1ToV2Schema.parse(comment))
}

export function convertV1toV2Schema(response: ApiCommentItem): IComment {
  return apiCommentItemV1ToV2Schema.parse(response)
}
