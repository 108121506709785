import { z } from 'zod'

export const apiOdsTicketVerbatimElementSchema = z.record(z.unknown()).and(
  z.object({
    displayedQuestion: z.string().optional(),
  }),
)

export const apiDiagnosticSessionAttributesSchema = z.record(z.unknown()).and(
  z.object({
    'User Consent Given': z.union([z.literal('True'), z.literal('False')]).optional(),
  }),
)

export const apiOdsDiagnosticConversationDataSchema = z.record(z.unknown()).and(
  z.object({
    StatusCode: z.number(),
  }),
)

export const apiOdsDiagnosticSubstrateSearchDataSchema = z.record(z.unknown()).and(
  z.object({
    StatusCode: z.number(),
    TransactionId: z.union([z.string(), z.undefined()]),
  }),
)

export const apiOdsDiagnosticOfflineDataSchema = z.record(z.unknown()).and(
  z.object({
    StatusCode: z.number(),
    TransactionId: z.union([z.string(), z.undefined()]),
  }),
)

export const apiOdsTicketVerbatimSchema = z.record(z.unknown()).and(
  z.object({
    elements: z.array(apiOdsTicketVerbatimElementSchema).optional(),
  }),
)

export const apiOdsDiagnosticDataSchema = z.record(z.unknown()).and(
  z.object({
    Conversation: apiOdsDiagnosticConversationDataSchema,
    SubstrateSearch: z.array(apiOdsDiagnosticSubstrateSearchDataSchema).optional(),
    Offline: z.array(apiOdsDiagnosticOfflineDataSchema).optional(),
  }),
)

export const apiOdsInteractionInfosSchema = z.record(z.unknown()).and(
  z.object({
    InteractionTimeStr: z.string().optional(),
    MessageId: z.string().optional(),
    ClientName: z.string().optional(),
    Index: z.number(),
    ConversationId: z.string().optional(),
    SubstrateSearchReplayInfoList: z
      .array(
        z.record(z.unknown()).and(
          z.object({
            TransactionId: z.string().optional(),
            ReplayServiceCallTraceId: z.string().optional(),
          }),
        ),
      )
      .optional(),
    ImpressionIds: z.array(z.string()).optional(),
    Diagnostic: apiOdsDiagnosticDataSchema.optional(),
  }),
)

export const apiOdsTicketDataSchema = z.record(z.unknown()).and(
  z.object({
    'Client Name': z.string().optional(),
    ClientName: z.string(),
    'Feedback Date': z.string().optional(),
    'Correlation Vector': z.string().optional(),
    'Detailed Feedback Verbatim': z.union([z.string(), apiOdsTicketVerbatimSchema]).optional(),
    'General Feedback Verbatim': z.string().optional(),
    HasVerbatim: z.string().optional(),
    InteractionInfos: z.array(apiOdsInteractionInfosSchema).optional(),
    InteractionInfoGZip: z.string().optional(),
    IsConverged: z.union([z.literal('True'), z.literal('False')]).optional(),
    Query: z.string().optional(),
    Ring: z.string().optional(),
    TraceId: z.string().optional(),
    UserEmail: z.string().optional(),
    UserSMTPEmail: z.string().optional(),
    UserUpn: z.string().optional(),
    Verbatim: z.union([z.string(), apiOdsTicketVerbatimSchema]).optional(),
    'User Consent Given': z.union([z.literal('True'), z.literal('False')]).optional(),
    DiagnosticSessionAttributes: apiDiagnosticSessionAttributesSchema.optional(),
    ProblemStatement: z.union([z.string(), apiOdsTicketVerbatimSchema]).optional(),
    ProblemStatementInvariant: z.union([z.string(), apiOdsTicketVerbatimSchema]).optional(),
  }),
)
