import { AsyncLoader, AsyncSnapshots, IStoreGetter, IStoreSetter } from '@copilot-dash/core'
import { IComment } from '@copilot-dash/domain'
import { IDiscussionPanelActions } from './IDiscussionPanelActions'
import { IDiscussionPanelState } from './IDiscussionPanelState'

interface IParams {
  readonly get: IStoreGetter<IDiscussionPanelState>
  readonly set: IStoreSetter<IDiscussionPanelState>
}

export function createDiscussionPanelActions({ get, set }: IParams): IDiscussionPanelActions {
  const commentsLoader = new AsyncLoader<IComment[]>()
  const postCommentLoader = new AsyncLoader<IComment>()
  const deleteCommentLoader = new AsyncLoader<void>()
  return {
    //Initialization
    init() {
      commentsLoader.submit({
        promise: () => {
          return application.store.actions.getComments(get().discussionId)
        },
        onChanged: (snapshot) => {
          set((state) => {
            state.commentsSnapshot = snapshot
          })
        },
      })
    },

    //Update discussionId
    updateDiscussionId: (discussionId: string) => {
      set((state) => {
        state.discussionId = discussionId
        state.commentsSnapshot = AsyncSnapshots.waiting()
      })
      commentsLoader.submit({
        promise: () => {
          if (!discussionId) {
            return null
          }
          return app.store.actions.getComments(discussionId)
        },
        onChanged: (snapshot) => {
          set((state) => {
            state.commentsSnapshot = snapshot
          })
        },
      })
    },

    //Post comment
    postComment: (discussionId: string, content: string) => {
      return postCommentLoader.submit({
        promise: () => {
          if (!content || !discussionId) {
            return null
          }
          return app.store.actions.createComment(discussionId, content)
        },
        onChanged: (snapshot) => {
          set((state) => {
            state.postCommentSnapshot = snapshot
            if (snapshot.status === 'done' && state.commentsSnapshot.status === 'done') {
              state.commentsSnapshot.data.push(snapshot.data)
            }
          })
        },
      })
    },

    //Edit comment
    editComment: (discussionId: string, id: string, content: string) => {
      return postCommentLoader.submit({
        promise: () => {
          if (!content || !id || !discussionId) {
            return null
          }
          return app.store.actions.updateComment(discussionId, id, content)
        },
        onChanged: (snapshot) => {
          set((state) => {
            state.editCommentSnapshot = snapshot
            if (snapshot.status === 'done' && state.commentsSnapshot.status === 'done') {
              state.commentsSnapshot.data.map((comment) => {
                if (comment.id === id) {
                  comment.content = snapshot.data.content
                  comment.updateDateTime = snapshot.data.updateDateTime
                }
              })
            }
          })
        },
      })
    },

    //Delete comment
    deleteComment: (discussionId: string, commentId: string) => {
      deleteCommentLoader.submit({
        promise: async () => {
          if (!discussionId || !commentId) {
            return
          }
          await app.store.actions.deleteComment(discussionId, commentId)
        },
        onChanged: (snapshot) => {
          set((state) => {
            state.deleteCommentSnapshot = snapshot
            if (snapshot.status === 'done' && state.commentsSnapshot.status === 'done') {
              state.commentsSnapshot.data = state.commentsSnapshot.data.filter((comment) => comment.id !== commentId)
            }
          })
        },
      })
    },

    resetEditCommentSnapshot: () => {
      set((state) => {
        state.editCommentSnapshot.status = 'none'
        state.editCommentSnapshot.data = undefined
      })
    },
  }
}
