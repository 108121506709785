import { ApiStateAndDurationKustoLogItem, ApiStateAndDurationKustoLogItemV2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoStateDurationLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'
import { getTicketTurnMetadata } from '../actions-ticket-chat/getTicketTurnMetadata'

export function getStateDurationLog(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoStateDurationLogItem[]> {
  return context.getOrFetch<IKustoStateDurationLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.stateAndDurationLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.stateAndDurationLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const logs = context.enableV2Endpoint ? await doFetchFromV2() : await doFetchFromV1()
      if (logs && logs.length > 0) {
        return logs
      }

      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.BizChat3SLatencyLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.StateDurationLog })
    },
  })

  async function doFetchFromV1(): Promise<IKustoStateDurationLogItem[]> {
    const turn = await getTicketTurnMetadata(context, ticketId, messageId).promise.catch(() => undefined)

    const response = await context.api.logCollector.getSydneySingleInfo({
      FetchLogName: 'stateDurationLog',
      HappenTimeArray: turn?.time ? [turn.time] : [],
      MessageId: messageId,
    })

    return response.logData['turingBotMonitoredScopeLogs (Kusto)']?.map(convertV1Item) ?? []
  }

  async function doFetchFromV2(): Promise<IKustoStateDurationLogItem[] | undefined> {
    const ticketSource = await getTicketSource(context, ticketId).promise
    const source = ticketSource.turns[messageId]?.stateDurationLog
    if (source) {
      const results = await context.api.copilotDash.getTicketBlob(ticketId, source.blobUrl).asStateDurationLog()
      return results.map(convertV2Item)
    }

    return undefined
  }

  function convertV1Item(raw: ApiStateAndDurationKustoLogItem): IKustoStateDurationLogItem {
    return {
      scopeName: raw.scopeName,
      message: raw.message,
      raw,
    }
  }

  function convertV2Item(raw: ApiStateAndDurationKustoLogItemV2): IKustoStateDurationLogItem {
    return {
      scopeName: raw.ScopeName,
      message: raw.Message,
      raw,
    }
  }
}
