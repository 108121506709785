import { ApiError, ApiOcvTicket } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getRawOcvTicket(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ApiOcvTicket> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.rawOcv
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.rawOcv = snapshot

      if (snapshot.status === 'error') {
        const status = ApiError.getStatusCode(snapshot.error)
        if (status === 403 || status === 401) {
          state.basic.hasOcvAccess = false
          state.basic.ocvPermissionError = snapshot.error
        }
      }
    },
    fetch: () => {
      if (context.state.basic.hasOcvAccess === false) {
        throw context.state.basic.ocvPermissionError
      }

      return context.api.ocv.getItem(ticketId)
    },
  })
}
