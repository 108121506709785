import { ApiErrorData } from './ApiErrorData'

export class ApiError extends Error {
  readonly data: ApiErrorData

  constructor(data: ApiErrorData) {
    super(ApiError.formatMessage(data))
    this.data = data
  }

  private static getStack(data: ApiErrorData): string | undefined {
    switch (data.details.type) {
      case 'axios':
      case 'zod':
        return data.details.cause.stack
      case 'unknown':
        if (data.details.cause instanceof Error) {
          return data.details.cause.stack
        }
        return undefined
    }
  }

  private static formatMessage(data: ApiErrorData): string {
    switch (data.details.type) {
      case 'axios':
        return data.details.cause.message
      case 'zod':
        return data.details.cause.message
      case 'unknown':
        return String(data.details.cause)
    }
  }

  static getStatusCode(error: unknown): number | undefined
  static getStatusCode(error: unknown): number | undefined {
    if (error instanceof ApiError) {
      return error.data.statusCode
    }

    return undefined
  }
}
