import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketSource } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicketInteractions } from '../actions-raw-ticket-ods/getRawOdsTicketInteractions'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { convertToTicketSource } from './convertToTicketSource'
import { convertToTicketSourceFromOds } from './convertToTicketSourceFromOds'

export function getTicketSource(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketSource> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.source
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.source = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      return context.enableV2Endpoint ? fetchTicketSourceV2() : fetchTicketSourceV1()
    },
  })

  async function fetchTicketSourceV1(): Promise<ITicketSource> {
    const interactions = await getRawOdsTicketInteractions(context, ticketId).promise
    const files = await context.api.ods.getFiles(ticketId)

    return convertToTicketSourceFromOds(interactions, files)
  }

  async function fetchTicketSourceV2(): Promise<ITicketSource> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    return convertToTicketSource(raw.sources ?? [])
  }
}
