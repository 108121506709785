import { ApplicationType, ProductApplication } from '@copilot-dash/domain'
import { compact, includes, sortBy, uniq } from 'lodash'

import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { useMemo } from 'react'
import { useProductIdEndpoints } from '@copilot-dash/store'

interface IProps {
  productId: number
  platforms: string[]
  licenses: string[]
  selectedValues: string[] | undefined
  onChangeValue: (endpoints: string[]) => void
}

export const ApplicationFilter: React.FC<IProps> = ({
  productId,
  platforms,
  licenses,
  selectedValues,
  onChangeValue,
}) => {
  let productEndpoints = useProductIdEndpoints({ productId }).data

  productEndpoints =
    platforms.length === 0
      ? productEndpoints
      : productEndpoints?.filter((endpoint) => includes(platforms, endpoint.platform))

  productEndpoints =
    licenses.length === 0
      ? productEndpoints
      : productEndpoints?.filter((endpoint) => includes(licenses, endpoint.license))

  const applicationList = useMemo(() => {
    const items = uniq(compact(productEndpoints?.map((endpoint) => endpoint.application)))

    return sortBy(items, (item) => sortApplication(item)).map((application) => ({
      key: application,
      text: application,
    }))
  }, [productEndpoints])

  return (
    <>
      <CopilotDashMultiSelectDropdown
        comboId={'combo-applicationFilter'}
        filterType={'Applications'}
        isGroup={true}
        optionsList={applicationList}
        defaultSelectedOption={selectedValues}
        onChangeFilter={onChangeValue}
      />
    </>
  )
}

function sortApplication(item: ApplicationType): number {
  return ProductApplication.indexOf(item)
}
