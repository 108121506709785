import { IFCConversationMessageData, IFCNewQuery } from '@copilot-dash/domain'

import { IDashStoreContext } from '../../IDashStoreContext'
import { PromiseSnapshots } from '@copilot-dash/core'

export async function appendNewFCConversation(context: IDashStoreContext, newQuery: IFCNewQuery): Promise<void> {
  context.use.setState((state) => {
    const newFCConvs: IFCConversationMessageData = {
      messageId: newQuery.messageId,
      query: newQuery.userQuery,
      queryResultIsRead: true,
      botDefaultResponse: 'Working on it...',
      queryStatus: 'NotStarted',
      clientStartTimeUTC: newQuery.clientStartTimeUTC,
    }
    const currentDataSnapshot = state.feedbackCopilot.allFCConversations
    state.feedbackCopilot.lastFCConversation = PromiseSnapshots.done(newFCConvs)
    if (currentDataSnapshot?.data) {
      currentDataSnapshot.data.push(newFCConvs)
    }
  })
}
