import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicket } from '../actions-raw-ticket-ods/getRawOdsTicket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'

export function getTicketEmail(context: IDashStoreContext, ticketId: string): PromiseSnapshot<string> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.email
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.email = snapshot
    },
    fetch: async () => {
      if (context.enableV2Endpoint) {
        return fetchFromV2()
      }

      return fetchFromV1()
    },
  })

  async function fetchFromV2(): Promise<string> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    const emailAddress = raw.user?.email
    if (emailAddress) {
      return emailAddress
    }

    await assertTicketHasUserConsent(context, ticketId)
    throw TicketError.create('NoEmail', { ticketId })
  }

  async function fetchFromV1(): Promise<string> {
    await assertTicketHasUserConsent(context, ticketId)

    const api = await getRawOdsTicket(context, ticketId).promise.catch((error) => {
      throw TicketError.create('NoEmail', { ticketId })
    })

    // Check if email is not available
    const email = api.UserEmail || api.UserSMTPEmail || api.UserUpn
    if (!email) {
      throw TicketError.create('NoEmail', { ticketId })
    }

    // Check if email is not available due to tenant not in scope
    if (email === `Tenant not in scope of MSIT`) {
      throw TicketError.create('NoEmailDueToTenantNotInScope', { ticketId })
    }

    return email
  }
}
