import { ITelemetryMetric } from '@copilot-dash/domain'
import { AccordionHeader, makeStyles, mergeClasses, Text, tokens } from '@fluentui/react-components'
import { Row, Spacer } from '../../../../../../components/Layout'
import { MetricCardHeaderOpenJson } from './MetricCardHeaderOpenJson'
import { MetricCardHeaderGroup4Indicator } from './MetricCardHeaderGroup4Indicator'

interface IProps {
  readonly selected: boolean
  readonly metric: ITelemetryMetric
}

export function MetricCardHeader({ metric, selected }: IProps) {
  const styles = useStyles()
  const isWarning = metric.statusLevel !== 'success'

  return (
    <AccordionHeader className={mergeClasses(styles.container, isWarning && styles.containerWarning)} size="small">
      <Row className={styles.root} fill vAlign="center" gap="gap.small">
        <Text weight="semibold">{metric.serviceName}</Text>
        <MetricCardHeaderGroup4Indicator metric={metric} />
        {selected && <MetricCardHeaderOpenJson metric={metric} />}
        <Spacer />
        {isWarning && (
          <Text size={200} weight="semibold" className={styles.warning}>
            Warning
          </Text>
        )}
      </Row>
    </AccordionHeader>
  )
}

const useStyles = makeStyles({
  container: {
    padding: '0px',
    backgroundColor: tokens.colorNeutralBackground2,
  },
  containerWarning: {
    backgroundColor: tokens.colorStatusWarningBackground1,
  },
  root: {
    height: '44px',
  },
  warning: {
    color: tokens.colorStatusWarningForeground1,
  },
})
