import { ITicketData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { PromiseSnapshots } from '@copilot-dash/core'

export async function batchUpdateTickets(context: IDashStoreContext, tickets: ITicketData[]): Promise<void> {
  context.use.setState((state) => {
    tickets.forEach((ticket) => {
      const item = (state.tickets[ticket.ticketId] ??= {})
      item.ticket = PromiseSnapshots.done(ticket)
      if (ticket.customTags && ticket.customTags.length > 0) {
        item.customTags = PromiseSnapshots.done(ticket.customTags)
      }
    })
  })
}
