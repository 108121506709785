import { IFilterOption } from '../../Filter/IFilterOption.types'
import { InvocationSlicer } from '@copilot-dash/domain'

export const OptionSetInvocationSlicer: IFilterOption[] = [
  {
    key: InvocationSlicer.TurnN,
    text: 'Turn N',
  },
  {
    key: InvocationSlicer.UXAssistedQueries,
    text: 'UX Assisted Queries',
  },
  {
    key: InvocationSlicer.QueriesWithCIQ,
    text: 'Grounded Prompts',
  },
  {
    key: InvocationSlicer.CIQFile,
    text: 'CIQ_File',
  },
  {
    key: InvocationSlicer.CIQLocalFile,
    text: 'Uploaded Local Files',
  },
  {
    key: InvocationSlicer.CIQTurn0,
    text: 'CIQ + Turn 0',
  },
  {
    key: InvocationSlicer.CIQCloudFile,
    text: 'Cloud Files from CIQ or Attach',
  },
  {
    key: InvocationSlicer.QueriesFromTurn0,
    text: 'Turn 0',
  },
  {
    key: InvocationSlicer.TryThesePrompts,
    text: 'Try These Prompts',
  },
  {
    key: InvocationSlicer.CIQPeople,
    text: 'People',
  },
  {
    key: InvocationSlicer.CIQFileUrl,
    text: 'Copy-Pasted File URL',
  },
  {
    key: InvocationSlicer.CIQEvent,
    text: 'Meeting',
  },
  {
    key: InvocationSlicer.CopilotLab,
    text: 'Copilot Lab',
  },
  {
    key: InvocationSlicer.CIQEmail,
    text: 'Emails',
  },
  {
    key: InvocationSlicer.ScheduledPrompts,
    text: 'Scheduled Prompts',
  },
  {
    key: InvocationSlicer.EntityPrompts,
    text: 'Entity Prompts',
  },
  {
    key: InvocationSlicer.GoBold,
    text: 'Go Bold',
  },
  {
    key: InvocationSlicer.PromptElaboration,
    text: 'Elaborated Prompts',
  },
  {
    key: InvocationSlicer.CIQExternal,
    text: '3P Connector',
  },
]
