import { ITicketSystemData } from '@copilot-dash/domain'
import { TicketTagsHelper } from './TicketTagsHelper'

// Experience
const TAG_EXPERIENCE_TYPE_VALUE_MAPPING: { [key: string]: string } = {
  SCS: 'Try these prompts',
  FeedInTTP: 'Try these prompts',
  FEED: 'Catch up',
  SSS: 'Copilot Lab',
  SSSTurnN: 'Copilot Lab',
  SuggestionPill: 'Suggestion pills',
}

export function convertTicketTagData(tags: string[]): ITicketSystemData {
  const helper = new TicketTagsHelper(tags)

  // Experience
  const experienceType = helper.findValue('ExperienceType:')
  const experienceTypeInvocatedData = experienceType ? TAG_EXPERIENCE_TYPE_VALUE_MAPPING[experienceType] : undefined

  // CIQ
  const isCIQTriggered = helper.contains('CIQ:IsTriggered')
  const ciqValue = helper.findValue('CIQ:', { exclude: ['CIQ:IsTriggered'] })
  const ciq = isCIQTriggered ? ciqValue : undefined

  return {
    tags,
    webToggleIsOff: helper.contains('WebToggle:off'),
    sssTriggered: helper.contains('3S:Triggered'),
    utteranceLanguage: helper.findValue('UtteranceLang:'),
    responseLanguage: helper.findValue('ResponseLang:'),
    experienceType,
    experienceTypeInvocatedData,
    ciq,
    luRequiredDomain: helper.findValues('LuRequiredDomain:'),
    webSearchTriggered: helper.contains('WebSearch:Triggered'),
    isApology: helper.contains('Response:isApology'),
    isSensitive: getIsSensitive(helper),
    hasCopilotExtensionIds: helper.contains('HasCopilotExtensionIds:'),
  }
}

function getIsSensitive(helper: TicketTagsHelper): boolean {
  const isMSITInternal = helper.contains('Ring:MSIT') || helper.contains('Ring:SDFV2')
  const isEncrypted = helper.contains('IsEncrypted:true')
  const isConfidential =
    helper.contains('SensitivityLabelDisplayName:Confidential\\Recipients Only') ||
    helper.contains('SensitivityLabelDisplayName:Highly Confidential\\Recipients Only')

  if (isMSITInternal && isEncrypted && isConfidential) return true
  if (!isMSITInternal && isEncrypted) return true

  return false
}
