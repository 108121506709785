import {
  AllSupportedNonAIFColumns,
  INewTicketData,
  NonRemovableColumns,
  TeamViewSubMenuIds,
} from '@copilot-dash/domain'
import { mergeClasses } from '@fluentui/react-components'
import { motion } from 'framer-motion'
import * as React from 'react'
import { ColumnSettingPanel } from '../../../components/ColumnSettingPanel/ColumnSettingPanel'
import { COLUMN_SETTING_PANEL_WIDTH } from '../../../components/ColumnSettingPanel/children/ColumnSettingPanelBody.styles'
import { Body, Column, Right, Row } from '../../../components/Layout'
import { FILTER_PANEL_WIDTH } from '../../../components/TicketsFilterPanel/layout/TicketsFilterPanelLayout.styles'
import { TicketRoute } from '../../../router'
import { BatchUpdateTicketsPanel } from '../children/BatchUpdateTicketsPanel'
import { BATCH_UPDATE_TICKETS_PANEL_WIDTH } from '../children/BatchUpdateTicketsPanel.styles'
import { FilterPanel } from '../children/FilterPanel'
import { RootCauseTable } from '../children/RootCauseTable/RootCauseTable'
import { SharedQueriesView } from '../children/SharedQueries/SharedQueriesView'
import { TicketsTableForAll } from '../children/TicketsTableForAll'
import { TicketsTableForRootCause } from '../children/TicketsTableForRootCause'
import { TicketsTableForTopIssues } from '../children/TicketsTableForTopIssues'
import { UnRootCauseTickets } from '../children/UnRootCauseTickets'
import { useTeamViewStore } from '../store'
import { useStyles } from './TicketsLayout.styles'

interface ITicketsLayoutProps {
  className?: string
}

export const TicketsLayout: React.FC<ITicketsLayoutProps> = React.memo(({ className }) => {
  const styles = useStyles()
  const currentSelectedSubMenu = useTeamViewStore((state) => state.teams.selectedSubMenu)
  const selectedTicket = useTeamViewStore((state) => state.selectedTicket)
  const setSelectedTicket = useTeamViewStore((state) => state.setSelectedTicket)
  const selectedRootCauseId = useTeamViewStore((state) => state.rootCauses.selectedRootCauseId)

  const columns = app.settings.teamViewColumns.use()

  const onRowClick = React.useCallback(
    (row?: INewTicketData) => {
      setSelectedTicket(row)
      if (row && row.ticketId) {
        app.store.actions.updateTicket(row.ticketId, row)
        TicketRoute.navigator.navigateToTicketModal({ id: row.ticketId })
        return
      }
    },
    [setSelectedTicket],
  )

  const ticketsTabType = useTeamViewStore((state) => state.tickets.ticketsTabType)
  const tableRender = React.useMemo(() => {
    if (ticketsTabType === 'category') {
      switch (currentSelectedSubMenu) {
        case TeamViewSubMenuIds.All:
          return (
            <Column>
              <TicketsTableForAll onRowClick={onRowClick} clickedTicketKey={selectedTicket?.ticketId} />
            </Column>
          )
        case TeamViewSubMenuIds.UnRootCaused:
          return (
            <Column>
              <UnRootCauseTickets onRowClick={onRowClick} clickedTicketKey={selectedTicket?.ticketId} />
            </Column>
          )
        case TeamViewSubMenuIds.TopIssues:
          return (
            <Column>
              <TicketsTableForTopIssues onRowClick={onRowClick} clickedTicketKey={selectedTicket?.ticketId} />
            </Column>
          )
        case TeamViewSubMenuIds.RootCauses:
          if (selectedRootCauseId)
            return (
              <Column>
                <TicketsTableForRootCause onRowClick={onRowClick} clickedTicketKey={selectedTicket?.ticketId} />
              </Column>
            )
          return (
            <Column>
              <RootCauseTable />
            </Column>
          )
        case TeamViewSubMenuIds.SharedQueries:
          return (
            <Column>
              <SharedQueriesView />
            </Column>
          )
      }
    }

    if (ticketsTabType === 'rootCause') {
      return (
        <Column>
          <TicketsTableForRootCause onRowClick={onRowClick} clickedTicketKey={selectedTicket?.ticketId} />
        </Column>
      )
    }
    return null
  }, [ticketsTabType, currentSelectedSubMenu, onRowClick, selectedTicket?.ticketId, selectedRootCauseId])

  const isTicketsFilterPanelOpen = useTeamViewStore((state) => state.computed.filterPanelOpen)
  const isTicketDetailOpen = useTeamViewStore((state) => state.computed.ticketDetailOpen)
  const isColumnSettingOpen = useTeamViewStore((state) => state.computed.columnSettingOpen)
  const isBatchUpdateOpen = useTeamViewStore((state) => state.computed.batchUpdatePanelOpen)

  const selectedTicketsKey = useTeamViewStore((state) =>
    state.batchTicketsSubStore.tickets.map((ticket) => ticket.ticketId).join(','),
  )

  return (
    <Row className={mergeClasses(styles.container, className)}>
      <Body className={styles.body}>{tableRender}</Body>
      <motion.div
        layout
        animate={
          isTicketsFilterPanelOpen
            ? 'filterOpen'
            : isTicketDetailOpen
              ? 'ticketSummaryOpen'
              : isColumnSettingOpen
                ? 'columnSettingOpen'
                : isBatchUpdateOpen
                  ? 'batchUpdateOpen'
                  : 'close'
        }
        variants={{
          close: { width: 0 },
          filterOpen: { width: FILTER_PANEL_WIDTH },
          columnSettingOpen: { width: COLUMN_SETTING_PANEL_WIDTH },
          batchUpdateOpen: { width: BATCH_UPDATE_TICKETS_PANEL_WIDTH },
        }}
        initial={false}
      >
        <Right className={styles.right}>
          <Column>
            <FilterPanel />
            <ColumnSettingPanel
              isPanelOpen={isColumnSettingOpen}
              onDismiss={() => useTeamViewStore.getState().toggleColumnSetting(false)}
              NonRemovableColumns={NonRemovableColumns}
              AllSupportedColumns={AllSupportedNonAIFColumns}
              selectedColumns={columns}
              setSelectedColumns={app.settings.teamViewColumns.set}
            />
            <BatchUpdateTicketsPanel open={isBatchUpdateOpen} key={selectedTicketsKey} />
          </Column>
        </Right>
      </motion.div>
    </Row>
  )
})

TicketsLayout.displayName = 'TicketsLayout'
