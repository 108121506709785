import { ReactElement, useMemo } from 'react'
import { ValueViewForBoolean } from './views/ValueViewForBoolean'
import { ValueViewForCustom } from './views/ValueViewForCustom'
import { ValueViewForDatetime } from './views/ValueViewForDatetime'
import { ValueViewForEmail } from './views/ValueViewForEmail'
import { ValueViewForEmpty } from './views/ValueViewForEmpty'
import { ValueViewForEnum } from './views/ValueViewForEnum'
import { ValueViewForJson } from './views/ValueViewForJson'
import { ValueViewForNumber } from './views/ValueViewForNumber'
import { ValueViewForString } from './views/ValueViewForString'
import { ValueViewForLink } from './views/ValueViewForLink'
import { ICellRendererParams } from 'ag-grid-community'
import { AnyPropertyItem } from '../../AnyPropertyType'

export function ValueColumnView({ data }: ICellRendererParams<AnyPropertyItem>) {
  const item = data!

  return useMemo((): ReactElement => {
    if (item.value === undefined || item.value === null || item.value === '') {
      return <ValueViewForEmpty value={item.value} />
    }

    if (typeof item.value === 'object') {
      if (Array.isArray(item.value)) {
        if (item.value.length === 0) {
          return <ValueViewForEmpty value={[]} />
        }
      } else {
        if (Object.keys(item.value).length === 0) {
          return <ValueViewForEmpty value={{}} />
        }
      }
    }

    switch (item.type) {
      case 'string':
        return <ValueViewForString value={item.value} />
      case 'datetime':
        return <ValueViewForDatetime value={item.value} />
      case 'number':
        return <ValueViewForNumber value={item.value} />
      case 'boolean':
        return <ValueViewForBoolean value={item.value} />
      case 'empty':
        return <ValueViewForEmpty value={item.value} />
      case 'enum':
        return <ValueViewForEnum value={item.value} />
      case 'json':
        return <ValueViewForJson value={item.value} />
      case 'custom':
        return <ValueViewForCustom value={item.value} />
      case 'email':
        return <ValueViewForEmail value={item.value} />
      case 'link':
        return <ValueViewForLink value={item.value} />
    }
  }, [item.type, item.value])
}
