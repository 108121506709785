import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV1Ticket } from '../actions-raw-ticket/getRawV1Ticket'
import { convertTicketDataFromApiContext } from './converters/convertTicketData'

export function getTicket(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketData> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.ticket
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.ticket = snapshot
    },
    fetch: async () => {
      const raw = await getRawV1Ticket(context, ticketId).promise
      return convertTicketDataFromApiContext(raw)
    },
  })
}
