import { Body, Column, Right, Row } from '../../../components/Layout'
import { IFeedbackCopilotScreenState, useFeedbackCopilotScreenState } from '../store'
import { memo, useMemo } from 'react'

import { ErrorView } from '../../../components/Error'
import { FeedbackCopilotMain } from '../child/FeedbackCopilotMain'
import { FeedbackCopilotStartup } from '../child/FeedbackCopilotStartup'
import { RagResultPanel } from '../child/ragresult/RagResultPanel'
import { Spinner } from '@fluentui/react-components'
import { TICKET_SUMMARY_PANEL_WIDTH } from '../child/ragresult/RagResultPanel.styles'
import { motion } from 'framer-motion'
import { useStyles } from './FeedbackCopilotScreenLayout.styles'

export const FeedbackCopilotScreenLayout = memo(function FeedbackCopilotScreenLayout() {
  const styles = useStyles()
  const state = useFeedbackCopilotScreenState((state: IFeedbackCopilotScreenState) => state)
  const allFCConversations = app.store.use().feedbackCopilot.allFCConversations
  const tableRender = useMemo(() => {
    if (allFCConversations) {
      switch (allFCConversations.status) {
        case 'waiting':
          return (
            <Row vAlign="center" hAlign="center">
              <Spinner label="Loading..." />
            </Row>
          )
        case 'error':
          return <ErrorView error={allFCConversations.error} />
        case 'done':
          return allFCConversations.data.length > 0 ? <FeedbackCopilotMain /> : <FeedbackCopilotStartup />
        default:
          return null
      }
    }
    return null
  }, [allFCConversations])

  return (
    <motion.div layout animate={state.isRagResultPanelOpen ? 'filterOpen' : 'close'} className={styles.framerContainer}>
      <Row fill>
        <Body className={styles.body}>
          <Column>{tableRender}</Column>
        </Body>
        <motion.div
          layout
          variants={{
            close: { width: 0 },
            filterOpen: { width: TICKET_SUMMARY_PANEL_WIDTH },
          }}
          className={styles.framerPanel}
          initial={false}
        >
          <Right className={styles.right}>
            <Column fill>
              <RagResultPanel />
            </Column>
          </Right>
        </motion.div>
      </Row>
    </motion.div>
  )
})
