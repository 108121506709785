import { makeStyles, shorthands } from '@fluentui/react-components'
const SMALL_SCREEN_COLUMN_WIDTH = 308
export const SMALL_SCREEN_WIDTH = 880

export const useStyles = makeStyles({
  container: {
    ...shorthands.padding('4px', '16px', '4px', '16px'),
    gap: '8px',
    width: '100%',
    overflow: 'auto',
  },
  filterGroup: {
    gap: '8px',
    width: '90%',
    '> *:not(:first-child):not(:nth-child(1))': {
      flex: 1,
      maxWidth: '24%',
    },
    '& > :first-child, & > :nth-child(1)': {
      flex: 2,
    },
  },
  smallScreenColumn: {
    minWidth: `${SMALL_SCREEN_COLUMN_WIDTH}px`,
  },
})
