import { Tab, TabList, makeStyles, tokens } from '@fluentui/react-components'
import { z } from 'zod'
import { useTicketFilterStore, HeaderTab } from '../../store/store'
import { isProductSupportTeamView, getProductNameById } from '@copilot-dash/domain'
import { useCallback } from 'react'

type FilterPanelHeaderTabs = {
  readonly id: HeaderTab
  readonly name: string
  readonly disabled?: boolean
}

const HeaderTabList: FilterPanelHeaderTabs[] = [
  {
    id: HeaderTab.Filters,
    name: 'Filters',
  },

  {
    id: HeaderTab.SharedQueries,
    name: 'Shared Queries',
  },
  {
    id: HeaderTab.MyQueries,
    name: 'My Queries',
  },
]

export function FilterPanelHeader() {
  const styles = useStyles()
  const selectedHeaderTab = useTicketFilterStore((state) => state.selectedHeaderTab)
  const productId = useTicketFilterStore((state) => state.selectedProductId)

  const onSelect = (tabId: string) => {
    const tab = z.nativeEnum(HeaderTab).safeParse(tabId).data

    if (tab) {
      Logger.telemetry.trackEvent('FiltersPanel/SwitchTab', {
        tabName: tab,
        productId: productId,
      })
      useTicketFilterStore.getState().setSelectedHeaderTab(tab)
    }
  }

  const disableTeamQueriesTab = useCallback(
    (tabId: HeaderTab): boolean => {
      const productName = getProductNameById(productId)
      if (tabId !== HeaderTab.SharedQueries) return false
      return productName ? !isProductSupportTeamView(productName) : false
    },
    [productId],
  )

  return (
    <TabList selectedValue={selectedHeaderTab} onTabSelect={(_, d) => onSelect(String(d.value))}>
      {HeaderTabList.map((tab) => {
        return (
          <Tab value={tab.id} key={tab.id} className={styles.tab} disabled={disableTeamQueriesTab(tab.id)}>
            {tab.name}
          </Tab>
        )
      })}
    </TabList>
  )
}

const useStyles = makeStyles({
  tab: {
    padding: '4px 12px 4px 0px',
    fontSize: tokens.fontSizeBase300,
    marginRight: '4px',
    ':before': {
      right: '12px',
      left: 0,
    },
    ':after': {
      right: '12px',
      left: 0,
    },
  },
})
