import { ErrorViewBoundary } from '../../components/Error'
import { FeedbackCopilotScreenLayout } from './layout/FeedbackCopilotScreenLayout'
import { FeedbackCopilotScreenStoreProvider } from './store'
import { TelemetryScope } from '@copilot-dash/logger'

export function FeedbackCopilotScreen() {
  return (
    <ErrorViewBoundary label="FeedbackCopilotScreen">
      <TelemetryScope scope="FeedbackCopilotScreen">
        <FeedbackCopilotScreenStoreProvider>
          <FeedbackCopilotScreenLayout />
        </FeedbackCopilotScreenStoreProvider>
      </TelemetryScope>
    </ErrorViewBoundary>
  )
}

FeedbackCopilotScreen.displayName = 'FeedbackCopilotScreen'
