import { AbsoluteTimeRange, RelativeTimeRange, TimeRange, Times, timeRangeSchema } from '@copilot-dash/core'
import {
  ColumnIdNameMapping,
  ColumnKey,
  DefaultTriggeredSkillList,
  SearchFilterFieldNames,
  TeamViewSubMenuIds,
  TicketDSATStatusType,
  TicketRingType,
  getChannelTitleByChannelName,
} from '@copilot-dash/domain'
import { isArray, isNil, omit, omitBy } from 'lodash'

import { DefaultHasErrorMessagesfilterOptions } from '../filters'
import { IFilterOption } from '@copilot-dash/store'
import { z } from 'zod'

export interface ITicketFilterFormData {
  agentTypes?: string[]
  appTypes?: string[]
  teamName?: string
  subMenu?: string
  range?: TimeRange
  userId?: string[]
  searchText?: string
  searchTextPrefix?: string
  tenantIds?: string[]
  applications?: string[]
  customerTypes?: string[]
  licenses?: string[]
  platforms?: string[]
  promptLanguages?: string[]
  ring?: TicketRingType[]
  groundedPrompts?: string[]
  thumbs?: string[]
  hasVerbatim?: string[]
  hasUserConsent?: string[]
  invocationSlicers?: string[]
  invocationType?: string[]
  triggeredSkill?: string[]
  hasCitation?: string[]
  hasEntityCard?: string[]
  hitAvalon?: string[]
  isApology?: string[]
  priority?: string[]
  isSTCAChina?: string[]
  isTopi18N?: string[]
  responseHeroType?: string[]
  responseLinkType?: string[]
  semanticSearchType?: string[]
  bizchatScenario?: string[]
  experienceType?: string[]
  hasConnector?: string[]
  hasGPTExtension?: string[]
  hasMessageExtension?: string[]
  hasCopilotExtensionIds?: string[]
  optionsSets?: string
  errorCode?: string[]
  channel?: string[]
  isGCIntent?: string[]
  hasConnectorResult?: string[]
  dSATStatus?: TicketDSATStatusType[]
  customTags?: string[]
  dSATAssignedTo?: string
  sliceIds?: string
  copilotExtensionIds?: string
  topIssueBatchId?: string | null
  flights?: string
  hasErrorMessages?: string[]
  meetingScenarios?: string[]
  order?: string[]
  hasScreenshot?: boolean | null
}

const friendlyNameMapping: Record<
  keyof ITicketFilterFormData,
  { friendlyName: string; isTag: boolean; isBoolean: boolean; hidden?: boolean }
> = {
  range: { friendlyName: 'Date', isTag: false, isBoolean: false },
  searchText: { friendlyName: 'Search text', isTag: false, isBoolean: false },
  searchTextPrefix: { friendlyName: 'Search text prefix', isTag: false, isBoolean: false },
  userId: { friendlyName: 'Email', isTag: false, isBoolean: false, hidden: true },
  tenantIds: { friendlyName: 'Tenant', isTag: false, isBoolean: false },
  applications: { friendlyName: 'Applications', isTag: false, isBoolean: false },
  channel: { friendlyName: 'Feedback entries', isTag: false, isBoolean: false },
  ring: { friendlyName: 'Rings', isTag: false, isBoolean: false },
  thumbs: { friendlyName: 'Emotion type', isTag: false, isBoolean: true },
  licenses: { friendlyName: 'Licenses', isTag: false, isBoolean: false },
  platforms: { friendlyName: 'Platforms', isTag: false, isBoolean: false },
  priority: { friendlyName: 'Priority', isTag: false, isBoolean: false },
  dSATStatus: { friendlyName: 'State', isTag: false, isBoolean: false },
  customTags: { friendlyName: 'Tags', isTag: false, isBoolean: false },
  dSATAssignedTo: { friendlyName: 'Assigned To', isTag: false, isBoolean: false },
  optionsSets: { friendlyName: 'Options sets', isTag: false, isBoolean: false },
  copilotExtensionIds: { friendlyName: 'CopilotExtensionIds', isTag: false, isBoolean: false },
  sliceIds: { friendlyName: 'Slice IDs', isTag: false, isBoolean: false },
  customerTypes: { friendlyName: 'Customer types', isTag: true, isBoolean: false },
  promptLanguages: { friendlyName: 'Prompt languages', isTag: true, isBoolean: false },
  hasVerbatim: { friendlyName: 'Has verbatim', isTag: true, isBoolean: true },
  invocationSlicers: { friendlyName: 'Invocation slicers', isTag: false, isBoolean: false },
  invocationType: { friendlyName: 'Invocation types', isTag: true, isBoolean: false },
  isApology: { friendlyName: 'Is apology', isTag: true, isBoolean: true },
  hasCitation: { friendlyName: 'Has citation', isTag: true, isBoolean: true },
  hasEntityCard: { friendlyName: 'Has entity representation', isTag: true, isBoolean: true },
  hitAvalon: { friendlyName: 'Hit avalon', isTag: true, isBoolean: true },
  hasUserConsent: { friendlyName: 'Has user consent', isTag: true, isBoolean: true },
  groundedPrompts: { friendlyName: 'Grounded prompts', isTag: true, isBoolean: false },
  isSTCAChina: { friendlyName: 'Is STCA China', isTag: true, isBoolean: true },
  isTopi18N: { friendlyName: 'Is Top i18N Tenants', isTag: true, isBoolean: true },
  responseHeroType: { friendlyName: 'Response hero type', isTag: true, isBoolean: false },
  responseLinkType: { friendlyName: 'Response link type', isTag: true, isBoolean: false },
  semanticSearchType: { friendlyName: 'Semantic search type', isTag: true, isBoolean: false },
  bizchatScenario: { friendlyName: 'BizChat scenario', isTag: true, isBoolean: false },
  experienceType: { friendlyName: 'PromptMetadata_ExperienceType', isTag: true, isBoolean: false },
  triggeredSkill: { friendlyName: 'Triggered skills', isTag: true, isBoolean: false },
  hasGPTExtension: { friendlyName: 'Has GPT-Extension keywords', isTag: true, isBoolean: true },
  hasConnector: { friendlyName: 'Has Connector keywords', isTag: true, isBoolean: true },
  hasMessageExtension: { friendlyName: 'Has MessageExtension keywords', isTag: true, isBoolean: true },
  hasCopilotExtensionIds: { friendlyName: 'Has CopilotExtensionIds', isTag: true, isBoolean: true },
  errorCode: { friendlyName: 'Error Code', isTag: true, isBoolean: false },
  isGCIntent: { friendlyName: 'Is GC Intent', isTag: true, isBoolean: true },
  hasConnectorResult: { friendlyName: 'Has Connector Result', isTag: true, isBoolean: true },
  teamName: { friendlyName: 'Team name', isTag: false, isBoolean: false, hidden: false },
  subMenu: { friendlyName: 'Sub menu', isTag: false, isBoolean: false, hidden: false },
  topIssueBatchId: { friendlyName: 'Weekly rolling date', isTag: false, isBoolean: false, hidden: false },
  flights: { friendlyName: 'Flight', isTag: false, isBoolean: false, hidden: false },
  hasErrorMessages: { friendlyName: 'Has error messages', isTag: true, isBoolean: true },
  agentTypes: { friendlyName: 'Agent types', isTag: true, isBoolean: false },
  appTypes: { friendlyName: 'App types', isTag: true, isBoolean: false },
  meetingScenarios: { friendlyName: 'Meeting scenarios', isTag: false, isBoolean: false },
  order: { friendlyName: 'Order By', isTag: false, isBoolean: false },
  hasScreenshot: { friendlyName: 'Has screenshot', isTag: false, isBoolean: true },
}

export interface ITicketFilterFormProps {
  onSubmit: (data?: ITicketFilterFormData) => void
  defaultValues?: ITicketFilterFormData
}

export type WatchFn = (values: ITicketFilterFormProps['defaultValues']) => void

export interface ITicketFilterFormRef {
  setValues: (values: Partial<ITicketFilterFormProps['defaultValues']>) => void
  getValues: () => ITicketFilterFormProps['defaultValues']
  watch: (fn: WatchFn) => void
}

export const customFiltersToAdd: Array<{ key: keyof ITicketFilterFormData; name: SearchFilterFieldNames }> = [
  { key: 'isTopi18N', name: SearchFilterFieldNames.isTopi18N },
  { key: 'isSTCAChina', name: SearchFilterFieldNames.isSTCAChina },
  { key: 'hasGPTExtension', name: SearchFilterFieldNames.hasGPTExtension },
  { key: 'hasMessageExtension', name: SearchFilterFieldNames.hasMessageExtension },
  { key: 'hasConnector', name: SearchFilterFieldNames.hasConnector },
  { key: 'isGCIntent', name: SearchFilterFieldNames.isGCIntent },
  { key: 'hasConnectorResult', name: SearchFilterFieldNames.hasConnectorResult },
  { key: 'semanticSearchType', name: SearchFilterFieldNames.semanticSearchType },
  { key: 'experienceType', name: SearchFilterFieldNames.experienceType },
  { key: 'errorCode', name: SearchFilterFieldNames.errorCode },
]

export function toODataQuery(
  params: Partial<ITicketFilterFormData>,
  timezone: string,
  tagsMetaDataMappingList: IFilterOption[],
  tenantName?: string[],
  emailName?: string[],
): string {
  const queryParts: string[] = []
  const getTextByKey = (key: string): string | undefined => {
    const item = tagsMetaDataMappingList.find((option) => option.key === key)
    return item ? item.text : undefined
  }
  const addParam = (
    key: keyof ITicketFilterFormData,
    value: string | string[] | TimeRange | TicketRingType[] | TicketDSATStatusType[] | boolean | undefined | null,
  ) => {
    const { friendlyName, isTag, isBoolean, hidden } = friendlyNameMapping[key]
    if (key === 'range' && value) {
      const parsedValue = timeRangeSchema.safeParse(value)
      if (parsedValue.success) {
        if (parsedValue.data.type === 'relative') {
          const relativeValue = parsedValue.data as RelativeTimeRange
          queryParts.push(`${friendlyName} eq '${Times.toStringOfRelativeTimeRange(relativeValue)}'`)
        } else if (parsedValue.data.type === 'absolute') {
          const absoluteValue = parsedValue.data as AbsoluteTimeRange
          if (
            absoluteValue.from.length > 0 &&
            absoluteValue.from !== 'null' &&
            absoluteValue.to.length > 0 &&
            absoluteValue.to !== 'null'
          ) {
            const fromDateString = Times.format(absoluteValue.from, { timezone, format: 'MM/DD' }) ?? absoluteValue.from
            const toDateString = Times.format(absoluteValue.to, { timezone, format: 'MM/DD' }) ?? absoluteValue.to
            queryParts.push(`${friendlyName} eq 'from ${fromDateString} to ${toDateString}'`)
          }
        }
      }
    } else if (key === 'channel' && Array.isArray(value)) {
      const channelTitle = value.map((v) => getChannelTitleByChannelName(v)).filter(Boolean)
      if (channelTitle.length > 0) {
        queryParts.push(`${friendlyName} in (${channelTitle.map((title) => `'${title}'`).join(', ')})`)
      }
    } else if (key === 'priority' && Array.isArray(value)) {
      if (value.length > 0) {
        queryParts.push(`${friendlyName} in (${value.map((priority) => `'P${priority}'`).join(', ')})`)
      }
    } else if (key === 'thumbs' && Array.isArray(value)) {
      if (value.length > 0) {
        queryParts.push(`${friendlyName} eq ${value.map((item) => `'${item}'`).join(', ')}`)
      }
    } else if (key === 'invocationType' && Array.isArray(value)) {
      if (value.length > 0) {
        queryParts.push(`${friendlyName} in (${value.map((item) => `'${InvocationTypeMapping[item]}'`).join(', ')})`)
      }
    } else if (key === 'triggeredSkill' && Array.isArray(value)) {
      if (value.length > 0) {
        const mappedValues = value.map((item) => {
          const skill = DefaultTriggeredSkillList.find((skill) => skill.key === item)
          return skill ? skill.text : item
        })

        queryParts.push(`${friendlyName} in (${mappedValues.map((text) => `'${text}'`).join(', ')})`)
      }
    } else if (key === 'hasErrorMessages' && Array.isArray(value)) {
      if (value.length > 0) {
        const mappedValues = value.map((item) => {
          const selected = DefaultHasErrorMessagesfilterOptions.find((option) => option.key === item)
          return selected ? selected.text : item
        })

        queryParts.push(`${friendlyName} eq ${mappedValues.map((text) => `'${text}'`).join(', ')}`)
      }
    } else if (key === 'order' && Array.isArray(value)) {
      const query = value
        .map((item) => {
          const [field, order] = item.split(' ')
          const parsedField = z.nativeEnum(ColumnKey).parse(field)
          if (parsedField) {
            return `Order by ${ColumnIdNameMapping[parsedField]} ${order}`
          } else return null
        })
        .filter(Boolean)
        .join(' and ')
      queryParts.push(`${query} `)
    } else if (typeof value === 'boolean') {
      if (value === true) {
        queryParts.push(`${friendlyName} eq 'Yes'`)
      } else if (value === false) {
        queryParts.push(`${friendlyName} eq 'No'`)
      }
    } else if (key === 'teamName' && typeof value === 'string') {
      queryParts.push(`${value} Team`)
    } else if (key === 'subMenu' && typeof value === 'string' && value) {
      queryParts.push(`${SubMenuMapping[value as TeamViewSubMenuIds]}`)
    } else if (key === 'topIssueBatchId' && typeof value === 'string') {
      const [start, end] = value.split('_')
      const formatDate = (date: string) => `${date.slice(0, 4)}/${date.slice(4, 6)}/${date.slice(6, 8)}`
      if (start && end) queryParts.push(`Weekly rolling date: ${formatDate(start)}-${formatDate(end)}`)
    } else if (key === 'tenantIds' && Array.isArray(value)) {
      if (value.length > 0) {
        if (tenantName) {
          queryParts.push(`${friendlyName} in (${tenantName.map((v) => `'${v}'`).join(', ')})`)
        } else {
          queryParts.push(`${friendlyName} in (${value.map((v) => `'${v}'`).join(', ')})`)
        }
      }
    } else if (key === 'userId' && Array.isArray(value)) {
      if (value.length > 0) {
        if (emailName) {
          queryParts.push(`${friendlyName} in (${emailName.map((v) => `'${v}'`).join(', ')})`)
        } else {
          queryParts.push(`${friendlyName} in (${value.map((v) => `'${v}'`).join(', ')})`)
        }
      }
    } else if (Array.isArray(value)) {
      if (isBoolean) {
        if (value.length > 0 && value[0] !== undefined) {
          const booleanValue = value[0].startsWith('!') ? 'No' : 'Yes'
          queryParts.push(`${friendlyName} eq '${booleanValue}'`)
        }
      } else if (isTag) {
        const tagTexts = value.map((v) => getTextByKey(v)).filter(Boolean)
        if (tagTexts.length > 0) {
          queryParts.push(`${friendlyName} in (${tagTexts.map((text) => `'${text}'`).join(', ')})`)
        }
      } else {
        if (value.length > 0) {
          queryParts.push(`${friendlyName} in (${value.map((v) => `'${v}'`).join(', ')})`)
        }
      }
    } else if (value && !hidden && !(key === 'searchTextPrefix' || key === 'searchText')) {
      queryParts.push(`${friendlyName} eq '${value}'`)
    }
  }

  ;(Object.keys(params) as Array<keyof ITicketFilterFormData>).forEach((key) => {
    const value = params[key]
    if (key === 'searchText' && value && params.searchTextPrefix) {
      queryParts.push(`Search '${params.searchTextPrefix}' contains '${value}'`)
    } else if (key in friendlyNameMapping) {
      addParam(key, value)
    }
  })
  return queryParts.join(' and ')
}

export function getCleanedFilters(combinedFilters: ITicketFilterFormData) {
  if (isNil(combinedFilters.searchText) || combinedFilters.searchText === '') {
    combinedFilters = omit(combinedFilters, 'searchTextPrefix')
  }
  combinedFilters = omit(combinedFilters, ['isAIF', 'count', 'offset'])

  return omitBy(combinedFilters, (value, key) => {
    return key === 'queryId' || isNil(value) || value === '' || (isArray(value) && value.length === 0)
  })
}

const InvocationTypeMapping: Record<string, string> = {
  'ExperienceType:SCS|ExperienceType:FeedInTTP': 'Try These Prompts (ZQ)',
  'ExperienceType:FEED': 'Catch up',
  'ExperienceType:SSS|ExperienceType:SSSTurnN': 'Copilot Lab',
  'ExperienceType:SuggestionPill': 'Suggestion Pills',
}
const SubMenuMapping: Record<TeamViewSubMenuIds, string> = {
  [TeamViewSubMenuIds.All]: 'All DSATs',
  [TeamViewSubMenuIds.UnRootCaused]: 'Un-Root Caused DSATs',
  [TeamViewSubMenuIds.TopIssues]: 'Top Issues',
  [TeamViewSubMenuIds.RootCauses]: 'Root Causes',
  [TeamViewSubMenuIds.SharedQueries]: 'Shared Queries',
}
