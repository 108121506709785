import { makeStyles, mergeClasses } from '@fluentui/react-components'
// import 'ag-grid-community/styles/ag-grid.css'
// import 'ag-grid-community/styles/ag-theme-quartz.min.css'
import { AgGridReact } from 'ag-grid-react'
import { useRef } from 'react'
import { useThemeMode } from '../../../../../theme/useThemeMode'
import { Column } from '../../../../Layout'
import { AnyDataStore } from '../../../AnyDataStore'
import { useAnyTableColumns } from './hooks/useAnyTableColumns'
import { useAnyTableData } from './hooks/useAnyTableData'
import { AnyObject } from './types/AnyObject'

const THEME_CLASS_LIGHT = 'ag-theme-quartz'
const THEME_CLASS_DARK = 'ag-theme-quartz-dark'

export function AnyTable() {
  const ref = useRef<AgGridReact<AnyObject>>(null)
  const styles = useStyles()
  const data = useAnyTableData()
  const columns = useAnyTableColumns(data)
  const keyword = AnyDataStore.use((state) => state.table.keyword)
  const actions = AnyDataStore.useActions()

  const themeMode = useThemeMode()
  const themeClass = themeMode === 'dark' ? THEME_CLASS_DARK : THEME_CLASS_LIGHT

  return (
    <Column className={mergeClasses(themeClass, styles.root)} fill>
      <AgGridReact
        ref={ref}
        rowData={data}
        columnDefs={columns}
        rowSelection={{
          mode: 'multiRow',
          headerCheckbox: false,
          checkboxes: false,
          enableClickSelection: true,
        }}
        readOnlyEdit={true}
        quickFilterText={keyword}
        cacheQuickFilter={true}
        onFilterChanged={() => {
          const instance = ref.current
          if (instance) {
            const visibleRows = instance.api.getDisplayedRowCount()
            actions.updateSearchResultCount(visibleRows)
          }
        }}
      />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    '& .ag-root-wrapper': {
      borderRadius: '0',
    },
  },
})
