import { Button, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, Text } from '@fluentui/react-components'
import { Dismiss24Regular, List24Regular } from '@fluentui/react-icons'
import { memo, useMemo } from 'react'
import { useFeedbackCopilotScreenActions, useFeedbackCopilotScreenState } from '../../store'

import { ErrorView } from '../../../../components/Error'
import { INewTicketData } from '@copilot-dash/domain'
import { LoaderComponent } from '../../../search/children/loader/LoaderComponent'
import { RagResultTableView } from './RagResultTableView'
import { useStyles } from './RagResultPanel.styles'

export const RagResultPanel = memo(() => {
  const styles = useStyles()
  const searchSnapshot = useFeedbackCopilotScreenState((state) => state.loadMoreSnapshot)
  const searchFeedbackResult = useFeedbackCopilotScreenState((state) => state.searchFeedbackResult)
  const ragInfo = useFeedbackCopilotScreenState((state) => state.clickedRagInfo)
  const defaultColumns = app.settings.ragResultTableColumns.use()
  const actions = useFeedbackCopilotScreenActions()
  const tableContent = useMemo(() => {
    let tableItem: INewTicketData[] = []
    switch (searchSnapshot[0]?.status) {
      case 'none':
        return null
      case 'waiting':
        return <LoaderComponent />
      case 'done':
        tableItem = searchFeedbackResult.tickets
        return (
          <RagResultTableView
            tableItem={tableItem}
            selectedColumns={defaultColumns}
            keyPicker={(ticket, _) => ticket.ticketId}
          />
        )
      case 'error':
        return <ErrorView error={searchSnapshot[0].error} />
      default:
        return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFeedbackResult.tickets, searchSnapshot, ragInfo])

  return (
    <Drawer
      className={styles.card}
      type="inline"
      separator
      open={useFeedbackCopilotScreenState((state) => state.isRagResultPanelOpen)}
      position="end"
    >
      <DrawerHeader className={styles.drawerHeader}>
        <DrawerHeaderTitle
          className={styles.headerTitle}
          action={
            <Button
              appearance="subtle"
              aria-label="Close Panel"
              icon={<Dismiss24Regular />}
              onClick={actions.onDismissRagResultPanel}
            />
          }
        >
          <Button appearance="subtle" icon={<List24Regular />} />
          <Text className={styles.header}>
            {ragInfo?.title ? ` DSATs of ${ragInfo?.title}` : `Tickets(${ragInfo?.tickets?.length})`}
          </Text>
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>{tableContent}</DrawerBody>
    </Drawer>
  )
})

RagResultPanel.displayName = 'RagResultPanel'
