import { Spinner, makeStyles, tokens, Text, Button } from '@fluentui/react-components'
import { useTeamViewStore } from '../../screens/team/store'
import { UserPhoto } from '../User/UserPhoto'
import { useDebounce } from '../../hooks/useDebounce'
import { CopilotDashPopover } from './CopilotDashPopover'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { NullableOption } from '@copilot-dash/api'

interface IProps {
  emailAddress: string | undefined
  onChangeUser: (value: string | undefined) => void
  placeholder?: string
  showInput?: boolean
}

const Unassigned = 'Unassigned'

export const EmailTagPickerFilter = ({ emailAddress, onChangeUser, placeholder = 'Select', showInput }: IProps) => {
  const [selectedOption, setSelectedOption] = useState<string>('')
  const selectedOptions = useMemo(() => (selectedOption ? [selectedOption] : []), [selectedOption])
  const productId = useTeamViewStore((state) => state.computed.productId)
  const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
  const [options, setOptions] = useState<string[]>([Unassigned])
  const [loading, setLoading] = useState(false)
  const [input, setInput] = useState<string>('')
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const debouncedInput = useDebounce(input.trim(), 100)

  const styles = useStyles()

  useEffect(() => {
    setSelectedOption(emailAddress ?? '')
  }, [emailAddress])

  const defaultOptionsValue = useCallback(async () => {
    setLoading(true)
    if (productId && teamId) {
      const res = await application.store.actions.getAssignedTeamMembers(productId, teamId)
      const validEmail =
        res.assignedTeamMembers.length > 0 && res.assignedTeamMembers.filter((item) => isValidEmail(item))
      validEmail && setOptions([Unassigned, ...validEmail])
      setLoading(false)
    }
  }, [productId, teamId])

  useEffect(() => {
    if (isPopoverOpen) {
      defaultOptionsValue()
    }
  }, [isPopoverOpen, selectedOption, defaultOptionsValue])

  const onOptionSelect = (option: NullableOption<string> | undefined) => {
    if (option) {
      onChangeUser(option)
      setSelectedOption(option)
    } else {
      onChangeUser(undefined)
      setSelectedOption('')
    }
    setInput('')
  }
  function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSelectCleanClick = () => {
    onChangeUser(undefined)
    setSelectedOption('')
  }
  const optionsRender = (options: string[]) => {
    return loading ? (
      <Spinner />
    ) : options.length === 0 ? (
      <Text>No results found</Text>
    ) : (
      options.map((option) => {
        return (
          <Button
            key={option}
            icon={<UserPhoto userId={option} className={styles.avatar} />}
            appearance="subtle"
            className={styles.selectButton}
            onClick={() => onOptionSelect(option)}
          >
            <Text>{option}</Text>
          </Button>
        )
      })
    )
  }

  const targetFinalOptions = optionsRender(
    options.filter((option) => option.trim().toLocaleLowerCase().includes(debouncedInput.trim().toLocaleLowerCase())),
  )
  return (
    <CopilotDashPopover
      optionContent={targetFinalOptions}
      value={selectedOption}
      placeholder={placeholder}
      selectedOptions={selectedOptions}
      handleSelectCleanClick={handleSelectCleanClick}
      inputValue={input}
      setInputValue={setInput}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      showInput={showInput}
    />
  )
}

const useStyles = makeStyles({
  avatar: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    fontSize: tokens.fontSizeBase200,
  },
  displayName: {
    maxWidth: '130px',
    fontSize: tokens.fontSizeBase300,
  },

  optionList: {
    maxHeight: '50vh',
  },
  selectButton: {
    textAlign: 'left',
    justifyContent: 'left',
    fontWeight: tokens.fontWeightRegular,
  },
})
