import { INewTicketData, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { UserProfile } from '../User/UserProfile'
import { memo, useMemo } from 'react'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { CustomCellRendererProps } from 'ag-grid-react'

export const ColumnConfigAssignTo: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.AssignTo],
  field: String(ColumnKey.AssignTo) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <RenderUserProfile item={props.data} />
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.AssignTo]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 100,
  sortable: false,
  resizable: true,
}

const RenderUserProfile = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  const email = item.assignTo

  const content = useMemo(() => {
    if (!email) {
      return null
    }

    return (
      <UserProfile
        userId={email}
        fallback={email}
        customUserPhotoClassName={styles.customUserPhoto}
        customUserDisplayNameClassName={styles.customUserName}
      />
    )
  }, [styles.customUserName, styles.customUserPhoto, email])

  return <TableCellLayoutWithStyles>{content}</TableCellLayoutWithStyles>
})

const useStyles = makeStyles({
  customUserPhoto: {
    width: '24px',
    height: '24px',
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase300,
  },
})
RenderUserProfile.displayName = 'RenderUserProfile'
