import { PromiseSnapshot } from '@copilot-dash/core'
import { DefaultProducts, IProductData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getProducts(context: IDashStoreContext): PromiseSnapshot<IProductData[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.basic.products
    },
    set: (state, snapshot) => {
      state.basic.products = snapshot
    },
    fetch: () => {
      return DefaultProducts
    },
  })
}
