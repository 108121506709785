import { Spinner } from '@fluentui/react-components'
import { Row } from '../../../Layout'
import { LexicalRichTextEditorStore } from '../../LexicalRichTextEditorStore'

export function UploadLoadingPlugin() {
  const isUploading = LexicalRichTextEditorStore.use((state) => state.isUploading)
  return (
    <Row style={{ position: 'absolute', left: '4px', bottom: '4px', display: isUploading ? 'block' : 'none' }}>
      <Spinner size="small" />
    </Row>
  )
}
