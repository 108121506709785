import { PromiseSnapshot, PromiseSnapshots } from '@copilot-dash/core'

import { IDashStoreContext } from '../../IDashStoreContext'
import { IFCConversationMessageData } from '@copilot-dash/domain'

export function getFCConversationByUserId(
  context: IDashStoreContext,
  userId: string,
  topN: number,
): PromiseSnapshot<IFCConversationMessageData[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.feedbackCopilot.allFCConversations
    },
    set: (state, snapshot) => {
      state.feedbackCopilot.allFCConversations = snapshot
      if (snapshot.data && snapshot.data.length > 0) {
        //get the last record of all fc conversation
        const lastTurnData = snapshot.data[snapshot.data.length - 1]
        if (lastTurnData) {
          state.feedbackCopilot.lastFCConversation = PromiseSnapshots.done(lastTurnData)
        }
      }
    },
    fetch: async () => {
      const response = await context.api.automation.getHistoryRagQuery({
        userId: userId,
        topN: topN,
      })
      return response.historyQueryList.map((item) => {
        return {
          ...item,
        }
      })
    },
  })
}
