import { INewTicketData, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { ResponsiveEmailColumnComponent } from './ResponsiveEmailColumnComponent'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { CustomCellRendererProps } from 'ag-grid-react'

export const ColumnConfigEmailAddress: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.Email],
  field: String(ColumnKey.Email) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        {props.data.ticketId && <ResponsiveEmailColumnComponent item={props.data} />}
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles truncate>{ColumnIdNameMapping[ColumnKey.Email]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 100,
  width: 190,
  sortable: true,
  resizable: true,
}
