import { z } from 'zod'
import { ApiV2Ring } from './ApiV2Ring'
import { apiV2TicketSourceSchema } from './ApiV2TicketSource.schema'

export const apiV2TicketUserSchema = z.object({
  objectId: z.string().optional(),
  email: z.string().optional(),
  tenantId: z.string().optional(),
  locale: z.string().optional(),
  isEU: z.boolean().optional(),
})

export const apiV2TicketUserFeedbackVerbatimSchema = z.object({
  text: z.string().optional(),
  locale: z.string().optional(),
  language: z.string().optional(),
  textInEnglish: z.string().optional(),
})

export const apiV2TicketChatUtteranceSchema = z.object({
  text: z.string(),
  locale: z.string().optional(),
  language: z.string().optional(),
  textInEnglish: z.string().optional(),
  sydneyText: z.string().optional(),
})

export const apiV2TicketChatResponseSchema = z.object({
  text: z.string(),
  locale: z.string().optional(),
  language: z.string().optional(),
  textInEnglish: z.string().optional(),
  sydneyText: z.string().optional(),
})

export const apiV2RingSchema = z.nativeEnum(ApiV2Ring)

export const apiV2TicketUserFeedbackSchema = z.object({
  createdDateTime: z.string(),
  sentiment: z.union([z.literal('Positive'), z.literal('Negative')]),
  hasUserConsent: z.boolean(),
  verbatim: apiV2TicketUserFeedbackVerbatimSchema,
  screenshotCount: z.number(),
  ocvLink: z.string().optional(),
})

export const apiV2TicketEnvironmentSchema = z.object({
  clientName: z.string(),
  scenarioName: z.union([
    z.literal('CopilotChatFeedback'),
    z.literal('CopilotGlobalFeedback'),
    z.literal('CopilotCatchUpFeedback'),
    z.literal('CopilotAssistantFeedback'),
  ]),
  ring: apiV2RingSchema,
  featureArea: z.string().optional(),
  clientVersion: z.string().optional(),
})

export const apiV2TicketChatSchema = z.object({
  index: z.number(),
  messageId: z.string(),
  mode: z.union([z.literal('Text'), z.literal('Voice')]),
  invocationSlicers: z.string().optional(),
  utterance: apiV2TicketChatUtteranceSchema.optional(),
  response: apiV2TicketChatResponseSchema.optional(),
})

export const apiV2TicketDiagnosticContextSchema = z.object({
  sydneyEndpoint: z.string(),
  conversationId: z.string().optional(),
  correlationId: z.string().optional(),
  clientSessionId: z.string().optional(),
  chat: z.array(apiV2TicketChatSchema).optional(),
})

export const apiV2TicketSchema = z.object({
  id: z.string(),
  user: apiV2TicketUserSchema.optional(),
  userFeedback: apiV2TicketUserFeedbackSchema,
  environment: apiV2TicketEnvironmentSchema.optional(),
  diagnosticContext: apiV2TicketDiagnosticContextSchema.optional(),
  sources: z.array(apiV2TicketSourceSchema).optional(),
  tags: z.array(z.string()).optional(),
  version: z.number().optional(),
})
