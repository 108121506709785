import {
  getProductIdByName,
  IProductData,
  parseProductName,
  TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST,
} from '@copilot-dash/domain'
import { useCallback, useMemo } from 'react'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { GlobalProductSelector } from '../../../providers/product'
import { TeamScreen } from '../../../screens/team/TeamScreen'
import { NotFoundRoutePage } from '../404/NotFoundRoutePage'
import { createNavigator } from '../../navigator/createNavigator'
import { ITeamViewRouteArgs } from './types'
import { RouteWithQueryData } from '../../utils/RouteWithQueryData'

interface ITeamRoutePageProps {
  teamViewNavigator: ReturnType<typeof createNavigator<ITeamViewRouteArgs>>
}

export function TeamRoutePage(props: ITeamRoutePageProps) {
  const { teamViewNavigator } = props
  const args = teamViewNavigator.useArgs()

  const handleSelectProduct = useCallback(
    (product: IProductData) => {
      teamViewNavigator.navigate({
        product: product.name,
      })
    },
    [teamViewNavigator],
  )

  const [isProductValid, productId] = useMemo(() => {
    const valid = parseProductName(args.product)
    const productId = getProductIdByName(args.product)
    return [valid !== undefined, productId]
  }, [args.product])

  if (!isProductValid || !productId) {
    return <NotFoundRoutePage />
  }

  return (
    <GlobalProductSelector
      value={args.product}
      onChange={handleSelectProduct}
      isItemDisabled={(product) => !TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST.includes(product.name)}
    >
      <PageTitle title="Team">
        {args.queryId ? (
          <RouteWithQueryData queryId={args.queryId} args={args}>
            {(parsedArgs, currentQuery) => (
              <TeamScreen
                productId={productId}
                key={parsedArgs.product}
                args={parsedArgs}
                currentQuery={currentQuery}
              />
            )}
          </RouteWithQueryData>
        ) : (
          <TeamScreen key={args.product} args={args} productId={productId} />
        )}
      </PageTitle>
    </GlobalProductSelector>
  )
}
