/* eslint-disable @typescript-eslint/no-explicit-any */
import { PromiseSnapshot } from '@copilot-dash/core'
import { mapValues } from 'lodash'
import { DashStoreActions } from '../actions/DashStoreActions'
import { IDashStoreContext } from '../IDashStoreContext'

export type IDashStoreUseHooks = Pick<
  {
    [K in keyof typeof DashStoreActions]: (typeof DashStoreActions)[K] extends (
      context: IDashStoreContext,
      ...args: unknown[]
    ) => Promise<never>
      ? never
      : (typeof DashStoreActions)[K] extends (
            context: IDashStoreContext,
            ...args: infer Args
          ) => PromiseSnapshot<infer Res>
        ? (...args: Args) => PromiseSnapshot<Res>
        : never
  },
  {
    [K in keyof typeof DashStoreActions]: (typeof DashStoreActions)[K] extends (...args: any[]) => PromiseSnapshot<any>
      ? K
      : never
  }[keyof typeof DashStoreActions]
>

export function createDashStoreUseHooks(context: IDashStoreContext): IDashStoreUseHooks {
  return mapValues(DashStoreActions, (action) => {
    return (...args: unknown[]) => {
      return context.use(() => {
        const result = (action as any)(context, ...args) as ReturnType<typeof action>
        if (result instanceof Promise) {
          throw new Error('Actions should return PromiseSnapshot, not Promise')
        }

        return result
      })
    }
  }) as any
}
