import { DrawerBody, makeStyles } from '@fluentui/react-components'
import { ActivityHistoryPanel } from '../../../../components/ActivityHistory/ActivityHistoryPanel'
import { DiscussionPanel } from '../../../../components/DiscussionPanel/DiscussionPanel'
import { Column } from '../../../../components/Layout'
import { Scrollbar } from '../../../../components/Scrollbar/Scrollbar'
import { TicketScreenDrawerType } from '../../store/IState'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketContextView } from './ticket-context/TicketContextView'

interface IProps {
  readonly type: TicketScreenDrawerType
}

export function TicketScreenDrawerBody({ type }: IProps) {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)

  return (
    <DrawerBody className={styles.root} style={{ paddingBottom: 0 }}>
      <Column className={styles.container} vAlign="stretch" hAlign="stretch">
        {(() => {
          switch (type) {
            case TicketScreenDrawerType.TicketContext:
              return <TicketContextView />
            case TicketScreenDrawerType.DiscussionPanel:
              return (
                <Scrollbar className={styles.scrollbar}>
                  <DiscussionPanel discussionId={ticketId} />
                </Scrollbar>
              )
            case TicketScreenDrawerType.ActivityHistory:
              return (
                <Scrollbar className={styles.scrollbar}>
                  <ActivityHistoryPanel ticketId={ticketId} />
                </Scrollbar>
              )
          }
        })()}
      </Column>
    </DrawerBody>
  )
}

const useStyles = makeStyles({
  root: {
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },
  container: {
    width: '100%',
    height: '100%',
  },
  scrollbar: {
    height: '100%',
  },
})
