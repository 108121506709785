import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
  },
  sortable: {
    cursor: 'pointer',
  },
  arrow: {
    fontWeight: tokens.fontWeightBold,
  },
})
