import { Checkbox, Label, makeStyles } from '@fluentui/react-components'
import { Column, Row } from '../Layout'

import { memo } from 'react'

interface IProps {
  selectedValue: boolean | null
  onChangeValue: (item: boolean | null) => void
  label: string
}

export const BooleanCheckbox: React.FC<IProps> = memo(({ selectedValue, onChangeValue, label }) => {
  const styles = useStyles()
  const handleChange = (checkboxLabel: string) => {
    if (!selectedValue && checkboxLabel === 'Yes') {
      selectedValue = true
    } else if (selectedValue !== false && checkboxLabel === 'No') {
      selectedValue = false
    } else {
      selectedValue = null
    }
    onChangeValue(selectedValue)
  }

  return (
    <Column>
      <Label weight="semibold">{label}</Label>
      <Row>
        <Checkbox
          className={styles.checkboxLayout}
          label="Yes"
          checked={selectedValue === true}
          onChange={() => handleChange('Yes')}
        />
        <Checkbox
          className={styles.checkboxLayout}
          label="No"
          checked={selectedValue === false}
          onChange={() => handleChange('No')}
        />
      </Row>
    </Column>
  )
})

const useStyles = makeStyles({
  checkboxLayout: {
    width: '50%',
    wordBreak: 'break-word',
  },
})
BooleanCheckbox.displayName = 'BooleanCheckbox'
