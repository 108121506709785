import { ApiBizPerfLogItem, ApiBizPerfLogItemV2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoBizPerfLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'
import { getTicketTurnMetadata } from '../actions-ticket-chat/getTicketTurnMetadata'

export function getPerformanceLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoBizPerfLogItem[]> {
  return context.getOrFetch<IKustoBizPerfLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.perfLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.perfLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const logs = context.enableV2Endpoint ? await doFetchFromV2() : await doFetchFromV1()
      if (logs.length > 0) {
        return logs
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.BizChatPerfLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.BizChatPerfLog })
    },
  })

  async function doFetchFromV1(): Promise<IKustoBizPerfLogItem[]> {
    const turn = await getTicketTurnMetadata(context, ticketId, messageId).promise.catch(() => undefined)

    const response = await context.api.logCollector.getSydneySingleInfo({
      FetchLogName: 'bizchatPerformanceLog',
      HappenTimeArray: turn?.time ? [turn.time] : [],
      MessageId: messageId,
    })

    const rows = response.logData['bizchatPerformanceEvent (Kusto)'] ?? []
    return rows.map(convertToItem)
  }

  async function doFetchFromV2(): Promise<IKustoBizPerfLogItem[]> {
    const ticketSource = await getTicketSource(context, ticketId).promise
    const source = ticketSource.turns[messageId]?.performanceLog
    if (source) {
      const results = await context.api.copilotDash.getTicketBlob(ticketId, source.blobUrl).asPerformanceLog()
      return results.map(convertToItemV2)
    }

    return []
  }

  function convertToItem(raw: ApiBizPerfLogItem): IKustoBizPerfLogItem {
    return {
      chatFCFR: raw.chatFCFR,
      perfTraceFile: raw.perfTraceFile,
      raw,
    }
  }

  function convertToItemV2(raw: ApiBizPerfLogItemV2): IKustoBizPerfLogItem {
    return {
      chatFCFR: raw.chatFCFR,
      perfTraceFile: raw.PerfTraceFile,
      raw,
    }
  }
}
