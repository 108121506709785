import { IDashStoreContext } from '../../IDashStoreContext'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { NewSearchCopilotTicketsResult } from './NewSearchTicketsAction.types'
import { SearchTicketAction } from './SearchTicketAction'

export function searchTickets(
  context: IDashStoreContext,
  options: ISearchTicketOptions,
  isAISearchEnable?: boolean,
): Promise<NewSearchCopilotTicketsResult> {
  return new SearchTicketAction(context).search(options, isAISearchEnable)
}
