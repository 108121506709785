import { makeStyles, TableCell, TableRow, Text } from '@fluentui/react-components'

interface IProps {
  readonly ticketId: string
}

export function RowForPreChecksWebToggleOff({ ticketId }: IProps) {
  const styles = useStyles()
  const snapshot = application.store.use.getTicketSystemTags(ticketId)
  const webToggleIsOff = snapshot.data?.webToggleIsOff
  if (webToggleIsOff !== true) {
    return
  }

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Pre-checks</Text>
      </TableCell>
      <TableCell>
        User turns off Web Toggle from UX Setting{' '}
        <Text weight="semibold" className={styles.warningColor}>
          Warning
        </Text>
      </TableCell>
    </TableRow>
  )
}

// TODO: Need to be removed and replaced with the global styles
const useStyles = makeStyles({
  loading: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  errorColor: {
    color: 'red',
  },

  warningColor: {
    color: 'orange',
  },

  okColor: {
    color: 'green',
  },
})
