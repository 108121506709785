import { INewTicketData, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { makeStyles, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components'
import { ErrorView } from '../Error'
import { UserProfile } from '../User/UserProfile'
import { memo, useMemo } from 'react'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { CustomCellRendererProps } from 'ag-grid-react'

export const ColumnConfigUserProfile: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.UserProfile],
  field: String(ColumnKey.UserProfile) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <RenderUserProfile item={props.data} />
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.UserProfile]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 120,
  sortable: false,
  resizable: true,
}

const RenderUserProfile = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  const userEmail = application.store.use.getTicketEmail(item.ticketId)

  const content = useMemo(() => {
    switch (userEmail.status) {
      case 'waiting':
        return (
          <Skeleton appearance="translucent">
            <SkeletonItem />
          </Skeleton>
        )
      case 'done':
        return (
          <UserProfile
            userId={userEmail.data}
            fallback={userEmail.data}
            customUserPhotoClassName={styles.customUserPhoto}
            customUserDisplayNameClassName={styles.customUserName}
          />
        )
      case 'error':
        return <ErrorView type="inline" error={userEmail.error} />
      default:
        return null
    }
  }, [styles.customUserName, styles.customUserPhoto, userEmail.data, userEmail.error, userEmail.status])

  return <TableCellLayoutWithStyles>{content}</TableCellLayoutWithStyles>
})
RenderUserProfile.displayName = 'RenderUserProfile'

const useStyles = makeStyles({
  customUserPhoto: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
  },
  customUserName: {
    fontSize: tokens.fontSizeBase300,
  },
})
