import { Spinner } from '@fluentui/react-components'
import { Column } from '../Layout'

export function LoadingTable() {
  return (
    <Column hAlign="center" vAlign="center">
      <Spinner labelPosition="below" label="Loading..." />
    </Column>
  )
}
