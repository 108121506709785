import { SearchBox, InfoLabel, InputOnChangeData } from '@fluentui/react-components'
import { memo, useState, useEffect, useCallback } from 'react'
import { Column, Spacer } from '../Layout'
import { useStyles } from './CopilotDashSearchBox.styles'

interface IProps {
  value: string | undefined
  onChangeValue: (value?: string) => void
  title: string
  placeholder?: string
  info?: string
}

export const CopilotDashSearchBox: React.FC<IProps> = memo(({ value, onChangeValue, placeholder, title, info }) => {
  const styles = useStyles()
  const [inputValue, setInputValue] = useState<string>('')

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (inputValue) {
        onChangeValue(inputValue)
      }
    }
  }
  const onBlur = () => {
    if (inputValue) {
      onChangeValue(inputValue)
    }
  }
  const onInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }, [])

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLSpanElement>,
    data: InputOnChangeData,
  ) => {
    if (inputValue && !data.value) {
      onChangeValue(data.value)
    }
    setInputValue(data.value)
  }

  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  return (
    <Column className={styles.root}>
      <InfoLabel weight="semibold" info={info}>
        {title}
      </InfoLabel>
      <Spacer size={4} />
      <SearchBox
        autoComplete="none"
        aria-autocomplete="none"
        placeholder={placeholder}
        value={inputValue}
        onKeyDownCapture={handleKeyDown}
        onChange={onChange}
        className={inputValue ? styles.selected : ''}
        onInput={onInput}
        onBlur={onBlur}
        type="search"
      />
    </Column>
  )
})

CopilotDashSearchBox.displayName = 'CopilotDashSearchBox'
