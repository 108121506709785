import { EnableExceptionTracking, Logger } from '@copilot-dash/logger'
import { BaseTicketSearchAction } from './BaseTicketSearchAction'
import { IDashStoreContext } from '../../IDashStoreContext'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { uuid } from '@copilot-dash/core'
import { convertRootCauseList } from '../actions-team-root-cause/converters/convertRootCauseList'
import { SearchTicketWithIdListAction } from './SearchTicketWithIdListAction'

interface IParams {
  readonly options: ISearchTicketOptions
}

export class SearchRootCauseByTicketCharacter extends BaseTicketSearchAction {
  private readonly SearchTicketWithIdListAction: SearchTicketWithIdListAction

  constructor(context: IDashStoreContext) {
    super(context)
    this.SearchTicketWithIdListAction = new SearchTicketWithIdListAction(context)
  }

  @EnableExceptionTracking()
  async search(params: IParams) {
    const searchId = uuid()
    const startTime = Date.now()

    const needToSearchPartialAiSearch = this.needToSearchPartialAiSearch(params.options)
    let partialAiSearchTicketIdList: string[] = []
    if (needToSearchPartialAiSearch) {
      partialAiSearchTicketIdList = await this.searchWithPartialAiSearch(params.options)
    }

    const request = this.createRequest({
      options: params.options,
      searchId: searchId,
      partialAiSearchTicketIdList,
      partialAiSearchTriggered: needToSearchPartialAiSearch,
    })

    try {
      const response = await this.context.api.logCollector.searchRootCauseListByTicketCharacter(request)
      return convertRootCauseList(response, this.context)
    } finally {
      const duration = Date.now() - startTime

      Logger.telemetry.trackMetric('SearchTime', {
        duration,
        filters: params.options,
        searchId,
        page: 'Team View',
        partialAiSearchTriggered: false,
        isFetchingRootCause: true,
      })
    }
  }
}
