import { PromiseSnapshot } from '@copilot-dash/core'
import { mapValues } from 'lodash'
import { DashStoreActions } from '../actions/DashStoreActions'
import { IDashStoreContext } from '../IDashStoreContext'

export type IDashStoreActions = {
  [K in keyof typeof DashStoreActions]: (typeof DashStoreActions)[K] extends (
    context: IDashStoreContext,
    ...args: infer Args
  ) => Promise<infer Res>
    ? (...args: Args) => Promise<Res>
    : (typeof DashStoreActions)[K] extends (
          context: IDashStoreContext,
          ...args: infer Args
        ) => PromiseSnapshot<infer Res>
      ? (...args: Args) => Promise<Res>
      : never
}

export function createDashStoreActions(context: IDashStoreContext): IDashStoreActions {
  return mapValues(DashStoreActions, (action) => {
    return (...args: unknown[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result = (action as any)(context, ...args) as ReturnType<typeof action>
      if (result instanceof Promise) {
        return result
      }

      switch (result.status) {
        case 'waiting':
          return result.promise
        case 'done':
          return Promise.resolve(result.data)
        case 'error':
          return Promise.reject(result.error)
      }
    }
  }) as IDashStoreActions
}
