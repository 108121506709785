import { ApiConversationGroup3Metric, ApiConversationGroup4Metric } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { ITelemetryMetric } from '@copilot-dash/domain'
import { compact, sortBy, uniqBy } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawConversationGroup3 } from '../actions-raw-ticket-chat/getRawConversationGroup3'
import { getRawConversationGroup4 } from '../actions-raw-ticket-chat/getRawConversationGroup4'

export function getTelemetryMetrics(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<ITelemetryMetric[]> {
  return context.getOrFetch<ITelemetryMetric[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.conversationMetrics
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.conversationMetrics = snapshot
    },
    fetch: async () => {
      const group3MetricsPromise = fetchFromGroup3()
      const group4MetricsPromise = fetchFromGroup4().catch(() => [])

      const metrics = merge(await group3MetricsPromise, await group4MetricsPromise)
      if (metrics.length > 0) {
        return metrics
      }

      throw new Error('No telemetry metrics found')
    },
  })

  async function fetchFromGroup3(): Promise<ITelemetryMetric[]> {
    const group3 = await getRawConversationGroup3(context, ticketId, messageId).promise
    const group3Metrics = group3.conversation?.requests?.[0]?.telemetry?.metrics ?? []

    return compact(group3Metrics.map((item) => convertItem(item, false)))
  }

  async function fetchFromGroup4(): Promise<ITelemetryMetric[]> {
    if (!context.hasTier2) {
      return []
    }

    const group4 = await getRawConversationGroup4(context, ticketId, messageId).promise
    const group4Metrics = group4.conversation?.requests?.[0]?.telemetry?.metrics ?? []

    return compact(group4Metrics.map((item) => convertItem(item, true)))
  }

  function merge(group3: ITelemetryMetric[], group4: ITelemetryMetric[]): ITelemetryMetric[] {
    const items1 = [...group3, ...group4]
    const items2 = uniqBy(items1, (item) => item.scopeId)
    const items3 = sortBy(items2, (item) => item.startTime)

    return items3
  }

  function convertItem(
    raw: ApiConversationGroup3Metric | ApiConversationGroup4Metric,
    fromGroup4: boolean,
  ): ITelemetryMetric | undefined {
    if (!raw.scopeId && !raw.scopeName) {
      return undefined
    }

    return {
      serviceName: raw.serviceName,
      scopeId: raw.scopeId,
      scopeName: raw.scopeName,
      startTime: raw.startTime,
      status: raw.status,
      statusLevel: getStatusLevel(raw),
      path: raw.path,
      latency: raw.latencyMilliseconds,
      input: raw.input,
      output: raw.output,
      fromGroup4,
      raw,
    }
  }

  function getStatusLevel(raw: ApiConversationGroup3Metric): 'success' | 'error' | 'unknown' {
    const status = raw.status?.toLowerCase()
    if (!status) {
      return 'unknown'
    }

    if (status.includes('success') || status.includes('ok') || status.includes('pass') || status.includes('good')) {
      return 'success'
    }

    if (status.includes('error') || status.includes('fail')) {
      return 'error'
    }

    return 'unknown'
  }
}
