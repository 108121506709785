import { INewTicketData } from '@copilot-dash/domain'
import { ColDef, SortDirection } from 'ag-grid-community'
import { z } from 'zod'

const SortDirectionSchema = z.enum(['asc', 'desc'])
const SUFFIX_CONTAINER_CLASS = 'CopilotDashTableStickySuffixContainer'
export const getOrCreateStickySuffixContainer = (rowElement: HTMLElement): HTMLElement => {
  const existingContainer = rowElement.querySelector('.' + SUFFIX_CONTAINER_CLASS)
  if (existingContainer) {
    return existingContainer as HTMLElement
  }

  const anchorContainer = document.createElement('div')

  anchorContainer.style.width = '0px'
  anchorContainer.style.height = '0px'
  anchorContainer.style.position = 'sticky'
  anchorContainer.style.right = '0'
  anchorContainer.style.marginLeft = 'auto'
  anchorContainer.style.zIndex = '1'

  const container = document.createElement('div')
  container.className = SUFFIX_CONTAINER_CLASS
  container.style.transform = 'translateX(-100%)'
  container.style.width = 'fit-content'
  container.addEventListener('click', (e) => {
    e.stopPropagation()
  })

  anchorContainer.appendChild(container)
  rowElement.appendChild(anchorContainer)
  return container
}

export function addSortModelToAGGridColumn(
  columns: ColDef<INewTicketData>[],
  sortModel?: Array<string>,
): ColDef<INewTicketData>[] {
  return columns.map((column) => {
    const sortState = sortModel?.find((sort) => sort.startsWith(String(column.field)))
    const sort = sortState
      ? SortDirectionSchema.safeParse(sortState.split(' ')[1]).success
        ? (sortState.split(' ')[1] as SortDirection)
        : undefined
      : undefined
    return {
      ...column,
      sort: sort, // Set the initial sort state
    }
  })
}
