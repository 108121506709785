import { Control, Controller } from 'react-hook-form'
import { GroundedPromptsFilter, InvocationTypeFilter, LanguageFilter, InvocationSlicerFilter } from '../filters'

import { Body1Stronger } from '@fluentui/react-components'
import { Card } from './Card'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'
import { PromptDetailsCardFilters } from './config'
import { SearchFilterFieldNames } from '@copilot-dash/domain'
import { useMemo } from 'react'
import { useStyles } from './Card.styles'
import { useTicketFilterStore } from '../../store/store'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export function PromptDetailsCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)

  const isInvocationSlicerEnable = useTicketFilterStore((state) => state.isInvocationSlicerEnable)

  const hasPromptDetailsCard = useMemo(() => {
    return filterPanelItems.some((item) => PromptDetailsCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasPromptDetailsCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Prompt details</Body1Stronger>
      <Card className={styles.card}>
        {filterPanelItems?.includes(SearchFilterFieldNames.promptLanguages) && (
          <Controller
            name="promptLanguages"
            control={control}
            render={({ field }) => <LanguageFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.groundedPrompts) && (
          <Controller
            name="groundedPrompts"
            control={control}
            render={({ field }) => (
              <GroundedPromptsFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}

        {!isInvocationSlicerEnable && filterPanelItems?.includes(SearchFilterFieldNames.invocationType) && (
          <Controller
            name="invocationType"
            control={control}
            render={({ field }) => <InvocationTypeFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {isInvocationSlicerEnable && filterPanelItems?.includes(SearchFilterFieldNames.invocationSlicers) && (
          <Controller
            name="invocationSlicers"
            control={control}
            render={({ field }) => (
              <InvocationSlicerFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}
      </Card>
    </section>
  )
}
