import { makeStyles } from '@fluentui/react-components'
import { CopyButton } from '../../../../Copy/CopyButton'

const LONG_TEXT_LENGTH = 100

interface IProps {
  readonly value: string
}

export function ValueViewForStringCopyButton({ value }: IProps) {
  const styles = useStyles()
  const isLongText = value.length > LONG_TEXT_LENGTH
  if (!isLongText) {
    return null
  }

  return (
    <span className={styles.root}>
      <CopyButton content={value} hideText />
    </span>
  )
}

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    paddingLeft: '16px',
  },
})
