import { TelemetryScope } from '@copilot-dash/logger'
import { useEffect } from 'react'
import { SymptomReportLayout } from './layout/SymptomReportLayout'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function SymptomReport({ ticketId, messageId }: IProps) {
  useEffect(() => {
    let isAlive = true

    const promises: Promise<unknown>[] = [
      application.store.actions.getTicketMetadata(ticketId),
      application.store.actions.get3sGwsLogs(ticketId, messageId),
      application.store.actions.getTicketHealthData(ticketId, messageId),
    ]

    Promise.all(promises.map((item) => item.catch())).then(async () => {
      const ticket = await application.store.actions.getTicketEnvironment(ticketId)
      if (isAlive) {
        Logger.telemetry.trackEvent('TicketSymptomReport/Loaded', {
          ticketId,
          ticketRing: ticket.ring,
          productEndpoint: ticket.clientName,
        })
      }
    })

    return () => {
      isAlive = false
    }
  }, [ticketId, messageId])

  return (
    <TelemetryScope scope="SymptomReportView" properties={{ ticketId: ticketId }}>
      <SymptomReportLayout ticketId={ticketId} messageId={messageId} />
    </TelemetryScope>
  )
}
