import { Tag, Tooltip, makeStyles } from '@fluentui/react-components'

import { ApiClientEndpoint } from '@copilot-dash/api'

interface IProps {
  readonly baseUrl: string
  readonly endpoint: ApiClientEndpoint
}

export function ApiRequestTableEndpoint({ baseUrl, endpoint }: IProps) {
  const styles = useStyles()

  const getTooltipContent = () => {
    if (baseUrl) {
      return baseUrl
    }

    switch (endpoint) {
      case ApiClientEndpoint.CopilotDash:
        return 'Copilot Dash API'
      case ApiClientEndpoint.MicrosoftGraph:
        return 'Microsoft Graph API'
      case ApiClientEndpoint.ODS:
        return 'ODS API'
      case ApiClientEndpoint.OCV:
        return 'OCV API'
      case ApiClientEndpoint.Automation:
        return 'Automation API'
    }
  }

  return (
    <Tooltip content={getTooltipContent()} relationship={'label'}>
      <Tag className={styles.tag} size="extra-small" primaryText={endpoint} shape="circular" />
    </Tooltip>
  )
}

const useStyles = makeStyles({
  tag: {
    cursor: 'pointer',
  },
})
