import { Text, Button } from '@fluentui/react-components'
import { ImageRegular } from '@fluentui/react-icons'
import { CopilotDashTooltip } from '../../../CopilotDashTooltip/CopilotDashTooltip'
import { useRef } from 'react'
import { IsExpectedImageType, MAX_IMAGE_SIZE, ValidImageTypes } from '../../utils/file'
import { useToast } from '../../../../hooks/useToast'
import { LexicalEditor } from 'lexical'
import { INSERT_IMAGE_COMMAND } from './ImagesPlugin'
import { LexicalRichTextEditorStore } from '../../LexicalRichTextEditorStore'

interface FileUploadButtonProps {
  editor: LexicalEditor
}

export async function handleImageUpload(editor: LexicalEditor, file: File): Promise<void> {
  const imageUrl = await application.store.actions.uploadImage(file)
  editor.dispatchCommand(INSERT_IMAGE_COMMAND, imageUrl)
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({ editor }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const actions = LexicalRichTextEditorStore.useActions()
  const toast = useToast()
  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      if (!IsExpectedImageType(file)) {
        toast.showWarning('Please select ' + ValidImageTypes.join(',') + 'type.')
        return
      }
      if (file.size > MAX_IMAGE_SIZE) {
        toast.showWarning('The maximum value is 10MB.')
        return
      }
      try {
        actions.uploading()
        await handleImageUpload(editor, file)
        toast.showSuccess('Image uploaded successfully')
      } catch (error) {
        toast.showError('Failed to upload the image. Please try again.')
      } finally {
        actions.uploaded()
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
      }
    }
  }

  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={<Text>Upload Image</Text>}
      toolTipParentContent={
        <div>
          <Button icon={<ImageRegular />} appearance="transparent" onClick={handleButtonClick} />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept={ValidImageTypes.join(',')}
            onChange={handleFileChange}
          />
        </div>
      }
    />
  )
}
