import { ApiBizChat3SLatencyLogItem, ApiBizChat3SLatencyLogItemV2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoBizChat3SLatencyLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicket3sTriggered } from '../actions-ticket-assert/assertTicket3sTriggered'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'
import { getTicketTurnMetadata } from '../actions-ticket-chat/getTicketTurnMetadata'

export function get3SLatencyLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoBizChat3SLatencyLogItem[]> {
  return context.getOrFetch<IKustoBizChat3SLatencyLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.threeSLatencyLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.threeSLatencyLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const logs = context.enableV2Endpoint ? await doFetchFromV2() : await doFetchFromV1()
      if (logs.length > 0) {
        return logs
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicket3sTriggered(context, ticketId, messageId)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.BizChat3SLatencyLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.BizChat3SLatencyLog })
    },
  })

  async function doFetchFromV1(): Promise<IKustoBizChat3SLatencyLogItem[]> {
    const turn = await getTicketTurnMetadata(context, ticketId, messageId).promise.catch(() => undefined)

    const response = await context.api.logCollector.getSydneySingleInfo({
      FetchLogName: 'bizChat3SLatencyLog',
      HappenTimeArray: turn?.time ? [turn.time] : [],
      MessageId: messageId,
    })

    return response.logData['bizChat3SLatencyLogs (Kusto)']?.map(convertItemV1) ?? []
  }

  async function doFetchFromV2(): Promise<IKustoBizChat3SLatencyLogItem[]> {
    const ticketSource = await getTicketSource(context, ticketId).promise
    const source = ticketSource.turns[messageId]?.substrateSearchLatencyLog
    if (source) {
      const results = await context.api.copilotDash
        .getTicketBlob(ticketId, source.blobUrl)
        .asSubstrateSearchLatencyLog()

      return results.map(convertItemV2)
    }

    return []
  }

  function convertItemV1(raw: ApiBizChat3SLatencyLogItem): IKustoBizChat3SLatencyLogItem {
    return {
      ...raw,
      transactionId: raw.transactionId,
      perfTraceFile: raw.perfTraceFile,
    }
  }

  function convertItemV2(raw: ApiBizChat3SLatencyLogItemV2): IKustoBizChat3SLatencyLogItem {
    return {
      ...raw,
      transactionId: raw.TransactionId,
      perfTraceFile: raw.PerfTraceFile,
    }
  }
}
