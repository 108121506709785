import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { Row } from '../../../../../../components/Layout'

interface IProps {
  readonly group: string | undefined
}

export function LabsPanelGroupTitle({ group }: IProps) {
  const classes = useStyles()
  if (!group) {
    return null
  }

  return (
    <Row className={classes.root}>
      <Text className={classes.text} size={200}>
        {group}
      </Text>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '4px 12px',
  },
  text: {
    color: tokens.colorNeutralForeground4,
  },
})
