import { Column, Spacer } from '../../../Layout'
import { InfoLabel } from '@fluentui/react-components'
import { EmailTagPickerFilter } from '../../../Filter/EmailTagPickerFilter'

interface IProps {
  selectedValues: string | undefined
  onChangeValue: (value: string | undefined) => void
}

export const AssignedToFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  return (
    <Column>
      <InfoLabel weight="semibold">Assigned To:</InfoLabel>
      <Spacer size={4} />
      <EmailTagPickerFilter emailAddress={selectedValues} onChangeUser={onChangeValue} showInput={true} />
    </Column>
  )
}
