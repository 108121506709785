import { Times } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicket } from '../actions-raw-ticket-ods/getRawOdsTicket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export async function assertTicket3sOfflineCooked(context: IDashStoreContext, ticketId: string): Promise<void> {
  let createTime: string | undefined
  if (context.enableV2Endpoint) {
    const raw = await getRawV2Ticket(context, ticketId).promise
    createTime = raw.userFeedback.createdDateTime
  } else {
    const raw = await getRawOdsTicket(context, ticketId).promise
    createTime = raw['Feedback Date']
  }

  if (!createTime) {
    return
  }

  if (Times.isWithinLastXHours(createTime, 6)) {
    throw TicketError.create('No3sOfflineDueTo6Hours', { ticketId, ticketCreationTime: createTime })
  }

  if (Times.isWithinLastXHours(createTime, 48)) {
    throw TicketError.create('No3sOfflineDueTo48Hours', { ticketId, ticketCreationTime: createTime })
  }
}
