import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  tableCellLayout: {
    width: '100%',
    height: '100%',
    ...shorthands.padding('8px 0'),
    wordWrap: 'break-word',
    wordBreak: 'normal',
    overflowWrap: 'break-word',
    lineHeight: tokens.lineHeightBase300,
    alignItems: 'center',
    overflow: 'hidden',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    alignContent: 'center',
    '&.em': {
      color: 'red',
    },
  },
})
