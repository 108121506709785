import { Button, Input, makeStyles, tokens } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'

interface IProps {
  inputValue: string
  setInputValue: (value: string) => void
  onKeyDown?: (value: string) => void
  isKeyDownAllowed?: boolean
}

export const CopilotDashDropdownInput: React.FC<IProps> = ({
  inputValue,
  setInputValue,
  onKeyDown,
  isKeyDownAllowed,
}) => {
  const styles = useStyles()

  const handleInputChange = (value: string) => {
    setInputValue(value)
  }

  const handleInputCleanClick = () => {
    setInputValue('')
  }
  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isKeyDownAllowed) {
      return
    }
    if (event.key === 'Enter' && onKeyDown) {
      onKeyDown(inputValue)
    }
  }

  return (
    <Input
      className={styles.input}
      onChange={(_, data) => handleInputChange(data.value)}
      value={inputValue}
      placeholder="Search"
      onKeyDown={handleKeydown}
      contentAfter={
        inputValue.length > 0 ? (
          <Button icon={<DismissRegular />} appearance="subtle" size="small" onClick={handleInputCleanClick} />
        ) : null
      }
    />
  )
}

const useStyles = makeStyles({
  input: {
    backgroundColor: tokens.colorNeutralCardBackgroundPressed,
    borderRadius: '4px',
    margin: '8px',
    border: 'none',
  },
})
