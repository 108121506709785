import { PromiseSnapshots } from '@copilot-dash/core'
import { IQuery } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function deleteQuery(context: IDashStoreContext, query: IQuery): Promise<void> {
  return context.requestAnd({
    request: async () => {
      await context.api.logCollector.deleteQuery(query.queryId)
    },
    onSuccess(state, _) {
      const productId = query.productId
      const teamId = query.teamId
      const key = `${productId}-${teamId}`
      const queryId = query.queryId

      // update queriesMap
      state.query.QueriesMap[queryId] = PromiseSnapshots.error('Query deleted')

      const myQueriesMapSnapshot = state.query.myQueriesMap[productId]
      const teamQueriesMapSnapshot = state.query.teamQueriesMap[key]
      let updateTeamQueries: IQuery[] = []
      let updateMyQueries: IQuery[] = []
      // update teamQueriesMapSnapshot
      if (query.isTeamShared) {
        if (teamQueriesMapSnapshot) {
          switch (teamQueriesMapSnapshot.status) {
            case 'error':
              break
            case 'waiting':
              break
            case 'done':
              updateTeamQueries = teamQueriesMapSnapshot.data.filter((q) => q.queryId !== queryId)
              state.query.teamQueriesMap[key] = PromiseSnapshots.done(updateTeamQueries)
              break
          }
        }
      }
      // update myQueriesMapSnapshot
      else {
        if (myQueriesMapSnapshot) {
          switch (myQueriesMapSnapshot.status) {
            case 'error':
              break
            case 'waiting':
              break
            case 'done':
              updateMyQueries = myQueriesMapSnapshot.data.filter((q) => q.queryId !== queryId)
              state.query.myQueriesMap[productId] = PromiseSnapshots.done(updateMyQueries)
              break
          }
        }
      }
    },
  })
}
