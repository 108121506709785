import { TicketCallFlowLayout } from './layout/TicketCallFlowLayout'
import { TicketCallFlowStore } from './TicketCallFlowStore'

interface IProps {
  readonly ticketId: string
  readonly selectedMessageId?: string
  readonly selectedEventId?: string
  readonly onSelect?: (messageId: string, scopeId: string | undefined) => void
}

export function TicketCallFlow({ ticketId, selectedMessageId, selectedEventId, onSelect }: IProps) {
  return (
    <TicketCallFlowStore
      ticketId={ticketId}
      selectedMessageId={selectedMessageId}
      selectedEventId={selectedEventId}
      onSelect={onSelect}
    >
      <TicketCallFlowLayout />
    </TicketCallFlowStore>
  )
}
