import { makeStyles, tokens } from '@fluentui/react-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import { TicketScreenDrawerType } from '../../store/IState'

const MIN_DRAGGABLE_WIDTH = 128

interface IProps {
  readonly type: TicketScreenDrawerType
  readonly drawerRef: React.RefObject<HTMLDivElement>
  readonly setDrawerWidth: (width: number) => void
  readonly open: boolean
}

export function TicketScreenDrawerResizer({ type, drawerRef, setDrawerWidth, open }: IProps) {
  const styles = useStyles()
  const animationFrame = useRef<number>(0)
  const [isResizing, setIsResizing] = useState(false)

  const startResizing = useCallback(() => setIsResizing(true), [])
  const stopResizing = useCallback(() => setIsResizing(false), [])

  const handleWidthChange = useCallback(
    (width: number) => {
      setDrawerWidth(width)
      switch (type) {
        case TicketScreenDrawerType.TicketContext:
          return application.settings.ticketContextDrawerWidth.set(width)
        case TicketScreenDrawerType.DiscussionPanel:
          return application.settings.discussionPanelDrawerWidth.set(width)
        case TicketScreenDrawerType.ActivityHistory:
          return application.settings.activityHistoryDrawerWidth.set(width)
      }
    },
    [type, setDrawerWidth],
  )

  const resize = useCallback(
    ({ clientX }: MouseEvent) => {
      if (isResizing) {
        animationFrame.current = requestAnimationFrame(() => {
          if (drawerRef.current) {
            const width = drawerRef.current.getBoundingClientRect().right - clientX
            handleWidthChange(width < MIN_DRAGGABLE_WIDTH ? MIN_DRAGGABLE_WIDTH : width)
          }
        })
      }
    },
    [isResizing, handleWidthChange, drawerRef],
  )

  const ResizeComponent: React.FC = () => <div className={styles.resizer} onMouseDown={startResizing} />

  useEffect(() => {
    window.addEventListener('mousemove', resize)
    window.addEventListener('mouseup', stopResizing)

    return () => {
      cancelAnimationFrame(animationFrame.current)
      window.removeEventListener('mousemove', resize)
      window.removeEventListener('mouseup', stopResizing)
    }
  }, [resize, stopResizing])

  return open && <ResizeComponent />
}

const useStyles = makeStyles({
  resizer: {
    width: '1px',
    cursor: 'col-resize',
    resize: 'horizontal',
    '&:hover': {
      borderLeft: `4px solid ${tokens.colorNeutralBackground5}`,
    },
  },
})
