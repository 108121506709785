import { Spinner } from '@fluentui/react-components'
import { Row } from '../Layout'

export function LoadingRow({ height = '56px' }: { height?: string }) {
  return (
    <Row style={{ height: height }} hAlign="center" vAlign="center">
      <Spinner />
    </Row>
  )
}
