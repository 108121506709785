import { IDashStoreContext } from '../../IDashStoreContext'

export async function removeTicketFromTopIssue(
  context: IDashStoreContext,
  batchId: string,
  issueId: string,
  feedbackId: string,
) {
  return context.api.logCollector.removeTicketFromTopIssue({
    ClusterBatchId: batchId,
    ClusterIssueId: issueId,
    FeedbackId: feedbackId,
  })
}
