import { INewTicketData, TicketEmotionType, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { Row, Column } from '../Layout'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'
import { memo } from 'react'
import { useStyles } from './ColumnComponent.styles'
import { Text } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { CustomCellRendererProps } from 'ag-grid-react'

export const ColumnConfigRootCause: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.RootCause],
  field: String(ColumnKey.RootCause) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        <RenderRootCauseList item={props.data} />
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.RootCause]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 130,
  sortable: false,
  resizable: true,
}

const RenderRootCauseList = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else if (!item.rootCauseList || item.rootCauseList.length === 0) {
    return <Text className={styles.grayColor}>Need to add</Text>
  }
  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Column className={styles.tooltipContent}>
          {item.rootCauseList.map((item) => (
            <Row key={item.issueId}>{item.rootCauseTitle}</Row>
          ))}
        </Column>
      }
      className={styles.tooltip}
      toolTipParentContent={
        <Text className={styles.text}>{item.rootCauseList.map((item) => item.rootCauseTitle).join(', ')}</Text>
      }
    />
  )
})

RenderRootCauseList.displayName = 'RenderRootCauseList'
