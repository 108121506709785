import { IRootCauseData, IRootCauseListByTeam } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { PromiseSnapshot } from '@copilot-dash/core'
import { assign } from 'lodash'
import { convertRootCauseList } from './converters/convertRootCauseList'
import { ISearchTicketArgs } from '../actions-search/SearchTicketAction.types'
import { SearchRootCauseByTicketCharacter } from '../actions-search/SearchRootCauseByTicketCharacter'

export async function getRootCauseListByTeam(
  context: IDashStoreContext,
  productId: number,
  teamId: number,
): Promise<IRootCauseListByTeam> {
  const response = await context.api.logCollector.getRootCauseListByTeam({ ProductId: productId, TeamId: teamId })

  return convertRootCauseList(response, context)
}

export async function searchRootCauseByTicketCharacter(context: IDashStoreContext, filter: ISearchTicketArgs) {
  // NOTE: @Ethan - offset and count are not used in service side, we keep it here for future use.
  return new SearchRootCauseByTicketCharacter(context).search({ options: { offset: 0, count: 100, ...filter } })
}

export function getOrFetchRootCauseListByTeam(
  context: IDashStoreContext,
  productId: number,
  teamId: number,
): PromiseSnapshot<IRootCauseListByTeam> {
  return context.getOrFetch({
    get: (state) => {
      return state.team.rootCausesMap?.[`${productId}-${teamId}`]
    },
    set: (state, snapshot) => {
      if (!state.team.rootCausesMap) {
        state.team.rootCausesMap = {}
      }
      state.team.rootCausesMap[`${productId}-${teamId}`] = snapshot
    },
    fetch: () => {
      return getRootCauseListByTeam(context, productId, teamId)
    },
  })
}

export async function appendRootCauseByTeam(
  context: IDashStoreContext,
  productId: number,
  teamId: number,
  rootCause: IRootCauseData,
): Promise<void> {
  const rootCausesMap = context.state.team.rootCausesMap
  const rootCauseList = rootCausesMap?.[`${productId}-${teamId}`]?.data?.rootCauseList

  if (!rootCauseList) {
    return
  }
  const existedRootCauseIndex = rootCauseList.findIndex((rc) => rc.issueId === rootCause.issueId)
  if (existedRootCauseIndex > -1) {
    context.use.setState((state) => {
      state.team.rootCausesMap?.[`${productId}-${teamId}`]?.data?.rootCauseList?.splice(
        existedRootCauseIndex,
        1,
        rootCause,
      )
    })
  } else {
    context.use.setState((state) => {
      const list = state.team.rootCausesMap?.[`${productId}-${teamId}`]?.data?.rootCauseList
      const data = state.team.rootCausesMap?.[`${productId}-${teamId}`]?.data
      if (data && list) {
        data.rootCauseList = [...list, rootCause]
      }
    })
  }
  return
}

export async function updateRootCauseByTeam(
  context: IDashStoreContext,
  productId: number,
  teamId: number,
  rootCause: Partial<IRootCauseData> & { issueId: string },
): Promise<void> {
  const rootCausesMap = context.state.team.rootCausesMap
  const rootCauseList = rootCausesMap?.[`${productId}-${teamId}`]?.data?.rootCauseList

  if (!rootCauseList) {
    return
  }
  const existedRootCauseIndex = rootCauseList.findIndex((rc) => rc.issueId === rootCause.issueId)
  if (existedRootCauseIndex > -1) {
    context.use.setState((state) => {
      state.team.rootCausesMap?.[`${productId}-${teamId}`]?.data?.rootCauseList?.splice(
        existedRootCauseIndex,
        1,
        assign({}, rootCauseList[existedRootCauseIndex], rootCause),
      )
    })
  }
  return
}
