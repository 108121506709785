import { useMemo } from 'react'
import { Column } from '../Layout'
import { AnyPropertyViewContent } from './AnyPropertyViewContent'
import { generateAnyPropertyItems } from './utils/generateAnyPropertyItems'

interface IProps {
  readonly data: unknown
}

export function AnyPropertyView({ data }: IProps) {
  const items = useMemo(() => {
    return generateAnyPropertyItems(data)
  }, [data])

  return (
    <Column style={{ flex: 1 }} hAlign="stretch" fill>
      <AnyPropertyViewContent items={items} />
    </Column>
  )
}
