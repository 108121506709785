import { ColDef } from 'ag-grid-community'
import { AnyPropertyItem } from '../../AnyPropertyType'

export const NameColumn: ColDef<AnyPropertyItem> = {
  field: 'name',
  headerName: 'key',
  width: 200,
  cellRenderer: 'nameRenderer',
  cellStyle: {
    userSelect: 'text',
    cursor: 'initial',
  },
}
