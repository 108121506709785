import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getAllCustomTags(context: IDashStoreContext): PromiseSnapshot<string[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.basic.allCustomTags
    },
    set: (state, snapshot) => {
      state.basic.allCustomTags = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getAllCustomTags()
      return response.customTags ?? []
    },
  })
}
