import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicketInteractions } from '../actions-raw-ticket-ods/getRawOdsTicketInteractions'

export async function assertTicket3sOfflineExists(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
  transactionId?: string,
): Promise<void> {
  await assertFromODS(context, ticketId, messageId, transactionId)
}

async function assertFromODS(context: IDashStoreContext, ticketId: string, messageId: string, transactionId?: string) {
  const interactions = await getRawOdsTicketInteractions(context, ticketId).promise
  const interaction = interactions.find((i) => i.MessageId === messageId)
  if (!interaction) {
    return
  }

  // If transactionId is provided, check if the interaction is part of the transaction
  const transaction = interaction?.Diagnostic?.Offline?.find((item) => item.TransactionId === transactionId)
  if (transaction) {
    const statusCode = transaction.StatusCode
    if (statusCode) {
      const error = TicketError.diagnostic(statusCode, ticketId)
      if (error) {
        throw error
      }
    }
    return
  }

  // Return the first diagnostic error if it exists
  for (const item of interaction?.Diagnostic?.Offline || []) {
    const statusCode = item.StatusCode
    if (statusCode) {
      const error = TicketError.diagnostic(statusCode, ticketId)
      if (error) {
        throw error
      }
    }
  }
}
