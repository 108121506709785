import { TableCell, TableRow, Text } from '@fluentui/react-components'

interface IProps {
  readonly ticketId: string
}

export function RowForUtteranceInputCIQ({ ticketId }: IProps) {
  const tag = application.store.use.getTicketSystemTags(ticketId).data
  const ciq = tag?.ciq
  if (!ciq) {
    return null
  }

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Utterance Input</Text>
      </TableCell>
      <TableCell>
        <span>
          <Text>Attach</Text>
          <Text weight="semibold"> {ciq}</Text>
          <Text> from</Text>
          <Text weight="semibold"> CIQ</Text>
        </span>
      </TableCell>
    </TableRow>
  )
}
