import { Text } from '@fluentui/react-components'
import { useMemo } from 'react'
import { RouteLink } from '../../../../router'
import { TicketScreenStore } from '../../TicketScreenStore'
import { tryParseUrl } from '../common/Utils'

export function TicketScreenBarTicketADOLink() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const snapshot = application.store.use.getRawV1Ticket(ticketId)

  const vsoLinkObject = useMemo(() => {
    const vsoLink = snapshot?.data?.vsoLink
    if (!vsoLink) {
      return null
    }

    const vsoUrl = tryParseUrl(vsoLink)
    if (!vsoUrl) {
      return null
    }

    const vsoTicketId =
      vsoUrl.pathname
        .split('/')
        .filter((segment) => segment.length > 0)
        .pop() ?? vsoLink

    return { vsoTicketId, vsoLink }
  }, [snapshot])

  if (!vsoLinkObject) {
    return null
  }

  return (
    <Text>
      <Text weight="semibold">ADO:&nbsp;</Text>
      <RouteLink path={vsoLinkObject.vsoLink} openInNewTab type="link">
        {vsoLinkObject.vsoTicketId}
      </RouteLink>
    </Text>
  )
}
