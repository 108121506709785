import { z } from 'zod'

const apiConversationEnterpriseSchema = z.object({
  Index: z.number(),
  Type: z.string(),
})

const apiConversationWebSearchResultsSchema = z.object({
  index: z.union([z.number(), z.string()]),
  Type: z.string().optional(),
})

const apiConversationReferenceMetadataSchema = z.object({
  type: z.string().optional(),
})

const apiConversationSpecialTextSchema = z.object({
  Enterprise: z.array(apiConversationEnterpriseSchema).optional(),
  web_search_results: z.array(apiConversationWebSearchResultsSchema).optional(),
})

const apiConversationSourceAttributionsSchema = z.object({
  referenceMetadata: apiConversationReferenceMetadataSchema.optional(),
})

const apiConversationSuggestedResponseSchema = z.record(z.unknown()).and(
  z.object({
    text: z.union([z.string(), apiConversationSpecialTextSchema]).optional(),
    author: z.string().optional(),
    messageId: z.string(),
    messageType: z.string(),
    sourceAttributions: z.array(apiConversationSourceAttributionsSchema).optional(),
  }),
)

const apIOdsConversationMessageSchema = z.record(z.unknown()).and(
  z.object({
    text: z.union([z.string(), apiConversationSpecialTextSchema]).optional(),
    author: z.string().optional(),
    messageId: z.string(),
    messageType: z.string().optional(),
    invocation: z.string().optional(),
    createdAt: z.string().optional(),
    sourceAttributions: z.array(apiConversationSourceAttributionsSchema).optional(),
    suggestedResponses: z.array(apiConversationSuggestedResponseSchema).optional(),
  }),
)

const apiConversationDataSchema = z.record(z.unknown()).and(
  z.object({
    messages: z.array(apIOdsConversationMessageSchema),
    utterance: z.string().optional(),
    botResponse: z.string().optional(),
  }),
)

export const apiConversationSchema = z.record(z.unknown()).and(
  z.object({
    conversation: apiConversationDataSchema,
  }),
)
