import { Text } from '@fluentui/react-components'
import { useStyles } from './IssueDetailCard.style'
import { Column, Row, Spacer } from '../../../../../../../components/Layout'
import { VIPUserTag } from '../../../tagComponents/VIPUserTag'
import { CAPTenantTag } from '../../../tagComponents/CAPTenantTag'
import { renderedPriority } from '../../../children/topIssue/tableColumns/Priority'
import { useMemo } from 'react'
import { TagCounter } from '../../../../tags/TagCounter'
import { FoldedTags } from '../../../tagComponents/FoldedTags'
import { CardLoading } from '../../CardLoading'

interface IssueDetailCardProps {
  readonly loading: boolean
  readonly priority: number
  readonly ticketCount: number
  readonly coverage: number
  readonly vipUsers: string[]
  readonly capTenants: Array<{
    tenantId: string
    tenantName: string
  }>
}
const MAX_SHOW_CAP_TENANT_TAGS = 5
const MAX_SHOW_VIP_USER_TAGS = 3
export const IssueDetailCard: React.FC<IssueDetailCardProps> = ({
  loading,
  priority,
  ticketCount,
  coverage,
  vipUsers,
  capTenants,
}) => {
  const styles = useStyles()
  const renderedTenantTags = useMemo(() => {
    if (capTenants.length > MAX_SHOW_CAP_TENANT_TAGS) {
      return (
        <Row className={styles.tagValueRow}>
          {capTenants.slice(0, MAX_SHOW_CAP_TENANT_TAGS).map((item, index) => (
            <CAPTenantTag enableTooltip={true} tenantName={item.tenantName} tenantId={item.tenantId} key={index} />
          ))}
          {capTenants.length - MAX_SHOW_CAP_TENANT_TAGS > 0 && (
            <TagCounter
              tagCount={capTenants.length - MAX_SHOW_CAP_TENANT_TAGS}
              foldedTags={<FoldedTags isShowTitle={false} CAPTenants={capTenants.slice(MAX_SHOW_CAP_TENANT_TAGS)} />}
              className={styles.interactionTagPrimary}
            />
          )}
        </Row>
      )
    }
    return (
      <Row className={styles.tagValueRow}>
        {capTenants.map((item, index) => (
          <CAPTenantTag enableTooltip={true} tenantName={item.tenantName} tenantId={item.tenantId} key={index} />
        ))}
      </Row>
    )
  }, [capTenants, styles.interactionTagPrimary, styles.tagValueRow])

  const renderedUserTags = useMemo(() => {
    if (vipUsers.length > MAX_SHOW_VIP_USER_TAGS) {
      return (
        <Row className={styles.tagValueRow}>
          {vipUsers.slice(0, MAX_SHOW_VIP_USER_TAGS).map((userId: string, index: number) => (
            <VIPUserTag enableTooltip={true} userId={userId} key={index} />
          ))}
          {vipUsers.length - MAX_SHOW_VIP_USER_TAGS > 0 && (
            <TagCounter
              tagCount={vipUsers.length - MAX_SHOW_VIP_USER_TAGS}
              foldedTags={<FoldedTags isShowTitle={false} VIPUserIds={vipUsers.slice(MAX_SHOW_VIP_USER_TAGS)} />}
              className={styles.interactionTagPrimary}
            />
          )}
        </Row>
      )
    }
    return (
      <Row className={styles.tagValueRow}>
        {vipUsers.map((userId: string, index: number) => (
          <VIPUserTag enableTooltip={true} userId={userId} key={index} className={styles.userName} />
        ))}
      </Row>
    )
  }, [styles.interactionTagPrimary, styles.tagValueRow, vipUsers, styles.userName])

  if (loading) {
    return <CardLoading />
  }

  return (
    <Column className={styles.container}>
      <Column>
        <Row>
          <Column>
            <Row className={styles.tagTitleRow}> Priority</Row>
          </Column>
          <Column>{renderedPriority(priority)}</Column>
        </Row>
        <Spacer height={18} />
        <Row>
          <Column>
            <Row className={styles.tagTitleRow}> DSATs</Row>
          </Column>
          <Column>
            <Row>
              <Text weight="semibold" size={300}>
                {ticketCount}
              </Text>
            </Row>
          </Column>
        </Row>
        <Spacer height={18} />
        <Row>
          <Column>
            <Row className={styles.tagTitleRow}> DSAT Coverage</Row>
          </Column>
          <Column>
            <Row>
              <Text weight="semibold" size={300}>
                {coverage}%
              </Text>
            </Row>
          </Column>
        </Row>
        <Spacer height={18} />
        <Row>
          <Column>
            <Row className={styles.tagTitleRow}> VIP User</Row>
          </Column>
          <Column>
            {vipUsers.length > 0 ? (
              renderedUserTags
            ) : (
              <Text size={300} className={styles.grayColor}>
                No DSAT from VIP User
              </Text>
            )}
          </Column>
        </Row>
        <Spacer height={18} />
        <Row>
          <Column>
            <Row className={styles.tagTitleRow}> CAP Tenants</Row>
          </Column>
          <Column>
            {capTenants.length > 0 ? (
              renderedTenantTags
            ) : (
              <Text size={300} className={styles.grayColor}>
                No DSAT from VIP Tenant
              </Text>
            )}
          </Column>
        </Row>
      </Column>
    </Column>
  )
}
