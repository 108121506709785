import { TableCellLayoutProps, mergeClasses } from '@fluentui/react-components'
import { useStyles } from './TableCellLayoutWithStyles.styles'

export const TableCellLayoutWithStyles: React.FC<TableCellLayoutProps> = (props) => {
  const styles = useStyles()
  return (
    <div className={mergeClasses(styles.tableCellLayout, props.className)} title={props.title}>
      {props.children}
    </div>
  )
}
