import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ':not(:first-of-type)': {
      marginTop: '24px',
    },
    height: 'fit-content',
  },

  accordionContent: {
    overflow: 'hidden',
  },

  header: {
    ...shorthands.padding('16px'),
    backgroundColor: tokens.colorNeutralBackground3,
    cursor: 'pointer',
    borderRadius: '8px',
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
  },

  table: {
    width: '100%',
  },
  fixedHeight: {
    height: '900px',
  },
})
