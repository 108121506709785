import {
  ApiCreateRagQueryRequest,
  ApiCreateRagQueryResponse,
  ApiFCConversationMessageData,
  ApiGetHistoryRagQuery,
  ApiHistortyRagQueryResponse,
  ApiUpdateHistoryReadQuery,
  apiCreateRagQueryResponseSchema,
  apiHistortyRagQueryResponseSchema,
} from './types'

import { ApiClient } from '../../client/ApiClient'
import { AutomationApiClient } from './AutomationApiClient'
import { AutomationNotificationService } from './AutomationNotificationService'
import { DashApiOptions } from '../../DashApiOptions'
import { Logger } from '@copilot-dash/logger'
import { z } from 'zod'

export class AutomationApi {
  private readonly client: ApiClient
  private readonly signalRService: AutomationNotificationService
  constructor(options: DashApiOptions) {
    this.client = new AutomationApiClient(options)
    this.signalRService = new AutomationNotificationService(options)
  }

  getHistoryRagQuery(params: ApiGetHistoryRagQuery): Promise<ApiHistortyRagQueryResponse> {
    return this.client.get(`/getHistoryRagQueries`, {
      params: params,
      schema: apiHistortyRagQueryResponseSchema,
    })
  }

  submitRagQuery(data: ApiCreateRagQueryRequest): Promise<ApiCreateRagQueryResponse> {
    return this.client.post(`/submitRagQuery`, {
      data: data,
      schema: apiCreateRagQueryResponseSchema,
    })
  }

  updateUserHasReadRagQuery(data: ApiUpdateHistoryReadQuery): Promise<boolean> {
    return this.client.post(`/updateUserHasReadRagQuery`, {
      data: data,
      schema: z.boolean(),
    })
  }

  // Start the SignalR connection
  async startConnection(): Promise<void> {
    try {
      if (this.signalRService.connection.state !== 'Connected') {
        await this.signalRService.connection.start()
        Logger.trace.info('SignalR Connected')
      }
    } catch (error) {
      Logger.trace.error('Error starting SignalR connection:', error)
      setTimeout(() => this.startConnection(), 5000) // restart connection after 5 seconds
    }
  }

  // Stop the SignalR connection
  async stopConnection(): Promise<void> {
    try {
      await this.signalRService.connection.stop()
      Logger.trace.info('SignalR Disconnected')
    } catch (error) {
      Logger.trace.error('Error stopping SignalR connection:', error)
    }
  }

  // Listen for messages from the server
  onReceiveMessage(callback: (result: ApiFCConversationMessageData) => void): void {
    Logger.trace.info('Receive messages from the server')
    this.signalRService.connection.on('ReceiveSummary', callback)
  }
}
