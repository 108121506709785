import { ApiTicketType, ApiTicketsRequest } from '@copilot-dash/api'
import { BasicSearchTicketArgsNames, ISearchTicketOptions } from './SearchTicketAction.types'
import { SearchTextPrefixType, TicketRingType, getProductEndpoints } from '@copilot-dash/domain'
import { EnableExceptionTracking, Logger } from '@copilot-dash/logger'
import { Times, uuid } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { NewSearchCopilotTicketsResult } from './NewSearchTicketsAction.types'
import { NewTicketConverter } from './converters/NewTicketConverter'
import { SearchTicketIdByAISearchAction } from './SearchTicketIdByAISearchAction'
import { SearchTicketWithIdListAction } from './SearchTicketWithIdListAction'

export class SearchTicketAction {
  private readonly context: IDashStoreContext
  private readonly SearchTicketWithIdListAction: SearchTicketWithIdListAction
  private readonly SearchTicketIdByAISearchAction: SearchTicketIdByAISearchAction

  constructor(context: IDashStoreContext) {
    this.context = context
    this.SearchTicketWithIdListAction = new SearchTicketWithIdListAction(context)
    this.SearchTicketIdByAISearchAction = new SearchTicketIdByAISearchAction(context)
  }

  async search(options: ISearchTicketOptions, isAISearchEnable?: boolean): Promise<NewSearchCopilotTicketsResult> {
    const searchId = uuid()
    const startTime = Date.now()
    const page = this.pageLocation(options)
    const partialAiSearchTriggered =
      !isAISearchEnable && this.SearchTicketWithIdListAction.checkNeedToSearchPartialAiSearch(options)
    try {
      Logger.telemetry.trackEvent('Search', { filters: options, searchId })
      Logger.telemetry.trackEvent('SearchStart', { filters: options, searchId })
      return await this.doSearch(options, searchId, isAISearchEnable)
    } finally {
      const duration = Date.now() - startTime

      Logger.telemetry.trackEvent('SearchEnd', { filters: options, searchId })
      Logger.telemetry.trackMetric('SearchTime', {
        duration,
        filters: options,
        searchId,
        page,
        partialAiSearchTriggered,
        isAISearchEnable,
      })
    }
  }

  @EnableExceptionTracking()
  private async doSearch(
    options: ISearchTicketOptions,
    searchId: string,
    isAISearchEnable?: boolean,
  ): Promise<NewSearchCopilotTicketsResult> {
    const clientNames = getProductEndpoints(
      options.product,
      options.applications,
      options.platforms,
      options.licenses,
      options.meetingScenarios,
    )
    if (clientNames.length === 0 && options[BasicSearchTicketArgsNames.id]?.length === 0) {
      return this.createEmptyResult(searchId)
    }

    const updatedOptions: ISearchTicketOptions = {
      ...options,
      client: clientNames,
      channel: options.channel,
      ring: options.ring || [
        TicketRingType.DONMT,
        TicketRingType.MSIT,
        TicketRingType.SDFV2,
        TicketRingType.SIP,
        TicketRingType.WW,
        TicketRingType.UNKNOWN,
      ],
      searchTextPrefix: options.searchTextPrefix || SearchTextPrefixType.All,
    }
    if (isAISearchEnable) {
      return await this.SearchTicketIdByAISearchAction.search(updatedOptions)
    }
    if (options.isAIF) {
      return await this.searchAIFTickets(updatedOptions, searchId)
    } else {
      return await this.searchUIFTickets(updatedOptions, searchId)
    }
  }

  private pageLocation = (options: ISearchTicketOptions): 'Overview' | 'Team View' => {
    if (options.teamId || options.teamName) {
      return 'Team View'
    }
    return 'Overview'
  }

  private async searchUIFTickets(
    options: ISearchTicketOptions,
    searchId: string,
  ): Promise<NewSearchCopilotTicketsResult> {
    return await this.SearchTicketWithIdListAction.search({
      options: options,
      searchId: searchId,
    })
  }

  private async searchAIFTickets(
    options: ISearchTicketOptions,
    searchId: string,
  ): Promise<NewSearchCopilotTicketsResult> {
    const request = this.createAIFRequest(options)
    const response = await this.context.api.logCollector.searchAIFTickets(request)
    return {
      searchId,
      ticketCount: response.ticketCount ?? response.aifTickets.length,
      tickets: response.aifTickets.map(NewTicketConverter.fromApi),
      hasMore: response.hasMore,
      errorMessage: response.errorMessage ?? '',
    }
  }

  private createAIFRequest(options: ISearchTicketOptions): ApiTicketsRequest {
    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })

    return {
      Count: options.count,
      Offset: options.offset,
      ClientNames: options.client,
      ScenarioNames: options.channel,
      Rings: options.ring,
      From: from,
      To: to,
      TicketType: ApiTicketType.SearchTicket,
      HasVerbatim: options.hasVerbatim?.length ? 'Yes' : 'All',
      TenantIds: options.tenantIds,
    }
  }

  private createEmptyResult(searchId: string): NewSearchCopilotTicketsResult {
    return {
      searchId: searchId,
      ticketCount: 0,
      tickets: [],
      hasMore: false,
      errorMessage: '',
      satCount: 0,
      dsatCount: 0,
    }
  }
}
