import { User } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getUserProfile(context: IDashStoreContext, userId: string): PromiseSnapshot<User> {
  return context.getOrFetch({
    get: (state) => {
      return state.users[userId]?.profile
    },
    set: (state, snapshot) => {
      const user = (state.users[userId] ??= {})
      user.profile = snapshot
    },
    fetch: () => {
      return context.api.microsoftGraph.findUserByIdOrEmail(userId)
    },
  })
}
