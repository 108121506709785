import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.gap('8px'),
  },
  interactionTagPrimary: {
    backgroundColor: tokens.colorBrandBackground2,
    ':hover': {
      backgroundColor: tokens.colorBrandBackground2,
    },
    ':active': {
      backgroundColor: tokens.colorBrandBackground2,
    },
  },
  tooltip: {
    boxShadow: tokens.shadow2,
    paddingRight: '0px',
  },
})
