import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  selectButton: {
    textAlign: 'left',
    justifyContent: 'left',
    fontWeight: tokens.fontWeightRegular,
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    },
  },
  checkBox: {
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    },
  },
  text: {
    color: tokens.colorNeutralForeground1,
  },

  checkboxLabel: {
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    },
  },
})
