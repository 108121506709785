import { IState } from './IState'

interface IParams {
  readonly initialValue: string | undefined
  readonly mode: 'create' | 'edit'
}

export function createState({ initialValue, mode }: IParams): IState {
  return {
    initialValue,
    mode,
    isSubmitting: false,
    isUploading: false,
  }
}
