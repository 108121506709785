import { makeStyles, shorthands } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '100vw',
    height: '100%',
    ...shorthands.flex(1),
  },
  tagsBar: {
    ...shorthands.padding('4px', '16px'),
  },
  body: {
    ...shorthands.padding('4px', '16px'),
  },
  flexSpinner: {
    display: 'flex',
    ...shorthands.flex(1),
  },
  errorLayout: {},
  startupbody: {
    margin: '0 auto',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'transparent',
  },
  greeting: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '44px',
    ...shorthands.padding('44px', 'auto', '56px', 'auto'),
  },
  header: {
    width: '100%',
    maxWidth: '100vw',
    alignItems: 'center',
    flexDirection: 'row',
    height: '58px',
    ...shorthands.padding('16px', '32px', '10px', '24px'),
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  root: {
    margin: '8px 16px',
    gap: '12px',
  },
  content: {
    flex: '1 1 0',
    gap: '8px',
    overflow: 'hidden',
  },
  contentbody: {
    minHeight: '5em',
    flex: '3 1 0%',
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  contentInput: {
    ...shorthands.padding('0px', '16px', '24px'),
    minHeight: '85px',
    position: 'sticky',
    marginTop: 'auto',
  },
})
