import { useMemo } from 'react'
import { PromiseSnapshots } from '@copilot-dash/core'
import { ScreenLoadingView } from './ScreenLoadingView'
import { ErrorView } from '../../components/Error'
import { ITeamViewRouteArgs } from '../routes/product.team/types'
import { ISearchScreenPropsQuery } from '../../screens/search/SearchScreen'
import { omitBy } from 'lodash'
import { IQuery } from '@copilot-dash/domain'

type RouteArgs = ITeamViewRouteArgs | ISearchScreenPropsQuery

export function RouteWithQueryData<TArgs extends RouteArgs>({
  queryId,
  args,
  children,
}: {
  queryId: string
  args: TArgs
  children: (parsedArgs: TArgs, currentQuery?: IQuery) => React.ReactNode
}) {
  const queriesSnapshot = application.store.use.getQuery(queryId)
  const mergedQueriesSnapshot = useMemo(() => {
    const parsedPayload = JSON.parse(queriesSnapshot?.data?.filterPayload || '{}') as TArgs

    return PromiseSnapshots.map(queriesSnapshot, (data) => {
      return mergeQueryParam(parsedPayload, args)
    })
  }, [queriesSnapshot, args])

  switch (mergedQueriesSnapshot.status) {
    case 'waiting':
      return <ScreenLoadingView />
    case 'done':
      return <>{children(mergedQueriesSnapshot.data, queriesSnapshot.data)}</>
    case 'error':
      return <ErrorView type="block" error={mergedQueriesSnapshot.error} />
  }
}

const mergeQueryParam = <T extends RouteArgs>(parsedPayload: T, query: T): T => {
  const omitUndefined = (value: unknown): boolean => value === undefined
  const filteredQuery = omitBy(query, omitUndefined)
  return { ...parsedPayload, ...filteredQuery }
}
