import { FC, memo, useState, useEffect } from 'react'
import { Field, Skeleton, SkeletonItem, Dropdown, Option } from '@fluentui/react-components'
import { useCommonStyles } from './commonStyles.styles'
import { useStyles } from './Area.styles'
import { ITeamList } from '@copilot-dash/domain'

interface IAreaProps {
  value: number | undefined
  onChange: (value: number | undefined) => void
  orientation?: 'horizontal' | 'vertical'
  teamList?: ITeamList
  loading?: boolean
  className?: string
  style?: React.CSSProperties
  defaultValueText?: string
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
}

export const Area: FC<IAreaProps> = memo(
  ({
    value,
    onChange,
    orientation = 'horizontal',
    teamList = [],
    loading = false,
    className,
    style,
    defaultValueText,
    disabled,
    fieldProps,
  }) => {
    const styles = useCommonStyles()
    const areaStyles = useStyles()
    const [selectedValue, setSelectedValue] = useState('')

    useEffect(() => {
      const curTeam = teamList.find((item) => item.id === value)
      curTeam && setSelectedValue(curTeam.name)
    }, [value, teamList])

    return (
      <Field className={className} label="Team:" orientation={orientation} style={style} {...(fieldProps ?? {})}>
        {loading ? (
          <Skeleton>
            <SkeletonItem size={20} />
          </Skeleton>
        ) : (
          <Dropdown
            disabled={disabled}
            appearance="underline"
            value={selectedValue ?? defaultValueText}
            selectedOptions={value ? [String(value)] : []}
            onOptionSelect={(_, data) => {
              if (data.selectedOptions[0]) {
                onChange(Number(data.selectedOptions[0]))
                setSelectedValue(data.optionText ?? '')
              } else {
                onChange(undefined)
              }
            }}
            listbox={{
              className: areaStyles.listBox,
            }}
            className={styles.minWidth150}
          >
            {[...teamList]
              .sort((a, b) => {
                const x1 = a.name.toLocaleUpperCase().trim()
                const x2 = b.name.toLocaleUpperCase().trim()
                if (x1 < x2) {
                  return -1
                } else if (x1 > x2) {
                  return 1
                } else {
                  return 0
                }
              })
              .map((item) => (
                <Option text={item.name} key={item.id} value={String(item.id)}>
                  {item.name}
                </Option>
              ))}
          </Dropdown>
        )}
      </Field>
    )
  },
)

Area.displayName = 'Area'
