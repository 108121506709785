import { INewTicketData, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { ResponsiveCustomTagColumnComponent } from './ResponsiveCustomTagColumnComponent'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { CustomCellRendererProps } from 'ag-grid-react'

export const ColumnConfigCustomTag: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.CustomTags],
  field: String(ColumnKey.CustomTags) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        {props.data.ticketId && <ResponsiveCustomTagColumnComponent item={props.data} />}
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.CustomTags]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 150,
  sortable: false,
  resizable: true,
}
