import { PromiseSnapshot } from '@copilot-dash/core'
import { getProductIdByEndpoint, ITicketEnvironment } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV1Ticket } from '../actions-raw-ticket/getRawV1Ticket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export function getTicketEnvironment(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ITicketEnvironment> {
  return context.getOrFetch<ITicketEnvironment>({
    get: (state) => {
      return state.tickets[ticketId]?.environment
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.environment = snapshot
    },
    fetch: async () => {
      return context.enableV2Endpoint ? fetchFromV2() : fetchFromV1()
    },
  })

  async function fetchFromV1(): Promise<ITicketEnvironment> {
    const raw = await getRawV1Ticket(context, ticketId).promise

    return {
      clientName: raw.clientName,
      scenarioName: raw.scenarioName,
      ring: raw.ring,
      productId: getProductIdByEndpoint(raw.clientName),
    }
  }

  async function fetchFromV2(): Promise<ITicketEnvironment> {
    const raw = await getRawV2Ticket(context, ticketId).promise

    return {
      clientName: raw.environment?.clientName,
      scenarioName: raw.environment?.scenarioName,
      ring: raw.environment?.ring,
      productId: getProductIdByEndpoint(raw.environment?.clientName),
    }
  }
}
