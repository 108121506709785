import { HeaderTab, useTicketFilterStore } from './store/store'
import { ITicketFilterFormRef, getCleanedFilters } from './children/TicketFilterForm/TicketFilterForm.types'
import { forwardRef, useEffect } from 'react'

import { ITicketFilterPanel } from './TicketFilterPanel.types'
import { ProductIds } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import { TicketsFilterPanelLayout } from './layout/TicketsFilterPanelLayout'
import { isEqual } from 'lodash'

export const TicketsFilterPanel = forwardRef<ITicketFilterFormRef, ITicketFilterPanel>(
  (props: ITicketFilterPanel, ref) => {
    useEffect(() => {
      useTicketFilterStore.getState().setSelectedProductId(props.selectedProductId ?? ProductIds.M365Chat)
      useTicketFilterStore.getState().setSupportedTicketFilterItems(props.supportedTicketFilterItems)
      useTicketFilterStore.getState().setCustomizedFilters(props.customizedFilters)
      useTicketFilterStore.getState().setCopilotDashPath(props.copilotDashPath)
      useTicketFilterStore.getState().setIsAISearchEnable(props.isAISearchEnable ?? false)
      useTicketFilterStore.getState().setIsInvocationSlicerEnable(props.isInvocationSlicerEnable ?? false)
      useTicketFilterStore.getState().setSelectedApplications(props.selectedApplications)
      useTicketFilterStore.getState().setSelectedPlatforms(props.selectedPlatforms)
      useTicketFilterStore.getState().setSelectedLicenses(props.selectedLicenses)
    }, [
      props.selectedProductId,
      props.supportedTicketFilterItems,
      props.customizedFilters,
      props.copilotDashPath,
      props.isAISearchEnable,
      props.isInvocationSlicerEnable,
      props.selectedApplications,
      props.selectedPlatforms,
      props.selectedLicenses,
    ])
    useEffect(() => {
      useTicketFilterStore.getState().setSelectedHeaderTab(HeaderTab.Filters)
    }, [props.selectedProductId])

    useEffect(() => {
      const parsedPayload = getCleanedFilters(JSON.parse(props.currentQuery?.filterPayload || '{}'))
      const currentFilter = getCleanedFilters({ ...props.ticketFilterForm, ...props.externalArgs })
      const equal = isEqual(parsedPayload, currentFilter)

      useTicketFilterStore.getState().setQueryChanged(!equal)
      useTicketFilterStore.getState().setCurrentQuery(props.currentQuery ?? undefined)
    }, [props.currentQuery, props.ticketFilterForm, props.externalArgs])

    return (
      <TelemetryScope scope="SearchFiltersView">
        <TicketsFilterPanelLayout {...props} ref={ref} />
      </TelemetryScope>
    )
  },
)
TicketsFilterPanel.displayName = 'TicketsFilterPanel'
