import { Times } from '@copilot-dash/core'
import { KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketMetadata } from '../actions-ticket/getTicketMetadata'

const TICKET_EXPIRATION_DAYS_FOR_3S = 28
const TICKET_EXPIRATION_DAYS = 30

export async function assertTicketKustoNotExpired(
  context: IDashStoreContext,
  ticketId: string,
  table: KustoLogTable,
): Promise<void> {
  if (context.enableV2Endpoint) {
    return
  }

  const ticket = await getTicketMetadata(context, ticketId).promise
  const createdDate = ticket.createdDateTime
  if (!createdDate) {
    return
  }

  if (table === KustoLogTable.GwsLog) {
    if (Times.isBeforeNDays(createdDate, TICKET_EXPIRATION_DAYS_FOR_3S)) {
      throw TicketError.create('No3SDataDueToExpired', { ticketId, ticketCreationTime: createdDate })
    }
    return
  }

  if (Times.isBeforeNDays(createdDate, TICKET_EXPIRATION_DAYS)) {
    throw TicketError.create('NoKustoDueToExpired', { ticketId, ticketCreationTime: createdDate })
  }
}
