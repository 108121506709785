import { makeStyles, Text } from '@fluentui/react-components'

interface IProps {
  readonly value: boolean
}

export function ValueViewForBoolean({ value }: IProps) {
  const styles = useStyles()

  return (
    <div className={styles.root} title={value.toString()}>
      <Text>{value}</Text>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
  },
})
