import { TimeRange } from '@copilot-dash/core'
import { Button, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'
import { DatePanelProps } from 'react-multi-date-picker/plugins/date_panel'
import { Column } from '../Layout'

const DAY_OPTIONS = [
  { value: 7, label: 'Last 7 days' },
  { value: 30, label: 'Last 30 days' },
  { value: 90, label: 'Last 90 days' },
  // { value: 180, label: 'Last 6 months' },
] as const

interface IProps {
  readonly range?: TimeRange
  readonly onChanged: (range: TimeRange) => void
}

export function DateTimeRangePickerHeader({ range, onChanged }: IProps & Pick<DatePanelProps, 'position'>) {
  const styles = useStyles()

  const selectedOptions = useMemo(() => {
    if (range && range.type === 'relative' && range.unit === 'days') {
      return DAY_OPTIONS.find((option) => option.value === range.value)
    }

    return undefined
  }, [range])

  const handleClick = (value: number) => {
    onChanged({
      type: 'relative',
      value,
      unit: 'days',
    })
  }

  return (
    <Column className={styles.root}>
      {DAY_OPTIONS.map((option) => (
        <Button
          key={option.value}
          onClick={() => handleClick(option.value)}
          className={mergeClasses(styles.button, selectedOptions === option ? styles.buttonSelected : '')}
        >
          {option.label}
        </Button>
      ))}
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  button: {
    ...shorthands.padding('4px', '16px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    fontWeight: tokens.fontWeightRegular,
    borderRadius: '0px',
  },
  buttonSelected: {
    fontWeight: tokens.fontWeightSemibold,
    backgroundColor: 'rgba(0,0,0,0.15)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.15)',
    },
  },
})
