import { makeStyles, Option, Dropdown, shorthands, tokens } from '@fluentui/react-components'
import { SearchTextPrefixTypeSchema, SearchTextPrefixType } from '@copilot-dash/domain'
import { FC, memo } from 'react'

interface IProps {
  searchTextPrefix?: SearchTextPrefixType
  onChange: (value: SearchTextPrefixType) => void
  disabled?: boolean
}

export const PrefixFilter: FC<IProps> = memo(({ searchTextPrefix, onChange, disabled }) => {
  const styles = useStyles()

  const optionsArray: Array<SearchTextPrefixType> = Object.values(SearchTextPrefixType)
  return (
    <Dropdown
      onOptionSelect={(_, data) => {
        if (data.optionText) {
          const result = SearchTextPrefixTypeSchema.safeParse(data.optionText)
          if (result.success) {
            onChange(result.data)
          }
        }
      }}
      selectedOptions={searchTextPrefix ? [searchTextPrefix] : [SearchTextPrefixType.All]}
      className={styles.root}
      listbox={{ className: styles.listBox }}
      value={searchTextPrefix || SearchTextPrefixType.All}
      disabled={disabled}
    >
      {optionsArray.map((item) => (
        <Option value={item} key={item} text={item}>
          {item}
        </Option>
      ))}
    </Dropdown>
  )
})
PrefixFilter.displayName = 'PrefixFilter'

const useStyles = makeStyles({
  root: {
    width: '110px',
    height: '32px',
    minWidth: '110px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
    '&:hover': {
      ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
    },
  },
  listBox: {
    width: '110px',
    minWidth: '110px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
})
