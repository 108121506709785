import { z } from 'zod'

export interface ApiRootCauseListByTicketCharacterResponse {
  readonly rootCauseList: Array<{
    rootCauseTitle: string
    reportingStatus?: string
    eTA?: string
    owner?: string
    enable: boolean
    visible: boolean
    issueId: string
    vsoAccount: string
    createdTimeUtc: string
    rootCauseCount: number
    rootCauseStatus?: string
    rootCausePriority?: number
  }>
}

export const apiRootCauseListByTicketCharacterResponseSchema = z.object({
  rootCauseList: z.array(
    z.object({
      rootCauseTitle: z.string(),
      reportingStatus: z.string().optional(),
      eTA: z.string().optional(),
      owner: z.string().optional(),
      enable: z.boolean(),
      visible: z.boolean(),
      issueId: z.string(),
      vsoAccount: z.string(),
      createdTimeUtc: z.string(),
      rootCauseCount: z.number(),
      rootCauseStatus: z.string().optional(),
      rootCausePriority: z.number().optional(),
    }),
  ),
})
