import { ITicketTurnConversation } from '@copilot-dash/domain'
import { Tag } from '@fluentui/react-components'

interface IProps {
  readonly data: ITicketTurnConversation
}

export function MessageLabel({ data }: IProps) {
  const text = getLabelText(data)
  if (!text) {
    return null
  }

  return (
    <Tag appearance="brand" size="small">
      {text}
    </Tag>
  )
}

function getLabelText(data: ITicketTurnConversation): string | null {
  if (application.env.ring === 'msit' || application.env.ring === 'sdf') {
    return null
  }

  switch (data.source) {
    case 'conversation-legacy':
      return 'Conversation Legacy'
    case 'conversation-group1':
      return null
    case 'OCV':
      return 'OCV'
    case 'V2Metadata':
      return 'Ticket Metadata'
    case 'offline-group1':
      return 'Offline'
  }
}
