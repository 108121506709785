import { ApiAISearchResponse, ApiSearchItem } from '@copilot-dash/api'
import { ColumnKey, INewTicketData, TicketEmotionType, getProductIdByEndpoint } from '@copilot-dash/domain'

import { NewSearchCopilotTicketsResult } from '../NewSearchTicketsAction.types'

export class AiTicketConverter {
  static readonly fromApi = (api: ApiAISearchResponse): NewSearchCopilotTicketsResult => {
    const tickets: INewTicketData[] = api.searchItems.map((ticket: ApiSearchItem) => {
      return this.fromApiContext(ticket)
    })

    const hasMore = api.startIndex + api.stepNumber < api.count
    // The emotionType field is normalized to lowercase in AI search.
    const satCount = api.emotionTypeCounts['positive'] || 0
    const dsatCount = api.emotionTypeCounts['negative'] || 0
    return {
      tickets,
      hasMore,
      satCount,
      dsatCount,
      ticketCount: api.count,
    }
  }

  static readonly fromApiContext = (item: ApiSearchItem): INewTicketData => {
    const highlights = item.highlights || {}
    const verbatimWithHighlight = highlights['verbatim'] ? highlights['verbatim'] : undefined
    const utteranceWithHighlight = highlights['utterance'] ? highlights['utterance'] : undefined
    const responseWithHighlight = highlights['response'] ? highlights['response'] : undefined

    return {
      ...item,
      ticketId: item.id,
      dateTimeUtc: item.createDateTime,
      thumbFeedback: item.emotionType,
      tenantId: item.tenantId,
      ring: item.ring,
      endpoint: item.clientName,
      emotionType: item.emotionType === 'Positive' ? TicketEmotionType.Positive : TicketEmotionType.Negative,
      productId: getProductIdByEndpoint(item.clientName),
      invocationSlicers: item.invocationSlicers,
      verbatimWithHighlight: verbatimWithHighlight,
      utteranceWithHighlight: utteranceWithHighlight,
      responseWithHighlight: responseWithHighlight,
      screenshotCount: item.screenshotCount,
    }
  }

  static readonly postApiOrder = (order: Array<string>): Array<string> => {
    const fieldMapping: Partial<{ [K in ColumnKey]: string }> = {
      Date: 'createDateTime',
      EmotionType: 'emotionType',
      Endpoint: 'clientName',
      Ring: 'ring',
      Email: 'emailAddress',
    }

    return order
      .map((order) => {
        const [field, direction] = order.split(' ')
        if (field) {
          const mappedField = fieldMapping[field as ColumnKey] || field
          return `${mappedField} ${direction}`
        }
        return undefined
      })
      .filter((order): order is string => order !== undefined)
  }
}
