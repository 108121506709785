import { Tooltip } from '@fluentui/react-components'
import { ReactElement, useMemo } from 'react'
import { getErrorMessage } from '../ErrorConfigs/utils/getErrorMessage'
import { getErrorMessageDetails } from '../ErrorConfigs/utils/getErrorMessageDetails'

interface IProps {
  readonly ticketId: string
  readonly children: ReactElement
}

export function TicketClientTooltip({ ticketId, children }: IProps) {
  const snapshot = application.store.use.getTicketEnvironment(ticketId)

  const content = useMemo(() => {
    switch (snapshot.status) {
      case 'waiting':
        return 'Loading...'
      case 'done':
        return snapshot.data?.clientName ?? 'No client name found'
      case 'error':
        return getErrorMessageDetails(snapshot.error) ?? getErrorMessage(snapshot.error)
    }
  }, [snapshot])

  return (
    <Tooltip content={content} relationship={'label'}>
      {children}
    </Tooltip>
  )
}
