import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { SymptomReportHealthLabel } from './SymptomReportHealthLabel'

/**
 * Most of the suggestions count is 0 or 3, so we don't need to show the warning in that cases
 */
const VALID_SUGGESTION_COUNTS = [0, 3]

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function RowForConversationSuggestions({ ticketId, messageId }: IProps) {
  const turn = application.store.use.getTicketTurnConversation(ticketId, messageId)
  const suggestionCount = turn?.data?.suggestions.length ?? 0

  if (VALID_SUGGESTION_COUNTS.includes(suggestionCount)) {
    return null
  }

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Next turn suggestion</Text>
      </TableCell>
      <TableCell>
        <span>Next turn suggestion count: {suggestionCount} </span>
        <SymptomReportHealthLabel status="warning" />
      </TableCell>
    </TableRow>
  )
}
