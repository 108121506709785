import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { COMMAND_PRIORITY_NORMAL, PASTE_COMMAND } from 'lexical'
import * as React from 'react'
import { handleImageUpload } from './UploadImage'
import { useToast } from '../../../../hooks/useToast'
import { LexicalRichTextEditorStore } from '../../LexicalRichTextEditorStore'

export const PasteImageUploadPlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext()
  const actions = LexicalRichTextEditorStore.useActions()
  const toast = useToast()
  React.useEffect(() => {
    const handlePasteCommand = async (event: ClipboardEvent) => {
      const { clipboardData } = event
      if (clipboardData) {
        const items = clipboardData.items
        let handledImage = false

        for (const item of items) {
          if (item.type.startsWith('image/')) {
            const file = item.getAsFile()
            if (file) {
              handledImage = true
              try {
                actions.uploading()
                await handleImageUpload(editor, file)
                toast.showSuccess('Image uploaded successfully')
              } catch (error) {
                toast.showError('Failed to upload the image. Please try again.')
              } finally {
                actions.uploaded()
              }
            }
          }
        }

        if (handledImage) {
          event.preventDefault()
          return true
        }
      }
      return false
    }

    const unregister = editor.registerCommand(
      PASTE_COMMAND,
      (event: ClipboardEvent) => {
        handlePasteCommand(event)
        return false
      },
      COMMAND_PRIORITY_NORMAL,
    )

    return unregister
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  return null
}
