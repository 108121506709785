import { ApiCopilotTicketDetailContext } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getRawV1Ticket(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ApiCopilotTicketDetailContext> {
  return context.getOrFetch<ApiCopilotTicketDetailContext>({
    get: (state) => {
      return state.tickets[ticketId]?.rawV1Ticket
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.rawV1Ticket = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getCopilotTicket({ ExternalTicketId: ticketId })
      const ticket = response.copilotTicketDetailContext
      if (ticket) {
        return ticket
      }

      const dsrResponse = await context.api.logCollector.getDSRTicket(ticketId)
      if (dsrResponse.feedbackItemId) {
        throw TicketError.create('DSRTicket', { ticketId })
      } else {
        throw TicketError.create('NoTicket', { ticketId })
      }
    },
  })
}
