import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Column, Row, Spacer } from '../../../components/Layout'
import { ChevronDownRegular, SparkleFilled } from '@fluentui/react-icons'
import { tokens, Text, Button, Spinner } from '@fluentui/react-components'
import { INewTicketData, IRootCauseItem } from '@copilot-dash/domain'
import { useTeamViewStore } from '../store'
import { TableLoading } from './TableLoading'
import { ErrorView } from '../../../components/Error'
import { NoRecommendationID } from '../store/const'
import { useStyles } from './ClusteringTickets.styles'
import { AGGridTable, IAGGridTableRef } from '../../../components/AGGridTable/AGGridTable'
import { ColDef } from 'ag-grid-community'

interface IClusteringTickets {
  rootCause: IRootCauseItem
  columns: ColDef<INewTicketData>[]
  clickedTicketKey?: string
  onRowClick?: (row?: INewTicketData) => void
  onSelectionChange?: (selectedItems: INewTicketData[] | undefined) => void
  tableRef?: (ref: IAGGridTableRef | null) => void
  clusteringListLength?: number
}

function ClusteringUnRootCauseTicketsComp({
  rootCause,
  columns,
  clickedTicketKey,
  onRowClick,
  onSelectionChange,
  tableRef,
  clusteringListLength,
}: IClusteringTickets) {
  const styles = useStyles()

  const filter = useTeamViewStore((state) => state.tickets.filterForm)

  // Load un-root caused tickets
  useEffect(() => {
    if (rootCause.issueId) {
      useTeamViewStore.getState().tickets.filterUnRootCausedTickets(rootCause.issueId, filter)
    }
  }, [rootCause.issueId, filter])

  const ticketIdsSnapshot = useTeamViewStore((state) => state.tickets.unRootCausedTicketIds[rootCause.issueId])
  const ticketsSnapshot = app.store.use.getTickets(ticketIdsSnapshot?.data?.ticketIds ?? [])

  const [loading, setLoading] = useState(false)

  const handleLoadMore = useCallback(() => {
    const offset = ticketIdsSnapshot?.data?.ticketIds?.length ?? 0
    if (ticketIdsSnapshot?.status === 'done' && ticketIdsSnapshot.data.hasMore) {
      setLoading(true)
      useTeamViewStore
        .getState()
        .tickets.loadMoreUnRootCausedTickets(rootCause.issueId, offset)
        .finally(() => {
          setLoading(false)
        })
    }
  }, [rootCause.issueId, ticketIdsSnapshot])

  const table = useMemo(() => {
    if (ticketIdsSnapshot?.status === 'waiting') {
      return <TableLoading className={styles.loading} />
    }
    if (ticketIdsSnapshot?.status === 'error') {
      return <ErrorView error={ticketsSnapshot.error} />
    }
    if (ticketsSnapshot?.status === 'done' && ticketIdsSnapshot?.status === 'done') {
      return ticketIdsSnapshot.data?.ticketCount > 0 ? (
        <AGGridTable
          rowData={ticketsSnapshot.data}
          getRowId={(data) => data.data.ticketId}
          columnDefs={columns}
          onRowClicked={onRowClick}
          focusedRowId={clickedTicketKey}
          rowSelectionMode="multiRow"
          onSelectionChange={onSelectionChange}
          domLayout="autoHeight"
          suppressRowVirtualisation={true}
          ref={(ref) => tableRef?.(ref)}
        />
      ) : (
        <ErrorView.Custom level="WARNING" message="No tickets found for this query" />
      )
    }
    return null
  }, [
    ticketsSnapshot,
    ticketIdsSnapshot,
    styles.loading,
    columns,
    onRowClick,
    clickedTicketKey,
    onSelectionChange,
    tableRef,
  ])

  const hasMore =
    ticketIdsSnapshot?.data?.hasMore &&
    ticketIdsSnapshot.data.ticketCount - (ticketIdsSnapshot.data?.ticketIds?.length ?? 0) > 0

  useEffect(() => {
    // clear tickets in store when unmount
    return () => {
      useTeamViewStore.getState().tickets.clearUnRootCausedTickets(rootCause.issueId)
    }
  }, [rootCause.issueId])

  if (!ticketsSnapshot) return null

  if (
    rootCause.issueId !== NoRecommendationID &&
    ticketIdsSnapshot?.status === 'done' &&
    ticketIdsSnapshot.data?.ticketCount === 0
  ) {
    return null
  }

  return (
    <Column className={styles.wrapper}>
      <Row vAlign="center" className={styles.title}>
        {rootCause.issueId !== NoRecommendationID ? (
          <>
            <SparkleFilled fontSize={20} color={tokens.colorCompoundBrandForeground1} />
            <Spacer width={12} />
            <Text size={400}>Root Cause Recommendation:</Text>
            <Spacer width={6} />
            <Text size={400} className={styles.blue}>
              {rootCause.title}
            </Text>
            <Spacer width={12} />
            <Text size={400}>
              {ticketIdsSnapshot?.data && ticketIdsSnapshot.data.ticketCount > 0
                ? ` (${ticketIdsSnapshot.data.ticketCount})`
                : ''}
            </Text>
          </>
        ) : clusteringListLength === 1 ? null : (
          <Text size={400}>Others:</Text>
        )}
      </Row>
      {table}
      {hasMore && (
        <Row hAlign="center" className={styles.loadMore}>
          {loading ? (
            <Spinner />
          ) : (
            <Button
              className={styles.loadMoreTickets}
              onClick={handleLoadMore}
              icon={<ChevronDownRegular />}
              iconPosition="after"
            >
              Load more tickets ({ticketIdsSnapshot.data.ticketCount - (ticketIdsSnapshot.data?.ticketIds?.length ?? 0)}
              )
            </Button>
          )}
        </Row>
      )}
    </Column>
  )
}

export const ClusteringTickets = memo(ClusteringUnRootCauseTicketsComp)

ClusteringTickets.displayName = 'ClusteringTickets'
