import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { motion } from 'framer-motion'
import { clamp } from 'lodash'
import { ReactNode, useEffect, useRef, useState } from 'react'

const DEFAULT_OPTIONS: IOptions = {
  duration: 0.2,
  keepAliveDuration: 2 * 60 * 1000,
  lazy: true,
}

interface IProps {
  readonly tabIndex: number
  readonly activeTabIndex: number
  readonly children: () => ReactNode
  readonly options?: IOptions
}

interface IOptions {
  readonly duration?: number

  /**
   * If the child component is not activated for more than {keepAliveDuration} milliseconds, it will be unmounted.
   */
  readonly keepAliveDuration?: number
  readonly lazy?: boolean
}

export function AnimatedTabItem({ tabIndex, activeTabIndex, children, options }: IProps) {
  const duration = options?.duration ?? DEFAULT_OPTIONS.duration
  const lazy = options?.lazy ?? DEFAULT_OPTIONS.lazy
  const keepAliveDuration = options?.keepAliveDuration ?? DEFAULT_OPTIONS.keepAliveDuration
  const keepAliveTimerRef = useRef<NodeJS.Timeout>()

  const styles = useStyles()
  const [initialized, setInitialized] = useState(tabIndex === activeTabIndex && !lazy)
  const isSelected = tabIndex === activeTabIndex

  const onSelected = () => {
    if (keepAliveTimerRef.current) {
      clearTimeout(keepAliveTimerRef.current)
      keepAliveTimerRef.current = undefined
    }

    setInitialized(true)
  }

  const onDeselected = () => {
    if (!keepAliveTimerRef.current) {
      keepAliveTimerRef.current = setTimeout(() => {
        setInitialized(false)
      }, keepAliveDuration)
    }
  }

  const onSelectedRef = useRef(onSelected)
  onSelectedRef.current = onSelected
  const onDeselectedRef = useRef(onDeselected)
  onDeselectedRef.current = onDeselected

  useEffect(() => {
    if (isSelected) {
      onSelectedRef.current()
    } else {
      onDeselectedRef.current()
    }
  }, [isSelected])

  const translateDirection = clamp(tabIndex - activeTabIndex, -1, 1)
  const translateX = `${translateDirection * 10}%`

  return (
    <motion.div
      className={mergeClasses(`AnimatedTabItem-${tabIndex}`, styles.root)}
      style={{
        pointerEvents: isSelected ? 'auto' : 'none',
      }}
      animate={{
        zIndex: isSelected ? 1 : 0,
        opacity: isSelected ? 1 : 0,
        transform: `translateX(${translateX})`,
      }}
      transition={{
        ease: 'easeInOut',
        duration: duration,
      }}
    >
      {(initialized || isSelected) && children()}
    </motion.div>
  )
}

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    '& > *': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
})
