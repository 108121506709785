export enum InvocationSlicer {
  TurnN = 'Queries from Turn N (Suggestion Pill)',
  UXAssistedQueries = 'UX Assisted Queries',
  QueriesWithCIQ = 'Queries with CIQ',
  CIQFile = 'CIQ_File',
  CIQLocalFile = 'CIQ_LocalFile',
  CIQTurn0 = 'CIQ + Turn 0',
  CIQCloudFile = 'CIQ_CloudFile',
  QueriesFromTurn0 = 'Queries from Turn 0 (ZQ + prompt Guide)',
  TryThesePrompts = 'Try These Prompts',
  CIQPeople = 'CIQ_People',
  CIQFileUrl = 'CIQ_FileUrl',
  CIQEvent = 'CIQ_Event',
  CopilotLab = 'Copilot Lab',
  CIQEmail = 'CIQ_Email',
  ScheduledPrompts = 'Scheduled Prompts',
  EntityPrompts = 'Entity Prompts',
  GoBold = 'Go Bold',
  PromptElaboration = 'Prompt Elaboration',
  CIQExternal = 'CIQ_External',
}
