import { makeStyles } from '@fluentui/react-components'
import { useMemo } from 'react'
import { RouteLink } from '../../../../../router'
import { CopyButton } from '../../../../Copy/CopyButton'
import { Row, Spacer } from '../../../../Layout'

interface IProps {
  readonly value: string
}

export function ValueViewForLink({ value }: IProps) {
  const styles = useStyles()
  const segments = useMemo(() => parseUrl(value), [value])

  return (
    <div className={styles.root} title={value}>
      <Row vAlign="center">
        <RouteLink type="link" path={value} openInNewTab>
          {(() => {
            if (typeof segments === 'string') {
              return <span className={styles.text}>{value}</span>
            }

            return (
              <>
                <span className={styles.part1}>{segments[0]}</span>
                <span className={styles.part2}>{segments[1]}</span>
                <span className={styles.part3}>{segments[2]}</span>
              </>
            )
          })()}
        </RouteLink>
        <Spacer width={16} />
        <CopyButton content={value} hideText />
      </Row>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
  },
  text: {
    wordBreak: 'break-word',
  },
  part1: {
    opacity: 0.67,
  },
  part2: {
    // fontWeight: 'bold',
  },
  part3: {
    opacity: 0.67,
  },
})

function parseUrl(url: string): [string, string, string] | string {
  try {
    const urlObject = new URL(url)
    return [
      urlObject.protocol + '//', //
      urlObject.hostname,
      urlObject.pathname + urlObject.search + urlObject.hash,
    ]
  } catch {
    return url
  }
}
