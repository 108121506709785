import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
    minWidth: '0',
    width: '100%',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderLeft: 'none',
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
    '&:hover': {
      ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
    },
  },
  options: {
    maxHeight: '240px',
    ...shorthands.padding('4px', '0px', '4px', '0px'),
  },
  popoverSurface: {
    ...shorthands.padding('4px', '0px', '4px', '4px'),
  },
  hint: {
    color: tokens.colorBackgroundOverlay,
    fontSize: tokens.fontSizeBase200,
  },
  prefix: {
    minWidth: '30%',
    cursor: 'pointer',
    color: tokens.colorBrandForegroundLink,
  },
  row: {
    ...shorthands.padding('0px', '16px', '0px', '8px'),
    cursor: 'pointer',
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground5Pressed,
    },
  },
})
