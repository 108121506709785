import { PromiseSnapshot } from '@copilot-dash/core'
import { I3sLogData, I3sLogDataItem, IKustoGwsLogItem, ITicketTurnMetadata } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { sortBy } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicket3sOfflineCooked } from '../actions-ticket-assert/assertTicket3sOfflineCooked'
import { assertTicket3sOfflineExists } from '../actions-ticket-assert/assertTicket3sOfflineExists'
import { assertTicket3sOnlineExists } from '../actions-ticket-assert/assertTicket3sOnlineExists'
import { assertTicket3sTriggered } from '../actions-ticket-assert/assertTicket3sTriggered'
import { getTicketTurnMetadata } from '../actions-ticket-chat/getTicketTurnMetadata'
import { get3sGwsLogs } from '../actions-ticket-kusto/get3sGwsLogs'
import { get3sTransactionIds } from './get3sTransactionIds'

export function get3sLogs(context: IDashStoreContext, ticketId: string, turnId: string): PromiseSnapshot<I3sLogData> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.sssLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.sssLogs = snapshot
    },
    fetch: async () => {
      try {
        return await doFetch()
      } catch (e) {
        await assertTicket3sTriggered(context, ticketId, turnId)

        throw e
      }
    },
  })

  async function doFetch(): Promise<I3sLogData> {
    const turn = await getTicketTurnMetadata(context, ticketId, turnId).promise
    const transactionIds = await get3sTransactionIds(context, ticketId, turnId).promise

    let gwsLogs: IKustoGwsLogItem[] = []
    let gwsLogsError: unknown
    try {
      gwsLogs = await get3sGwsLogs(context, ticketId, turnId).promise
    } catch (e) {
      gwsLogsError = e
      if (!turn) {
        throw e
      }
    }

    const items = await Promise.all(
      transactionIds.map((transactionId) =>
        getItem(
          turn,
          transactionId,
          gwsLogs.find((log) => log.transactionId === transactionId),
          gwsLogsError,
        ),
      ),
    )

    const sortedItems = sortBy(items, (item) => {
      if (item.isQuery) return 0
      if (item.isAsyncQuery) return 1
      return 2
    })

    return {
      ticketId,
      ticketTurnId: turnId,
      ticketTurnIndex: turn.index,
      items: sortedItems,
    }
  }

  async function getItem(
    turn: ITicketTurnMetadata,
    transactionId: string,
    log: IKustoGwsLogItem | undefined,
    logError: unknown,
  ): Promise<I3sLogDataItem> {
    const routeAction = log?.routeAction
    const isQuery = routeAction === 'query'
    const isAsyncQuery = routeAction === 'asyncQuery'.toLowerCase()

    const transactionLabels: string[] = []
    if (!isQuery && routeAction) {
      transactionLabels.push(routeAction)
    }

    const containsSearchMessageExtension = log?.requestedActions?.includes('SearchMessageExtension') ?? false
    if (containsSearchMessageExtension) {
      transactionLabels.push('SearchMessageExtension')
    }

    let hasOfflineData: boolean = false
    let offlineDataError: unknown
    if (!routeAction || isQuery) {
      try {
        hasOfflineData = await getHasOfflineData(turn, transactionId)
      } catch (e) {
        hasOfflineData = false
        offlineDataError = e
      }
    }

    let hasOnlineData: boolean = false
    let onlineDataError: unknown
    if (!routeAction || isQuery) {
      try {
        hasOnlineData = await getHasOnlineData(turn, transactionId)
      } catch (e) {
        hasOnlineData = false
        onlineDataError = e
      }
    }

    return {
      transactionId,
      transactionLabels,
      isQuery,
      isAsyncQuery,
      log,
      logError,
      hasOfflineData,
      offlineDataError,
      hasOnlineData,
      onlineDataError,
    }
  }

  async function getHasOfflineData(turn: ITicketTurnMetadata, transactionId: string): Promise<boolean> {
    const data = turn.offlineTransactionIds.find((item) => item === transactionId)
    if (data) {
      return true
    }

    // Throw error
    await assertTicket3sOfflineCooked(context, ticketId)
    await assertTicket3sOfflineExists(context, ticketId, turnId, transactionId)
    throw TicketError.create('No3sOffline', { ticketId, ticketMessageId: turnId })
  }

  async function getHasOnlineData(turn: ITicketTurnMetadata, transactionId: string): Promise<boolean> {
    if (turn.onlineTransactionIds.includes(transactionId)) {
      return true
    }

    // Throw error
    await assertTicket3sOnlineExists(context, ticketId, turnId, transactionId)
    throw TicketError.create('No3S', { ticketId, ticketMessageId: turnId })
  }
}
