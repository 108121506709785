import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { compact, sortBy, uniq } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicketInteractions } from '../actions-raw-ticket-ods/getRawOdsTicketInteractions'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'

export function getTicketMessageIds(context: IDashStoreContext, ticketId: string): PromiseSnapshot<string[]> {
  return context.getOrFetch<string[]>({
    get: (state) => {
      return state.tickets[ticketId]?.messageIds
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.messageIds = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      if (context.enableV2Endpoint) {
        return fetchFromV2()
      } else {
        return fetchFromV1()
      }
    },
  })

  async function fetchFromV2(): Promise<string[]> {
    // TODO: This is incorrect, this data only contains the last one conversation
    const raw = await getRawV2Ticket(context, ticketId).promise
    const chat = raw.diagnosticContext?.chat
    if (!chat || chat.length === 0) {
      throw TicketError.create('NoConversations', { ticketId })
    }

    return uniq(sortBy(chat, (item) => -item.index).map((item) => item.messageId))
  }

  async function fetchFromV1(): Promise<string[]> {
    const interactions = await getRawOdsTicketInteractions(context, ticketId).promise
    if (interactions.length === 0) {
      throw TicketError.create('NoConversations', { ticketId })
    }

    // ODS metadata has a bug where it contains duplicate interaction info
    return compact(uniq(sortBy(interactions, (item) => -item.Index).map((item) => item.MessageId)))
  }
}
