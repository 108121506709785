import { TimeRange, Times } from '@copilot-dash/core'
import { Button, Input, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { CalendarLtrRegular, ChevronDownRegular, DismissRegular } from '@fluentui/react-icons'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { CalendarProps, DateObject, DatePickerProps, DatePickerRef } from 'react-multi-date-picker'
import { DatePickerWrapped } from '../ReactMultiDatePickerWrapped/ReactMultiDatePickerWrapped'
import { TimeRangePickerPanel } from '../ReactMultiDatePickerWrapped/plugins/TimeRangePickerPanel'
import { TimeZoneInfo } from '../ReactMultiDatePickerWrapped/plugins/TimeZoneInfo'
import { toMicroseconds } from '../ReactMultiDatePickerWrapped/utils'
import { Submit } from './Submit'
import { DateTimeRangePickerHeader } from './DateTimeRangePickerHeader'
import { Row } from '../Layout'

interface IProps {
  readonly range: TimeRange | undefined
  readonly onChanged: (range: TimeRange) => void
  readonly minDate?: string
  readonly maxDate?: string
  readonly hasDeselectedButton?: boolean
}

type DateValue = DateObject | Date | string | number
function isArrayWithTwoDates(value: unknown): value is [DateValue, DateValue] {
  return Array.isArray(value) && value.length === 2
}

export const DateTimeRangePicker = memo(({ range, onChanged, minDate, maxDate, hasDeselectedButton }: IProps) => {
  const styles = useStyles()
  const timezone = app.settings.timezone.use()
  const [value, setValue] = useState<CalendarProps['value']>(null) // NOTE: value for the calendar
  const datePickerRef = useRef<DatePickerRef>(null)

  // Reset value from the props
  const resetValue = useCallback(() => {
    if (!range) {
      setValue('')
      return
    }

    const { from, to } = Times.formatTimeRange(range, { timezone })
    if (!from || !to) {
      setValue('')
      return
    }

    setValue([new Date(from), new Date(to)])
  }, [range, timezone])

  // Reset value when first render
  useEffect(() => {
    resetValue()
  }, [resetValue])

  // Handle calendar value change
  const handleChange: DatePickerProps['onChange'] = (date) => {
    setValue(date)
  }

  // submit the value when OK button is clicked
  const handleOK = useCallback(() => {
    if (isArrayWithTwoDates(value)) {
      const [start, end] = value
      const startNum = toMicroseconds(start)
      const endNum = toMicroseconds(end)

      const startStr = Times.format(startNum, { timezone })
      const endStr = Times.format(endNum, { timezone })
      if (startStr && endStr) {
        onChanged({ type: 'absolute', from: startStr, to: endStr })
      }
    }

    if (datePickerRef.current) {
      datePickerRef.current.closeCalendar()
    }
  }, [onChanged, timezone, value])

  const handleQuickSelectorChange = useCallback(
    (range: TimeRange) => {
      onChanged(range)

      if (datePickerRef.current) {
        datePickerRef.current.closeCalendar()
      }
    },
    [onChanged],
  )

  // NOTE: @Ethan - disable no-explicit-any rule due to the need of picking the correct type from DatePickerProps['render']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderInput = useCallback<Extract<DatePickerProps['render'], (...args: any[]) => any>>(
    (value, openCalendar) => {
      const dateValue = range && range.type === 'relative' ? Times.toStringOfRelativeTimeRange(range) : value
      return (
        <Input
          className={styles.input}
          value={range ? dateValue : ''}
          type="text"
          onClick={openCalendar}
          contentBefore={<CalendarLtrRegular onClick={openCalendar} />}
          contentAfter={
            hasDeselectedButton && dateValue.length > 0 ? (
              <Row className={styles.selectCloseButton}>
                <Button
                  icon={<DismissRegular />}
                  appearance="subtle"
                  size="small"
                  onClick={() => {
                    onChanged({ type: 'absolute', from: 'null', to: 'null' })
                  }}
                />
              </Row>
            ) : (
              <ChevronDownRegular aria-label="Dropdown" onClick={openCalendar} />
            )
          }
          placeholder="Time Range"
        />
      )
    },
    [styles.input, range, onChanged, styles.selectCloseButton, hasDeselectedButton],
  )

  return (
    <DatePickerWrapped
      ref={datePickerRef}
      timezone={timezone}
      className={'DateRangePicker'}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      format="MM/DD HH:mm"
      range={true}
      arrow={false}
      showOtherDays={true}
      disableYearPicker={true}
      numberOfMonths={1}
      monthYearSeparator=" "
      onChange={handleChange}
      render={renderInput}
      onClose={() => {
        // Reset value when the calendar is closed without submitting
        resetValue()
      }}
      plugins={[
        <DateTimeRangePickerHeader key="MyPlugin" position="top" range={range} onChanged={handleQuickSelectorChange} />,
        <TimeZoneInfo key="MessageBarHitPlugin" position="top" timezone={timezone} />,
        <TimeRangePickerPanel key="TimeRangePickerPanel" position="bottom" />,
        <Submit
          key="Submit"
          position="bottom"
          onClick={handleOK}
          submittable={!Array.isArray(value) || (value?.length ?? 0) !== 2}
        />,
      ]}
      dateSeparator=" to "
    />
  )
})

DateTimeRangePicker.displayName = 'DateTimeRangePicker'

const useStyles = makeStyles({
  input: {
    width: '100%',
    marginLeft: '0px',
    ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralBackground3Pressed),
    ':hover': {
      ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    },
    '&> input': {
      cursor: 'pointer',
    },
  },
  selectCloseButton: {
    padding: 0,
    width: '20px',
    height: '20px',
  },
})
