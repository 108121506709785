import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoLLMLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'
import { getTicketTurnMetadata } from '../actions-ticket-chat/getTicketTurnMetadata'

export function getLlmLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoLLMLogItem[]> {
  return context.getOrFetch<IKustoLLMLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.llmLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.llmLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const logs = context.enableV2Endpoint ? await doFetchFromV2() : await doFetchFromV1()
      if (logs.length > 0) {
        return logs
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.LLMLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.LLMLog })
    },
  })

  async function doFetchFromV1(): Promise<IKustoLLMLogItem[]> {
    const turn = await getTicketTurnMetadata(context, ticketId, messageId).promise.catch(() => undefined)

    const response = await context.api.logCollector.getSydneySingleInfo({
      FetchLogName: 'llmLog',
      HappenTimeArray: turn?.time ? [turn.time] : [],
      MessageId: messageId,
    })

    return response.logData['modelDHttpQAS (Kusto)'] ?? []
  }

  async function doFetchFromV2(): Promise<IKustoLLMLogItem[]> {
    const ticketSource = await getTicketSource(context, ticketId).promise
    const source = ticketSource.turns[messageId]?.llmLog
    if (source) {
      const results = await context.api.copilotDash.getTicketBlob(ticketId, source.blobUrl).asLlmLog()
      return results
    }

    return []
  }
}
