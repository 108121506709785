import { ApiTraceKustoLogItem, ApiTraceKustoLogItemV2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoTraceLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'
import { getTicketTurnMetadata } from '../actions-ticket-chat/getTicketTurnMetadata'

export function getTraceLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoTraceLogItem[]> {
  return context.getOrFetch<IKustoTraceLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.traceLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.traceLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const logs = context.enableV2Endpoint ? await doFetchFromV2() : await doFetchFromV1()
      if (logs && logs.length > 0) {
        return logs
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.TraceLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.TraceLog })
    },
  })

  async function doFetchFromV1(): Promise<IKustoTraceLogItem[] | undefined> {
    const turn = await getTicketTurnMetadata(context, ticketId, messageId).promise.catch(() => undefined)

    const response = await context.api.logCollector.getSydneySingleInfo({
      FetchLogName: 'traceLog',
      HappenTimeArray: turn?.time ? [turn.time] : [],
      MessageId: messageId,
    })

    return response.logData['turingBotTraceMDSLogs (Kusto)']?.map(convertToItemV1)
  }

  async function doFetchFromV2(): Promise<IKustoTraceLogItem[] | undefined> {
    const ticketSource = await getTicketSource(context, ticketId).promise
    const source = ticketSource.turns[messageId]?.traceLog
    if (source) {
      const results = await context.api.copilotDash.getTicketBlob(ticketId, source.blobUrl).asTraceLog()
      return results.map(convertToItemV2)
    }

    return undefined
  }

  function convertToItemV1(raw: ApiTraceKustoLogItem): IKustoTraceLogItem {
    return {
      eventName: raw.eventName,
      message: raw.message,
      raw,
    }
  }

  function convertToItemV2(raw: ApiTraceKustoLogItemV2): IKustoTraceLogItem {
    return {
      eventName: raw.EventName,
      message: raw.Message,
      raw,
    }
  }
}
