import { INewTicketData } from '@copilot-dash/domain'
import { memo, useMemo, useState } from 'react'
import { ColumnSettingButton } from '../../../../components/Button/ColumnSettingButton'
import { TicketsFilterButton } from '../../../../components/Button/TicketsFilterButton'
import { ErrorView } from '../../../../components/Error'
import { Body, Column, Header, Row, Spacer } from '../../../../components/Layout'
import { ResizeDimensions, useResizeObserver } from '../../../../hooks/useResizeObserver'
import { useSearchScreenActions, useSearchScreenState } from '../../store'
import { SearchScreenBar } from '../SearchScreenBar'
import { SearchScreenForm } from '../SearchScreenForm'
import { SMALL_SCREEN_WIDTH } from '../SearchScreenForm.styles'
import { SearchScreenTableView } from '../table/SearchScreenTableView'
import { useStyles } from './AllFeedback.styles'
import { SatAndDastCount } from './SatAndDastCount'
import { DateTimeRender } from './DateTimeRender'
import { ColDef } from 'ag-grid-community'
import { SUPPORTED_TAGS } from '../../../../components/TicketsFilterPanel/config/SupportedTags'
import { isNil } from 'lodash'

interface IProps {
  readonly selectedColumns: ColDef<INewTicketData>[]
}

export const AllFeedback = memo(function SearchScreenBody({ selectedColumns }: IProps) {
  const styles = useStyles()
  const searchSnapshot = useSearchScreenState((state) => state.loadMoreSnapshot)
  const searchFeedbackResult = useSearchScreenState((state) => state.searchFeedbackResult)
  const form = useSearchScreenState((state) => state.form)
  const isColumnSettingPanelOpen = useSearchScreenState((state) => state.isColumnSettingPanelOpen)
  const isTicketsFilterPanelOpen = useSearchScreenState((state) => state.isTicketsFilterPanelOpen)
  const actions = useSearchScreenActions()
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  const validTags = SUPPORTED_TAGS.filter((tag) => {
    const value = form[tag.key]
    if (isNil(value)) return false
    if ((Array.isArray(value) || typeof value === 'string') && value.length === 0) return false
    return true
  })

  const handleResize = (width: number) => {
    if (width < SMALL_SCREEN_WIDTH) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }
  const ref = useResizeObserver(ResizeDimensions.Width, handleResize)

  const tableContent = useMemo(() => {
    let tableItem: INewTicketData[] = []
    switch (searchSnapshot[0]?.status) {
      case 'none':
      case 'waiting':
      case 'done':
        tableItem = searchFeedbackResult.tickets ?? ([] as INewTicketData[])
        return (
          <SearchScreenTableView
            tableItem={tableItem}
            selectedColumns={selectedColumns}
            loadingTable={searchSnapshot[0].status !== 'done'}
          />
        )
      case 'error':
        return <ErrorView error={searchSnapshot[0].error} className={styles.errorLayout} />
      default:
        return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFeedbackResult.tickets, searchSnapshot, selectedColumns])

  return (
    <Column className={styles.container} ref={ref}>
      {/* Header */}
      <Header>
        <Header>
          <SearchScreenBar />
          <SearchScreenForm onDismissTicketSummaryPanel={actions.onDismissTicketSummaryPanel} />
        </Header>
        <Row className={styles.tagsBar} space="between">
          {/* Left */}
          <SatAndDastCount />
          {/* Right */}
          <Row>
            <DateTimeRender />
            <Spacer width="8px" />
            <TicketsFilterButton
              onClickButton={actions.handleTicketsFilterPanelButtonClick}
              isTicketsFilterPanelOpen={isTicketsFilterPanelOpen}
              placeholder={isSmallScreen ? '' : 'Filters'}
              badgeCount={validTags.length}
            />
            <Spacer size={4} />
            {/* Column Setting Button */}
            <ColumnSettingButton
              placeholder={isSmallScreen ? '' : 'Columns'}
              isColumnSettingPanelOpen={isColumnSettingPanelOpen}
              onClickButton={actions.handleColumnSettingButtonClick}
            />
          </Row>
        </Row>
        <Spacer height="8px" />
      </Header>
      {/* Body */}
      <Body className={styles.body}>{tableContent}</Body>
    </Column>
  )
})
