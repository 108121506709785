import { SearchTextPrefixType, getProductEndpoints } from '@copilot-dash/domain'

import { IQueryBuilderArgs } from './AiSearchQueryBuilder.types'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { Times } from '@copilot-dash/core'
import { convertHasErrorMessages } from './utils/convertHasErrorMessages'
import { convertTriggeredSkillsFromKeyArray } from './utils/convertTriggeredSkillsFromKeyArray'

export class AiSearchQueryBuilder {
  buildQuery(options: ISearchTicketOptions): { searchQuery?: string; filterQuery: string } {
    const args = this.prepareParam(options)
    const encodeArg = this.encodeParam(args)

    const searchQuery = this.createSearchQuery(encodeArg)
    const filterQuery = this.createFilter(encodeArg)
    return { searchQuery, filterQuery }
  }

  private prepareParam = (options: ISearchTicketOptions): IQueryBuilderArgs => {
    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })
    const triggeredSkills = convertTriggeredSkillsFromKeyArray(options.triggeredSkill)

    const hasErrorMessagesConditions = convertHasErrorMessages(options.hasErrorMessages)

    const clientNames = getProductEndpoints(
      options.product,
      options.applications,
      options.platforms,
      options.licenses,
      options.meetingScenarios,
    )

    const request: IQueryBuilderArgs = {
      userId: options.userId,
      tenantId: options.tenantIds,
      from: from,
      to: to,
      emotionType: options.thumbs,
      hasUserConsent:
        options.hasUserConsent?.length === 1 ? (options.hasUserConsent[0]?.startsWith('!') ? false : true) : undefined,
      hasVerbatim:
        options.hasVerbatim?.length === 1 ? (options.hasVerbatim[0]?.startsWith('!') ? false : true) : undefined,
      clientName: clientNames,
      scenarioName: options.channel,
      ring: options.ring,

      verbatim:
        options.searchTextPrefix === SearchTextPrefixType.Verbatim ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      utterance:
        options.searchTextPrefix === SearchTextPrefixType.Utterance ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      response:
        options.searchTextPrefix === SearchTextPrefixType.Response ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      customTags: options.customTags,
      copilotExtensionIds: options.copilotExtensionIds,
      flights: options.flights,
      sliceIds: options.sliceIds,
      optionsSets: options.optionsSets,
      hasScreenshot: options.hasScreenshot,
      tags: [
        ...(options.promptLanguages && options.promptLanguages.length ? [options.promptLanguages.join('|')] : []),
        ...(options.groundedPrompts?.length ? [options.groundedPrompts.join('|')] : []),
        ...(options.isApology?.length ? [options.isApology.join('|')] : []),
        ...(options.customerTypes?.length ? [options.customerTypes.join('|')] : []),
        ...(options.invocationType?.length ? [options.invocationType.join('|')] : []),
        ...(triggeredSkills ?? []),
        ...(hasErrorMessagesConditions ?? []),
        ...(options.hasCitation?.length ? [options.hasCitation.join('|')] : []),
        ...(options.hasEntityCard?.length ? [options.hasEntityCard.join('|')] : []),
        ...(options.hitAvalon?.length ? [options.hitAvalon.join('|')] : []),
        ...(options.isSTCAChina?.length ? [options.isSTCAChina.join('|')] : []),
        ...(options.isTopi18N?.length ? [options.isTopi18N.join('|')] : []),
        ...(options.responseHeroType?.length ? [options.responseHeroType.join('|')] : []),
        ...(options.responseLinkType?.length ? [options.responseLinkType.join('|')] : []),
        ...(options.semanticSearchType?.length ? [options.semanticSearchType.join('|')] : []),
        ...(options.bizchatScenario?.length ? [options.bizchatScenario.join('|')] : []),
        ...(options.experienceType?.length ? [options.experienceType.join('|')] : []),
        ...(options.hasConnector?.length ? [options.hasConnector.join('|')] : []),
        ...(options.hasGPTExtension?.length ? [options.hasGPTExtension.join('|')] : []),
        ...(options.hasMessageExtension?.length ? [options.hasMessageExtension.join('|')] : []),
        ...(options.hasCopilotExtensionIds?.length ? [options.hasCopilotExtensionIds.join('|')] : []),
        ...(options.errorCode?.length ? [options.errorCode.join('|')] : []),
        ...(options.isGCIntent?.length ? [options.isGCIntent.join('|')] : []),
        ...(options.hasConnectorResult?.length ? [options.hasConnectorResult.join('|')] : []),
        ...(options.agentTypes?.length ? [options.agentTypes.join('|')] : []),
        ...(options.appTypes?.length ? [options.appTypes.join('|')] : []),
      ],
      invocationSlicers: options.invocationSlicers,
      id: options.id ? [...options.id] : [],
    }

    return request
  }

  private encodeParam = (args: IQueryBuilderArgs): IQueryBuilderArgs => {
    const escapeFilter: (text: string | undefined) => string | undefined = (
      text: string | undefined,
    ): string | undefined => {
      return text ? this.escapeSpecialCharactersInFilter(text) : undefined
    }

    const escapeArray = (arr: string[] | undefined): string[] | undefined => {
      return arr
        ? arr.map((item) => escapeFilter(item)).filter((item): item is string => item !== undefined)
        : undefined
    }

    const encodedRequest: IQueryBuilderArgs = {
      ...args,

      // Escape special characters in search query for verbatim, utterance, and response
      verbatim: args.verbatim ? this.escapeSpecialCharactersInSearchQuery(args.verbatim) : undefined,
      utterance: args.utterance ? this.escapeSpecialCharactersInSearchQuery(args.utterance) : undefined,
      response: args.response ? this.escapeSpecialCharactersInSearchQuery(args.response) : undefined,

      // Escape special characters in filter for copilotExtensionIds, flights, sliceIds, and optionsSets
      copilotExtensionIds: escapeFilter(args.copilotExtensionIds),
      flights: escapeFilter(args.flights),
      sliceIds: escapeFilter(args.sliceIds),
      optionsSets: escapeFilter(args.optionsSets),

      // Escape special characters in array elements for customTags and tags
      customTags: escapeArray(args.customTags),
      tags: escapeArray(args.tags),
      invocationSlicers: escapeArray(args.invocationSlicers),
      id: escapeArray(args.id),
      userId: escapeArray(args.userId),
    }

    return encodedRequest
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax
   */
  private createSearchQuery = (args: IQueryBuilderArgs): string | undefined => {
    const searchTerms: string[] = []
    // Add searchable fields to searchQuery
    if (args.utterance) searchTerms.push(`utterance:"${args.utterance}"`)
    if (args.verbatim) searchTerms.push(`verbatim:"${args.verbatim}"`)
    if (args.response) searchTerms.push(`response:"${args.response}"`)

    return searchTerms.length > 0 ? searchTerms.join(' OR ') : undefined
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/search-query-odata-filter
   */
  private createFilter = (args: IQueryBuilderArgs): string => {
    const filters: string[] = []
    const addFilter = (condition: boolean, filter: string) => {
      if (condition) filters.push(filter)
    }

    // Add other fields to filterQuery
    addFilter(!!args.userId?.length, `search.in(userId, '${args.userId?.join(',')}', ',')`)
    addFilter(!!args.tenantId?.length, `search.in(tenantId, '${args.tenantId?.join(',')}', ',')`)
    addFilter(!!args.from, `createDateTime ge ${args.from}`)
    addFilter(!!args.to, `createDateTime le ${args.to}`)
    addFilter(!!args.emotionType?.length, `search.in(emotionType, '${args.emotionType?.join(',')}', ',')`)
    addFilter(args.hasUserConsent !== undefined, `hasUserConsent eq ${args.hasUserConsent}`)

    /**
     * The AI search using Cosmos DB is faster than the collection process based on SQL Server.
     * This discrepancy may result in search indexing being completed before the ticket collection is finished.
     */
    // short-term solution 1: add a time offset to filter tickets from 15 minutes ago.
    const now = new Date()
    const fifteenMinutesAgo = new Date(now.getTime() - 15 * 60 * 1000)
    addFilter(true, `createDateTime le ${fifteenMinutesAgo.toISOString()}`)

    //Short-term solution 2: Show tickets even when it's "cooked" - Search filter: fullTags not eq null or empty
    // Note: tickets before 2024/12/20 don't have fullTags, so we need to show them as well.
    //addFilter(true, `fullTags/any()`)

    // Add filter for hasVerbatim
    if (args.hasVerbatim === true) {
      addFilter(true, `(verbatim ne null and verbatim ne '')`)
    } else if (args.hasVerbatim === false) {
      addFilter(true, `(verbatim eq null or verbatim eq '')`)
    }

    addFilter(!!args.clientName?.length, `search.in(clientName, '${args.clientName?.join(',')}', ',')`)
    addFilter(
      !!args.scenarioName?.length,
      `(${args.scenarioName?.map((name) => `scenarioName eq '${name}'`).join(' or ')})`,
    )
    addFilter(!!args.ring?.length, `search.in(ring, '${args.ring?.join(',')}', ',')`)
    addFilter(!!args.customTags?.length, `customTags/any(t: search.in(t, '${args.customTags?.join(',')}', ','))`)

    /**
     * Tag-Based Filter: This filter handles both 'tags' and 'fullTags' fields in the AI search.
     * It ensures that the search considers both fields for the presence or absence of tags.
     * - If a tag starts with '!', it excludes the tag from both 'tags' and 'fullTags'.
     * - If a tag includes '|', it checks for multiple values in both 'tags' and 'fullTags' using 'search.in'.
     * - For single values, it checks if the tag is present in either 'tags' or 'fullTags'.
     * Short-term solution: Check both 'tags' and 'fullTags' fields for the presence or absence of tags.
     * Long-term solution: We plan to merge 'tags' and 'fullTags' into a single property.
     */
    addFilter(
      !!args.tags?.length,
      `(${args.tags
        ?.map((tag) => {
          if (tag.startsWith('!')) {
            return `(not tags/any(t: t eq '${tag.substring(1)}') and not fullTags/any(t: t eq '${tag.substring(1)}'))`
          } else if (tag.includes('|')) {
            return `(tags/any(t: search.in(t, '${tag}', '|')) or fullTags/any(t: search.in(t, '${tag}', '|')))`
          } else {
            return `(tags/any(t: t eq '${tag}') or fullTags/any(t: t eq '${tag}'))`
          }
        })
        .join(' and ')})`,
    )

    //Filter for optionsSets, sliceIds, and flights.Note: These fields are filterable in AI search.
    addFilter(!!args.optionsSets, `optionsSets/any(t: t eq '${args.optionsSets}')`)
    addFilter(!!args.sliceIds, `sliceIds/any(t: t eq '${args.sliceIds}')`)
    addFilter(!!args.flights, `flights/any(t: t eq '${args.flights}')`)

    // Support partial pattern search for copilotExtensionIds. Note: copilotExtensionIds is searchable in AI search
    addFilter(!!args.copilotExtensionIds, `search.ismatch('${args.copilotExtensionIds}', 'copilotExtensionIds')`)

    // addFilter(!!args.invocationSlicers, `slicers/any(s: s eq '${args.invocationSlicers}')`)
    addFilter(!!args.invocationSlicers?.length, `slicers/any(s: search.in(s, '${args.invocationSlicers}', ','))`)

    addFilter(!!args.id?.length, `search.in(id, '${args.id?.join(',')}', ',')`)
    if (args.hasScreenshot === true) {
      addFilter(true, `screenshotCount ne null and screenshotCount ne '' and screenshotCount ne '0'`)
    } else {
      addFilter(
        args.hasScreenshot === false,
        `(screenshotCount eq null or screenshotCount eq '' or screenshotCount eq '0')`,
      )
    }
    return filters.join(' and ')
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax#escaping-special-characters
   */
  private escapeSpecialCharactersInSearchQuery(text: string): string {
    // Escape special characters in search query
    const specialCharacters = /[+\-&|!(){}[\]^"~*?:\\/]/g
    return text.replace(specialCharacters, '\\$&')
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-odata-filter-orderby-syntax#escaping-special-characters-in-string-constants
   */
  private escapeSpecialCharactersInFilter(text: string): string {
    // Escape single quotes by doubling them
    return text.replace(/'/g, "''")
  }
}
