import { Times } from '@copilot-dash/core'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Row, Spacer } from '../../../../Layout'

interface IProps {
  readonly value: string
}

export function ValueViewForDatetime({ value }: IProps) {
  const styles = useStyles()
  const timezone = app.settings.timezone.use()

  const displayText = useMemo(() => {
    return Times.format(value, { timezone }) ?? String(value)
  }, [timezone, value])

  return (
    <Row className={styles.root} vAlign="center" title={value}>
      <Text>{displayText}</Text>
      <Spacer width={8} />
      <Text className={styles.timezone} title={timezone}>
        {timezone}
      </Text>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
    gap: '4px',
  },
  timezone: {
    color: tokens.colorNeutralForegroundDisabled,
  },
  icon: {
    color: tokens.colorNeutralForegroundDisabled,
  },
})
