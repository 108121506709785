import { IFilterOption } from '../../Filter/IFilterOption.types'
import { TicketRingType } from '@copilot-dash/domain'

export const OptionSetRing: IFilterOption[] = [
  {
    key: TicketRingType.SDFV2,
    text: TicketRingType.SDFV2,
  },
  {
    key: TicketRingType.MSIT,
    text: TicketRingType.MSIT,
  },
  {
    key: TicketRingType.DONMT,
    text: TicketRingType.DONMT,
  },
  {
    key: TicketRingType.SIP,
    text: TicketRingType.SIP,
  },
  {
    key: TicketRingType.WW,
    text: TicketRingType.WW,
  },
  {
    key: TicketRingType.UNKNOWN,
    text: TicketRingType.UNKNOWN,
  },
]
