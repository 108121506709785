import { getProductNameById, IRootCauseList, TeamViewSubMenuIds } from '@copilot-dash/domain'
import {
  Button,
  Field,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Skeleton,
  SkeletonItem,
  Tag,
  Text,
  tokens,
} from '@fluentui/react-components'
import { AddRegular, ClipboardTextLtrFilled, Dismiss16Filled } from '@fluentui/react-icons'
import { FC, memo, useCallback, useLayoutEffect, useState } from 'react'
import { useToast } from '../../../hooks/useToast'
import { useGlobalStore } from '../../../store'
import { Column, Row, Spacer } from '../../Layout'
import { RootCauseCreateDialog } from '../../RootCauseCreateDialog/RootCauseCreateDialog'
import { IFormData as ICreateRootCauseFormData } from '../../RootCauseCreateDialog/RootCauseCreateForm'
import { EMPTY_TEAM_ID } from '../../../screens/team/store/const'
import { isNil } from 'lodash'
import { useStyles } from './RootCauses.styles'
import { CopilotDashDropdownInput } from '../../Filter/CopilotDashDropdownInput'
import { Scrollbar } from '../../Scrollbar/Scrollbar'
import { RootCauseSelectorList } from './components/RootCauseSelectorList'
import { RouteLink, TeamRoute } from '../../../router'

interface IRootCausesProps {
  value: string[] | undefined | null
  onChange: (value: string[]) => void
  productId?: number
  orientation?: 'horizontal' | 'vertical'
  loading?: boolean
  className?: string
  rootCauseMaxWidth?: string
  style?: React.CSSProperties
  defaultValueText?: string
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
  teamName?: string
  teamId?: number
  vsoAccount?: string
  children: React.ReactNode
  allowCreateNewRootCause?: boolean
  rootCauseGrouped?: Record<string, IRootCauseList>
}
export const RootCauses: FC<IRootCausesProps> = memo(
  ({
    value,
    onChange,
    productId,
    orientation = 'horizontal',
    loading = false,
    className,
    rootCauseMaxWidth,
    style,
    defaultValueText,
    disabled,
    fieldProps,
    teamName,
    teamId,
    vsoAccount,
    children,
    allowCreateNewRootCause = false,
    rootCauseGrouped = {},
  }) => {
    const styles = useStyles()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [values, setValues] = useState(() => (value && value.length > 0 ? value : []))
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
    const [input, setInput] = useState<string>('')

    useLayoutEffect(() => {
      setValues(value && value.length > 0 ? value : [])
    }, [value])

    const onRootCauseChange = useCallback(
      (issueId: string) => {
        const newValues = [...values]
        newValues.push(issueId)
        const uniqueValues = Array.from(new Set(newValues))
        onChange(uniqueValues)
        setIsPopoverOpen(false)
      },
      [onChange, values],
    )

    const onRemoveRootCause = (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      const newValues = [...values].filter((item) => item !== value)
      onChange(newValues)
    }

    const generatePath = useCallback(
      (issueId: string) => {
        const product = getProductNameById(productId)
        const teamAndRootCauseList = Object.entries(rootCauseGrouped).find(([_, rootCauseList]) =>
          rootCauseList.some((rootCause) => rootCause.issueId === issueId),
        )
        if (!teamAndRootCauseList || !product) return ''
        const teamName = teamAndRootCauseList[0]
        const vsoAccount = teamAndRootCauseList[1].find((rootCause) => rootCause.issueId === issueId)!.vsoAccount

        return TeamRoute.navigator.generatePath({
          product,
          subMenu: TeamViewSubMenuIds.RootCauses,
          teamName,
          issueId,
          vsoAccount,
        })
      },
      [productId, rootCauseGrouped],
    )

    const toast = useToast()

    const handleSaveAndAdd = useCallback(
      async (data: ICreateRootCauseFormData) => {
        if (isNil(teamId) || teamId === EMPTY_TEAM_ID || !vsoAccount) return
        return application.store.actions
          .createRootCause({
            title: data.title,
            description: data.description ? data.description : '',
            priority: data.priority,
            vsoAccount: vsoAccount,
            teamId: teamId,
            productId: productId,
          })
          .then((response) => {
            if (response.workItemId) {
              // NOTE: @Ethan - Update the root cause list in the store.
              if (teamName) {
                useGlobalStore.getState().addRootCauseListByAreaPath(teamName, {
                  issueId: response.workItemId,
                  title: data.title,
                  vsoAccount: vsoAccount,
                  visible: true,
                  rootCauseStatus: 'Active',
                })
              }
              if (productId && teamId) {
                application.store.actions.appendRootCauseByTeam(productId, teamId, {
                  issueId: response.workItemId,
                  rootCauseTitle: data.title,
                  vsoAccount: vsoAccount,
                  reportingStatus: 'Active',
                  eTA: '',
                  owner: '',
                  createdTimeUtc: '',
                  rootCauseCount: 0,
                  enable: false,
                })
              }
              onChange([...values, response.workItemId])
              setDialogOpen(false)
            } else {
              // handle error
              toast.showError('Failed to save', response.errorMessage)
            }
          })
          .catch((err) => {
            toast.showError('Failed to save', err.message)
          })
      },
      [teamId, vsoAccount, teamName, values, productId, onChange, toast],
    )

    return (
      <Field orientation={orientation} className={className} label="Root Cause:" style={style} {...(fieldProps ?? {})}>
        {loading ? (
          <Skeleton>
            <Column>
              <SkeletonItem size={16} />
              <Spacer height={4} />
              <SkeletonItem size={16} />
            </Column>
          </Skeleton>
        ) : (
          <div
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', minWidth: '150px', overflow: 'hidden' }}
          >
            {values
              .filter((rootCause) => rootCause)
              .map((rootCause, index) => (
                <Tag
                  dismissible
                  value={rootCause}
                  key={rootCause}
                  tabIndex={index}
                  className={styles.tagStyle}
                  dismissIcon={{
                    as: 'span',
                    children: (
                      <Dismiss16Filled
                        className={styles.dismissButton}
                        onClick={(e) => onRemoveRootCause(e, rootCause)}
                      />
                    ),
                  }}
                >
                  <RouteLink path={generatePath(rootCause)} key={rootCause} openInNewTab>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ClipboardTextLtrFilled
                        style={{
                          color: tokens.colorBrandForeground1,
                          width: '20px',
                          height: '20px',
                          marginRight: '5px',
                          flexShrink: 0,
                        }}
                      />
                      <Text className={mergeClasses(styles.textStyle, rootCauseMaxWidth)}>
                        {
                          Object.entries(rootCauseGrouped)
                            .map(([_, rootCauseList]) => rootCauseList)
                            .flat(1)
                            .find((item) => item.issueId === rootCause)?.title
                        }
                      </Text>
                    </div>
                  </RouteLink>
                </Tag>
              ))}
            <Popover
              positioning={{
                align: 'start',
                pinned: false,
                position: 'below',
              }}
              onOpenChange={(_, data) => setIsPopoverOpen(data.open)}
              open={isPopoverOpen}
            >
              <PopoverTrigger disableButtonEnhancement>
                <Button
                  appearance="subtle"
                  style={{ color: tokens.colorBrandForeground1, whiteSpace: 'nowrap' }}
                  icon={<AddRegular fontSize={18} />}
                >
                  Add root cause
                </Button>
              </PopoverTrigger>
              <PopoverSurface style={{ padding: '0px' }}>
                <Column style={{ width: '400px' }}>
                  <CopilotDashDropdownInput inputValue={input} setInputValue={setInput} />
                  <Scrollbar className={styles.scrollbar}>
                    <Column>
                      <RootCauseSelectorList
                        input={input}
                        setInput={setInput}
                        disabledValues={values}
                        onChange={(value) => {
                          onRootCauseChange(value)
                        }}
                        onCreateNewRootCauseClicked={() => setDialogOpen(true)}
                        defaultValueText={defaultValueText}
                        disabled={disabled}
                        teamName={teamName}
                        allowCreateNewRootCause={allowCreateNewRootCause}
                        rootCauseGrouped={rootCauseGrouped}
                      />
                    </Column>
                  </Scrollbar>
                </Column>
              </PopoverSurface>
            </Popover>
            <Row className={mergeClasses(styles.alignItemsCenter, styles.addNew)}>{children}</Row>
            <RootCauseCreateDialog
              open={dialogOpen}
              onClose={() => {
                setDialogOpen(false)
              }}
              onOk={handleSaveAndAdd}
              okBtnText="Save and Add"
            />
          </div>
        )}
      </Field>
    )
  },
)

RootCauses.displayName = 'RootCauses'
