import { INewTicketData, TicketEmotionType, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { memo } from 'react'
import { useStyles } from './ColumnComponent.styles'
import { mergeClasses, Text } from '@fluentui/react-components'
import { getTicketStepStageByFields, mapADOStateToCopilotDashState } from '../TicketActionForm/utils'
import { FloatingTooltip, FloatingTooltipContent, FloatingTooltipTrigger } from '../FloatingTooltip'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { CustomCellRendererProps } from 'ag-grid-react'

export const ColumnConfigStatus: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.Status],
  field: String(ColumnKey.Status) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        <RenderStatus item={props.data} />
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.Status]}</TableCellLayoutWithStyles>,
  },
  flex: 1,
  minWidth: 110,
  sortable: false,
  resizable: true,
}

const RenderStatus = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else {
    let text: string

    const copilotDashState = mapADOStateToCopilotDashState(item.status, item.reasoning)
    if (item.status === 'Closed') {
      text = copilotDashState
    } else {
      text = getTicketStepStageByFields(copilotDashState, item.rootCauseList ?? [], item.teamId)
    }
    return (
      <FloatingTooltip>
        <FloatingTooltipTrigger>
          <Text
            className={mergeClasses(
              styles.text,
              text.startsWith('Closed') && styles.green,
              text === 'Team Assigned' && styles.yellow,
              text === 'Root Caused' && styles.blue,
            )}
          >
            {text}
          </Text>
        </FloatingTooltipTrigger>
        <FloatingTooltipContent>
          <Text className={styles.tooltipContent}>{text}</Text>
        </FloatingTooltipContent>
      </FloatingTooltip>
    )
  }
})

RenderStatus.displayName = 'RenderStatus'
