import { memo, useCallback, useMemo, useState, type MouseEvent } from 'react'
import { useTeamViewStore } from '../../store'
import { TableLoading } from '../TableLoading'
import { ErrorView } from '../../../../components/Error'
import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbItem,
  Divider,
  SearchBox,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
} from '@fluentui/react-components'
import { columnsConfig } from './columns'
import { ProductNames, TeamViewSubMenuIds } from '@copilot-dash/domain'
import { Row, Spacer } from '../../../../components/Layout'
import { ToggleLeftDrawerIcon } from '../ToggleDrawerLeftIcon'
import { useStyles } from './RootCauseTable.styles'
import { RouteLink, TeamRoute } from '../../../../router'
import { TicketsFilterButton } from '../../../../components/Button/TicketsFilterButton'
import { SUPPORTED_TAGS } from '../../../../components/TicketsFilterPanel/config/SupportedTags'
import { useRootCauseList } from './useRootCauseList'
import { RootCauseMultiselect } from './RootCauseMultiselect'
import { AssignedToSearch } from './AssignedToSearch'
import { AdaptiveBar } from '../../../../components/AdaptiveBar/AdaptiveBar'
import { debounce } from 'lodash'
import { TeamDateTimeRender } from '../TeamDateTimeRender'

const priorityOptions = ['P1', 'P2', 'P3', 'P4']
const stateOptions = ['Active', 'Closed']
export const RootCauseTable = memo(() => {
  const styles = useStyles()
  const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
  const product = useTeamViewStore((state) => state.productName)
  const isM365Chat = product === ProductNames.M365Chat
  const teamName = useTeamViewStore((state) => state.teams.lastSelectedTeam)
  const [showDivider, setShowDivider] = useState(false)

  const rootCauseSnapshot = useRootCauseList()

  const [searchInput, setSearchInput] = useState('')
  const [priority, setPriority] = useState<string[]>([])
  const [assignedTo, setAssignedTo] = useState<string[]>([])
  const [state, setState] = useState<string[]>([])
  const [sort, setSort] = useState<{ columnId: string; direction: 'ascending' | 'descending' } | null>({
    columnId: 'rootCauseStatus',
    direction: 'ascending',
  })
  const emails: string[] = useMemo(() => {
    if (rootCauseSnapshot && rootCauseSnapshot.status === 'done') {
      const full = rootCauseSnapshot.data.rootCauseList
      const validEmail = full.filter((item) => item.owner).map((item) => item.owner)
      const uniqueArr = Array.from(new Set(validEmail))
      return uniqueArr as string[]
    }
    return []
  }, [rootCauseSnapshot])

  const tableItems = useMemo(() => {
    if (rootCauseSnapshot && rootCauseSnapshot.status === 'done') {
      const full = rootCauseSnapshot.data.rootCauseList

      const list = full
        .filter((rootCause) => rootCause.visible)
        .filter((rootCause) => rootCause.rootCauseTitle.toLowerCase().includes(searchInput.trim().toLowerCase()))
        .filter((rootCause) => {
          const validPriority = priority.map((item) => Number(item[1]))
          if (validPriority.length > 0) {
            return rootCause.rootCausePriority && validPriority.includes(rootCause.rootCausePriority)
          } else {
            return true
          }
        })
        .filter((rootCause) => {
          if (assignedTo.length === 0) return true
          return rootCause.owner && assignedTo.includes(rootCause.owner)
        })
        .filter((rootCause) => {
          if (state.length === 0) return true
          return rootCause.rootCauseStatus && state.includes(rootCause.rootCauseStatus)
        })

      if (sort) {
        list.sort((a, b) => {
          const column = columnsConfig.find((c) => c.id === sort.columnId)
          if (column && column.onSort) {
            return column.onSort(a, b, sort.direction)
          }
          return 0
        })
      }
      return list
    }
    return []
  }, [rootCauseSnapshot, searchInput, sort, priority, assignedTo, state])

  const headerSortProps = useCallback(
    (columnId: string) => {
      return {
        onClick: (e: MouseEvent) => {
          const newSort =
            sort && sort.columnId === columnId
              ? {
                  columnId,
                  direction: sort.direction === 'ascending' ? ('descending' as const) : ('ascending' as const),
                }
              : { columnId, direction: 'ascending' as const }
          setSort(newSort)

          // Log telemetry for sorting
          Logger.telemetry.trackEvent('RootCause/Sort', { columnId: columnId, teamName: teamName ?? undefined })
        },
        sortDirection: sort && sort.columnId === columnId ? sort.direction : undefined,
      }
    },
    [sort, teamName],
  )

  const filters = useTeamViewStore((state) => state.tickets.filterForm)
  const generatePath = useCallback(
    (issueId: string, vsoAccount: string) => {
      const product = useTeamViewStore.getState().productName
      const teamName = useTeamViewStore.getState().teams.lastSelectedTeam
      // all fetch tickets actions will be triggered by the url args change, so we just need to navigate to the right url
      // the url monitor is in the src/screens/team/TeamScreen.tsx
      return TeamRoute.navigator.generatePath({
        product,
        subMenu: TeamViewSubMenuIds.RootCauses,
        teamName: teamName || useTeamViewStore.getState().route.args['teamName'],
        issueId,
        vsoAccount,
        priority: isM365Chat ? ['0', '1'] : undefined,
        ...filters,
      })
    },
    [isM365Chat, filters],
  )

  const table = useMemo(() => {
    if (!rootCauseSnapshot || rootCauseSnapshot.status === 'waiting') {
      return <TableLoading />
    }
    if (rootCauseSnapshot.status === 'error') {
      return <ErrorView error={rootCauseSnapshot.error} />
    }
    if (rootCauseSnapshot.status === 'done') {
      return tableItems.length > 0 ? (
        <Table sortable noNativeElements aria-label="Root Causes" className={styles.table}>
          <TableHeader>
            <TableRow className={styles.headerRow}>
              {columnsConfig.map((column) => (
                <TableHeaderCell {...headerSortProps(column.id)} key={column.id} style={column.cellStyle}>
                  {column.renderHeaderCell()}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableItems.map((rootCause) => {
              return (
                <RouteLink path={generatePath(rootCause.issueId, rootCause.vsoAccount)} key={rootCause.issueId}>
                  <TableRow key={rootCause.issueId} className={styles.bodyRow}>
                    {columnsConfig.map((column) => (
                      <TableCell key={column.id} style={column.cellStyle}>
                        {column.renderCell(rootCause)}
                      </TableCell>
                    ))}
                  </TableRow>
                </RouteLink>
              )
            })}
          </TableBody>
        </Table>
      ) : (
        <ErrorView.Custom level="WARNING" message="No root causes found" />
      )
    }
    return null
  }, [rootCauseSnapshot, tableItems, styles.table, styles.headerRow, styles.bodyRow, headerSortProps, generatePath])

  const form = useTeamViewStore((state) => state.tickets.filterForm)
  const filterPanelOpened = useTeamViewStore((state) => state.computed.filterPanelOpen)
  const validTags = SUPPORTED_TAGS.filter((tag) => {
    const value = form[tag.key]
    return value && Array.isArray(value) && value.length > 0
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logSearchInput = useCallback(
    debounce((input) => {
      Logger.telemetry.trackEvent('RootCause/Filter', {
        filterName: 'Search',
        teamName: teamName ?? undefined,
        filter: { search: input },
      })
    }, 300),
    [],
  )

  return (
    <>
      <Row className={styles.header}>
        {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbButton size="large" style={{ height: 'unset', minWidth: 80, padding: 0 }}>
              <Text size={500} weight="semibold">
                Root Causes
              </Text>
            </BreadcrumbButton>
          </BreadcrumbItem>
        </Breadcrumb>
      </Row>
      <Row style={{ minWidth: '720px' }}>
        <SearchBox
          className={styles.search}
          value={searchInput}
          onChange={(_, data) => {
            setSearchInput(data.value)
            logSearchInput(data.value)
          }}
          placeholder="Search root cause"
        />

        <AdaptiveBar className={styles.adaptiveFilter} onNavShow={(show) => setShowDivider(show)}>
          <Row>
            <RootCauseMultiselect
              comboId="prioritySearch"
              defaultValue={priority}
              options={priorityOptions}
              placeholder="Priority"
              onChangeFilter={(data) => {
                setPriority(data)
                // Log telemetry for filter
                Logger.telemetry.trackEvent('RootCause/Filter', {
                  filterName: 'Priority',
                  teamName: teamName ?? undefined,
                  filter: { priority: data },
                })
              }}
            />
            <Spacer width={8} />
          </Row>
          <Row>
            <AssignedToSearch
              users={assignedTo}
              onChangeUser={(data) => {
                setAssignedTo(data)
                Logger.telemetry.trackEvent('RootCause/Filter', {
                  filterName: 'AssignTo',
                  teamName: teamName ?? undefined,
                  filter: { assignTo: data },
                })
              }}
              emails={emails}
            />
            <Spacer width={8} />
          </Row>
          <Row>
            <RootCauseMultiselect
              comboId="stateSearch"
              defaultValue={state}
              options={stateOptions}
              placeholder="State"
              onChangeFilter={(data) => {
                setState(data)
                Logger.telemetry.trackEvent('RootCause/Filter', {
                  filterName: 'State',
                  teamName: teamName ?? undefined,
                  filter: { state: data },
                })
              }}
            />
            <Spacer width={8} />
          </Row>
          <Row>
            <TeamDateTimeRender />
            <Spacer width={8} />
          </Row>
        </AdaptiveBar>
        {showDivider ? (
          <Divider vertical style={{ height: '100%', width: '16px', flexGrow: 0 }} />
        ) : (
          <Spacer width={16} />
        )}
        <TicketsFilterButton
          placeholder={'Feedback Filters'}
          isTicketsFilterPanelOpen={filterPanelOpened}
          onClickButton={() => {
            useTeamViewStore.getState().toggleFilterPanel((bool) => !bool)
          }}
          badgeCount={validTags.length}
        />
      </Row>
      {table}
    </>
  )
})

RootCauseTable.displayName = 'RootCauseTable'
