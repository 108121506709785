import axios from 'axios'
import { ApiClient } from '../../client/ApiClient'
import { Caches } from '../../client/ApiClientCaches'
import { DashApiOptions } from '../../DashApiOptions'
import { LogCollectorApiClient } from './LogCollectorApiClient'
import {
  ApiActivityHistoryResponse,
  apiActivityHistoryResponseSchema,
  ApiAIFTicketsResponse,
  apiAIFTicketsResponseSchema,
  ApiAllCustomTagsResponse,
  apiAllCustomTagsResponseSchema,
  ApiAreaListAndRootCauseListResponse,
  apiAreaListAndRootCauseListResponseSchema,
  ApiBatchGetTopIssueExtendInfoRequest,
  ApiBatchGetTopIssueExtendInfoResponse,
  ApiBatchGetTopIssueExtendInfoResponseSchema,
  ApiBatchTicketsCustomTagsRequest,
  ApiBatchTicketsCustomTagsResponse,
  apiBatchTicketsCustomTagsResponseSchema,
  ApiCommentListResponse,
  apiCommentListResponseSchema,
  ApiCopilotTicketDetailResponse,
  apiCopilotTicketDetailResponseSchema,
  ApiCopilotTicketRequest,
  ApiCreateCommentRequest,
  ApiCreateRootCauseRequest,
  ApiCreateRootCauseResponse,
  ApiCreateRootCauseResponseSchema,
  ApiDeleteQueryResponse,
  apiDeleteQueryResponseSchema,
  ApiDSRTicketResponse,
  apiDSRTicketResponseSchema,
  ApiEditCommentRequest,
  ApiExtensibilityLogsRequest,
  ApiExtensibilityLogsResponse,
  apiExtensibilityLogsResponseSchema,
  ApiFilterTagListResponse,
  apiFilterTagListResponseSchema,
  ApiGetAssignedTeamMembersResponse,
  apiGetAssignedTeamMembersResponseSchema,
  ApiGetQueriesRequest,
  ApiGetQueryResponse,
  apiGetQueryResponseSchema,
  ApiGetRootCauseInfoByIdRequest,
  ApiGetRootCauseInfoByIdResponse,
  ApiGetRootCauseInfoByIdResponseSchema,
  ApiGetTeamDRIListByTeamIdRequest,
  ApiGetTeamDRIListByTeamIdResponse,
  apiGetTeamDRIListByTeamIdResponseSchema,
  ApiGetTicketIdByWorkItemIdRequest,
  ApiGetTicketIdByWorkItemIdResponse,
  apiGetTicketIdByWorkItemIdResponseSchema,
  ApiIssueClusteringBatchInfoListRequest,
  ApiIssueClusteringBatchInfoListResponse,
  apiIssueClusteringBatchInfoListResponseSchema,
  ApiIssueClusteringInfoListRequest,
  ApiIssueClusteringInfoListResponse,
  apiIssueClusteringInfoListResponseSchema,
  ApiKustoLogRequest,
  ApiKustoLogResponse,
  ApiM365TenantListResponse,
  apiM365TenantListResponseSchema,
  ApiM365TenantRequest,
  ApiMcpExperimentFeedbackRequest,
  ApiMcpExperimentFeedbackResponse,
  ApiNewSearchCopilotTicketsRequest,
  ApiNewSearchCopilotTicketsResponse,
  apiNewSearchCopilotTicketsResponseSchema,
  ApiRemoveTicketFromTopIssueRequest,
  ApiRemoveTicketFromTopIssueResponse,
  ApiRemoveTicketFromTopIssueResponseSchema,
  ApiRootCauseListByTeamResponse,
  apiRootCauseListByTeamResponseSchema,
  ApiRootCauseRequest,
  ApiRootCauseResponse,
  apiRootCauseResponseSchema,
  ApiRootCausesByTeamResponse,
  apiRootCausesByTeamResponseSchema,
  ApiSetRootCauseRequest,
  ApiSetTeamDRIListRequest,
  ApiSetTeamDRIListResponse,
  apiSetTeamDRIListResponseSchema,
  ApiTeamListResponse,
  apiTeamListResponseSchema,
  ApiTicketCustomTagsResponse,
  apiTicketCustomTagsResponseSchema,
  ApiTicketsRequest,
  ApiUpdateQueryRequest,
  ApiUpdateRootCauseRequest,
  ApiUpdateRootCauseResponse,
  ApiUpdateRootCauseResponseSchema,
  ApiUpdateTicketStatusInfoRequest,
  ApiUpsertCommentResponse,
  apiUpsertCommentResponseSchema,
} from './types'
import {
  ApiAttachmentFileRequest,
  ApiAttachmentRequest,
  ApiAttachmentResponse,
  ApiAttachmentResponseSchema,
  ApiAttachmentUrlResponse,
  ApiAttachmentUrlResponseSchema,
  AzureBlobResponse,
} from './types/ApiAttachment'
import {
  ApiBatchUpdateTicketStatusInfoResponse,
  ApiBatchUpdateTicketStatusInfoResponseSchema,
} from './types/ApiBatchUpdateTicketStatusInfoResponse'
import { ApiDeleteCommentResponse, apiDeleteCommentResponseSchema } from './types/ApiDeleteCommentResponse'
import { apiKustoLogResponseSchema } from './types/ApiKustoLogResponse.schema'
import { apiMcpExperimentFeedbackResponseSchema } from './types/ApiMcpExperimentFeedback.schema'
import { ApiRootCauseListByTeamRequest } from './types/ApiRootCauseListByTeamRequest'
import { ApiSearchRootCauseByTicketCharacterRequest } from './types/ApiSearchRootCauseByTicketCharacterRequest'
import {
  ApiRootCauseListByTicketCharacterResponse,
  apiRootCauseListByTicketCharacterResponseSchema,
} from './types/ApiSearchRootCauseByTicketCharacterResponse'
import { ApiTeamListRequest } from './types/ApiTeamListRequest'
import { ApiTeamViewRootCausesByTeamRequest } from './types/ApiTeamViewRequest'
import {
  ApiUpdateTicketStatusInfoResponse,
  apiUpdateTicketStatusInfoResponseSchema,
} from './types/ApiUpdateTicketStatusInfoResponse'

/**
 * Please refer to the following bond file for the API definition
 *
 * https://dev.azure.com/msasg/Falcon/_git/DSS?path=/services/LogCollector/src/Service/Api/LogCollector/V1/LogCollectorService.bond&_a=contents&version=GBmaster
 */
export class LogCollectorApi {
  private readonly client: ApiClient

  constructor(options: DashApiOptions) {
    this.client = new LogCollectorApiClient(options)
  }

  searchAIFTickets(params: ApiTicketsRequest): Promise<ApiAIFTicketsResponse> {
    return this.client.get('api/v1/AIFTickets', {
      params: params,
      schema: apiAIFTicketsResponseSchema,
      cache: Caches.SHORT,
    })
  }

  getCopilotTicket(params: ApiCopilotTicketRequest): Promise<ApiCopilotTicketDetailResponse> {
    return this.client.get('/api/v1/CopilotTicket', {
      params: params,
      schema: apiCopilotTicketDetailResponseSchema,
      cache: Caches.SHORT,
    })
  }

  getDSRTicket(externalTicketId: string): Promise<ApiDSRTicketResponse> {
    return this.client.get(`/api/v1/DSRTicket/${externalTicketId}`, {
      schema: apiDSRTicketResponseSchema,
      cache: Caches.LONG,
    })
  }

  getSydneySingleInfo(param: ApiKustoLogRequest): Promise<ApiKustoLogResponse> {
    return this.client.get('/api/v1/SydneySingleInfo', {
      schema: apiKustoLogResponseSchema,
      cache: Caches.SHORT,
      params: param,
    })
  }

  getFilterTagList(): Promise<ApiFilterTagListResponse> {
    return this.client.get(`/api/v1/GetFilterTagList`, {
      schema: apiFilterTagListResponseSchema,
      cache: Caches.LONG,
    })
  }

  getM365TenantList(param: ApiM365TenantRequest): Promise<ApiM365TenantListResponse> {
    return this.client.get('/api/v1/GetM365TenantList', {
      schema: apiM365TenantListResponseSchema,
      cache: Caches.LONG,
      params: param,
    })
  }

  getTicketExistedRootCausingActions(param: ApiRootCauseRequest): Promise<ApiRootCauseResponse> {
    return this.client.get('/api/v1/GetTicketExistedRootCausingActionsAsync', {
      schema: apiRootCauseResponseSchema,
      cache: Caches.NONE,
      params: param,
    })
  }

  setTicketRootCausingActions(data: ApiSetRootCauseRequest): Promise<ApiRootCauseResponse> {
    return this.client.post('/api/v1/SetTicketRootCausingActionsAsync', {
      data: data,
      schema: apiRootCauseResponseSchema,
    })
  }

  getTeamList(params: ApiTeamListRequest): Promise<ApiTeamListResponse> {
    return this.client.get(`/api/v1/GetTeamList`, {
      params: params,
      schema: apiTeamListResponseSchema,
      cache: Caches.LONG,
    })
  }

  getRootCauseListByTeam(params: ApiRootCauseListByTeamRequest): Promise<ApiRootCauseListByTeamResponse> {
    return this.client.get(`/api/v1/GetRootCauseListByTeam`, {
      params: params,
      schema: apiRootCauseListByTeamResponseSchema,
      cache: Caches.NONE,
    })
  }

  searchRootCauseListByTicketCharacter(
    data: ApiSearchRootCauseByTicketCharacterRequest,
  ): Promise<ApiRootCauseListByTicketCharacterResponse> {
    return this.client.post('/api/v1/SearchRootCauseListByTicketsInfoAsync', {
      data: data,
      schema: apiRootCauseListByTicketCharacterResponseSchema,
    })
  }

  getAssignedTeamMembersByProductIdAndTeamId(
    params: ApiRootCauseListByTeamRequest,
  ): Promise<ApiGetAssignedTeamMembersResponse> {
    return this.client.get(`/api/v1/GetAssignedTeamMembersByProductIdAndTeamId`, {
      params: params,
      schema: apiGetAssignedTeamMembersResponseSchema,
      cache: Caches.NONE,
    })
  }

  getRootCausesByTeam(param: ApiTeamViewRootCausesByTeamRequest): Promise<ApiRootCausesByTeamResponse> {
    return this.client.get(`/api/v1/GetTeamViewInfoByTeamName`, {
      schema: apiRootCausesByTeamResponseSchema,
      params: param,
      cache: Caches.NONE,
    })
  }

  newSearchCopilotTickets(data: ApiNewSearchCopilotTicketsRequest): Promise<ApiNewSearchCopilotTicketsResponse> {
    return this.client.post(`/api/v1/NewSearchCopilotTickets`, {
      data: data,
      schema: apiNewSearchCopilotTicketsResponseSchema,
    })
  }

  updateTicketStatusInfo(data: ApiUpdateTicketStatusInfoRequest): Promise<ApiUpdateTicketStatusInfoResponse> {
    return this.client.post(`/api/v1/UpdateTicketStatusInfo`, {
      data: data,
      schema: apiUpdateTicketStatusInfoResponseSchema,
    })
  }

  batchUpdateTicketStatusInfo(
    data: {
      TicketStatusInfoList: ApiUpdateTicketStatusInfoRequest[]
    },
    signal: AbortSignal,
  ): Promise<ApiBatchUpdateTicketStatusInfoResponse> {
    return this.client.post(`/api/v1/BatchUpdateTicketStatusInfo`, {
      data: data,
      schema: ApiBatchUpdateTicketStatusInfoResponseSchema,
      signal: signal,
    })
  }

  getAreaListAndRootCauseList(): Promise<ApiAreaListAndRootCauseListResponse> {
    return this.client.get(`/api/v1/GetAreaListAndRootCauseList`, {
      schema: apiAreaListAndRootCauseListResponseSchema,
      cache: Caches.NONE,
    })
  }

  getComments(ticketId: string): Promise<ApiCommentListResponse> {
    return this.client.get(`/api/v1/Ticket/${encodeURIComponent(ticketId)}/Comments`, {
      schema: apiCommentListResponseSchema,
      cache: Caches.NONE,
    })
  }

  createComments(ticketId: string, data: ApiCreateCommentRequest): Promise<ApiUpsertCommentResponse> {
    return this.client.post(`/api/v1/Ticket/${encodeURIComponent(ticketId)}/Comments`, {
      schema: apiUpsertCommentResponseSchema,
      data: data,
    })
  }

  editComments(ticketId: string, commentId: string, data: ApiEditCommentRequest): Promise<ApiUpsertCommentResponse> {
    return this.client.put(`/api/v1/Ticket/${encodeURIComponent(ticketId)}/Comments/${encodeURIComponent(commentId)}`, {
      schema: apiUpsertCommentResponseSchema,
      data: data,
    })
  }

  deleteComments(ticketId: string, commentId: string): Promise<ApiDeleteCommentResponse> {
    return this.client.delete(
      `/api/v1/Ticket/${encodeURIComponent(ticketId)}/Comments/${encodeURIComponent(commentId)}`,
      {
        schema: apiDeleteCommentResponseSchema,
      },
    )
  }

  getExtensibilityLogsByMessageIds(request: ApiExtensibilityLogsRequest): Promise<ApiExtensibilityLogsResponse> {
    return this.client.get('/api/v1/GetExtensibilityLogs', {
      params: request,
      schema: apiExtensibilityLogsResponseSchema,
      cache: Caches.SHORT,
    })
  }

  getRootCauseInfoById(request: ApiGetRootCauseInfoByIdRequest): Promise<ApiGetRootCauseInfoByIdResponse> {
    return this.client.get('/api/v1/GetRootCauseInfoById', {
      params: request,
      schema: ApiGetRootCauseInfoByIdResponseSchema,
      cache: Caches.NONE,
    })
  }

  createRootCause(data: ApiCreateRootCauseRequest): Promise<ApiCreateRootCauseResponse> {
    return this.client.post(`/api/v1/CreateRootCauseAsync`, {
      data: data,
      schema: ApiCreateRootCauseResponseSchema,
    })
  }

  updateRootCause(data: ApiUpdateRootCauseRequest): Promise<ApiUpdateRootCauseResponse> {
    return this.client.post('/api/v1/UpdateRootCauseAsync', {
      data: data,
      schema: ApiUpdateRootCauseResponseSchema,
    })
  }

  getIssueClusteringBatchInfoList(
    request: ApiIssueClusteringBatchInfoListRequest,
  ): Promise<ApiIssueClusteringBatchInfoListResponse> {
    return this.client.get(`/api/v1/GetClusteringBatchInfoList`, {
      params: request,
      schema: apiIssueClusteringBatchInfoListResponseSchema,
      cache: Caches.SHORT,
    })
  }

  getIssueClusteringInfoList(request: ApiIssueClusteringInfoListRequest): Promise<ApiIssueClusteringInfoListResponse> {
    return this.client.get(`/api/v1/GetIssueClusteringInfoByTeamIdAndBatchId`, {
      params: request,
      schema: apiIssueClusteringInfoListResponseSchema,
      cache: Caches.NONE,
    })
  }

  getIssueExtendInfoList(data: ApiBatchGetTopIssueExtendInfoRequest): Promise<ApiBatchGetTopIssueExtendInfoResponse> {
    return this.client.post(`/api/v1/ObtainFeedbackInsightsByBatchIdAndIssueIdList`, {
      data: data,
      schema: ApiBatchGetTopIssueExtendInfoResponseSchema,
      cache: Caches.LONG,
    })
  }

  getActivityHistory(ticketId: string): Promise<ApiActivityHistoryResponse> {
    return this.client.get(`/api/v1/ActivityHistory/${encodeURIComponent(ticketId)}`, {
      schema: apiActivityHistoryResponseSchema,
      cache: Caches.NONE,
    })
  }

  getMcpExperimentFeedbackResponse(
    request: ApiMcpExperimentFeedbackRequest,
  ): Promise<ApiMcpExperimentFeedbackResponse> {
    return this.client.get(`/api/v1/GetMcpExperimentFeedbackResponse`, {
      params: request,
      schema: apiMcpExperimentFeedbackResponseSchema,
      cache: Caches.NONE,
    })
  }

  getTicketCustomTags(ticketId: string): Promise<ApiTicketCustomTagsResponse> {
    return this.client.get(`/api/v1/Ticket/${encodeURIComponent(ticketId)}/CustomTags`, {
      schema: apiTicketCustomTagsResponseSchema,
      cache: Caches.NONE,
    })
  }

  getBatchTicketsCustomTags(data: ApiBatchTicketsCustomTagsRequest): Promise<ApiBatchTicketsCustomTagsResponse> {
    return this.client.post('/api/v1/CustomTags', {
      data: data,
      schema: apiBatchTicketsCustomTagsResponseSchema,
    })
  }

  getAllCustomTags(): Promise<ApiAllCustomTagsResponse> {
    return this.client.get('/api/v1/GetAllCustomTags', {
      schema: apiAllCustomTagsResponseSchema,
      cache: Caches.SHORT,
    })
  }

  updateQuery(data: ApiUpdateQueryRequest): Promise<ApiGetQueryResponse> {
    return this.client.post('/api/v1/UpdateQuery', {
      data: data,
      schema: apiGetQueryResponseSchema,
    })
  }

  getQueryByQueryId(queryId: string): Promise<ApiGetQueryResponse> {
    return this.client.get(`/api/v1/Query/${encodeURIComponent(queryId)}`, {
      schema: apiGetQueryResponseSchema,
      cache: Caches.SHORT,
    })
  }

  getTeamQueries(teamId: number, data: ApiGetQueriesRequest): Promise<ApiGetQueryResponse> {
    return this.client.post(`/api/v1/Queries/${encodeURIComponent(teamId)}`, {
      data: data,
      schema: apiGetQueryResponseSchema,
    })
  }

  getMyQueries(data: ApiGetQueriesRequest): Promise<ApiGetQueryResponse> {
    return this.client.post('/api/v1/MyQueries/', {
      data: data,
      schema: apiGetQueryResponseSchema,
    })
  }

  deleteQuery(queryId: string): Promise<ApiDeleteQueryResponse> {
    return this.client.delete(`/api/v1/Query/${encodeURIComponent(queryId)}`, {
      schema: apiDeleteQueryResponseSchema,
    })
  }

  getTeamDRIListByTeamId(params: ApiGetTeamDRIListByTeamIdRequest): Promise<ApiGetTeamDRIListByTeamIdResponse> {
    return this.client.get(`/api/v1/GetTeamDRIListByTeamId`, {
      params: params,
      schema: apiGetTeamDRIListByTeamIdResponseSchema,
      cache: Caches.NONE,
    })
  }

  setTeamDRIList(data: ApiSetTeamDRIListRequest): Promise<ApiSetTeamDRIListResponse> {
    return this.client.post('/api/v1/SetTeamDRIList', {
      data: data,
      schema: apiSetTeamDRIListResponseSchema,
    })
  }

  getTicketIdByWorkItemId(request: ApiGetTicketIdByWorkItemIdRequest): Promise<ApiGetTicketIdByWorkItemIdResponse> {
    return this.client.get('/api/v1/GetTicketIdByWorkItemId', {
      params: request,
      schema: apiGetTicketIdByWorkItemIdResponseSchema,
      cache: Caches.LONG,
    })
  }

  postAttachment(data: ApiAttachmentRequest): Promise<ApiAttachmentResponse> {
    return this.client.post(`/api/v1/Attachments/upload`, {
      params: data,
      schema: ApiAttachmentResponseSchema,
      cache: Caches.NONE,
    })
  }

  uploadAttachmentToAzureBlob(data: ApiAttachmentFileRequest): Promise<void> {
    return axios.put(data.rawUrl + '?' + data.sasToken, data.file, {
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': data.file.type || 'application/octet-stream',
      },
    })
  }

  getAttachmentUrl(rawUrl: string): Promise<ApiAttachmentUrlResponse> {
    return this.client.get('/api/v1/Attachments', {
      params: { rawUrl: rawUrl },
      schema: ApiAttachmentUrlResponseSchema,
      cache: Caches.NONE,
    })
  }

  getAttachment(url: string): Promise<AzureBlobResponse> {
    return axios.get(url, {
      responseType: 'arraybuffer',
    })
  }

  removeTicketFromTopIssue(data: ApiRemoveTicketFromTopIssueRequest): Promise<ApiRemoveTicketFromTopIssueResponse> {
    return this.client.post('/api/v1/RemoveFromTopIssue', {
      data: data,
      schema: ApiRemoveTicketFromTopIssueResponseSchema,
    })
  }
}
