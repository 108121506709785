import { makeStyles, shorthands, tokens, Text } from '@fluentui/react-components'
import { FC } from 'react'
import { Column, Row } from '../../../../../components/Layout'
import { VIPUserTag } from './VIPUserTag'
import { CAPTenantTag } from './CAPTenantTag'

interface IProps {
  isShowTitle?: boolean
  VIPUserIds?: string[]
  CAPTenants?: Array<{
    tenantId: string
    tenantName: string
  }>
}

export const FoldedTags: FC<IProps> = ({ isShowTitle = true, VIPUserIds, CAPTenants }) => {
  const styles = useStyles()
  return (
    <Column className={styles.card}>
      <Column>
        {VIPUserIds && VIPUserIds.length > 0 && (
          <Column>
            {isShowTitle && (
              <Row className={styles.titleRow}>
                <Text className={styles.title}>VIP Users</Text>
              </Row>
            )}
            <Row className={styles.tagRow}>
              {VIPUserIds.map((userId: string, index: number) => (
                <VIPUserTag userId={userId} key={index} />
              ))}
            </Row>
          </Column>
        )}

        {CAPTenants && CAPTenants.length > 0 && (
          <Column>
            {isShowTitle && (
              <Row className={styles.titleRow}>
                <Text className={styles.title}>VIP Tenants</Text>
              </Row>
            )}
            <Row className={styles.tagRow}>
              {CAPTenants.map((item, index) => (
                <CAPTenantTag tenantName={item.tenantName} tenantId={item.tenantId} key={index} />
              ))}
            </Row>
          </Column>
        )}
      </Column>
    </Column>
  )
}

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    ...shorthands.padding('2px', '10px'),
    minWidth: '280px',
    maxWidth: '320px',
    maxHeight: '448px',
  },
  title: {
    fontWeight: tokens.fontWeightMedium,
    fontSize: tokens.fontSizeBase200,
    color: tokens.colorNeutralForeground4,
  },
  titleRow: {
    display: 'inline-block',
    ...shorthands.gap('8px'),
    ...shorthands.padding('4px', '0px'),
  },
  tagRow: {
    flex: '1 1 auto',
    flexWrap: 'wrap',
    ...shorthands.gap('10px'),
  },
})
