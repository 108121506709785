import { IDashStoreContext } from '../../IDashStoreContext'

export async function uploadImage(context: IDashStoreContext, file: File): Promise<string> {
  if (!context.enableV2Discussion) {
    const initUploadResponse = await context.api.logCollector.postAttachment({
      fileName: file.name,
    })
    await context.api.logCollector.uploadAttachmentToAzureBlob({
      rawUrl: initUploadResponse.url,
      sasToken: initUploadResponse.sasToken,
      file: file,
    })

    return initUploadResponse.url
  } else {
    const response = await context.api.copilotDash.uploadImageV2({
      file: file,
    })
    return response.url
  }
}
