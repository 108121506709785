import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getTicketCustomTags(
  context: IDashStoreContext,
  ticketId: string,
  forceRequest?: boolean,
): PromiseSnapshot<string[]> {
  return context.getOrFetch({
    get: (state) => {
      return forceRequest ? undefined : state.tickets[ticketId]?.customTags
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.customTags = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getTicketCustomTags(ticketId)
      return response.customTags ?? []
    },
  })
}
