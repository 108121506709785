import { AsyncSnapshots } from '@copilot-dash/core'
import { IKustoBizPerfLogItem } from '@copilot-dash/domain'
import { AnyData } from '../../../../../../../components/AnyData/AnyData'
import { Row } from '../../../../../../../components/Layout'
import { TicketScreenStore } from '../../../../../TicketScreenStore'
import { LoadingStatusComponent } from '../../../../common/LoadingStatusComponent'
import { TicketAsyncView } from '../../../../common/TicketAsyncView'
import { TicketFlameGraphButton } from '../../../../common/TicketFlameGraphButton'

export function BizChatPerfView() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!selectedTurnId) {
    const snapshot = AsyncSnapshots.error(new Error('No conversation selected'))
    return <LoadingStatusComponent promiseSnapshot={snapshot} content={() => null} />
  }

  return <Content ticketId={ticketId} selectedTurnId={selectedTurnId} />
}

function Content(props: { readonly ticketId: string; readonly selectedTurnId: string }) {
  const snapshot = app.store.use.getPerformanceLogs(props.ticketId, props.selectedTurnId)

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => {
        const rawData = data.map((item) => item.raw)

        return (
          <AnyData
            data={rawData}
            options={{
              toolbar: <Toolbar data={data} />,
            }}
          />
        )
      }}
    </TicketAsyncView>
  )
}

function Toolbar({ data }: { data: IKustoBizPerfLogItem[] }) {
  return (
    <Row gap="gap.small">
      {data.map((item, index) => {
        return <TicketFlameGraphButton key={index} index={index} value={item.perfTraceFile} />
      })}
    </Row>
  )
}
