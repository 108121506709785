import { IQuery, ITeamData } from '@copilot-dash/domain'
import { memo, useMemo } from 'react'
import { RenameQueryDialog } from '../../../SaveQueryDialog/RenameQueryDialog'
import { FieldNames } from '../../../SaveQueryDialog/SaveQueryForm'

interface IProps {
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
  query: IQuery
}

export const RenameQueryScreen = memo(function RenameQueryScreen(props: IProps) {
  const teamsSnapshot = app.store.use.getTeams(props.query.productId)

  const teamsOptions: ITeamData[] = useMemo(() => {
    switch (teamsSnapshot.status) {
      case 'done':
        return teamsSnapshot.data
      default:
        return []
    }
  }, [teamsSnapshot.data, teamsSnapshot.status])

  return (
    <RenameQueryDialog
      productId={props.query.productId}
      open={props.dialogOpen}
      onClose={() => {
        props.setDialogOpen(false)
      }}
      onSaved={() => {}}
      okBtnText="Save"
      defaultValue={{
        [FieldNames.CopilotDashQuery]: props.query.copilotDashQuery,
        [FieldNames.CopilotDashPath]: props.query.copilotDashPath,
        [FieldNames.FilterPayload]: props.query.filterPayload,
        [FieldNames.ProductId]: props.query.productId,
        [FieldNames.IsTeamShared]: props.query.isTeamShared,
        [FieldNames.TeamId]: props.query.teamId,
        [FieldNames.QueryId]: props.query.queryId,
        [FieldNames.Title]: props.query.title,
      }}
      teamsOptions={teamsOptions}
    />
  )
})
