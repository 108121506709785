import { IDashStoreContext } from '../../IDashStoreContext'

export async function updateUserReadFCRagResult(context: IDashStoreContext, userId: string): Promise<boolean> {
  return context.requestAnd({
    request: async () => {
      const response = await context.api.automation.updateUserHasReadRagQuery({ userId: userId })
      return response
    },
    onSuccess: (state, data) => {
      // update queriesMap
      const currentDataSnapshot = state.feedbackCopilot.allFCConversations
      if (data && currentDataSnapshot?.data) {
        currentDataSnapshot?.data.forEach((item) => (item.queryResultIsRead = true))
        const lastConv = state.feedbackCopilot.lastFCConversation?.data
        if (lastConv?.queryResultIsRead) {
          lastConv.queryResultIsRead = data
        }
      }
    },
  })
}
