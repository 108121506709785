import { PromiseSnapshot } from '@copilot-dash/core'
import { ITelemetryMetric, TicketCallFlow, TicketCallFlowEvent, TicketCallFlowService } from '@copilot-dash/domain'
import { compact, uniq } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTelemetryMetrics } from './getTelemetryMetrics'

export function getCallFlow(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<TicketCallFlow> {
  return context.getOrFetch<TicketCallFlow>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.conversationCallFlow
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.conversationCallFlow = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const metrics = await getTelemetryMetrics(context, ticketId, messageId).promise
      const events = generateEvents(metrics)
      const services = generateServices(metrics)

      return {
        events,
        services,
      }
    },
  })
}

function generateEvents(metrics: ITelemetryMetric[]): TicketCallFlowEvent[] {
  return compact(
    metrics.map((item): TicketCallFlowEvent | undefined => {
      if (!item.scopeId || !item.serviceName) {
        return undefined
      }

      return {
        id: item.scopeId,
        name: item.scopeName ?? item.scopeId,
        label: item.path || item.scopeName?.split('.')[1] || item.scopeName || item.scopeId,
        status: item.status,
        timestamp: item.startTime,
        serviceId: item.serviceName,
      }
    }),
  )
}

function generateServices(metrics: ITelemetryMetric[]): TicketCallFlowService[] {
  const serviceIds1 = metrics.map((item) => item.serviceName)
  const serviceIds2 = uniq(compact(serviceIds1))

  return serviceIds2.map((item) => {
    return {
      id: item,
      name: item,
    }
  })
}
