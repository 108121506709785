import { DashApi } from '@copilot-dash/api'
import { IDashStore } from '../IDashStore'
import { defaultDashStoreState } from '../state/IDashStoreState'
import { createDashStoreActions } from './createDashStoreActions'
import { createDashStoreContext } from './createDashStoreContext'
import { createDashStoreUse } from './createDashStoreUse'
import { createDashStoreUseHooks } from './createDashStoreUseHooks'
import { IDashStoreOptions } from '../IDashStoreOptions'

interface IParams {
  readonly api: DashApi
  readonly options: IDashStoreOptions
}

export function createDashStore({ api, options }: IParams): IDashStore {
  const use = createDashStoreUse(defaultDashStoreState)
  const context = createDashStoreContext({ use, api, options })
  const useHooks = createDashStoreUseHooks(context)
  const actions = createDashStoreActions(context)

  return {
    use: Object.assign(use, useHooks),
    get state() {
      return use.getState()
    },
    get actions() {
      return actions
    },
  }
}
