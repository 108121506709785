import * as React from 'react'
import { Popover, PopoverTrigger, Button, PopoverSurface, tokens, makeStyles } from '@fluentui/react-components'
import { AddRegular } from '@fluentui/react-icons'
import { Column } from '../../Layout'
import { Scrollbar } from '../../Scrollbar/Scrollbar'
import { CopilotDashDropdownInput } from '../../Filter/CopilotDashDropdownInput'

interface IProps {
  optionContent: JSX.Element | (JSX.Element | null)[]
  inputValue: string
  setInputValue: (value: string) => void
  isPopoverOpen: boolean
  setIsPopoverOpen: (value: boolean) => void
  showInput?: boolean
  onKeyDown?: (value: string) => void
}

export const CustomTagsPopover: React.FC<IProps> = ({
  optionContent,
  inputValue,
  setInputValue,
  isPopoverOpen,
  setIsPopoverOpen,
  showInput,
  onKeyDown,
}) => {
  const styles = useStyles()

  return (
    <Popover
      positioning={{
        align: 'start',
        pinned: false,
        position: 'below',
      }}
      onOpenChange={(_, data) => setIsPopoverOpen(data.open)}
      open={isPopoverOpen}
    >
      <PopoverTrigger disableButtonEnhancement>
        <Button
          icon={<AddRegular />}
          appearance="subtle"
          style={{
            whiteSpace: 'nowrap',
            color: tokens.colorBrandForeground1,
            backgroundColor: isPopoverOpen ? tokens.colorSubtleBackgroundHover : '',
          }}
        >
          Add tag
        </Button>
      </PopoverTrigger>
      <PopoverSurface style={{ width: 236, padding: 0 }}>
        <Column>
          {showInput && (
            <CopilotDashDropdownInput
              inputValue={inputValue}
              setInputValue={setInputValue}
              onKeyDown={onKeyDown}
              isKeyDownAllowed={true}
            />
          )}
          <Scrollbar className={styles.scrollbar}>{optionContent}</Scrollbar>
        </Column>
      </PopoverSurface>
    </Popover>
  )
}

const useStyles = makeStyles({
  scrollbar: {
    maxHeight: '300px',
    padding: '6px',
  },
})
