import { ProductName } from '@copilot-dash/domain'
import { StateCreator } from 'zustand'
import { useGlobalStore } from '../../../store'
import { ITeamViewStore, ITeamViewTeams } from './types'

export const createTeamsStore: StateCreator<ITeamViewStore, [['zustand/immer', never]], [], ITeamViewTeams> = (
  set,
  get,
) => ({
  lastSelectedTeam: null,
  selectedSubMenu: null,
  computed: {
    get selectedTeamId() {
      return useGlobalStore.getState().team.teamList.data?.find((team) => team.name === get().teams.lastSelectedTeam)
        ?.id
    },
  },

  setProduct: (product: ProductName) => {
    set((state) => {
      state.productName = product
    })
  },
  setLastSelectedTeam: (teamName: string, sync2Storage = false, productId = -1) => {
    set((state) => {
      state.teams.lastSelectedTeam = teamName
    })
    if (sync2Storage) {
      get().teams.syncLastSelectedTeam2Storage(productId)
    }
  },
  setSelectedSubMenu: (category: string | null) => {
    set((state) => {
      state.teams.selectedSubMenu = category
    })
  },
  syncLastSelectedTeam2Storage: (productId = -1) => {
    if (productId === -1 || !get().teams.lastSelectedTeam || typeof get().teams.computed.selectedTeamId !== 'number')
      return
    const teamName = get().teams.lastSelectedTeam
    const teamId = get().teams.computed.selectedTeamId
    if (!teamName || !teamId) return
    const lastSelectedTeam = { teamName, teamId }
    const old = application.settings.lastSelectedTeamByProductId.value
    application.settings.lastSelectedTeamByProductId.set({ ...old, [productId]: lastSelectedTeam })
  },
  syncLastSelectedTeamFromStorage: (productId = -1) => {
    if (productId === -1) return
    const lastSelectedTeam = application.settings.lastSelectedTeamByProductId.value[productId]

    set((state) => {
      state.teams.lastSelectedTeam = lastSelectedTeam?.teamName ?? null
    })
  },
})
