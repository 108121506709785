import { makeStyles, Spinner } from '@fluentui/react-components'
import { ErrorView } from '../../Error'
import { Column, Row } from '../../Layout'
import { useScrollbar } from '../../Scrollbar/useScrollbar'
import { TicketConversationHistory } from '../TicketConversationHistory'
import { TicketConversationLayoutForData } from './TicketConversationLayoutForData'

export function TicketConversationLayout() {
  const styles = useStyles()
  const scrollbarRef = useScrollbar()

  const ticketId = TicketConversationHistory.use((state) => state.ticketId)
  const ticketMessageIdsSnapshot = application.store.use.getTicketMessageIds(ticketId)

  return (
    <div ref={scrollbarRef} className={styles.root}>
      <Column>
        {(() => {
          switch (ticketMessageIdsSnapshot.status) {
            case 'waiting':
              return (
                <Row className={styles.waiting} vAlign="center" hAlign="center">
                  <Spinner label="Loading..." />
                </Row>
              )
            case 'error':
              return <ErrorView error={ticketMessageIdsSnapshot.error} />
            case 'done':
              return <TicketConversationLayoutForData messageIds={ticketMessageIdsSnapshot.data} />
          }
        })()}
      </Column>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    overflowY: 'auto',
  },
  waiting: {
    minHeight: '300px',
  },
})
