import { z } from 'zod'

export enum ColumnKey {
  AreaPath = 'AreaPath',
  Date = 'Date',
  Email = 'Email',
  EmotionType = 'EmotionType',
  Priority = 'Priority',
  Response = 'Response',
  RootCause = 'RootCause',
  Status = 'Status',
  TicketId = 'TicketId',
  Utterance = 'Utterance',
  VerbatimFeedback = 'VerbatimFeedback',
  Team = 'Team',
  TenantName = 'TenantName',
  Ring = 'Ring',
  Endpoint = 'Endpoint',
  UserProfile = 'UserProfile',
  AssignTo = 'AssignTo',
  CustomTags = 'CustomTags',
}

export const columnKeySchema = z.nativeEnum(ColumnKey)

export type ColumnIdNameMap = Record<ColumnKey, string>

export const ColumnIdNameMapping: ColumnIdNameMap = {
  Date: 'Date',
  Email: 'Email',
  EmotionType: 'Emotion type',
  Priority: 'Priority',
  Response: 'Response',
  RootCause: 'Root cause',
  Status: 'State',
  TicketId: 'Ticket id',
  Utterance: 'Utterance',
  VerbatimFeedback: 'Verbatim feedback',
  Team: 'Team',
  TenantName: 'Tenant name',
  AreaPath: 'Area Path',
  Ring: 'Ring',
  Endpoint: 'Endpoint',
  UserProfile: 'User name',
  AssignTo: 'Assigned to',
  CustomTags: 'Tags',
}

export const DefaultSearchScreenNonAIFColumnIds: ColumnKey[] = [
  ColumnKey.Date,
  ColumnKey.Utterance,
  ColumnKey.Response,
  ColumnKey.VerbatimFeedback,
  ColumnKey.EmotionType,
  ColumnKey.TenantName,
]

export const DefaultSearchScreenAIFColumnIds: ColumnKey[] = [
  ColumnKey.Date,
  ColumnKey.Utterance,
  ColumnKey.Response,
  ColumnKey.VerbatimFeedback,
]

export const DefaultFeedbackInsightsVipDSATsColumnIds: ColumnKey[] = [
  ColumnKey.Date,
  ColumnKey.Utterance,
  ColumnKey.Response,
  ColumnKey.VerbatimFeedback,
  ColumnKey.UserProfile,
  ColumnKey.RootCause,
  ColumnKey.Status,
]

export const DefaultFeedbackInsightsTopIssueDetailsColumnIds: ColumnKey[] = [
  ColumnKey.Date,
  ColumnKey.Utterance,
  ColumnKey.Response,
  ColumnKey.VerbatimFeedback,
  ColumnKey.UserProfile,
  ColumnKey.Team,
  ColumnKey.Status,
]

export const DefaultTeamViewColumnIds: ColumnKey[] = [
  ColumnKey.Date,
  ColumnKey.Utterance,
  ColumnKey.Response,
  ColumnKey.VerbatimFeedback,
  ColumnKey.Priority,
  ColumnKey.RootCause,
  ColumnKey.Status,
]

export const NonRemovableColumns: ColumnKey[] = [
  ColumnKey.Date,
  ColumnKey.Utterance,
  ColumnKey.Response,
  ColumnKey.VerbatimFeedback,
]

export const AllSupportedNonAIFColumns: ColumnKey[] = [
  ColumnKey.TicketId,
  ColumnKey.Date,
  ColumnKey.Utterance,
  ColumnKey.Response,
  ColumnKey.VerbatimFeedback,
  ColumnKey.UserProfile,
  ColumnKey.EmotionType,
  ColumnKey.Email,
  ColumnKey.Priority,
  ColumnKey.RootCause,
  ColumnKey.Status,
  ColumnKey.Team,
  ColumnKey.TenantName,
  ColumnKey.Ring,
  ColumnKey.Endpoint,
  ColumnKey.AssignTo,
  ColumnKey.CustomTags,
]

export const AllSupportedAIFColumns: ColumnKey[] = [
  ColumnKey.TicketId,
  ColumnKey.Date,
  ColumnKey.Utterance,
  ColumnKey.Response,
  ColumnKey.VerbatimFeedback,
  ColumnKey.Email,
  ColumnKey.TenantName,
]

export const AlSearchEnabledColumns: ColumnKey[] = [
  ColumnKey.TicketId,
  ColumnKey.Date,
  ColumnKey.Utterance,
  ColumnKey.Response,
  ColumnKey.VerbatimFeedback,
  ColumnKey.UserProfile,
  ColumnKey.EmotionType,
  ColumnKey.Email,
  ColumnKey.TenantName,
  ColumnKey.Ring,
  ColumnKey.Endpoint,
  ColumnKey.CustomTags,
]

export const DefaultRagResultRableColumnIds: ColumnKey[] = [
  ColumnKey.Date,
  ColumnKey.Utterance,
  ColumnKey.Response,
  ColumnKey.VerbatimFeedback,
  ColumnKey.Priority,
  ColumnKey.Status,
  ColumnKey.Team,
  ColumnKey.TenantName,
]
