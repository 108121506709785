import { useCallback } from 'react'
import { TicketConversationHistory } from '../../../../../components/TicketConversationHistory/TicketConversationHistory'
import { TicketScreenStore } from '../../../TicketScreenStore'

export function TicketOutlineConversationHistory() {
  const actions = TicketScreenStore.useActions()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  const handleSelectConversation = useCallback(
    (turnId: string) => {
      actions.selectTurn(turnId)
    },
    [actions],
  )

  return (
    <TicketConversationHistory
      ticketId={ticketId}
      selectedMessageId={selectedTurnId}
      onSelect={handleSelectConversation}
    />
  )
}
