import { TableCell, TableRow, Text } from '@fluentui/react-components'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function RowForRetrieval3S({ ticketId, messageId }: IProps) {
  const snapshot = application.store.use.getTicketSystemTags(ticketId)
  const tag = snapshot.data
  if (!tag) {
    return null
  }

  const renderContent = () => {
    if (!tag.sssTriggered) {
      return '3S is not triggered'
    }

    return (
      <span>
        <span>3S is triggered </span>
      </span>
    )
  }

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Retrieval - 3S</Text>
      </TableCell>
      <TableCell>{renderContent()}</TableCell>
    </TableRow>
  )
}
