import { z } from 'zod'

export interface ApiCommentItemV2 {
  readonly id: string
  readonly discussionId: string
  readonly userId: string
  readonly content: string
  readonly createDateTime: string
  readonly updateDateTime?: string
}

export type ApiCommentsResponseV2 = Array<ApiCommentItemV2>

export const apiCommentItemV2Schema = z.object({
  id: z.string(),
  discussionId: z.string(),
  userId: z.string(),
  content: z.string(),
  createDateTime: z.string(),
  updateDateTime: z.string().optional(),
})

export const apiCommentListResponseV2Schema = z.array(apiCommentItemV2Schema)
