import { makeStyles } from '@fluentui/react-components'
import { useCallback, useEffect, useRef } from 'react'
import { Column } from '../../Layout'
import { TurnView } from '../children/TurnView'
import { TicketConversationHistory } from '../TicketConversationHistory'

interface IProps {
  readonly messageIds: string[]
}

export function TicketConversationLayoutForData({ messageIds }: IProps) {
  const styles = useStyles()
  const actions = TicketConversationHistory.useActions()

  const ticketId = TicketConversationHistory.use((state) => state.ticketId)
  const selectedTurnId = TicketConversationHistory.use((state) => state.selectedTurnId)
  const selectedConversationRef = useRef<HTMLDivElement>(null)

  const scrollToSelectedConversation = useCallback(() => {
    selectedConversationRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }, [])

  // Scroll to selected index when data is loaded
  const initLocationAfterLoadedRef = useRef(false)
  useEffect(() => {
    if (initLocationAfterLoadedRef.current) {
      return
    }

    const promises = messageIds.map((messageId) => {
      return application.store.actions.getTicketTurnConversation(ticketId, messageId).catch(() => undefined)
    })

    Promise.all(promises).then(() => {
      if (!initLocationAfterLoadedRef.current) {
        initLocationAfterLoadedRef.current = true
        scrollToSelectedConversation()
      }
    })
  }, [messageIds, scrollToSelectedConversation, ticketId])

  return (
    <Column className={styles.root} hAlign="stretch">
      {messageIds.map((messageId) => {
        const selected = selectedTurnId === messageId

        return (
          <TurnView
            key={messageId}
            ref={selected ? selectedConversationRef : undefined}
            ticketId={ticketId}
            messageId={messageId}
            selected={selected}
            selectedAppearance={messageIds.length > 1 ? 'outline' : 'none'}
            onSelect={() => {
              actions.selectTurn(messageId)
            }}
          />
        )
      })}
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    gap: '16px',
  },
})
