import { createStoreContext, useStore } from '@copilot-dash/core'
import { useEffect } from 'react'
import { ErrorViewBoundary } from '../Error'
import { createDiscussionPanelActions } from './store/createDiscussionPanelActions'
import { createDiscussionPanelState } from './store/createDiscussionPanelState'
import { IDiscussionPanelActions } from './store/IDiscussionPanelActions'
import { IDiscussionPanelState } from './store/IDiscussionPanelState'
import { DiscussionPanelDrawer } from './layout/DiscussionPanelDrawer'

interface IProps {
  readonly discussionId: string
}

export function DiscussionPanel({ discussionId }: IProps) {
  const store = useStore<IDiscussionPanelState, IDiscussionPanelActions>((set, get) => {
    return {
      state: createDiscussionPanelState(discussionId),
      actions: createDiscussionPanelActions({
        set,
        get,
      }),
    }
  })

  useEffect(() => {
    store.actions.init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Listen discussionId
  useEffect(() => {
    if (discussionId !== store.state.discussionId) {
      store.actions.updateDiscussionId(discussionId)
    }
  }, [store, discussionId])

  return (
    <StoreContext.Provider store={store}>
      <ErrorViewBoundary label="DiscussionPanel">
        <DiscussionPanelDrawer />
      </ErrorViewBoundary>
    </StoreContext.Provider>
  )
}
const StoreContext = createStoreContext<IDiscussionPanelState, IDiscussionPanelActions>()
DiscussionPanel.use = StoreContext.use
DiscussionPanel.useActions = StoreContext.useActions
