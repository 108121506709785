import { DefaultTriggeredSkillList } from '@copilot-dash/domain'

export function convertTriggeredSkillsFromKeyArray(triggeredSkills: string[] | undefined) {
  return triggeredSkills
    ?.map((val) => {
      const triggeredSkill = DefaultTriggeredSkillList.find((skill) => skill.key === val)
      return triggeredSkill ? triggeredSkill.options : []
    })
    .flat()
}
