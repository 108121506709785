import { makeStyles, Text } from '@fluentui/react-components'

interface IProps {
  readonly value: number
}

export function ValueViewForNumber({ value }: IProps) {
  const styles = useStyles()

  return (
    <div className={styles.root} title={value.toString()}>
      <Text weight="semibold">{value.toString()}</Text>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
  },
})
