import { Times } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketMetadata } from '../actions-ticket/getTicketMetadata'

export async function assertTicketNotExpired(context: IDashStoreContext, ticketId: string): Promise<void> {
  if (context.enableV2Endpoint) {
    return
  }

  const ticket = await getTicketMetadata(context, ticketId).promise
  const createdDate = ticket.createdDateTime
  if (!createdDate) {
    return
  }

  if (Times.isBefore(createdDate, '2024-03-18T00:00:00Z')) {
    throw TicketError.create('BeforeMarch182024', { ticketId })
  }
}
