import { InvocationSlicer } from '@copilot-dash/domain'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { OptionSetInvocationSlicer } from '../../config/InvocationSlicer'
import { z } from 'zod'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: InvocationSlicer[]) => void
}

export const InvocationSlicerFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const optionsList = OptionSetInvocationSlicer

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-invocationSlicerFilter'}
      filterType={'Invocation slicers'}
      isGroup={false}
      optionsList={optionsList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => {
        const result = z.array(z.nativeEnum(InvocationSlicer)).safeParse(item)
        if (result.success) {
          onChangeValue(result.data)
        }
      }}
    />
  )
}
