import { z } from 'zod'

export interface ApiAttachmentRequest {
  readonly file: File
}

export interface ApiAttachmentResponse {
  readonly url: string
}

export const ApiAttachmentResponseSchema = z.object({
  url: z.string(),
})

export const arrayBufferSchema = z.custom<ArrayBuffer>((data) => data instanceof ArrayBuffer, {
  message: 'Expected an ArrayBuffer',
})

export type ApiAttachmentUrlResponse = z.infer<typeof arrayBufferSchema>
