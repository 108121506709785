import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.flex(1),
  },
  header: {
    alignItems: 'center',
    marginTop: '32px',
    marginBottom: '24px',
  },
  search: {
    marginBottom: '16px',
  },
  expandIcon: {
    marginRight: '20px',
    minWidth: '22px',
  },
  flexGrow: {
    flexGrow: 1,
  },
  loading: {
    minHeight: '600px',
  },
  rollingDate: {
    color: tokens.colorNeutralStrokeAccessibleHover,
  },
  gridTable: {
    display: 'grid',
    gridTemplateColumns: '1fr 120px 120px 120px',
  },
  gridHeader: {
    backgroundColor: tokens.colorNeutralBackground4,
    padding: '8px 0',
    fontWeight: 600,
    color: tokens.colorNeutralForeground3,
    fontSize: '14px',
    '&:first-child': {
      paddingLeft: '32px',
    },
  },
})
