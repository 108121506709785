export enum ApiV2Ring {
  WW = 'WW',
  DONMT = 'DONMT',
  SIP = 'SIP',
  MSIT = 'MSIT',
  SDFv2 = 'SDFv2',
  Test = 'Test',
  TDF = 'TDF',
  Unknown = 'Unknown',
}
