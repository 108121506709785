import { useMemo } from 'react'
import { Controller, Control } from 'react-hook-form'
import { Card } from './Card'
import { useStyles } from './Card.styles'
import { Body1Stronger } from '@fluentui/react-components'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'
import { SearchFilterFieldNames } from '@copilot-dash/domain'
import { PriorityFilter, DSATStatusFilter, CustomTagsFilter } from '../filters'
import { useTicketFilterStore } from '../../store/store'
import { FeedbackHandlingCardFilters } from './config'
import { AssignedToFilter } from '../filters/AssignedToFilter'
interface IProps {
  control: Control<ITicketFilterFormData>
}

export function FeedbackHandlingCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  // TODO: Add disable to priority and DSAT status filters
  const isAISearchEnable = useTicketFilterStore((state) => state.isAISearchEnable)

  const hasFeedbackHandlingCard = useMemo(() => {
    return filterPanelItems.some((item) => FeedbackHandlingCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasFeedbackHandlingCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Feedback handling</Body1Stronger>
      <Card className={styles.card}>
        {filterPanelItems?.includes(SearchFilterFieldNames.priority) && (
          <Controller
            name="priority"
            control={control}
            render={({ field }) => (
              <PriorityFilter disabled={isAISearchEnable} selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.dSATStatus) && (
          <Controller
            name="dSATStatus"
            control={control}
            render={({ field }) => (
              <DSATStatusFilter
                disabled={isAISearchEnable}
                selectedValues={field.value}
                onChangeValue={field.onChange}
              />
            )}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.dSATAssignedTo) && (
          <Controller
            name="dSATAssignedTo"
            control={control}
            render={({ field }) => <AssignedToFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.customTags) && (
          <Controller
            name="customTags"
            control={control}
            render={({ field }) => <CustomTagsFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}
      </Card>
    </section>
  )
}
