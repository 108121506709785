import { IBatchTicketCustomTagsType } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function getBatchTicketsCustomTags(
  context: IDashStoreContext,
  ticketIds: string[],
): Promise<IBatchTicketCustomTagsType> {
  const response = await context.api.logCollector.getBatchTicketsCustomTags({ TicketIdList: ticketIds })
  return response.ticketCustomTags ?? {}
}
