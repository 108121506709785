import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  dismissButton: {
    color: tokens.colorNeutralForeground1,
    height: tokens.lineHeightBase100,
  },
  tagStyle: {
    backgroundColor: tokens.colorBrandBackground2,
    ':hover': {
      backgroundColor: tokens.colorBrandBackground2,
    },
    ':active': {
      backgroundColor: tokens.colorBrandBackground2,
    },
    cursor: 'pointer',
  },
})
