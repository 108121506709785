import { INewTicketData, TicketEmotionType, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { memo } from 'react'
import { useStyles } from './ColumnComponent.styles'
import { Text } from '@fluentui/react-components'
import { ToolTipText } from '../Text/ToolTipText'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { CustomCellRendererProps } from 'ag-grid-react'

export const ColumnConfigTeamArea: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.Team],
  field: String(ColumnKey.Team) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        <RenderTeam item={props.data} />
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.Team]}</TableCellLayoutWithStyles>,
  },
  flex: 1,
  minWidth: 120,
  sortable: false,
  resizable: true,
}

const RenderTeam = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else if (item.displayTeamName === '' || item.displayTeamName === undefined) return
  return <ToolTipText text={item.displayTeamName} />
})

RenderTeam.displayName = 'RenderTeam'
