import { IProductEndpointData } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'

interface IProps {
  readonly value: string
  readonly endpoint: IProductEndpointData
}

function formatEndpointIndicators(application?: string, platform?: string, license?: string): string {
  return [application, platform, license].filter((val) => val?.trim()).join(' - ')
}

export function ValueViewForStringIndicatorEndpoint({ value, endpoint }: IProps) {
  return (
    <Text wrap block truncate>
      {formatEndpointIndicators(endpoint.application, endpoint.platform, endpoint.license)}
    </Text>
  )
}
