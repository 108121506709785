import { IDashStoreContext } from '../../IDashStoreContext'
import { IFCConversationMessageData } from '@copilot-dash/domain'
import { PromiseSnapshots } from '@copilot-dash/core'

export async function updateFCConversation(
  context: IDashStoreContext,
  newConv: IFCConversationMessageData,
): Promise<void> {
  context.use.setState((state) => {
    const currentDataSnapshot = state.feedbackCopilot.allFCConversations

    // find the record in allFCConversations by messageid ,and update the data
    state.feedbackCopilot.lastFCConversation = PromiseSnapshots.done(newConv)
    if (currentDataSnapshot?.data) {
      //remove data with same messageid and push new data
      const existsData = currentDataSnapshot.data.find((item) => item.messageId === newConv.messageId)
      if (existsData) {
        existsData.queryResultIsRead = false
        existsData.queryResult = newConv.queryResult
        existsData.queryStatus = newConv.queryStatus
        existsData.botDefaultResponse = newConv.botDefaultResponse
        existsData.botResponseTimeUTC = newConv.botResponseTimeUTC
      }
    }
  })
}
