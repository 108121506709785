import { Text, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { ErrorCircleFilled } from '@fluentui/react-icons'
import { Handle, Node, NodeProps, Position } from '@xyflow/react'
import { motion, TargetAndTransition } from 'framer-motion'
import { memo, useMemo } from 'react'
import { Column, Row, Spacer } from '../../../Layout'
import { CallFlowNode } from '../../store/IState'
import { TicketCallFlowStore } from '../../TicketCallFlowStore'
import { generateColor } from '../../utils/generateColor'
import { escape } from 'lodash'

export const TurnViewFlowNodeView = memo(function TurnViewFlowNodeView({
  data,
  isConnectable,
  sourcePosition = Position.Bottom,
  targetPosition = Position.Top,
}: NodeProps<Node<CallFlowNode>>) {
  const styles = useStyles()
  const actions = TicketCallFlowStore.useActions()
  const selection: 'selected' | 'unselected' | 'rested' = TicketCallFlowStore.use((state) => {
    if (state.selectedEventId) {
      return state.selectedEventId === data.item.id ? 'selected' : 'unselected'
    } else {
      return 'rested'
    }
  })

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation()

    if (selection !== 'selected') {
      actions.selectScope(data.messageId, data.item.id)
    } else {
      actions.selectTurn(data.messageId)
    }
  }

  const color = generateColor(data.item.serviceId)
  const colorAlpha = generateColor(data.item.serviceId, 'alpha1')

  const animates: Record<'selected' | 'unselected' | 'rested', TargetAndTransition> = {
    selected: {
      boxShadow: tokens.shadow16,
      borderColor: color,
      color: tokens.colorNeutralForeground1,
    },
    unselected: {
      boxShadow: 'none',
      borderColor: colorAlpha,
      color: tokens.colorNeutralForeground4,
    },
    rested: {
      boxShadow: 'none',
      borderColor: color,
      color: tokens.colorNeutralForeground1,
    },
  }

  const status = data.item.status
  const displayStatus: boolean = !!status && !status.toLowerCase().includes('success')

  const title = displayStatus ? `${data.item.name} - ${status}` : data.item.name
  const escapedLabel = useMemo(() => {
    return escape(data.item.label)
  }, [data.item.label])

  return (
    <motion.div
      className={mergeClasses(styles.root)}
      animate={animates[selection]}
      whileHover={{
        boxShadow: selection === 'selected' ? tokens.shadow16Brand : tokens.shadow4,
        // borderColor: color,
      }}
      transition={{ duration: 0.16 }}
      onClick={handleClick}
    >
      <Handle type="target" position={targetPosition} isConnectable={isConnectable} className={styles.handle} />
      <Column hAlign="stretch" vAlign="center" title={title} fill>
        <Row className={styles.body} hAlign="center" vAlign="center">
          {displayStatus && <ErrorCircleFilled className={styles.status} fontSize={16} />}
          <Spacer width={2} />
          <Text size={100} className={styles.text} block>
            {escapedLabel}
          </Text>
        </Row>
      </Column>
      <Handle type="source" position={sourcePosition} isConnectable={isConnectable} className={styles.handle} />
    </motion.div>
  )
})

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',

    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
    borderRadius: tokens.borderRadiusMedium,
    overflow: 'hidden',
    borderTopWidth: '4px',
    borderBottomWidth: '1px',
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
  },

  body: {
    padding: '0 8px',
    flex: '1 1 0',
  },

  handle: {
    opacity: 0,

    '&.react-flow__handle-top': {
      transform: 'translate(-50%, 0)',
    },

    '&.react-flow__handle-bottom': {
      transform: 'translate(-50%, 0)',
    },

    '&.react-flow__handle-left': {
      transform: 'translate(0, -50%)',
    },

    '&.react-flow__handle-right': {
      transform: 'translate(0, -50%)',
    },
  },

  status: {
    color: tokens.colorStatusWarningBackground3,
  },

  text: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
})
