import { IM365TenantItem } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTenant } from './getTenant'
import { compact, uniqBy } from 'lodash'

const DEFAULT_LIMIT = 20

interface IOptions {
  readonly limit?: number
}

export async function searchM365Tenants(
  context: IDashStoreContext,
  keyword: string | undefined,
  tenantIds?: string[],
  options?: IOptions,
): Promise<IM365TenantItem[]> {
  const keywordResponse = await context.api.logCollector.getM365TenantList({
    Keyword: keyword,
    Limit: options?.limit ?? DEFAULT_LIMIT,
  })

  if (tenantIds && tenantIds.length > 0) {
    const tenantIdResponse = await Promise.all(
      tenantIds.map((tenant) => {
        return getTenant(context, tenant).promise.catch(() => {
          return null
        })
      }),
    )
    const filteredTenantIdResponse = compact(tenantIdResponse)

    return uniqBy([...filteredTenantIdResponse, ...keywordResponse.m365TenantList], 'tenantId')
  }

  return keywordResponse.m365TenantList
}
