import { Avatar, makeStyles } from '@fluentui/react-components'
import { TicketClientTooltip } from '../../../../TicketClientTooltip/TicketClientTooltip'
import { TicketConversationHistory } from '../../../TicketConversationHistory'
import CopilotAvatar from '../../assets/CopilotAvatar.svg'

export function CopilotMessageViewAvatar() {
  const styles = useStyles()
  const ticketId = TicketConversationHistory.use((state) => state.ticketId)

  return (
    <TicketClientTooltip ticketId={ticketId}>
      <Avatar className={styles.root} size={24} image={{ src: CopilotAvatar, className: styles.img }} />
    </TicketClientTooltip>
  )
}

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    marginTop: '4px',
  },
  img: {
    backgroundColor: 'transparent',
  },
})
