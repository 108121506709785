import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOcvTicket } from '../actions-raw-ticket/getRawOcvTicket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTicketTurnConversation } from '../actions-ticket-chat/getTicketTurnConversation'
import { getTicketLastTurnMessageId } from '../actions-ticket-chat/getTicketLastTurnMessageId'

export function getTicketUtteranceText(context: IDashStoreContext, ticketId: string): PromiseSnapshot<string> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.utteranceText
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.utteranceText = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<string> {
    if (context.enableV2Endpoint) {
      return fetchFromV2()
    }

    try {
      return await fetchFromConversation()
    } catch (error) {
      try {
        return await fetchFromOCV()
      } catch {
        throw error
      }
    }
  }

  async function fetchFromV2(): Promise<string> {
    await assertTicketHasUserConsent(context, ticketId)

    // 1. Get value
    const raw = await getRawV2Ticket(context, ticketId).promise
    const utterance = raw.diagnosticContext?.chat?.[0]?.utterance?.text
    if (utterance?.trim()) {
      return utterance
    }

    throw TicketError.create('NoUtterance', { ticketId })
  }

  async function fetchFromConversation(): Promise<string> {
    const turnId = await getTicketLastTurnMessageId(context, ticketId).promise
    const turn = await getTicketTurnConversation(context, ticketId, turnId).promise
    const utterance = turn.utterance?.content.text
    if (utterance) {
      return utterance
    }

    await assertTicketHasUserConsent(context, ticketId)
    throw TicketError.create('NoUtterance', { ticketId })
  }

  async function fetchFromOCV(): Promise<string> {
    const ocvTicket = await getRawOcvTicket(context, ticketId).promise
    const utterance = ocvTicket.AiContext?.Prompt
    if (utterance) {
      return utterance
    }

    throw TicketError.create('NoUtterance', { ticketId })
  }
}
