import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  filterContainer: {
    position: 'relative',
    marginTop: '16px',
  },
  container: {
    maxWidth: '100%',
    minWidth: '0',
    flexGrow: 1,
    paddingLeft: '14px',
  },
  loading: {
    height: '100px',
  },
  listbox: {
    maxHeight: '240px',
  },
  search: {
    position: 'absolute',
    left: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    width: '16px',
    height: '16px',
  },
  customUserPhoto: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
    fontSize: tokens.fontSizeBase200,
    ...shorthands.borderRadius('50%'),
    alignItems: 'center',
  },
  customUserName: {
    fontSize: tokens.fontSizeBase300,
    fontWeight: 600,
  },
  text: {
    fontSize: '12px',
    fontWeight: 400,
    height: '16px',
  },
  selectedListContainer: {
    height: '52px',
    alignItems: 'center',
  },
})
