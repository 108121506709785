import { $generateNodesFromDOM } from '@lexical/html'
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  LexicalEditor,
  LexicalNode,
  ParagraphNode,
  TextNode,
} from 'lexical'
import { useEffect } from 'react'
import { IsLexicalDataSchema } from '../../../../utils/IsLexicalDataSchema'
import { $createImageNode } from '../../editor-nodes/ImageNode'
import { LexicalRichTextEditorStore } from '../../LexicalRichTextEditorStore'

export function ImportHTMLPlugin() {
  const htmlString = LexicalRichTextEditorStore.use((state) => state.initialValue)
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (htmlString) {
      if (!IsLexicalDataSchema(htmlString)) {
        //Formatting our markdown (Includes custom @mentions format) is an indeterminate operation for us,
        //If it throws an exception, we'll use the raw text
        try {
          editor.update(() => {
            $convertFromMarkdownString(htmlString, TRANSFORMERS)
          })
        } catch {
          editor.update(() => {
            const root = $getRoot()
            root.clear()
            const paragraphNode = $createParagraphNode()
            const textNode = $createTextNode(htmlString)
            paragraphNode.append(textNode)
            root.append(paragraphNode)
          })
        }
      } else {
        setTimeout(() => {
          setEditorContentFromHTML(editor, htmlString)
        }, 0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function setEditorContentFromHTML(editor: LexicalEditor, value: string) {
    editor.update(() => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(value, 'text/html')
      const root = editor.getEditorState().read(() => $getRoot())
      root.clear()

      const allNodes = parseNodesFromDOM(editor, dom.body)
      root.append(...allNodes)
      if (root.getChildrenSize() > 0) {
        const lastChild = root.getLastChild()
        if (lastChild) {
          lastChild.selectEnd()
        }
      }
    })
  }

  function parseNodesFromDOM(editor: LexicalEditor, domNode: Node): LexicalNode[] {
    const nodes: LexicalNode[] = []
    domNode.childNodes.forEach((child) => {
      if (child.nodeName === 'IMG') {
        const img = child as HTMLImageElement
        const src = img.getAttribute('src')
        const alt = img.getAttribute('alt') ?? 'image'
        if (src) {
          const imageNode = $createImageNode(src, alt)
          nodes.push(imageNode)
        }
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        const element = child as HTMLElement
        if (element.tagName === 'P') {
          const tempNodes = parseNodesFromDOM(editor, element)
          if (tempNodes.length > 0) {
            const paragraphNode = new ParagraphNode()
            tempNodes.forEach((node) => paragraphNode.append(node))
            nodes.push(paragraphNode)
          }
        } else {
          const tempDoc = document.implementation.createHTMLDocument()
          tempDoc.body.appendChild(child.cloneNode(true))
          const tempNodes = $generateNodesFromDOM(editor, tempDoc)
          nodes.push(...tempNodes)
        }
      } else if (child.nodeType === Node.TEXT_NODE) {
        const textContent = child.textContent?.trim()
        if (textContent) {
          const textNode = new TextNode(textContent)
          nodes.push(textNode)
        }
      }
    })
    return nodes
  }

  return null
}
