import { useEffect, useState } from 'react'

interface IProps {
  src: string
  alt: string
  className: string
}

export function ImageNodeView({ src, alt, className }: IProps) {
  const [base64Src, setBase64Src] = useState<string | null>(null)
  useEffect(() => {
    const fetchAndConvertImage = async () => {
      const base64String = await application.store.actions.getAttachment(src)
      setBase64Src(base64String)
    }
    fetchAndConvertImage()
  }, [src])

  if (!base64Src) {
    return (
      <div
        style={{
          width: '100%',
          height: '150px',
          background: 'linear-gradient(90deg, #f3f3f3 25%, #ecebeb 50%, #f3f3f3 75%)',
          backgroundSize: '200% 100%',
          animation: 'placeholder-shimmer 1.5s infinite',
        }}
      >
        <style>
          {`
            @keyframes placeholder-shimmer {
              0% { background-position: 200% 0; }
              100% { background-position: -200% 0; }
            }
          `}
        </style>
      </div>
    )
  }

  return <img data-backup-src={src} src={base64Src} alt={alt} className={className} />
}
