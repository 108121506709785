import { IDiscussionPanelState } from './IDiscussionPanelState'

export function createDiscussionPanelState(discussionId: string): IDiscussionPanelState {
  return {
    discussionId,
    commentsSnapshot: { status: 'none' },
    postCommentSnapshot: { status: 'none' },
    editCommentSnapshot: { status: 'none' },
    deleteCommentSnapshot: { status: 'none' },
  }
}
