import { AsyncSnapshot } from '@copilot-dash/core'
import { ISearchTicketOptions } from '@copilot-dash/store'
import { produce } from 'immer'
import { isNil } from 'lodash'
import { StateCreator } from 'zustand'
import {
  CategoryTypeIds,
  defaultLinkedTicketsArgsByRootCause,
  defaultArgsForAll,
  defaultArgsForTopIssues,
  defaultArgsForUnRootCause,
  defaultFilterForm,
  EMPTY_TEAM_ID,
  defaultRecommendTicketsArgsByRootCause,
} from './const'
import { createTicketsSearcher } from './createTicketsSearcher'
import { ITeamViewStore, ITeamViewTickets, NewSearchCopilotTicketsResult } from './types'
import { batchUpdateAppStoreTickets, mapSearchTicketsSnapshotToSearchTicketIDsSnapshot } from './utils'

export const createTicketsStore: StateCreator<ITeamViewStore, [['zustand/immer', never]], [], ITeamViewTickets> = (
  set,
  get,
) => {
  const _ticketIdSetByCategory = new Set<string>()
  const _linkedTicketIdSetByRootCause = new Set<string>()
  const _recommendTicketIdSetByRootCause = new Set<string>()
  const _unRootCauseTicketIdSetByRecommendationId = new Map<string, Set<string>>()
  const _topIssueTicketIdSetByBatchIdAndIssueId = new Map<string, Set<string>>()

  let _preArgsForAll = defaultArgsForAll
  let _preLinkedDSATsArgsForRootCause = defaultLinkedTicketsArgsByRootCause
  let _preRecommendDSATsArgsForRootCause = defaultRecommendTicketsArgsByRootCause
  let _preArgsForUnRootCause = defaultArgsForUnRootCause
  let _preArgsForTopIssues = defaultArgsForTopIssues

  const allDSATsSearcher = createTicketsSearcher()
  const unRootCauseSearcherMap = new Map<string, ReturnType<typeof createTicketsSearcher>>()
  const rootCauseSearcher = createTicketsSearcher()
  const recommendDSATsSearcher = createTicketsSearcher()
  const topIssueSearcherMap = new Map<string, ReturnType<typeof createTicketsSearcher>>()

  async function _handleTicketsFromAll(snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>) {
    set((state) => {
      // batch update tickets
      if (snapshot.status === 'done') {
        const tickets = snapshot.data?.tickets ?? []
        batchUpdateAppStoreTickets(tickets)
      }

      // update ticketIdListByAll
      if (_preArgsForAll.offset === 0) {
        state.tickets.ticketIdListByAll = mapSearchTicketsSnapshotToSearchTicketIDsSnapshot(snapshot)
        _ticketIdSetByCategory.clear()
      } else {
        state.tickets.ticketIdListByAll.data?.ticketIds.push(
          ...(snapshot.data?.tickets ?? [])
            .map((item) => item.ticketId)
            .filter((item): item is string => !!item && !_ticketIdSetByCategory.has(item)),
        )
        ;(snapshot.data?.tickets ?? []).forEach((item) => {
          item.ticketId && _ticketIdSetByCategory.add(item.ticketId)
        })
      }

      // update hasMoreTicketsForAll
      if (snapshot.status === 'done') {
        state.tickets.hasMoreTicketsForAll = snapshot.data?.hasMore ?? false
      }
    })
  }

  async function _handleTicketsForRootCause(snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>) {
    set((state) => {
      // batch update tickets
      if (snapshot.status === 'done') {
        const tickets = snapshot.data?.tickets ?? []
        batchUpdateAppStoreTickets(tickets)
      }

      // update ticketIdListByRootCause
      if (_preLinkedDSATsArgsForRootCause.offset === 0) {
        state.tickets.ticketIdListByRootCause = mapSearchTicketsSnapshotToSearchTicketIDsSnapshot(snapshot)
        _linkedTicketIdSetByRootCause.clear()
      } else {
        state.tickets.ticketIdListByRootCause.data?.ticketIds.push(
          ...(snapshot.data?.tickets ?? [])
            .map((item) => item.ticketId)
            .filter((item): item is string => !!item && !_linkedTicketIdSetByRootCause.has(item)),
        )
        ;(snapshot.data?.tickets ?? []).forEach((item) => {
          item.ticketId && _linkedTicketIdSetByRootCause.add(item.ticketId)
        })
      }

      // update hasMoreTicketsForAll
      if (snapshot.status === 'done') {
        state.tickets.hasMoreLinkedTicketsForRootCause = snapshot.data?.hasMore ?? false
      }
    })
  }

  async function _handleRecommendTicketsForRootCause(snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>) {
    set((state) => {
      // batch update tickets
      if (snapshot.status === 'done') {
        const tickets = snapshot.data?.tickets ?? []
        batchUpdateAppStoreTickets(tickets)
      }

      // update recommendTicketIdListByRootCause
      if (_preRecommendDSATsArgsForRootCause.offset === 0) {
        state.tickets.recommendTicketIdListByRootCause = mapSearchTicketsSnapshotToSearchTicketIDsSnapshot(snapshot)
        _recommendTicketIdSetByRootCause.clear()
      } else {
        state.tickets.recommendTicketIdListByRootCause.data?.ticketIds.push(
          ...(snapshot.data?.tickets ?? [])
            .map((item) => item.ticketId)
            .filter((item): item is string => !!item && !_recommendTicketIdSetByRootCause.has(item)),
        )
        ;(snapshot.data?.tickets ?? []).forEach((item) => {
          item.ticketId && _recommendTicketIdSetByRootCause.add(item.ticketId)
        })
      }

      // update hasMoreTicketsForAll
      if (snapshot.status === 'done') {
        state.tickets.hasMoreRecommendTicketsForRootCause = snapshot.data?.hasMore ?? false
      }
    })
  }

  function _handleTicketsForUnRootCause(
    snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>,
    request?: ISearchTicketOptions,
  ) {
    const recommendationId = request?.recommendedRootCauseId
    if (!recommendationId) return
    if (!_unRootCauseTicketIdSetByRecommendationId.get(recommendationId)) {
      _unRootCauseTicketIdSetByRecommendationId.set(recommendationId, new Set())
    }
    set((state) => {
      // batch update tickets
      if (snapshot.status === 'done') {
        const tickets = snapshot.data?.tickets ?? []
        batchUpdateAppStoreTickets(tickets)
      }

      // update recommendTicketIdListByRootCause
      if (request.offset === 0 || !state.tickets.unRootCausedTicketIds[recommendationId]?.data) {
        state.tickets.unRootCausedTicketIds[recommendationId] =
          mapSearchTicketsSnapshotToSearchTicketIDsSnapshot(snapshot)
        _unRootCauseTicketIdSetByRecommendationId.get(recommendationId)?.clear()
      } else if (state.tickets.unRootCausedTicketIds[recommendationId]?.data) {
        state.tickets.unRootCausedTicketIds[recommendationId]!.data?.ticketIds.push(
          ...(snapshot.data?.tickets ?? [])
            .map((item) => item.ticketId)
            .filter(
              (item): item is string =>
                !!item && !_unRootCauseTicketIdSetByRecommendationId.get(recommendationId)?.has(item),
            ),
        )
        ;(snapshot.data?.tickets ?? []).forEach((item) => {
          item.ticketId && !_unRootCauseTicketIdSetByRecommendationId.get(recommendationId)?.add(item.ticketId)
        })
      }

      // update hasMore
      if (snapshot.status === 'done') {
        state.orderBy = undefined
        if (
          state.tickets.unRootCausedTicketIds[recommendationId] &&
          state.tickets.unRootCausedTicketIds[recommendationId]!.data
        ) {
          state.tickets.unRootCausedTicketIds[recommendationId]!.data!.hasMore = snapshot.data?.hasMore ?? false
        }
      }
    })
  }

  function _handleTicketsForTopIssue(
    snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>,
    request?: ISearchTicketOptions,
  ) {
    const batchId = request?.batchId
    const issueId = request?.clusteringIssueId
    if (!batchId || !issueId) return
    const id = `${batchId}-${issueId}`
    if (!_topIssueTicketIdSetByBatchIdAndIssueId.get(id)) {
      _topIssueTicketIdSetByBatchIdAndIssueId.set(id, new Set())
    }
    set((state) => {
      // batch update tickets
      if (snapshot.status === 'done') {
        const tickets = snapshot.data?.tickets ?? []
        batchUpdateAppStoreTickets(tickets)
      }

      // update recommendTicketIdListByRootCause
      if (request.offset === 0 || !state.tickets.topIssueTicketIds[id]?.data) {
        state.tickets.topIssueTicketIds[id] = mapSearchTicketsSnapshotToSearchTicketIDsSnapshot(snapshot)
        _topIssueTicketIdSetByBatchIdAndIssueId.get(id)?.clear()
      } else if (state.tickets.topIssueTicketIds[id]?.data) {
        state.tickets.topIssueTicketIds[id]!.data?.ticketIds.push(
          ...(snapshot.data?.tickets ?? [])
            .map((item) => item.ticketId)
            .filter((item): item is string => !!item && !_topIssueTicketIdSetByBatchIdAndIssueId.get(id)?.has(item)),
        )
        ;(snapshot.data?.tickets ?? []).forEach((item) => {
          item.ticketId && !_topIssueTicketIdSetByBatchIdAndIssueId.get(id)?.add(item.ticketId)
        })
      }

      // update hasMore
      if (snapshot.status === 'done') {
        if (state.tickets.topIssueTicketIds[id] && state.tickets.topIssueTicketIds[id]!.data) {
          state.tickets.topIssueTicketIds[id]!.data!.hasMore = snapshot.data?.hasMore ?? false
        }
      }
    })
  }

  return {
    ticketsTabType: 'category',
    ticketIdListByAll: { status: 'none' },
    ticketIdListByRootCause: { status: 'none' },
    recommendTicketIdListByRootCause: { status: 'none' },
    unRootCausedTicketIds: {},
    hasMoreTicketsForAll: true,
    hasMoreLinkedTicketsForRootCause: true,
    hasMoreRecommendTicketsForRootCause: true,
    ticketUpdatedByAcceptSuggestionCounter: 1,
    filterForm: defaultFilterForm,
    topIssueTicketIds: {},

    computed: {
      get unRootCauseTicketsCount() {
        if (!get().tickets.unRootCausedTicketIds) return null
        if (Object.values(get().tickets.unRootCausedTicketIds).some((data) => data.status === 'waiting')) return null
        return Object.keys(get().tickets.unRootCausedTicketIds).reduce((acc, key) => {
          return acc + (get().tickets.unRootCausedTicketIds[key]?.data?.ticketCount ?? 0)
        }, 0)
      },
    },

    setTicketsTabType: (type: 'category' | 'rootCause') => {
      set((state) => {
        state.tickets.ticketsTabType = type
      })
    },
    fetchTicketListForAll: (filter = {}) => {
      const teamId = get().teams.computed.selectedTeamId
      if (isNil(teamId) || teamId === EMPTY_TEAM_ID) return Promise.resolve(undefined)

      set((state) => {
        state.tickets.ticketIdListByAll = { status: 'none' }
      })

      _preArgsForAll = produce(defaultArgsForAll, (draft) => {
        draft.teamId = teamId
        draft.ticketStatus = CategoryTypeIds.All
        draft.offset = 0
        draft = Object.assign(draft, filter)
        draft.ticketStatus = CategoryTypeIds.All
      })
      return allDSATsSearcher(_preArgsForAll, _handleTicketsFromAll)
    },

    fetchTicketListByRootCause(issueId, vsoAccount, filter = {}) {
      const teamId = get().teams.computed.selectedTeamId
      if (isNil(teamId) || teamId === EMPTY_TEAM_ID) return Promise.resolve(undefined)

      set((state) => {
        state.tickets.ticketIdListByRootCause = { status: 'none' }
      })

      _preLinkedDSATsArgsForRootCause = produce(defaultLinkedTicketsArgsByRootCause, (draft) => {
        draft.teamId = teamId
        draft.issueId = issueId
        draft.vsoAccount = vsoAccount
        draft.offset = 0
        draft = Object.assign(draft, filter)
      })

      return rootCauseSearcher(_preLinkedDSATsArgsForRootCause, _handleTicketsForRootCause)
    },

    loadMoreTicketListForAll(offset) {
      _preArgsForAll = produce(_preArgsForAll, (draft) => {
        draft.offset = offset
      })
      return allDSATsSearcher(_preArgsForAll, _handleTicketsFromAll)
    },

    loadMoreTicketListByRootCause(offset) {
      _preLinkedDSATsArgsForRootCause = produce(_preLinkedDSATsArgsForRootCause, (draft) => {
        draft.offset = offset
      })
      return rootCauseSearcher(_preLinkedDSATsArgsForRootCause, _handleTicketsForRootCause)
    },

    filterTicketListInAll(filter) {
      const teamName = get().teams.lastSelectedTeam
      if (!teamName) return Promise.resolve(undefined)

      set((state) => {
        state.tickets.ticketIdListByAll = { status: 'none' }
      })

      _preArgsForAll = produce(_preArgsForAll, (draft) => {
        draft.teamName = teamName
        draft = Object.assign(draft, filter)
        draft.offset = 0
        draft.ticketStatus = CategoryTypeIds.All
      })
      set((state) => {
        state.tickets.filterForm = Object.assign(state.tickets.filterForm, filter)
      })
      return allDSATsSearcher(_preArgsForAll, _handleTicketsFromAll)
    },

    filterTicketListInRootCause(filter) {
      const teamName = get().teams.lastSelectedTeam
      if (!teamName) return Promise.resolve(undefined)

      set((state) => {
        state.tickets.ticketIdListByRootCause = { status: 'none' }
      })

      _preLinkedDSATsArgsForRootCause = produce(_preLinkedDSATsArgsForRootCause, (draft) => {
        draft.teamName = teamName
        draft = Object.assign(draft, filter)
        draft.offset = 0
      })
      set((state) => {
        state.tickets.filterForm = Object.assign(state.tickets.filterForm, filter)
      })
      return rootCauseSearcher(_preLinkedDSATsArgsForRootCause, _handleTicketsForRootCause)
    },

    resetFilterForm() {
      set((state) => {
        state.tickets.filterForm = defaultFilterForm
      })
    },
    setFilterForm(filter) {
      set((state) => {
        state.tickets.filterForm = { ...defaultFilterForm, ...filter }
      })
    },

    async filterUnRootCausedTickets(recommendationId, filter) {
      const teamId = get().teams.computed.selectedTeamId
      if (isNil(teamId) || teamId === EMPTY_TEAM_ID) return Promise.resolve(undefined)
      _preArgsForUnRootCause = produce(defaultArgsForUnRootCause, (draft) => {
        draft.teamId = teamId
        draft.offset = 0
        draft = Object.assign(draft, filter)
        draft.count = 10
        draft.ticketStatus = CategoryTypeIds.UnRootCaused
      })

      set((state) => {
        state.tickets.unRootCausedTicketIds[recommendationId] = { status: 'none' }
      })

      const searcher = unRootCauseSearcherMap.get(recommendationId) || createTicketsSearcher()
      unRootCauseSearcherMap.set(recommendationId, searcher)

      searcher({ ..._preArgsForUnRootCause, recommendedRootCauseId: recommendationId }, _handleTicketsForUnRootCause)
    },

    async loadMoreUnRootCausedTickets(recommendationId, offset) {
      const searcher = unRootCauseSearcherMap.get(recommendationId) || createTicketsSearcher()
      unRootCauseSearcherMap.set(recommendationId, searcher)
      await searcher(
        { ..._preArgsForUnRootCause, recommendedRootCauseId: recommendationId, offset },
        _handleTicketsForUnRootCause,
      )
      return
    },

    clearUnRootCausedTickets(recommendationId) {
      set((state) => {
        state.tickets.unRootCausedTicketIds[recommendationId] = { status: 'none' }
      })
    },

    async fetchTopIssueTickets(issueId, teamId, batchId, filter) {
      _preArgsForTopIssues = produce(defaultArgsForTopIssues, (draft) => {
        draft.teamId = teamId
        draft.clusteringIssueId = issueId
        draft.batchId = batchId
        draft = Object.assign(draft, filter)
        draft.offset = 0
        draft.count = 20
        draft.ticketStatus = CategoryTypeIds.TopIssue
      })
      const id = `${batchId}-${issueId}`
      set((state) => {
        state.tickets.topIssueTicketIds[id] = { status: 'none' }
      })

      const searcher = topIssueSearcherMap.get(id) || createTicketsSearcher()
      topIssueSearcherMap.set(id, searcher)
      searcher({ ..._preArgsForTopIssues, clusteringIssueId: issueId, batchId }, _handleTicketsForTopIssue)
    },
    async loadMoreTopIssueTickets(issueId: string, teamId: number, batchId: string, offset: number) {
      const id = `${batchId}-${issueId}`
      const searcher = topIssueSearcherMap.get(id) || createTicketsSearcher()
      topIssueSearcherMap.set(id, searcher)
      await searcher(
        { ..._preArgsForTopIssues, clusteringIssueId: issueId, batchId, offset },
        _handleTicketsForTopIssue,
      )
      return
    },
    clearTopIssueTickets(key) {
      set((state) => {
        state.tickets.topIssueTicketIds[key] = { status: 'none' }
      })
    },

    fetchRecommendTicketListByRootCause(issueId, vsoAccount, filter = {}) {
      const teamId = get().teams.computed.selectedTeamId
      if (isNil(teamId) || teamId === EMPTY_TEAM_ID) return Promise.resolve(undefined)

      set((state) => {
        state.tickets.recommendTicketIdListByRootCause = { status: 'none' }
      })

      _preRecommendDSATsArgsForRootCause = produce(defaultRecommendTicketsArgsByRootCause, (draft) => {
        draft = Object.assign(draft, filter)
        draft.count = 20
        draft.ticketStatus = CategoryTypeIds.UnRootCaused
        draft.teamId = teamId
        draft.recommendedRootCauseId = issueId
        draft.offset = 0
      })

      return recommendDSATsSearcher(_preRecommendDSATsArgsForRootCause, _handleRecommendTicketsForRootCause)
    },
    loadMoreRecommendTicketListByRootCause(offset, count = 20) {
      _preRecommendDSATsArgsForRootCause = produce(_preRecommendDSATsArgsForRootCause, (draft) => {
        draft.offset = offset
        draft.count = count
      })
      return recommendDSATsSearcher(_preRecommendDSATsArgsForRootCause, _handleRecommendTicketsForRootCause)
    },
  }
}
