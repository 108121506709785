import { ActorType } from '@copilot-dash/api'
import { z } from 'zod'

export interface FieldDiff {
  oldValue?: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[]
  newValue?: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[]
}
export type ActivityHistoryType =
  | 'TicketStatusUpdate'
  | 'AddComment'
  | 'UpdateComment'
  | 'DeleteComment'
  | 'SetTicketRootCausingActions'
  | 'Unset'
  | 'FeedbackCreated'
  | 'FeedbackCooked'
  | 'WorkItemCreated'
  | 'WorkItemAutoTriage'
  | 'UpdateCustomTags'

export interface IActivityHistory {
  revision: number
  activityId: string
  ticketId: string
  timestamp: string
  activity: ActivityHistoryType
  actor?: ActorType
  userId?: string
  system?: SystemType
  fieldDiffs?: Record<string, FieldDiff>
}

export interface TicketIssueData {
  readonly IssueId: string
  readonly VsoAccount?: string
  readonly RootCauseTitle?: string
}

export interface ActivityHistoryRootCauseContext {
  readonly AdoIssueId: string
  readonly Title: string
  readonly UserAction: string
  readonly Project: string
  readonly Areapath: string
  readonly AdoLink: string
  readonly TeamName?: string
}

export const activityHistoryRootCauseContextSchema = z.object({
  AdoIssueId: z.string(),
  Title: z.string(),
  UserAction: z.string(),
  Project: z.string(),
  Areapath: z.string(),
  AdoLink: z.string(),
  TeamName: z.string().optional(),
})

export const ticketIssueDataSchema = z.object({
  IssueId: z.string(),
  VsoAccount: z.string().optional(),
  RootCauseTitle: z.string().optional(),
})

export const activityHistoryTypeSchema = z.union([
  z.literal('TicketStatusUpdate'),
  z.literal('AddComment'),
  z.literal('UpdateComment'),
  z.literal('DeleteComment'),
  z.literal('SetTicketRootCausingActions'),
  z.literal('Unset'),
  z.literal('FeedbackCreated'),
  z.literal('FeedbackCooked'),
  z.literal('WorkItemCreated'),
  z.literal('WorkItemAutoTriage'),
  z.literal('UpdateCustomTags'),
])

export type SystemType = 'logcollector' | 'TFS' | 'Unset' | 'Automation' | 'Clustering'

export const systemTypeSchema = z.union([
  z.literal('logcollector'),
  z.literal('TFS'),
  z.literal('Unset'),
  z.literal('Automation'),
  z.literal('Clustering'),
])
