import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    width: '800px',
    maxWidth: '100vw',
    margin: '0 auto',
  },
  prompt: {
    width: '100%',
    justifyContent: 'end',
  },

  promptinput: {
    width: '100%',
    height: '40px',
  },
})
