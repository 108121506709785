import { TableCell, TableRow, Text } from '@fluentui/react-components'

interface IProps {
  readonly ticketId: string
}

export function RowForUtteranceInputInvocation({ ticketId }: IProps) {
  const ticketTag = application.store.use.getTicketSystemTags(ticketId).data
  const experienceType = ticketTag?.experienceType
  const experienceTypeInvocatedData = ticketTag?.experienceTypeInvocatedData

  if (!experienceType || !experienceTypeInvocatedData) {
    return null
  }

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Utterance Input</Text>
      </TableCell>
      <TableCell>
        <span>{`Prompt invocated from ${experienceTypeInvocatedData}`}</span>
        <br />
        <span>{`ExperienceType: ${experienceType}`}</span>
      </TableCell>
    </TableRow>
  )
}
