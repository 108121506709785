import { z } from 'zod'

export const apiV2TicketSourceSchema = z.object({
  relatedId: z.string().optional(),
  relatedType: z.string().optional(),
  type: z.string(),
  complianceLevel: z.number(),
  name: z.string().optional(),
  url: z.string(),
})
