import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Column, Spacer } from '../../Layout'
import { useEffect, useState } from 'react'
import { transformCommentValue } from './CommentUtil'
import { useThemeMode } from '../../../theme/useThemeMode'
import { IsLexicalDataSchema } from '../../../utils/IsLexicalDataSchema'
import { RenderContent } from '../../../utils/RenderContent'

interface CommentProps {
  readonly rawCommentContent: string
}

export const CommentContent: React.FC<CommentProps> = ({ rawCommentContent }) => {
  const styles = useStyles()
  const activeAccount = app.auth.activeAccount
  const theme = useThemeMode()
  const [renderCommentValue, setRenderCommentValue] = useState<string | undefined>(rawCommentContent)

  useEffect(() => {
    const handleUpdateCommentValue = async () => {
      if (!rawCommentContent || !activeAccount?.localAccountId) return
      const updatedValue = await transformCommentValue(rawCommentContent, activeAccount?.localAccountId)
      setRenderCommentValue(updatedValue)
    }
    handleUpdateCommentValue()

    return () => {
      if (!activeAccount?.localAccountId) {
        setRenderCommentValue(rawCommentContent)
      }
    }
  }, [activeAccount?.localAccountId, rawCommentContent])

  if (!IsLexicalDataSchema(rawCommentContent)) {
    return (
      <Column data-color-mode={theme}>
        <Spacer height={10} />
        <RenderContent
          rawComment={rawCommentContent}
          renderCommentValue={renderCommentValue}
          markdownStyle={styles.markdown}
        />
      </Column>
    )
  }

  return (
    <Column className="lexical" data-color-mode={theme}>
      <Spacer height={4} />
      <RenderContent rawComment={rawCommentContent} renderCommentValue={renderCommentValue} isLexicalData={true} />
    </Column>
  )
}

const useStyles = makeStyles({
  markdown: {
    '& p': {
      ...shorthands.margin(0),
    },
    '& span': {
      fontWeight: tokens.fontWeightSemibold,
    },
    '& ul, & ol': {
      margin: '5px',
      whiteSpace: 'normal',
    },
  },
})
