import { useSearchScreenActions, useSearchScreenState } from '../../store'
import { TimeFilter } from '../../../../components/SearchOptions/TimeFilter'
import { TimeRange } from '@copilot-dash/core'
import { useEffect, useCallback } from 'react'

export const DateTimeRender = () => {
  const actions = useSearchScreenActions()
  const state = useSearchScreenState((state) => state)
  const form = state.form

  const onChanged = useCallback(
    (range: TimeRange) => {
      actions.submit({ ...form, range })
      Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FromDateRange', value: null })
    },
    [actions, form],
  )

  useEffect(() => {
    if (!form.range) {
      onChanged(form.defaultRange)
    }
  }, [form, onChanged])

  return <TimeFilter onChanged={onChanged} range={form.range} hasDeselectedButton={true} />
}
