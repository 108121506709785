import { User } from '@copilot-dash/api'
import { SkeletonItem, Text, Tooltip } from '@fluentui/react-components'
import { FC, useMemo } from 'react'
import { Row, Spacer } from '../../Layout'
import { TimeView } from '../../Time/TimeView'
import { useStyles } from './MessageHeader.styles'

interface IProps {
  readonly createDateTime: string
  readonly userId: string
}

export const MessageHeader: FC<IProps> = ({ createDateTime, userId }) => {
  const styles = useStyles()

  const userSnapshot = app.store.use.getUserProfile(userId)

  const user: User | undefined = useMemo(() => {
    switch (userSnapshot.status) {
      case 'done':
        return userSnapshot.data
      default:
        return undefined
    }
  }, [userSnapshot.data, userSnapshot.status])

  return (
    <Row fill className={styles.msgHeaderContainer}>
      {user?.displayName ? (
        <Tooltip content={user?.displayName} relationship={'label'}>
          <Text className={styles.messageUserName}>{user?.displayName}</Text>
        </Tooltip>
      ) : (
        <SkeletonItem className={styles.skeletonItem} />
      )}
      <Spacer width={10} />
      <Text className={styles.messageBarTime}>{<TimeView value={createDateTime} format="YYYY/MM/DD HH:mm:ss" />}</Text>
    </Row>
  )
}
