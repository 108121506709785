import { z } from 'zod'

export const apiStateAndDurationKustoLogItemV2Schema = z.record(z.unknown()).and(
  z.object({
    Message: z.string(),
    ScopeName: z.string(),
  }),
)

export const apiTraceKustoLogItemV2Schema = z.record(z.unknown()).and(
  z.object({
    Message: z.string(),
    EventName: z.string().optional(),
  }),
)

export const apiBizPerfLogItemV2Schema = z.record(z.unknown()).and(
  z.object({
    chatFCFR: z.string().optional(),
    PerfTraceFile: z.string().optional(),
  }),
)

export const apiGwsLogItemV2Schema = z.record(z.unknown()).and(
  z.object({
    DiagnosticData: z.string().optional(),
    ResponseMetaJson: z.string().optional(),
    TransactionId: z.string().optional(),
    RouteAction: z.string().optional(),
    RequestedActions: z.string().optional(),
    HttpStatusCode: z.string().optional(),
    env_time: z.string().optional(),
  }),
)

export const apiBizChat3SLatencyLogItemV2Schema = z.record(z.unknown()).and(
  z.object({
    PerfTraceFile: z.string().optional(),
    TransactionId: z.string().optional(),
  }),
)

export const apiExtensibilityLogItemV2Schema = z.record(z.unknown()).and(
  z.object({
    CorrelationId: z.string(),
    BotConversationId: z.string(),
    BotRequestId: z.string(),
    CopilotExtensionIds: z.string(),
    MessageId: z.string(),
  }),
)
