import { listenPromise } from '@copilot-dash/core'
import { NewSearchCopilotTicketsResult } from '@copilot-dash/store'
import { ISearchScreenStore } from '../ISearchScreenStore'
import { uniqBy } from 'lodash'

const PAGE_SIZE = 50

export class SearchLoadMoreTicketsAction {
  private readonly store: ISearchScreenStore
  private readonly loadMorePromise: { [key: number]: Promise<NewSearchCopilotTicketsResult> }

  constructor(store: ISearchScreenStore) {
    this.store = store
    this.loadMorePromise = {}
  }

  clearLoadMorePromise(): void {
    for (const key in this.loadMorePromise) {
      delete this.loadMorePromise[key]
    }
  }

  loadMoreTickets(offset: number, isAISearchEnable?: boolean): void {
    if (this.loadMorePromise[offset] || !this.store.state.searchFeedbackResult.hasMore) return
    const form = this.store.state.form
    const newLoadMorePromisePromise = app.store.actions.searchTickets(
      {
        ...form,
        count: PAGE_SIZE,
        offset: offset,
        isAIF: form.product === 'TenantAdminFeedback',
      },
      isAISearchEnable,
    )

    this.loadMorePromise[offset] = newLoadMorePromisePromise

    try {
      listenPromise({
        promise: newLoadMorePromisePromise,
        isAlive: () => {
          return newLoadMorePromisePromise === this.loadMorePromise[offset]
        },
        onUpdated: (snapshot) => {
          this.store.setState({
            loadMoreSnapshot: {
              ...this.store.state.loadMoreSnapshot,
              [offset]: snapshot,
            },
          })
        },
        onSuccess: (snapshot) => {
          const mergedTickets = uniqBy(
            [...(this.store.state.searchFeedbackResult.tickets || []), ...(snapshot.data?.tickets || [])],
            'ticketId',
          )
          this.store.setState({
            searchFeedbackResult: {
              tickets: mergedTickets,
              total: snapshot.data?.ticketCount || 0,
              hasMore: snapshot.data?.hasMore || false,
            },
          })
        },
      })
    } catch (error) {
      this.store.setState({
        loadMoreSnapshot: {
          ...this.store.state.loadMoreSnapshot,
          [offset]: {
            status: 'error',
            error: error,
          },
        },
      })
    }
  }
}
