import { TableCell, TableRow, Text } from '@fluentui/react-components'

interface IProps {
  readonly ticketId: string
}

export function RowForPreChecksOCV({ ticketId }: IProps) {
  const snapshot = application.store.use.getTicketSystemTags(ticketId)
  const utteranceLanguage = snapshot.data?.utteranceLanguage
  const responseLanguage = snapshot.data?.responseLanguage

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Pre-checks</Text>
        <br />
        <span>Source: OCV</span>
      </TableCell>
      <TableCell>
        {utteranceLanguage && <span>Utterance Language: {utteranceLanguage}, </span>}
        {responseLanguage && <span>Response Language: {responseLanguage}</span>}
      </TableCell>
    </TableRow>
  )
}
