import { ApiOds3sOnlineDataGroup4 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicket3sOnlineExists } from '../actions-ticket-assert/assertTicket3sOnlineExists'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getRaw3sOnlineDataGroup4(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<ApiOds3sOnlineDataGroup4> {
  return context.getOrFetch<ApiOds3sOnlineDataGroup4>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.raw3sOnlineDataGroup4
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.raw3sOnlineDataGroup4 = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceBlobUrl = source.turns[messageId]?.substrateSearchOnlineGroup4?.blobUrl
      if (sourceBlobUrl) {
        if (source.endpoint === 'ODS') {
          return await context.api.ods.get3sOnlineDataGroup4(sourceBlobUrl)
        } else {
          return await context.api.copilotDash.getTicketBlob(ticketId, sourceBlobUrl).asSubstrateSearchOnlineGroup4()
        }
      }

      await assertTicket3sOnlineExists(context, ticketId, messageId)
      throw TicketError.create('No3sOnline', { ticketId, ticketMessageId: messageId })
    },
  })
}
