import { z } from 'zod'

export interface ApiHistortyRagQueryResponse {
  readonly historyQueryList: Array<ApiFCConversationMessageData>
}

export interface ApiFCConversationMessageData {
  readonly messageId: string
  readonly query?: string
  readonly queryStatus?: string
  readonly queryResultIsRead?: boolean
  readonly botDefaultResponse?: string
  readonly queryResult?: ApiFCRagResponseResultData
  readonly startTimeUTC?: string
  readonly clientStartTimeUTC?: string
  readonly botResponseTimeUTC?: string
}

export interface ApiFCRagResponseResultData {
  readonly response: string
  readonly response_references?: Array<ApiFCRagResponseResultAnswerItemData>
}

export interface ApiFCRagResponseResultAnswerItemData {
  readonly answer_id: string
  readonly title?: string
  readonly summary?: string
  readonly tickets?: Array<string>
}

export const apiFCRagResponseResultAnswerItemDataSchema = z.object({
  answer_id: z.string(),
  title: z.string().optional(),
  summary: z.string().optional(),
  tickets: z.array(z.string()).optional(),
})

export const apiFCRagResponseResultDataSchema = z
  .object({
    response: z.string(),
    response_references: z.array(apiFCRagResponseResultAnswerItemDataSchema).optional(),
  })
  .optional()

export const apiFCConversationMessageDataSchema = z.object({
  messageId: z.string(),
  query: z.string().optional(),
  queryStatus: z.string().optional(),
  queryResultIsRead: z.boolean().optional(),
  botDefaultResponse: z.string().optional(),
  queryResult: apiFCRagResponseResultDataSchema,
  startTimeUTC: z.string().optional(),
  clientStartTimeUTC: z.string().optional(),
  botResponseTimeUTC: z.string().optional(),
})

export const apiHistortyRagQueryResponseSchema = z.object({
  historyQueryList: z.array(apiFCConversationMessageDataSchema),
})
