import { INewTicketData } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import { useMemo } from 'react'
import { useSearchScreenActions, useSearchScreenState } from '../../store'
import { useStyles } from './SearchScreenTableView.styles'
import { AGGridTable } from '../../../../components/AGGridTable/AGGridTable'
import { addSortModelToAGGridColumn } from '../../../../components/AGGridTable/utils'
import { ColDef } from 'ag-grid-community'

interface IProps {
  readonly tableItem: INewTicketData[]
  readonly selectedColumns: ColDef<INewTicketData>[]
  readonly loadingTable: boolean
}

export function SearchScreenTableView({ tableItem, selectedColumns, loadingTable }: IProps) {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)
  const searchFeedbackResult = useSearchScreenState((state) => state.searchFeedbackResult)
  const searchSessionId = useSearchScreenState((state) => state.searchSessionId)
  const isAISearchEnable = app.features.aiSearch.use()
  const form = useSearchScreenState((state) => state.form)

  const loadMoreFunction = (offset: number) => {
    actions.loadMore(offset, isAISearchEnable)
  }

  const handleOrderChange = (order?: Array<string>) => {
    if (order !== form.order) {
      actions.submit({ ...form, order })
    }
  }

  const columnDefs = useMemo(() => {
    return addSortModelToAGGridColumn(selectedColumns, form.order)
  }, [selectedColumns, form.order])

  const supportLoadMore = useMemo(() => {
    return searchFeedbackResult.hasMore
  }, [searchFeedbackResult])

  return (
    <TelemetryScope
      scope="SearchScreenSessionView"
      properties={{ searchSessionId: searchSessionId ? searchSessionId : '' }}
    >
      <AGGridTable
        rowData={tableItem}
        getRowId={(data) => data.data.ticketId}
        columnDefs={columnDefs}
        className={styles.container}
        onRowClicked={actions.rowClick}
        focusedRowId={ticketId}
        hasMore={supportLoadMore}
        loadMore={loadMoreFunction}
        handleOrderChange={isAISearchEnable ? handleOrderChange : undefined}
        loading={loadingTable}
      />
    </TelemetryScope>
  )
}
