import { makeStyles, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'

interface IProps {
  readonly value: null | undefined | '' | Array<never> | Record<string, never>
}

export function ValueViewForEmpty({ value }: IProps) {
  const styles = useStyles()

  const displayValue = useMemo(() => {
    if (value === null) {
      return 'Null'
    }

    if (value === undefined) {
      return 'Undefined'
    }

    if (value === '') {
      return 'Empty'
    }

    if (Array.isArray(value)) {
      return '[ ]'
    }

    if (typeof value === 'object') {
      return '{ }'
    }

    return 'Empty'
  }, [value])

  return (
    <div className={styles.root} title={displayValue}>
      <span>{displayValue}</span>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
    color: tokens.colorNeutralForegroundDisabled,
    opacity: 0.5,
  },
})
