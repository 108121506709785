import { ApiOds3sOfflineDataGroup3 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicket3sOfflineCooked } from '../actions-ticket-assert/assertTicket3sOfflineCooked'
import { assertTicket3sOfflineExists } from '../actions-ticket-assert/assertTicket3sOfflineExists'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getRaw3sOfflineDataGroup3(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<ApiOds3sOfflineDataGroup3> {
  return context.getOrFetch<ApiOds3sOfflineDataGroup3>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.raw3sOfflineDataGroup3
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.raw3sOfflineDataGroup3 = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceBlobUrl = source.turns[messageId]?.substrateSearchOfflineGroup3?.blobUrl
      if (sourceBlobUrl) {
        if (source.endpoint === 'ODS') {
          return await context.api.ods.get3sOfflineDataGroup3(sourceBlobUrl)
        } else {
          return await context.api.copilotDash.getTicketBlob(ticketId, sourceBlobUrl).asSubstrateSearchOfflineGroup3()
        }
      }

      await assertTicket3sOfflineExists(context, ticketId, messageId)
      await assertTicket3sOfflineCooked(context, ticketId)
      throw TicketError.create('No3sOffline', { ticketId, ticketMessageId: messageId })
    },
  })
}
