import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  emailFilterContainer: {
    position: 'relative',
  },
  container: {
    maxWidth: '100%',
    minWidth: '0',
    flexGrow: 1,
    paddingLeft: '14px',
    backgroundColor: tokens.colorNeutralBackground3,
    height: '32px',
    border: 'none',
    '& svg': {
      display: 'none',
    },
  },
  loading: {
    height: '100px',
  },
  listbox: {
    maxHeight: '240px',
  },
  mailIcon: {
    position: 'absolute',
    left: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    width: '16px',
    height: '16px',
  },
})
