import { ITelemetryMetric } from '@copilot-dash/domain'
import {
  Accordion,
  AccordionItem,
  AccordionToggleEventHandler,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { useEffect, useRef } from 'react'
import { TicketScreenStore } from '../../../../TicketScreenStore'
import { TicketScreenOutlineTab } from '../../../../store/IState'
import { MetricCardBody } from './MetricCardBody'
import { MetricCardHeader } from './MetricCardHeader'

interface IProps {
  readonly messageId: string
  readonly metric: ITelemetryMetric
}

export function MetricCard({ messageId, metric }: IProps) {
  const styles = useStyles()
  const actions = TicketScreenStore.useActions()

  const selected = TicketScreenStore.use((state) => state.selectedScopeId === metric.scopeId)
  const selectedFromThisComponentRef = useRef<boolean>(false)

  const rootElementRef = useRef<HTMLDivElement | null>()
  useEffect(() => {
    if (selected && !selectedFromThisComponentRef.current && rootElementRef.current) {
      scrollToView(rootElementRef.current)
    }

    selectedFromThisComponentRef.current = false
  }, [selected])

  const onToggle: AccordionToggleEventHandler = (event, data) => {
    if (!selected) {
      actions.selectOutlineTab(TicketScreenOutlineTab.CallFlow)
      actions.selectTurnScope(messageId, metric.scopeId ?? '')

      selectedFromThisComponentRef.current = true
      return
    }

    if (data.openItems.length === 0) {
      actions.selectTurn(messageId)
    } else {
      actions.selectOutlineTab(TicketScreenOutlineTab.CallFlow)
      actions.selectTurnScope(messageId, metric.scopeId ?? '')

      selectedFromThisComponentRef.current = true
    }
  }

  return (
    <Accordion
      ref={(element) => {
        rootElementRef.current = element
        // ref(element)
      }}
      className={mergeClasses(styles.root, selected && styles.selected)}
      collapsible
      onToggle={onToggle}
      openItems={selected ? ['default'] : []}
    >
      <AccordionItem value="default">
        <MetricCardHeader metric={metric} selected={selected} />
        <MetricCardBody metric={metric} />
      </AccordionItem>
    </Accordion>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0px',
    border: `1px solid ${tokens.colorNeutralStroke2}`,
    borderRadius: tokens.borderRadiusMedium,
    // backgroundColor: tokens.colorNeutralBackground1,
    overflow: 'hidden',
    transition: `box-shadow ${tokens.durationNormal}`,
  },
  selected: {
    border: `1px solid ${tokens.colorBrandStroke1}`,
    boxShadow: tokens.shadow28,
  },
})

function scrollToView(element: HTMLElement): void {
  const parent = findScrollableParent(element)
  if (!parent) {
    return
  }

  const elementRect = element.getBoundingClientRect()
  const parentRect = parent.getBoundingClientRect()

  // Case1: Element is above the parent
  const isAbove = elementRect.top < parentRect.top
  if (isAbove) {
    parent.scrollTo({
      top: element.offsetTop - parent.offsetTop - 50,
      behavior: 'smooth',
    })
    return
  }

  // Case2: Element is below the parent
  const isBelow = elementRect.top + 50 > parentRect.bottom
  if (isBelow) {
    parent.scrollTo({
      top: element.offsetTop - parent.offsetTop - 100,
      behavior: 'smooth',
    })
    return
  }

  function findScrollableParent(element: HTMLElement): HTMLElement | undefined {
    if (element.scrollHeight > element.clientHeight) {
      return element
    }

    if (element.parentElement) {
      return findScrollableParent(element.parentElement)
    }

    return undefined
  }
}
