import { DefaultProducts } from '@copilot-dash/domain'
import type { IDashStoreStateBasic } from './IDashStoreStateBasic'
import { IDashStoreStateFeedbackCopilot } from '../IDashStoreStateFeedbackCopilot'
import { IDashStoreStateQuery } from './IDashStoreStateQuery'
import type { IDashStoreStateTeam } from './IDashStoreStateTeam'
import { IDashStoreStateTicket } from './IDashStoreStateTicket'
import { IDashStoreStateTopIssue } from './IDashStoreStateTopIssue'
import type { IDashStoreStateUsers } from './IDashStoreStateUsers'
import { PromiseSnapshots } from '@copilot-dash/core'

export interface IDashStoreState {
  readonly basic: IDashStoreStateBasic
  readonly team: IDashStoreStateTeam
  readonly users: IDashStoreStateUsers
  readonly tickets: { [ticketId: string]: IDashStoreStateTicket }
  readonly topIssue: IDashStoreStateTopIssue
  readonly query: IDashStoreStateQuery
  readonly feedbackCopilot: IDashStoreStateFeedbackCopilot
}

export const defaultDashStoreState: IDashStoreState = {
  basic: {
    products: PromiseSnapshots.done(DefaultProducts),
    hasOdsAccess: true,
    hasOcvAccess: true,
    tenants: {},
  },
  team: {},
  users: {},
  tickets: {},
  feedbackCopilot: {},
  topIssue: {
    topIssueListMap: {},
    topIssueExtendInfoMap: {},
  },
  query: {
    QueriesMap: {},
    myQueriesMap: {},
    teamQueriesMap: {},
  },
}
