import { TimeRange, Times } from '@copilot-dash/core'
import 'react-multi-date-picker/styles/colors/yellow.css'
import { DateTimeRangePicker } from '../DateTimeRangePicker/DateTimeRangePicker'
import { Column } from '../Layout'
import { useEffect, useState } from 'react'
import { makeStyles, shorthands } from '@fluentui/react-components'

interface IProps {
  range: TimeRange | undefined
  onChanged: (range: TimeRange) => void
  hasDeselectedButton?: boolean
}

// `minDate` is set to March 18, 2024, as there is no feedback diagnostic data available before this date (in UTC).
const MIN_DATE_STR = '2024-03-18'

export function TimeFilter({ range, onChanged, hasDeselectedButton }: IProps) {
  const styles = useStyles()
  const timezone = app.settings.timezone.use()

  // Define the date range for the date/time picker, adjusted for the user's preferred timezone.
  // `minDate` is set to March 18, 2024, as there is no feedback diagnostic data available before this date (in UTC).
  // `maxDate` is set to the current date.
  // Customers are restricted from selecting a date prior to March 18, 2024, or beyond the current date.
  const [[minDate, maxDate], setMinMaxDate] = useState([
    Times.startOfDay(new Date(MIN_DATE_STR), timezone),
    Times.endOfDay(new Date(), timezone),
  ])

  useEffect(() => {
    function updateMinMaxDate() {
      setMinMaxDate([Times.startOfDay(new Date(MIN_DATE_STR), timezone), Times.endOfDay(new Date(), timezone)])
    }
    updateMinMaxDate()
    const timer = setInterval(updateMinMaxDate, 60 * 1000) // Update the min max date every minute to keep the current date up to date
    return () => clearInterval(timer)
  }, [timezone])

  return (
    <Column className={styles.root}>
      <DateTimeRangePicker
        range={range?.type === 'absolute' && range.from === 'null' && range.to === 'null' ? undefined : range}
        onChanged={onChanged}
        minDate={minDate}
        maxDate={maxDate}
        hasDeselectedButton={hasDeselectedButton}
      />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    justifyItems: 'start',
    ...shorthands.gap('2px'),
  },
})
