import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

import { DashApiOptions } from '../../DashApiOptions'
import { z } from 'zod'

const BASE_URL_TEST = 'https://feedbackautomationv2-test.microsoft.com'
const BASE_URL_SDF = 'https://feedbackautomationv2-sdf.microsoft.com'
const BASE_URL_MSIT = 'https://feedbackautomationv2.microsoft.com'

export class AutomationNotificationService {
  readonly connection: HubConnection
  private readonly hubUrl: string

  constructor(options: DashApiOptions) {
    this.hubUrl = this.getBaseUrl(options) + '/processingHubRag?userId=' + options.getAccountId()
    this.connection = new HubConnectionBuilder()
      .withUrl(this.hubUrl, { withCredentials: true })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build()
  }

  private getBaseUrl(options: DashApiOptions): string {
    const endpointUrl = options.getATServerEndpointUrl()
    if (endpointUrl) {
      const url = z.string().url().safeParse(endpointUrl)
      if (url.success) {
        return endpointUrl
      }
    }

    const ring = options.getRing()
    switch (ring) {
      case 'dev':
      case 'test':
        return BASE_URL_TEST
      case 'sdf':
        return BASE_URL_SDF
      case 'msit':
      case 'unknown':
        return BASE_URL_MSIT
    }
  }
}
