import { ColDef } from 'ag-grid-community'
import { AnyPropertyItem } from '../../AnyPropertyType'

export const ValueColumn: ColDef<AnyPropertyItem> = {
  headerName: 'Value',
  field: 'value',
  flex: 1,
  autoHeight: true,
  cellRenderer: 'valueRenderer',
  cellStyle: {
    userSelect: 'text',
    cursor: 'initial',
    padding: 0,
    lineHeight: 'normal',
  },
}
