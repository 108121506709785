import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

import { Column } from '../../../../components/Layout'
import { CopilotMessageTile } from './CopilotMessageTile'
import { ErrorView } from '../../../../components/Error'
import { IFCConversationMessageData } from '@copilot-dash/domain'
import { TurnViewHeader } from './TurnViewHeader'
import { TurnViewSkeleton } from './TurnViewSkeleton'
import { UserMessageTile } from './UserMessageTile'
import { forwardRef } from 'react'

interface IProps {
  readonly isLast?: boolean
  readonly data?: IFCConversationMessageData
  readonly selected?: boolean
  readonly onSelect?: () => void
}

export const TurnView = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement> & IProps>(function TurnView(
  { isLast, data, selected, onSelect },
  ref,
) {
  const styles = useStyles()
  const conversation = isLast
    ? app.store.use().feedbackCopilot.lastFCConversation
    : { status: 'done', data: data, error: null }
  if (!conversation) {
    return null
  }

  const handleOnClick = () => {
    if (!selected) {
      onSelect?.()
    }
  }

  return (
    <Column ref={ref} className={styles.root} onClick={handleOnClick}>
      {renderChild()}
    </Column>
  )

  function renderChild() {
    if (!conversation) {
      return <ErrorView error={'Something went wrong'} />
    }
    const data = conversation.data
    switch (conversation.status) {
      case 'waiting':
        return <TurnViewSkeleton />
      case 'error':
        return <ErrorView error={conversation.error} />
      case 'done': {
        return (
          <Column hAlign="stretch">
            <TurnViewHeader data={data} isResponseTime={false} />
            {data?.query && <UserMessageTile data={data} />}
            <TurnViewHeader data={data} isResponseTime={true} />
            {(data?.botDefaultResponse || data?.queryResult) && <CopilotMessageTile data={data} />}
          </Column>
        )
      }
      default:
        return null
    }
  }
})

const useStyles = makeStyles({
  root: {
    padding: '12px 4px',
    position: 'relative',
    fontSize: '16px',
    color: tokens.colorNeutralForeground2,
  },
  border: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    '&.selected': {
      pointerEvents: 'none',
    },

    '&.selected.outline': {
      ...shorthands.border('3px', 'solid', tokens.colorBrandStroke1),
    },

    '&.unselected': {
      cursor: 'pointer',
    },

    '&.unselected:hover': {
      ...shorthands.borderColor(tokens.colorBrandStroke2),
    },
  },
})
