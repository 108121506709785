import { ATEndpointTile } from '../tiles/ATEndpointTile'
import { Column } from '../../../../../components/Layout'
import { EndpointTile } from '../tiles/EndpointTile'
import { SettingsScreenSection } from '../../common/SettingsScreenSection'
import { SettingsScreenSectionTitle } from '../../common/SettingsScreenSectionTitle'
import { ThemeModeTile } from '../tiles/ThemeModeTile'
import { TimeZoneTile } from '../tiles/TimeZoneTile/TimeZoneTile'

export function SettingsGeneralPanel() {
  return (
    <Column hAlign="start">
      {/* 1. Experimental Features */}
      <SettingsScreenSection>
        <SettingsScreenSectionTitle title="Appearance" />
        <ThemeModeTile />
      </SettingsScreenSection>

      {/* 2. Ring */}
      <SettingsScreenSection>
        <SettingsScreenSectionTitle title="Environment Settings" />
        <EndpointTile />
        <ATEndpointTile />
      </SettingsScreenSection>

      {/* 3. Timezone */}
      <SettingsScreenSection>
        <SettingsScreenSectionTitle title="Time zones" />
        <TimeZoneTile />
      </SettingsScreenSection>
    </Column>
  )
}
