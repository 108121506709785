import { IGetTeamDRIListByTeam, ISetTeamDRIList, ITeamDRIList } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function getTeamDRIListByTeamId(
  context: IDashStoreContext,
  teamId: number,
): Promise<IGetTeamDRIListByTeam> {
  const response = await context.api.logCollector.getTeamDRIListByTeamId({ teamId: teamId })
  return response
}

export async function setTeamDRIList(context: IDashStoreContext, params: ITeamDRIList): Promise<ISetTeamDRIList> {
  const response = await context.api.logCollector.setTeamDRIList(params)
  return response
}
