import { z } from 'zod'

export interface ApiCreateRagQueryResponse {
  readonly messageId: string
  readonly queryStatus: string
  readonly botDefaultResponse: string
  readonly queryResultIsRead?: boolean
}

export const apiCreateRagQueryResponseSchema = z.object({
  messageId: z.string(),
  queryStatus: z.string(),
  botDefaultResponse: z.string(),
  queryResultIsRead: z.boolean().optional(),
})
