import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketRootCauseData, ITicketRootCauseItem, RootCauseActionType } from '@copilot-dash/domain'
import { Popover, PopoverSurface, PopoverTrigger, Text, tokens } from '@fluentui/react-components'
import { SparkleFilled } from '@fluentui/react-icons'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { Row, Spacer } from '../../../../components/Layout'
import { RootCauseSuggestions } from '../../../../components/TicketActionForm/components/RootCauseSuggestions'
import { TicketScreenStore } from '../../TicketScreenStore'
import { useStyles } from './TicketScreenRootCauseSuggestions.styles'

interface ITicketScreenRootCauseSuggestionsProps {
  rootCauseSnapshot: PromiseSnapshot<ITicketRootCauseData>
  onAccept: (rootCause: ITicketRootCauseItem) => void
  invisibleRootCauseIds: string[]
}

export const TicketScreenRootCauseSuggestions: FC<ITicketScreenRootCauseSuggestionsProps> = memo(
  ({ onAccept, invisibleRootCauseIds, rootCauseSnapshot }) => {
    const styles = useStyles()
    const ticketId = TicketScreenStore.use((state) => state.ticketId)

    const [suggestions, setSuggestions] = useState<ITicketRootCauseItem[]>([])
    const [visibleSuggestions, setVisibleSuggestions] = useState<ITicketRootCauseItem[]>()

    useEffect(() => {
      rootCauseSnapshot.data?.items &&
        setSuggestions(
          rootCauseSnapshot.data.items.filter(
            (item) =>
              item.userAction === RootCauseActionType.UserNoAction ||
              item.userAction === RootCauseActionType.DirectLinked,
          ),
        )
    }, [rootCauseSnapshot.data])

    useEffect(() => {
      const visibleSuggestions = suggestions?.filter((item) => !invisibleRootCauseIds.includes(item.adoIssueId))
      if (visibleSuggestions) {
        setVisibleSuggestions(visibleSuggestions)
      } else {
        setVisibleSuggestions([])
      }
    }, [invisibleRootCauseIds, suggestions])

    const removeRootCause = useCallback((adoIssueId: string) => {
      setSuggestions((prev) => prev?.filter((item) => item.adoIssueId !== adoIssueId))
    }, [])

    const reportAction = useCallback(
      (adoIssueId: string, userAction: string) => {
        if (!ticketId) return
        const clickItem = suggestions?.find((item) => item.adoIssueId === adoIssueId)
        if (!clickItem) return

        application.store.actions.updateTicketRootCause(ticketId, {
          items: [{ ...clickItem, userAction }],
          updateAreaAndIssues: false,
        })
      },
      [ticketId, suggestions],
    )

    const handleAccept = useCallback(
      (suggestion: ITicketRootCauseItem) => {
        onAccept(suggestion)
        reportAction(suggestion.adoIssueId, RootCauseActionType.UserConfirmed)
        removeRootCause(suggestion.adoIssueId)
      },
      [onAccept, removeRootCause, reportAction],
    )

    const handleReject = useCallback(
      (suggestion: ITicketRootCauseItem) => {
        reportAction(suggestion.adoIssueId, RootCauseActionType.UserRejected)
        removeRootCause(suggestion.adoIssueId)
      },
      [removeRootCause, reportAction],
    )

    if (!visibleSuggestions || visibleSuggestions.length === 0) {
      return null
    }
    const firstSuggestion = visibleSuggestions[0]
    return (
      <Row>
        <Row>
          <SparkleFilled fontSize={20} color={tokens.colorCompoundBrandForeground1} />
          <Spacer width={12} />
          <Text size={400}>Recommendations:</Text>
          <Spacer width={6} />
          <Popover openOnHover withArrow positioning="below">
            <PopoverTrigger>
              <Text size={400} className={styles.blue}>
                {firstSuggestion?.title}
                {visibleSuggestions.length > 1 ? ` (+${visibleSuggestions.length - 1})` : ''}
              </Text>
            </PopoverTrigger>

            <PopoverSurface className={styles.popoverSurface} tabIndex={-1}>
              <RootCauseSuggestions
                suggestions={visibleSuggestions}
                onAcceptSuggestion={handleAccept}
                onRejectSuggestion={handleReject}
                border={false}
              />
            </PopoverSurface>
          </Popover>
        </Row>
      </Row>
    )
  },
)

TicketScreenRootCauseSuggestions.displayName = 'TicketScreenRootCauseSuggestions'
