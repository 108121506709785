export function setupRootElementDefaultStyles(element: HTMLElement) {
  element.style.padding = '12px 0'
  element.style.margin = ''
  element.style.backgroundColor = ''

  const firstChild = element.firstElementChild
  if (firstChild && firstChild instanceof HTMLElement) {
    firstChild.style.overflow = 'auto'
    firstChild.style.fontFamily = ''
    firstChild.style.fontSize = ''
    firstChild.style.fontWeight = ''
    firstChild.style.color = ''
  }
}
