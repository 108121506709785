import { INewTicketData, ColumnKey, ColumnIdNameMapping, SearchTextPrefixType } from '@copilot-dash/domain'
import { ResponsiveVerbatimColumnComponent } from './ResponsiveVerbatimColumnComponent'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { CustomCellRendererProps } from 'ag-grid-react'
import { RenderLongText } from './RenderLongText'

export const createColumnConfigVerbatimFeedback = (
  searchTextPrefix: SearchTextPrefixType,
  keyWords?: string,
): ColDef<INewTicketData> => {
  return {
    headerName: ColumnIdNameMapping[ColumnKey.VerbatimFeedback],
    field: String(ColumnKey.VerbatimFeedback) as NestedFieldPaths<INewTicketData>,
    cellRenderer: (props: CustomCellRendererProps) => {
      if (props.data.verbatim) {
        return (
          <TableCellLayoutWithStyles truncate>
            <RenderLongText originText={props.data.verbatim} highlightText={props.data.verbatimWithHighlight} />
          </TableCellLayoutWithStyles>
        )
      }
      return (
        <TableCellLayoutWithStyles truncate>
          {props.data.ticketId && (
            <ResponsiveVerbatimColumnComponent
              item={props.data}
              searchTextPrefix={searchTextPrefix}
              keyWords={keyWords}
            />
          )}
        </TableCellLayoutWithStyles>
      )
    },
    headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
    headerComponentParams: {
      customHeaderContent: (
        <TableCellLayoutWithStyles truncate>
          {ColumnIdNameMapping[ColumnKey.VerbatimFeedback]}
        </TableCellLayoutWithStyles>
      ),
    },
    flex: 2,
    minWidth: 150,
    sortable: false,
    resizable: true,
  }
}
