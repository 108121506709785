import { User } from '@copilot-dash/api'
import { makeStyles, mergeClasses, shorthands, Skeleton, SkeletonItem, Text, tokens } from '@fluentui/react-components'
import { ReactElement, useMemo } from 'react'
import { Row } from '../Layout'
import { FloatingTooltip, FloatingTooltipContent, FloatingTooltipTrigger } from '../FloatingTooltip'

interface IProps {
  readonly userId: string
  readonly className?: string
  readonly isEnableToolTip?: boolean
  readonly tooltipContent?: (user: User) => ReactElement
  readonly fallback?: React.ReactNode
}

export function UserDisplayName({ userId, className, isEnableToolTip = true, tooltipContent, fallback }: IProps) {
  const styles = useStyles()
  const userSnapshot = app.store.use.getUserProfile(userId)

  const user: User | undefined = useMemo(() => {
    switch (userSnapshot.status) {
      case 'done':
        return userSnapshot.data
      default:
        return undefined
    }
  }, [userSnapshot.data, userSnapshot.status])

  const text = useMemo(() => {
    if (userSnapshot.status === 'done') {
      return user?.displayName
    }
    if (userSnapshot.status === 'error') {
      return fallback
    }
    if (userSnapshot.status === 'waiting') {
      return (
        <Skeleton appearance="translucent" className={styles.skeleton}>
          <SkeletonItem />
        </Skeleton>
      )
    }
    return null
  }, [fallback, styles.skeleton, user?.displayName, userSnapshot.status])

  const tooltipContentElement = useMemo(() => {
    if (tooltipContent && user) {
      return tooltipContent(user)
    } else if (text) {
      return (
        <Text wrap={false} className={mergeClasses(styles.displayName, className)}>
          {text}
        </Text>
      )
    }
    return ''
  }, [className, styles.displayName, text, tooltipContent, user])

  return (
    <Row>
      {isEnableToolTip && text ? (
        <FloatingTooltip>
          <FloatingTooltipTrigger>
            <Row>
              <Text wrap={false} truncate className={mergeClasses(styles.displayName, className)}>
                {text}
              </Text>
            </Row>
          </FloatingTooltipTrigger>
          <FloatingTooltipContent>{tooltipContentElement}</FloatingTooltipContent>
        </FloatingTooltip>
      ) : (
        <Text wrap={false} truncate className={mergeClasses(styles.displayName, className)}>
          {text}
        </Text>
      )}
    </Row>
  )
}

const useStyles = makeStyles({
  displayName: {
    fontSize: tokens.fontSizeBase400,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100px',
    ...shorthands.gap('8px'),
  },
})
