import { makeStyles } from '@fluentui/react-components'
import { Column } from '../../Layout'
import { TurnView } from '../children/TurnView'

interface IProps {
  readonly messageIds: string[]
}

export function TicketCallFlowLayoutForData({ messageIds }: IProps) {
  const styles = useStyles()
  const lastTurnId = messageIds[messageIds.length - 1]

  return (
    <Column className={styles.root} hAlign="stretch">
      {lastTurnId && <TurnView messageId={lastTurnId} selectedAppearance="none" />}

      {/* TODO: The following code is for multiple turn. Let's keep it as is for now. */}
      {/* <Column className={styles.body} hAlign="stretch">
        {data.turns.map((turn) => (
          <TurnView key={turn.id} turnId={turn.id} selectedAppearance={data.turns.length === 1 ? 'none' : 'outline'} />
        ))}
      </Column> */}
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    gap: '16px',
    paddingBottom: '32px',
  },
  body: {
    paddingLeft: '16px',
    paddingRight: '16px',
    gap: '16px',
  },
})
