import { IDashStoreContext } from '../../IDashStoreContext'

export async function deleteComment(
  context: IDashStoreContext,
  discussionId: string,
  commentId: string,
): Promise<boolean> {
  return context.requestAnd({
    request: async () => {
      if (!context.enableV2Discussion) {
        const response = await context.api.logCollector.deleteComments(discussionId, commentId)
        return response.errorMessage === ''
      } else {
        const response = await context.api.copilotDash.deleteComment(discussionId, commentId)
        return response.isSucceeded
      }
    },
    onSuccess(state, data) {
      const ticket = (state.tickets[discussionId] ??= {})
      const snapshot = ticket.comments
      if (!snapshot) {
        return
      }

      switch (snapshot.status) {
        case 'error':
          break
        case 'waiting':
          break
        case 'done':
          {
            const index = snapshot.data.findIndex((item) => item.id === commentId)
            if (index !== -1 && data) {
              snapshot.data.splice(index, 1)
            }
          }
          break
      }
    },
  })
}
