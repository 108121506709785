import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRaw3sOfflineDataGroup1 } from '../actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup1'
import { getRaw3sOfflineDataGroup2 } from '../actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup2'
import { getRaw3sOfflineDataGroup3 } from '../actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup3'
import { getRawConversation } from '../actions-raw-ticket-chat/getRawConversation'
import { getRawConversationGroup1 } from '../actions-raw-ticket-chat/getRawConversationGroup1'
import { getTicketTurnMetadata } from './getTicketTurnMetadata'

export function getTicketTurnConversationContext(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<object> {
  return context.getOrFetch<object>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.conversationContext
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.conversationContext = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<object> {
    try {
      return await fetchFromConversationGroup1()
    } catch (e) {
      const legacy = await fetchFromConversationLegacy()
      if (legacy) {
        return legacy
      }

      const offline = await fetchFromOffline()
      if (offline) {
        return offline
      }

      throw e
    }
  }

  async function fetchFromConversationGroup1(): Promise<object> {
    const group1 = await getRawConversationGroup1(context, ticketId, messageId).promise

    return {
      traceId: messageId,
      conversation: group1.conversation,
      interaction: await fetchInteraction(),
    }
  }

  async function fetchFromConversationLegacy(): Promise<object | undefined> {
    const conversation = await getRawConversation(context, ticketId, messageId).promise.catch(() => undefined)
    if (!conversation) {
      return undefined
    }

    return {
      traceId: messageId,
      conversation: conversation.conversation,
      interaction: await fetchInteraction(),
    }
  }

  async function fetchFromOffline(): Promise<object | undefined> {
    const group1Promise = getRaw3sOfflineDataGroup1(context, ticketId, messageId).promise.catch((e) => undefined)
    const group2Promise = getRaw3sOfflineDataGroup2(context, ticketId, messageId).promise.catch((e) => undefined)
    const group3Promise = getRaw3sOfflineDataGroup3(context, ticketId, messageId).promise.catch((e) => undefined)

    const group1 = await group1Promise
    const group2 = await group2Promise
    const group3 = await group3Promise
    if (!group1 && !group2 && !group3) {
      return undefined
    }

    return {
      traceId: messageId,
      offline: {
        'Conversation Messages': group1 ?? '(Empty)',
        '3S Diagnostics': group2 ?? '(Empty)',
        'Conversation Diagnostics': group3 ?? '(Empty)',
      },
      interaction: await fetchInteraction(),
    }
  }

  async function fetchInteraction(): Promise<unknown> {
    const promise = getTicketTurnMetadata(context, ticketId, messageId).promise
    return promise.then((turn) => turn?.raw).catch((e) => String(e))
  }
}
