import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicket } from '../actions-raw-ticket-ods/getRawOdsTicket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export function getTicketUserConsent(context: IDashStoreContext, ticketId: string): PromiseSnapshot<boolean> {
  return context.getOrFetch<boolean>({
    get: (state) => {
      return state.tickets[ticketId]?.hasUserConsent
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.hasUserConsent = snapshot
    },
    fetch: async () => {
      if (context.enableV2Endpoint) {
        return fetchFromV2()
      } else {
        return fetchFromOds()
      }
    },
  })

  async function fetchFromV2(): Promise<boolean> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    return raw.userFeedback.hasUserConsent
  }

  async function fetchFromOds(): Promise<boolean> {
    const api = await getRawOdsTicket(context, ticketId).promise

    // Check 1
    const userConsent1 = api['User Consent Given']
    if (userConsent1 === 'True') {
      return true
    }
    if (userConsent1 === 'False') {
      return false
    }

    // Check 2
    const userConsent2 = api.DiagnosticSessionAttributes?.['User Consent Given']
    if (userConsent2 === 'True') {
      return true
    }
    if (userConsent2 === 'False') {
      return false
    }

    // Check 3
    const tags = api.Tags
    if (Array.isArray(tags) && tags.includes('Feedback:HasUserConsent')) {
      return true
    }

    return false
  }
}
