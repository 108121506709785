import { makeStyles } from '@fluentui/react-components'
import { AnyData } from '../../../../../components/AnyData/AnyData'
import { AnyDataTab } from '../../../../../components/AnyData/AnyDataTypes'
import { Column } from '../../../../../components/Layout'
import { TicketAsyncView } from '../../common/TicketAsyncView'

interface IProps {
  readonly ticketId: string
  readonly selectedTurnId: string
}

export function ConversationData({ ticketId, selectedTurnId }: IProps) {
  const styles = useStyles()
  const snapshot = app.store.use.getTicketTurnConversationContext(ticketId, selectedTurnId)

  return (
    <Column vAlign="stretch" hAlign="stretch" fill className={styles.root}>
      <TicketAsyncView snapshot={snapshot}>
        {(data) => {
          return <AnyData data={data} options={{ tabs: [AnyDataTab.Json] }} />
        }}
      </TicketAsyncView>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0px',
  },
})
