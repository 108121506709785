import { useCallback, useEffect, useMemo, useState } from 'react'
import { Field, makeStyles, tokens, Checkbox } from '@fluentui/react-components'
import { UserPhoto } from '../../../../components/User/UserPhoto'
import { UserDisplayName } from '../../../../components/User/UserDisplayName'
import { useDebounce } from '../../../../hooks/useDebounce'
import { Row, Spacer } from '../../../../components/Layout'
import { CopilotDashPopover } from '../../../../components/Filter/CopilotDashPopover'

interface IProps {
  users: string[]
  onChangeUser: (value: string[]) => void
  emails: string[]
}

export const AssignedToSearch = ({ users, onChangeUser, emails }: IProps) => {
  const styles = useStyles()
  const [query, setQuery] = useState<string>('')
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const debouncedQuery = useDebounce(query, 300)

  useEffect(() => {
    if (debouncedQuery.trim().length > 0) {
      setQuery(debouncedQuery.trim())
    }
  }, [debouncedQuery])

  const displayContainer = useMemo(() => {
    return (
      <Row>
        {users.length > 0 &&
          users.map((item, index) => {
            return (
              <Row key={index}>
                <Row>
                  <UserPhoto userId={item} className={styles.avatar} />
                  <Spacer width={'4px'} />
                  <UserDisplayName
                    userId={item}
                    isEnableToolTip={false}
                    fallback={item}
                    className={users.length === 1 ? styles.displayName : styles.displayNames}
                  />
                </Row>
                {index < users.length - 1 && <span>{'; '}</span>}
              </Row>
            )
          })}
      </Row>
    )
  }, [users, styles.avatar, styles.displayName, styles.displayNames])

  const options = useMemo(() => {
    if (debouncedQuery.trim().length > 0) {
      return emails.filter((email) => email.toLowerCase().includes(debouncedQuery.trim().toLowerCase()))
    } else {
      return emails
    }
  }, [debouncedQuery, emails])

  const handleCheckBoxChecked = useCallback(
    (key: string) => {
      return users.includes(key)
    },
    [users],
  )

  const handleCheckboxChange = (checked: string | boolean, option: string) => {
    if (checked) {
      onChangeUser([...users, option])
    } else {
      onChangeUser(users.filter((item) => item !== option))
    }
  }

  const targetFinalOptions = options.map((item) => {
    return (
      <Row key={item}>
        <Checkbox
          checked={handleCheckBoxChecked(item)}
          onChange={(_, data) => handleCheckboxChange(data.checked, item)}
          id={item}
          label={
            <Row>
              <UserPhoto userId={item} className={styles.avatar} />
              <Spacer width={'4px'} />
              <UserDisplayName
                userId={item}
                isEnableToolTip={false}
                fallback={item}
                className={styles.listDisplayName}
              />
            </Row>
          }
          className={styles.checkBox}
        />
      </Row>
    )
  })

  const handleSelectCleanClick = () => {
    onChangeUser([])
  }

  return (
    <Field className={styles.container}>
      <CopilotDashPopover
        optionContent={targetFinalOptions}
        value={displayContainer}
        placeholder="Assigned to"
        selectedOptions={users}
        handleSelectCleanClick={handleSelectCleanClick}
        inputValue={query}
        setInputValue={setQuery}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        showInput={true}
      />
    </Field>
  )
}

const useStyles = makeStyles({
  avatar: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    fontSize: tokens.fontSizeBase100,
  },
  displayName: {
    width: '110px',
    fontSize: tokens.fontSizeBase300,
  },
  displayNames: {
    width: '40px',
    fontSize: tokens.fontSizeBase300,
  },
  listDisplayName: {
    width: '150px',
    fontSize: tokens.fontSizeBase300,
  },
  container: {
    width: '180px',
    flexShrink: 0,
    flexGrow: 0,
    height: '32px',
    minWidth: '180px',
  },
  checkBox: {
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    },
  },
})
