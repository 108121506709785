import { useRef, ReactNode, FC } from 'react'
import { Tag, InteractionTag, InteractionTagPrimary, mergeClasses } from '@fluentui/react-components'
import { useStyles } from './CopilotDashTag.styles'
import { Dismiss16Filled } from '@fluentui/react-icons'
import { Column } from '../Layout'

interface IProps {
  isTagClearable: boolean
  tagValue: string
  tagKey: string
  tagContent: ReactNode
  className?: string
  onRemoveTag?: (e: React.MouseEvent) => void
  onClickTagFunction?: () => void
}

export const CopilotDashTag: FC<IProps> = ({
  isTagClearable,
  tagValue,
  tagKey,
  tagContent,
  className,
  onRemoveTag,
  onClickTagFunction,
}) => {
  const styles = useStyles()
  const firstTagRef = useRef<HTMLButtonElement>(null)

  if (isTagClearable)
    return (
      <Column>
        <Tag
          className={styles.tagStyle}
          appearance="brand"
          dismissible={isTagClearable}
          onClick={onClickTagFunction}
          dismissIcon={{
            as: 'span',
            children: (
              <Dismiss16Filled className={styles.dismissButton} onClick={(e) => onRemoveTag && onRemoveTag(e)} />
            ),
          }}
          value={tagValue}
          key={tagKey}
          ref={firstTagRef}
        >
          {tagContent}
        </Tag>
      </Column>
    )
  return (
    <Column>
      <InteractionTag className={className}>
        <InteractionTagPrimary onClick={onClickTagFunction} className={mergeClasses(styles.tagStyle, className)}>
          {tagContent}
        </InteractionTagPrimary>
      </InteractionTag>
    </Column>
  )
}
