import * as React from 'react'

import { Row, Spacer } from '../../../components/Layout'
import { makeStyles, shorthands } from '@fluentui/react-components'

import { HeaderAvatar } from './components/HeaderAvatar'
import { HeaderBrandView } from './components/HeaderBrandView'
import { HeaderFeedbackButton } from './components/HeaderFeedbackButton'
import { HeaderFeedbackCopilotButton } from './components/HeaderFeedbackCopilotButton'
import { HeaderPrivacySwitch } from './components/HeaderPrivacySwitch'
import { HeaderProductDropdown } from './components/HeaderProductDropdown'
import { HeaderReleaseNotesButton } from './components/HeaderReleaseNotesButton'
import { HeaderSettingsButton } from './components/HeaderSettingsButton'
import { HeaderTabs } from './components/HeaderTabs'
import { HeaderTimezoneButton } from './components/HeaderTimezoneButton'

export const RootScreenHeaderContent: React.FunctionComponent = () => {
  const styles = useStyles()
  const isAuthenticated = app.auth.isAuthenticated
  const env = application.env.ring
  const isPrivacySwitchVisible = env === 'dev' || env === 'sdf' || env === 'test'
  const hasGeneralAccess = app.auth.permission?.general ?? false
  const isfeedbackCopilotEnabled = app.features.feedbackCopilot.use()
  if (!isAuthenticated) {
    return (
      <Row className={styles.header}>
        <Row className={styles.headerLeft}>
          <HeaderBrandView />
        </Row>
      </Row>
    )
  }

  return (
    <Row className={styles.header}>
      <Row className={styles.headerLeft}>
        <HeaderBrandView />
        {hasGeneralAccess && <Spacer width="40px" />}
        {hasGeneralAccess && <HeaderProductDropdown />}
        {hasGeneralAccess && <Spacer width="40px" />}
        {hasGeneralAccess && <HeaderTabs />}
      </Row>
      <Row vAlign="center" hAlign="end">
        {hasGeneralAccess && isPrivacySwitchVisible && <HeaderPrivacySwitch />}
        {hasGeneralAccess && <HeaderTimezoneButton />}
        {hasGeneralAccess && <HeaderSettingsButton />}
        {hasGeneralAccess && isfeedbackCopilotEnabled && <HeaderFeedbackCopilotButton />}
        <HeaderFeedbackButton />
        <HeaderReleaseNotesButton />
        <HeaderAvatar />
      </Row>
    </Row>
  )
}

const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '56px',
    ...shorthands.padding('0px', '24px', '0px', '52px'),
  },
  headerLeft: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...shorthands.overflow('hidden'),
  },
})
