import { FC, memo } from 'react'
import { ICellBaseProps } from './types'
import { Row } from '../../../../../../../components/Layout'
import { FeedbackInsightsTag } from '../../../tagComponents/FeedbackInsightsTag'
import { Skeleton, SkeletonItem } from '@fluentui/react-components'

export const VIPUserAndCAPTenants: FC<ICellBaseProps> = memo(({ data }) => {
  if (data.extendInfo.status === 'waiting') {
    return (
      <Skeleton style={{ width: '100%' }} aria-label="Loading Content">
        <SkeletonItem />
      </Skeleton>
    )
  }
  return (
    <Row>
      <FeedbackInsightsTag
        VIPUserIds={data.extendInfo.data?.VIPPowerUserIdList ?? []}
        CAPTenants={data.extendInfo.data?.cAPTenantList ?? []}
      />
    </Row>
  )
})
VIPUserAndCAPTenants.displayName = 'VIPUserAndCAPTenants'
