import { DashApi } from '@copilot-dash/api'
import { IDashAuth } from '@copilot-dash/auth'
import { IDashEnvironment } from '@copilot-dash/core'
import { ISettings } from '@copilot-dash/settings'
import { Logger } from '@copilot-dash/logger'

export function createAppApi(env: IDashEnvironment, auth: IDashAuth, settings: ISettings): DashApi {
  return new DashApi({
    getRing: () => {
      return env.ring
    },
    getServerEndpointUrl: () => {
      return settings.endpointUrl.value
    },
    getATServerEndpointUrl: () => {
      return settings.atendpointUrl.value
    },
    acquireToken: (scopes: Array<string>): Promise<string> => {
      try {
        return auth.acquireToken(scopes)
      } catch (e) {
        Logger.trace.error(`Failed to acquire token by scopes: ${scopes}`, e)
        throw e
      }
    },
    getAccountId: () => {
      return auth.activeAccount?.localAccountId
    },
    getSessionId: () => {
      return env.sessionId
    },
  })
}
