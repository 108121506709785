import { IDashEnvironment } from '@copilot-dash/core'
import { IRequestContext } from '@microsoft/applicationinsights-common'
import { ICustomProperties, Snippet } from '@microsoft/applicationinsights-web'
import { ITelemetryCustomProperties } from '../telemetry'
import { getXMLRequestHeaders } from '../utils/getXMLRequestHeaders'

export class DashLoggerConfigs {
  static readonly CONSOLE_LOG_PREFIX = '[CopilotDash]'

  /**
   * CopilotDash Application Insights in MSIT Azure Subscription
   */
  static getConfig1(env: IDashEnvironment): Snippet {
    return {
      config: {
        instrumentationKey: '7aa67079-eef1-4b02-bc2a-e3f995107a99',
        appId: 'copilotdash',
        addRequestContext: (context) => {
          return this.buildRequestContext(env, context)
        },
      },
      version: env.buildVersion,
    }
  }

  /**
   * CopilotDash Application Insights in Torus Azure Subscription
   */
  static getConfig2(env: IDashEnvironment): Snippet {
    let instrumentationKey: string
    switch (env.ring) {
      case 'dev':
      case 'test':
        instrumentationKey = '12b701c9-df84-46c7-8998-1d2b7926e9ba'
        break
      case 'sdf':
        instrumentationKey = '95ba3fd8-4099-47f2-a249-4f40e330fdf0'
        break
      case 'msit':
      case 'unknown':
        instrumentationKey = 'e052a546-7425-4252-bf94-4c1e91e984d8'
        break
    }

    return {
      config: {
        instrumentationKey,
        appId: 'copilotdash',
        addRequestContext: (context) => {
          return this.buildRequestContext(env, context)
        },
      },
      version: env.buildVersion,
    }
  }

  private static buildRequestContext(
    env: IDashEnvironment,
    context: IRequestContext | undefined,
  ): ITelemetryCustomProperties & ICustomProperties {
    const request = context?.request
    const xmlRequestHeaders = context?.xhr ? getXMLRequestHeaders(context.xhr) : {}

    return {
      app: 'CopilotDash',
      ring: env.ring,
      version: env.buildVersion,
      sessionId: request?.headers.get('X-Session-Id') ?? xmlRequestHeaders['X-Session-Id'] ?? env.sessionId,
      correlationId: request?.headers.get('X-Correlation-Id') ?? xmlRequestHeaders['X-Correlation-Id'],
      traceId: request?.headers.get('X-Trace-Id') ?? xmlRequestHeaders['X-Trace-Id'],
    }
  }
}
