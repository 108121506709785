import { FeedbackCopilotScreen } from '../../../screens/feedbackcopilot/FeedbackCopilotScreen'
import { PageTitle } from '../../../components/PageTitle/PageTitle'

export function FeedbackCopilotRoutePage() {
  return (
    <PageTitle title="FeedbackCopilot">
      <FeedbackCopilotScreen />
    </PageTitle>
  )
}
