import { IFilterOption } from '../Filter/IFilterOption.types'
import { ISearchTicketArgs } from '@copilot-dash/store'
import { SearchFilterFieldNames } from '@copilot-dash/domain'

export const CustomizedFilterField = (
  form: ISearchTicketArgs,
  supportedTicketFilterItems: SearchFilterFieldNames[],
): IFilterOption[] => [
  {
    key: SearchFilterFieldNames.isTopi18N,
    text: 'Is Top i18N Tenants',
    disabled: !!form.isTopi18N?.length,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.isTopi18N),
  },
  {
    key: SearchFilterFieldNames.isSTCAChina,
    text: 'Is STCA China',
    disabled: !!form.isSTCAChina?.length,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.isSTCAChina),
  },
  {
    key: SearchFilterFieldNames.hasGPTExtension,
    text: 'Has GPT-Extension keywords',
    disabled: !!form.hasGPTExtension?.length,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.hasGPTExtension),
  },
  {
    key: SearchFilterFieldNames.hasMessageExtension,
    text: 'Has MessageExtension keywords',
    disabled: !!form.hasMessageExtension?.length,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.hasMessageExtension),
  },
  {
    key: SearchFilterFieldNames.hasConnector,
    text: 'Has Connector keywords',
    disabled: !!form.hasConnector?.length,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.hasConnector),
  },
  {
    key: SearchFilterFieldNames.isGCIntent,
    text: 'Is GC Intent',
    disabled: !!form.isGCIntent?.length,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.isGCIntent),
  },
  {
    key: SearchFilterFieldNames.hasConnectorResult,
    text: 'Has Connector Result',
    disabled: !!form.hasConnectorResult?.length,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.hasConnectorResult),
  },
  {
    key: SearchFilterFieldNames.semanticSearchType,
    text: 'Semantic search type',
    disabled: !!form.semanticSearchType?.length,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.semanticSearchType),
  },
  {
    key: SearchFilterFieldNames.experienceType,
    text: 'PromptMetadata_ExperienceType',
    disabled: !!form.experienceType?.length,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.experienceType),
  },
  {
    key: SearchFilterFieldNames.errorCode,
    text: 'Error code',
    disabled: !!form.errorCode?.length,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.errorCode),
  },
]
