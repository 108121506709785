import {
  Badge,
  Button,
  Image,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Text,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { CheckmarkCircle16Filled, Dismiss16Regular } from '@fluentui/react-icons'
import { useEffect, useMemo, useState } from 'react'

import { ApiFCConversationMessageData } from '@copilot-dash/api'
import FeedbackCopilot from '../../../../../assets/FeedbackCopilot.svg'
import { FeedbackCopilotRoute } from '../../../../router/routes/feedbackcopilot/FeedbackCopilotRoute'
import { IFCConversationMessageData } from '@copilot-dash/domain'
import { RouteLink } from '../../../../router'

const latestResultStatus = ['QueryReady', 'QueryFailed', 'QueryCancelled']
export function HeaderFeedbackCopilotButton() {
  const styles = useStyles()
  const feedbackCopilotRoutePath = FeedbackCopilotRoute.navigator.generatePath()
  const userId = app.auth.activeAccount?.localAccountId ?? ''
  const getConversation = app.store.use.getFCConversationByUserId(userId, 10)
  const [isOpen, setIsOpen] = useState(false)
  const signalRService = app.api.automation

  const hasNewResult = useMemo(() => {
    switch (getConversation?.status) {
      case 'done': {
        //check the latest result read status
        const lastRecord = getConversation.data?.[getConversation.data.length - 1]
        const hasNewResult = lastRecord
          ? !lastRecord.queryResultIsRead && latestResultStatus.includes(lastRecord.queryStatus ?? '')
          : false
        return hasNewResult
      }
      default:
        return false
    }
  }, [getConversation])

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(hasNewResult)
    }, 1000)
  }, [hasNewResult])

  useEffect(() => {
    signalRService.startConnection().then(() => {
      signalRService.onReceiveMessage((message: ApiFCConversationMessageData) => {
        const latestMessage: IFCConversationMessageData = { ...message, queryResultIsRead: false }
        app.store.actions.updateFCConversation(latestMessage)
      })
    })
  }, [signalRService, userId])

  function handleDismiss(): void {
    setIsOpen(false)
  }
  function handleOpen(): void {
    setIsOpen(true)
  }

  return (
    <RouteLink path={feedbackCopilotRoutePath} reloadDocument={false}>
      <Popover withArrow open={hasNewResult && isOpen} positioning="below">
        <PopoverTrigger>
          <Button appearance="subtle" className={styles.buttonIcon} title="Feedback Copilot" onMouseOver={handleOpen}>
            <Image src={FeedbackCopilot} alt={'Feedback Copilot'} />
            {hasNewResult && <Badge appearance="filled" color="danger" size="tiny" className={styles.redDot} />}
          </Button>
        </PopoverTrigger>
        <PopoverSurface className={styles.newResultPopover}>
          <CheckmarkCircle16Filled style={{ color: tokens.colorStatusSuccessBackground3 }} />
          <Text style={{ flex: 1, color: tokens.colorNeutralBackgroundStatic }}>
            Results are available in Feedback Copilot.
          </Text>
          <Button
            icon={<Dismiss16Regular />}
            appearance="subtle"
            onClick={handleDismiss}
            style={{ backgroundColor: 'transparent' }}
          />
        </PopoverSurface>
      </Popover>
    </RouteLink>
  )
}

const useStyles = makeStyles({
  buttonIcon: {
    position: 'relative',
    display: 'flex',
    minWidth: '32px',
  },
  redDot: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    transform: 'translate(50%, -50%)',
  },
  newResultPopover: {
    backgroundColor: tokens.colorNeutralForegroundStaticInverted,
    boxShadow: tokens.shadow2,
    borderRadius: '8px',
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '350px',
  },
})
