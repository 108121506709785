import { ColumnIdNameMapping, ColumnKey, INewTicketData } from '@copilot-dash/domain'
import { Skeleton, SkeletonItem, Text } from '@fluentui/react-components'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { ToolTipText } from '../Text/ToolTipText'
import { useStyles } from './ColumnComponent.styles'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { CustomCellRendererProps } from 'ag-grid-react'

export const ColumnConfigTenantName: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.TenantName],
  field: String(ColumnKey.TenantName) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    if (props.data.tenantId && !props.data.tenantName) {
      return <ConfigTenantNameFromStore tenantId={props.data.tenantId} />
    } else {
      return <ConfigTenantName tenantName={props.data.tenantName} />
    }
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.TenantName]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 130,
  sortable: false,
  resizable: true,
}

const ConfigTenantName = ({ tenantName, keyWords }: { tenantName?: string; keyWords?: string[] }) => {
  const styles = useStyles()
  return (
    <TableCellLayoutWithStyles truncate>
      {tenantName ? (
        <ToolTipText
          text={tenantName}
          keywords={tenantName === keyWords?.[0] ? tenantName : undefined}
          isTwoLinesTruncate={true}
        />
      ) : (
        <Text className={styles.grayColor}>N/A</Text>
      )}
    </TableCellLayoutWithStyles>
  )
}

const ConfigTenantNameFromStore = ({ tenantId, keyWords }: { tenantId: string; keyWords?: string[] }) => {
  const styles = useStyles()
  const snapshot = app.store.use.getTenant(tenantId)

  switch (snapshot.status) {
    case 'waiting':
      return (
        <Skeleton appearance="translucent" className={styles.skeleton}>
          <SkeletonItem className={styles.skeletonItem1} />
          <SkeletonItem className={styles.skeletonItem2} />
        </Skeleton>
      )
    case 'error':
      return <Text className={styles.grayColor}>N/A</Text>
    case 'done':
      return <ConfigTenantName tenantName={snapshot.data.tenantName} keyWords={keyWords} />
  }
}
