import { DecoratorNode, DOMExportOutput, EditorConfig, NodeKey } from 'lexical'
import { ImageNodeView } from './ImageNodeView'

type SerializedImageNode = {
  src: string
  alt: string
  type: 'image'
  version: 1
}

export class ImageNode extends DecoratorNode<JSX.Element> {
  __src: string
  __alt: string
  readonly _className = 'editor-img'

  static override getType() {
    return 'image'
  }

  static override clone(node: ImageNode) {
    return new ImageNode(node.__src, node.__alt, node.__key)
  }

  setSrc(src: string) {
    const writable = this.getWritable()
    writable.__src = src
  }

  constructor(src: string, alt: string, key?: NodeKey) {
    super(key)
    this.__src = src
    this.__alt = alt
  }

  override exportJSON(): SerializedImageNode {
    return {
      src: this.__src,
      alt: this.__alt,
      type: 'image',
      version: 1,
    }
  }

  static override importJSON(serializedNode: SerializedImageNode): ImageNode {
    const { src, alt } = serializedNode
    return new ImageNode(src, alt)
  }

  override createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement('span')
    const theme = config.theme
    const className = theme.image
    if (className !== undefined) {
      span.className = className
    }
    return span
  }

  override exportDOM(): DOMExportOutput {
    const element = document.createElement('img')
    element.setAttribute('src', this.__src)
    element.setAttribute('alt', this.__alt)
    element.setAttribute('class', this._className)
    return { element }
  }

  override updateDOM(prevNode: ImageNode, dom: HTMLElement) {
    if (dom instanceof HTMLImageElement) {
      if (prevNode.__src !== this.__src) {
        dom.src = this.__src
        return true
      }
    }
    return false
  }

  override decorate(): JSX.Element {
    return <ImageNodeView src={this.__src} alt={this.__alt} className={this._className} />
  }
}

export function $createImageNode(src: string, alt: string): ImageNode {
  return new ImageNode(src, alt)
}
