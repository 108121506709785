import { z } from 'zod'

export const apiConversationGroup4MetricSchema = z.record(z.unknown()).and(
  z.object({
    serviceName: z.string().optional(),
    scopeId: z.string().optional(),
    scopeName: z.string().optional(),
    startTime: z.string().optional(),
    status: z.string().optional(),
    input: z.unknown().optional(),
    output: z.unknown().optional(),
    path: z.string().optional(),
    latencyMilliseconds: z.number().optional(),
    decodedData: z.record(z.unknown()).optional(),
  }),
)

const apiConversationGroup4TelemetrySchema = z.record(z.unknown()).and(
  z.object({
    metrics: z.array(apiConversationGroup4MetricSchema).optional(),
  }),
)

const apiConversationGroup4RequestSchema = z.record(z.unknown()).and(
  z.object({
    telemetry: apiConversationGroup4TelemetrySchema.optional(),
  }),
)

const apiConversationGroup4DataSchema = z.record(z.unknown()).and(
  z.object({
    requests: z.array(apiConversationGroup4RequestSchema).optional(),
  }),
)

export const apiConversationGroup4Schema = z.record(z.unknown()).and(
  z.object({
    conversation: apiConversationGroup4DataSchema.optional(),
  }),
)
