import { Column } from '../../../../components/Layout'
import { IFCConversationMessageData } from '@copilot-dash/domain'
import { TurnView } from './TurnView'
import { makeStyles } from '@fluentui/react-components'
import { useEffect } from 'react'

interface IProps {
  readonly data: IFCConversationMessageData[]
}

export function ConversationLayoutForData({ data }: IProps) {
  const styles = useStyles()
  const allConvExceptLast = data.slice(0, data.length - 1)
  const lastConv = app.store.use().feedbackCopilot.lastFCConversation
  const userId = app.auth.activeAccount?.idTokenClaims?.oid ?? ''
  useEffect(() => {
    app.store.actions.updateUserReadFCRagResult(userId)
  }, [lastConv, userId])

  return (
    <Column className={styles.root} hAlign="stretch">
      {allConvExceptLast.map((item) => {
        return <TurnView key={item.messageId} isLast={false} data={item} />
      })}
      {lastConv && <TurnView isLast={true} />}
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    gap: '16px',
  },
})
