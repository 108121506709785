import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketTurnMetadata } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { compact, uniq } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicket3sTriggered } from '../actions-ticket-assert/assertTicket3sTriggered'
import { getTicketTurnMetadata } from '../actions-ticket-chat/getTicketTurnMetadata'
import { get3sGwsLogs } from '../actions-ticket-kusto/get3sGwsLogs'

export function get3sTransactionIds(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<string[]> {
  return context.getOrFetch<string[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.sssTransactionIds
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.sssTransactionIds = snapshot
    },
    fetch: async () => {
      const turn = await getTicketTurnMetadata(context, ticketId, messageId).promise
      const turnTransactionIds = await doFetch(turn)
      if (turnTransactionIds.length !== 0) {
        return turnTransactionIds
      }

      // Check if 3s is triggered
      await assertTicket3sTriggered(context, ticketId, messageId)

      // Check if the turn is the last turn
      if (!turn.isLastTurn) {
        throw TicketError.create('No3sOfflineDueToNotLatestTurn', { ticketId, ticketMessageId: messageId })
      }

      // Ops! No data found
      throw TicketError.create('No3S', { ticketId, ticketMessageId: messageId })
    },
  })

  async function doFetch(turn: ITicketTurnMetadata): Promise<string[]> {
    return uniq(
      compact([
        ...turn.onlineTransactionIds, //
        ...turn.offlineTransactionIds,
        ...(await doFetchFromGwsLogs()),
      ]),
    )
  }

  async function doFetchFromGwsLogs(): Promise<string[]> {
    try {
      const logs = await get3sGwsLogs(context, ticketId, messageId).promise
      return compact(logs.map((log) => log.transactionId))
    } catch (e) {
      return []
    }
  }
}
